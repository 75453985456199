import React from "react";
import "./index.scss";
import App from "./container";
import { Toaster } from "react-hot-toast";
import * as ReactDOMClient from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <>
    <Toaster toastOptions={{ position: "top-center", duration: 3000 }} />
    <App />
    <Toaster />
  </>
  // </React.StrictMode>
);

reportWebVitals();
