import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../layout/header";

const LayoutForSignin = () => {
  return (
    <>
      <Header />
      <div className="body-section">
        <Outlet />
      </div>
    </>
  );
};

export default LayoutForSignin;
