import {
  API_ALL_COMMUNITY,
  API_COMMUNITY_DETAILS,
  API_CREATE_COMMUNITY_RATING,
  API_GET_COMMUNITY_MEMBERS,
  API_JOIN_COMMUNITY,
  API_LEAVE_COMMUNITY,
  API_NOT_ASSO_COMMUNITY,
  API_UPDATE_COMMUNITY_RATING,
} from "../constants/api";
import {
  API,
  COMMUNITY_DETAILS_F,
  COMMUNITY_DETAILS_S,
  RENDER_ALL_COMMUNITY_F,
  RENDER_ALL_COMMUNITY_S,
  RENDER_NOT_ASSO_COMMUNITY_F,
  RENDER_NOT_ASSO_COMMUNITY_S,
} from "../constants/types";

export const renderCommunityList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ALL_COMMUNITY,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_ALL_COMMUNITY_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_ALL_COMMUNITY_F,
      payload: data,
    }),
  },
});

export const renderNotAssoCommunityList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_NOT_ASSO_COMMUNITY,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_NOT_ASSO_COMMUNITY_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_NOT_ASSO_COMMUNITY_F,
      payload: data,
    }),
  },
});

export const getCommunityDetails = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_COMMUNITY_DETAILS}${id}`,
    method: "GET",
    showLoader,
    success: (data) => ({
      type: COMMUNITY_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_DETAILS_F,
      payload: data,
    }),
  },
});

export const joinCommunityHandler = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_JOIN_COMMUNITY,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const leaveCommunityHandler = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_LEAVE_COMMUNITY}${id}`,
    method: "DELETE",
    showLoader,
  },
});

export const createCommunityRatingAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CREATE_COMMUNITY_RATING,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const updateCommunityRatingAction = (id, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_UPDATE_COMMUNITY_RATING}${id}`,
    method: "POST",
    data,
    showLoader,
  },
});

export const renderCommunityMemberListAction = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_GET_COMMUNITY_MEMBERS}${id}`,
    method: "GET",
    showLoader,
  },
});
