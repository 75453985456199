import { API_CMS_DETIALS } from "../constants/api";
import { API, CMS_DETAILS_F, CMS_DETAILS_S } from "../constants/types";

export const cmsDetailsAction = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CMS_DETIALS + id,
    method: "GET",
    showLoader,
    // data: data,
    success: (data) => ({
      type: CMS_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: CMS_DETAILS_F,
      payload: data,
    }),
  },
});
