import moment from "moment";
import { doLogout, Remembermereferesh } from "../actions/auth";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

export const setupAxios = (axios, store) => {
  // axios.interceptors.request.use(req => {
  //     const { auth: { user } } = store.getState();
  //     if (user && user?.data?.authToken) {
  //         req.headers["Authorization"] = `Bearer ${user?.data?.authToken}`;
  //     }

  //     return req;
  // }, err => Promise.reject(err));

  let isRefreshing = false;
  axios.interceptors.response.use(null, (err) => {
    if (err.response) {
      if (
        JSON.parse(localStorage.getItem("webUser"))?.data?.authToken &&
        err.response.status === 403 &&
        !isRefreshing
      ) {
        isRefreshing = true;
        store
          .dispatch(
            Remembermereferesh({
              token: JSON.parse(localStorage.getItem("webUser"))?.data
                ?.refreshToken,
            })
          )
          .then((res) => {
            isRefreshing = false;
            const newdata = {
              ...JSON.parse(localStorage.getItem("webUser")),
              data: {
                ...JSON.parse(localStorage.getItem("webUser")).data,
                authToken: res.data?.authToken,
              },
            };
            localStorage.setItem("webUser", JSON.stringify(newdata));
          })
          .catch((res) => {
            isRefreshing = false;
            // store.dispatch(doLogout())
          });
        return;
      } else if (
        !token?.data?.authToken &&
        err.response.status === 403 &&
        !isRefreshing
      ) {
        return Promise.reject(err);
      } else if (err.response.status === 401) {
        setTimeout(() => {
          const signinURL = `${window.location.origin}${process.env.PUBLIC_URL}/signin`;
          window.location.replace(signinURL);
          store.dispatch(doLogout());
        }, 1000);
      } else return Promise.reject(err);
    } else if (err.request) {
      return Promise.reject({
        response: {
          data: {
            message: "Something went wrong, Please try again later",
          },
        },
      });
    }
  });
};

export const removeMobileFomrmatter = (no, isRemoveCountryCode = false) => {
  if (!no) return "";
  if (isRemoveCountryCode) {
    if (no[1] === "9") {
      return no
        .replace("+91", "")
        .replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, "")
        .replaceAll(" ", "")
        .trim();
    }
    return no
      .replace("+1", "")
      .replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, "")
      .replaceAll(" ", "")
      .trim();
  } else
    return no
      .replace(/[&/\\#,()$~%.'":*?<>{}-]/g, "")
      .replaceAll(" ", "")
      .trim();
};

export const loadVideo = (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export const getVideoUrl = (
  baseurl,
  moduleName,
  fileName,
  isThumbnail = false
) => {
  if (!isThumbnail) return `${baseurl}${moduleName}/videos/${fileName}`;
  else return `${baseurl}${moduleName}/videos/thumbnail/${fileName}`;
};

export const isUserLoggedIn = () => {
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const userType = JSON.parse(localStorage.getItem("webUser"))?.data?.userType;
  return userId && userType !== 3 ? true : false;
};

export const scrolltoBottomFun = (wrapperId) => {
  let chatWrapper = document.getElementById(wrapperId);
  if (chatWrapper) {
    chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
  }
};
export const dateFormatChat = (date) => {
  if (date) return moment(date).format("DD/MM/YYYY hh:mm A");
  else return "-";
};

export const getImageUrl = (baseurl, moduleName, fileName) => {
  if (baseurl && fileName) return `${baseurl}${moduleName}/${fileName}`;
  else return ``;
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 700);
  };
};
