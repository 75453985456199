import React from "react";

function search() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.18182 17.3636C13.7005 17.3636 17.3636 13.7005 17.3636 9.18182C17.3636 4.66313 13.7005 1 9.18182 1C4.66313 1 1 4.66313 1 9.18182C1 13.7005 4.66313 17.3636 9.18182 17.3636Z"
          stroke="#1E1E1E"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M20.9997 20.9997L14.9688 14.9688"
          stroke="#1E1E1E"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default search;