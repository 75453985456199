/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import "./donationDetails.scss";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import {
  updateSubscriptionItemsAction,
  updateSubscriptionPercentageAction,
} from "../../actions/payment";
import { useNavigate, useParams } from "react-router-dom";
import SubscriptionItem from "../../components/common/SubscriptionItem";
import toast from "react-hot-toast";

const bread = [
  {
    name: "Donation Details",
    link: "",
    isActive: true,
  },
];

function DonationDetails() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const params = useParams();
  const navigate = useNavigate();
  const transactionId = params.id;

  const [itemList, setItemList] = useState([]);
  const [isDistributeEqually, setIsDistributeEqually] = useState(false);

  const getBasketDetails = async () => {
    dispatch(updateSubscriptionItemsAction(transactionId)).then((res) => {
      let charityCommunityList = [];

      const charityList = res.data.charity.map((item) => {
        return {
          ...item,
          isLocked: false,
          id: item?.charityId,
          allowedPercent: item?.paymentPercent,
          type: "charity",
        };
      });

      const communityList = res.data.community.map((item) => {
        return {
          ...item,
          isLocked: false,
          id: item?.communityId,
          allowedPercent: item?.paymentPercent,
          type: "community",
        };
      });

      charityCommunityList = [...charityList, ...communityList];
      setItemList(charityCommunityList);
    });
  };

  useEffect(() => {
    if (isDistributeEqually && itemList?.length) {
      let lockedItemCnt = 0;
      const lockedPer = itemList?.reduce((lockedPer, item) => {
        if (item?.isLocked === true) {
          lockedItemCnt++;
          return lockedPer + item.paymentPercent;
        }
        return lockedPer;
      }, 0);

      const equalPer = (100 - lockedPer) / (itemList?.length - lockedItemCnt);
      let extraPer = (100 - lockedPer) % (itemList?.length - lockedItemCnt);

      const payload = {
        subscriptionId: transactionId,
        basket: itemList?.map((item, ind) => {
          if (item?.isLocked === false && (equalPer || extraPer)) {
            let paymentPercent = Math.floor(equalPer);
            if (extraPer) {
              paymentPercent++;
              extraPer--;
            }
            return { ...item, paymentPercent };
          }
          return item;
        }),
      };
      setItemList(payload.basket);
    }
  }, [isDistributeEqually]);

  useEffect(() => {
    getBasketDetails();
  }, []);

  const distributeEquallyHandler = () => {
    setIsDistributeEqually((prev) => !prev);
  };

  const updatePercentageHandler = () => {
    const isAnyItemHasZeroPer = itemList?.reduce((acc, item) => {
      if (item.paymentPercent === 0) {
        acc = true;
      }
      return acc;
    }, false);

    if (isAnyItemHasZeroPer) {
      toast.error("Charity/Community can't has zero percentage");
      return;
    }

    const totalPer = itemList?.reduce(
      (acc, item) => acc + item.paymentPercent,
      0
    );

    if (totalPer < 100) {
      toast.error("Please set 100% percentage");
      return;
    }

    const payload = {
      subscriptionId: transactionId,
      basket: itemList,
    };

    dispatch(updateSubscriptionPercentageAction(payload)).then((res) => {
      toast.success(res.message);
      navigate(`/profile_details`, {
        state: { userID: userId, defaultIndex: 0 },
      });
    });
  };

  return (
    <>
      <section className="donationDetail">
        <div className="container">
          <div className="breadcrumbsDonationDetails">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="rosiBasketWrap">
            <div className="basketContent">
              <h3 className="basketTitle">Update Donation Details</h3>
              <div>
                <div className="basketContentWrap">
                  <div className="basketContentHeader">
                    <h3>Nonprofits/Communities</h3>
                    <h3>Percent Allocated</h3>
                  </div>
                  <div className="basketContentBody">
                    {itemList?.length ? (
                      <ScrollBar component="div">
                        <div className="basketScroll">
                          {itemList?.map((item) => (
                            <SubscriptionItem
                              key={item?._id}
                              item={item}
                              setBasketInfo={setItemList}
                              basketInfo={itemList}
                              basketId={itemList?._id}
                              getBasketDetails={getBasketDetails}
                              setIsDistributeEqually={setIsDistributeEqually}
                            />
                          ))}
                        </div>
                      </ScrollBar>
                    ) : (
                      <h4 className="notFound descPadding">
                        No Nonprofit/Community Found
                      </h4>
                    )}
                  </div>
                  {itemList?.length ? (
                    <div className="basketContentFooter">
                      <div className="summaryCheckBox">
                        <label className="check-label rememberReg">
                          <h4>Distribute Equally</h4>
                          <div>
                            <input
                              type="checkbox"
                              checked={isDistributeEqually}
                              onClick={() => distributeEquallyHandler()}
                            />
                            <span className="checkmark"></span>
                          </div>
                        </label>
                      </div>
                      <p>
                        If you want to distribute amount equally to all the
                        nonprofits and communities please click on this
                        checkbox.
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="donationButtonGrp">
                <button
                  onClick={() =>
                    navigate(`/profile_details`, {
                      state: { userID: userId, defaultIndex: 0 },
                    })
                  }
                  className="donationUpdated updatedGrp"
                >
                  Cancel
                </button>
                <button
                  className="donationCancel updatedGrp"
                  onClick={updatePercentageHandler}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DonationDetails;
