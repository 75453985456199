import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./registration.scss";
import Steps from "../../components/charityRegistrationSteps/steps.js";
import Steps1 from "../../components/charityRegistrationSteps/steps_1";
import Steps2 from "../../components/charityRegistrationSteps/steps_2";
import Steps3 from "../../components/charityRegistrationSteps/steps_3";
import Steps4 from "../../components/charityRegistrationSteps/steps_4";
import Steps5 from "../../components/charityRegistrationSteps/steps_5";
import { toAbsoluteUrl } from "../../utils";
import localforage from "localforage";
import ScrollBar from "react-perfect-scrollbar";

const CharityRegistration = () => {
  const [step, setSteps] = useState(0);
  const [isclass, setclass] = useState(false);
  const [iscompleted, setcompleted] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    localforage.getItem("curr_reg_step").then((res) => setSteps(res ? res : 0));
  }, []);

  const demos = [
    {
      title: "First",
      content: (
        <Steps1
          step={step}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <Steps2
          step={step}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
      ),
    },
    {
      title: "3",
      content: (
        <Steps3
          step={step}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
      ),
    },
    {
      title: "4",
      content: (
        <Steps4
          step={step}
          setclass={setclass}
          setcompleted={setcompleted}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
          setErrorMessages={setErrorMessages}
        />
      ),
    },
    {
      title: "5",
      content: (
        <Steps5
          step={step}
          setclass={setclass}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
        />
      ),
    },
  ];

  return (
    <>
      <section
        className={`d-flex flex-wrap w-100 align-items-center justify-content-center section-step ${
          isclass ? "header-section" : ""
        } `}
      >
        <div className="container-full w-100 ">
          <div className="row">
            <div className="col-6 col-section">
              <div className="header-link-box">
                <Link to="/" className="link-text">
                  <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" />
                </Link>
              </div>
            </div>
            {/* <ul className="errorProfile">
              {errorMessages && errorMessages?.map((item =>
                <li>
                  <h3>{item}</h3>
                  <br />
                </li>
              ))
              }
            </ul> */}
            <div className="col-12">
              <div className="charityHeader">
                {!iscompleted ? (
                  <>
                    <div className="headerRosi">
                      <Link to="/">
                        <img
                          src={toAbsoluteUrl("/images/logo.svg")}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <h2 className="charity-reg-header">
                      Nonprofit Registration Form
                    </h2>
                  </>
                ) : (
                  <div className="inner-div">
                    <h2>REVIEW REQUEST</h2>
                    <p>
                      Your request will be reviewed by the giving partner. You
                      can access the other features of the panel after the
                      approval.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <Steps
              step={step}
              setSteps={setSteps}
              setclass={setclass}
              demos={demos}
              completed={completedSteps}
            />

            <strong>© {new Date().getUTCFullYear()} Rosi Giving, Inc.</strong>
          </div>
        </div>
      </section>

      {errorMessages?.length > 0 && (
        <div className="registrationErrorModal">
          <ScrollBar component="div">
            <div className="registrationErrorModalWrap">
              <ul>
                {typeof errorMessages === "string" ? (
                  <li>{errorMessages}</li>
                ) : (
                  errorMessages?.map((err, ind) => {
                    return <li key={err}>{err}</li>;
                  })
                )}
              </ul>
            </div>
          </ScrollBar>
        </div>
      )}
    </>
  );
};

export default CharityRegistration;
