export const paymentFormValidations = (value, isNeedToValidateCard) => {
  let error = {};
  if (!value.manualAmount || !value.manualAmount.trim()) {
    error.manualAmount = "Please enter amount";
  } else if (Number(value.manualAmount.toString().replace(/,/g, "")) < 1) {
    error.manualAmount = "Amount should not be less than $1";
  } else if (!/^[0-9,.]+$/.test(value.manualAmount)) {
    error.manualAmount = "Please enter valid amount";
  }
  if (!value.streetName1 || !value.streetName1.trim()) {
    error.streetName1 = "Please enter address line 1";
  }

  if (!value.zip || !value.zip.trim()) {
    error.zip = "Please enter zipcode";
  } else if (value.zip.trim().length < 5) {
    error.zip = "Zipcode should be 5 digit";
  }

  if (isNeedToValidateCard === true) {
    if (!value.user || !value.user.trim()) {
      error.user = "Please enter card holder name";
    } else if (value.user && !/^[a-zA-Z ]*$/.test(value.user)) {
      error.user = "Only characters are allowed";
    }

    if (!value.cardNumber || !value.cardNumber.trim()) {
      error.cardNumber = "Please enter card number";
    }

    if (!value.expiry || !value.expiry.trim()) {
      error.expiry = "Please enter expiry date";
    } else if (value.expiry.trim().length < 7) {
      error.expiry = "Please enter valid expiry date";
    }

    if (!value.cvv || !value.cvv.trim()) {
      error.cvv = "Please enter cvv number";
    } else if (value.cvv.trim().length < 3 || value.cvv.trim().length > 4) {
      error.cvv = "Please enter valid cvv number";
    }
  }
  return error;
};

export const cardValidations = (value) => {
  let error = {};
  if (!value.user || !value.user.trim()) {
    error.user = "Please enter card holder name";
  } else if (value.user && !/^[a-zA-Z ]*$/.test(value.user)) {
    error.user = "Only characters are allowed";
  }

  if (!value.cardNumber || !value.cardNumber.trim()) {
    error.cardNumber = "Please enter card number";
  }

  if (!value.expiry || !value.expiry.trim()) {
    error.expiry = "Please enter expiry date";
  } else if (value.expiry.trim().length < 7) {
    error.expiry = "Please enter valid expiry date";
  }

  if (!value.cvv || !value.cvv.trim()) {
    error.cvv = "Please enter cvv number";
  } else if (value.cvv.trim().length < 3) {
    error.cvv = "Please enter valid cvv number";
  }
  return error;
};
