import React from "react";
import { Stepper, Step } from "react-form-stepper";
import "./steps.scss";

const Steps = ({ demos, step, setSteps, setclass, completed }) => {
  return (
    <>
      <div className="steps-main-div w-100">
        <Stepper className="stepper-box" activeStep={step + 1}>
          <Step
            className="step-btn"
            label="Registration"
            disabled={false}
            onClick={(e) => {
              setSteps(0);
              setclass(false);
            }}
          // disabled={completed[0] ? false : true}
          />
          <Step
            className="step-btn"
            label="Background Info"
            disabled={false}
            onClick={(e) => {
              setSteps(1);
              setclass(false);
            }}
          // disabled={completed[1] ? false : true}
          />
          <Step
            className="step-btn"
            label="Detailed Info"
            disabled={false}
            onClick={(e) => {
              setSteps(2);
              setclass(false);
            }}
          // disabled={completed[2] ? false : true}
          />
          <Step
            className="step-btn"
            label="Rating Info"
            disabled={false}
            onClick={(e) => {
              setSteps(3);
            }}
          // disabled={completed[3] ? false : true}
          />
        </Stepper>

        <div className="steps-content">{demos[step]?.content}</div>
      </div>
    </>
  );
};

export default Steps;
