import React, { useState } from "react";
import "./../SignIn/signin.scss";
import "./forgot.scss";
import Swiper from "../../components/common/Swiper";
import CardSignin from "../../components/common/Card_signin";
import { Link, useNavigate } from "react-router-dom";
import SwiperText from "../../components/common/SwiperText";
import { Form, Field } from "react-final-form";
import { renderTextInput } from "../../components/common/FormFields";
import { useDispatch } from "react-redux";
import { doForgotPass } from "../../actions/auth";
import { forgotPasswordValiations as validate } from "../../components/validations/signIn";
import Email from "../../assets/svg/email";

function Forgot_password() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const formSubmitHandler = (data) => {
    dispatch(doForgotPass(data))
      .then((res) => {
        navigate("/signin");
      })
      .catch((err) => setError(err.message));
  };
  return (
    <>
      <section className="signin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Forgot Password">
              <Form onSubmit={formSubmitHandler} validate={validate}>
                {({ handleSubmit }) => (
                  <>
                    <form className="form" onSubmit={handleSubmit}>
                      <ul>
                        <li className="main-input">
                          <Field
                            name="email"
                            type="text"
                            component={renderTextInput}
                            placeholder="Email Address"
                            containerClasses="form-group"
                          >
                            <Email />
                          </Field>
                        </li>
                        {/* <li>
                          <div className="forgot"> */}
                        {/* <div className="remember">
                              <input
                                className="check"
                                type="checkbox"
                                id="rem"
                                checked={isRemembered}
                                onClick={() => setIsRemembered((prev) => !prev)}
                              />
                              <label htmlFor="rem">Remember Me</label>
                            </div> */}
                        {/* </div>
                        </li> */}
                        {error && (
                          <h4
                            className="verification_error"
                            style={{ margin: "10px" }}
                          >
                            {error}
                          </h4>
                        )}
                        <li className="submit sign-btn">
                          <button className="buttonSubmit">Reset</button>
                        </li>
                        <li className="login-forgot">
                          <Link to="/signin">Back to Login</Link>
                        </li>
                      </ul>
                    </form>
                  </>
                )}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default Forgot_password;
