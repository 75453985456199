import { API_CLEARE_CHAT_HISTORY } from "../constants/api";
import { API } from "../constants/types";

export const clearechatHistoryAction = (id) => ({
  type: API,
  payload: {
    url: API_CLEARE_CHAT_HISTORY + id,
    method: "DELETE",
  },
});
