import React from "react";

function report() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 8V13C19 15.8284 19 17.2426 18.1213 18.1213C17.2426 19 15.8284 19 13 19H7C4.17157 19 2.75736 19 1.87868 18.1213C1 17.2426 1 15.8284 1 13V8C1 8.93188 1 9.39782 1.15224 9.76537C1.35523 10.2554 1.74458 10.6448 2.23463 10.8478C2.60218 11 3.06812 11 4 11H4.67544C5.25646 11 5.54696 11 5.77888 11.1338C5.83745 11.1675 5.89245 11.2072 5.94303 11.2521C6.14326 11.4298 6.23513 11.7054 6.41886 12.2566L6.54415 12.6325C6.76416 13.2925 6.87416 13.6225 7.13605 13.8112C7.39794 14 7.7458 14 8.44152 14H11.5585C12.2542 14 12.6021 14 12.864 13.8112C13.1258 13.6225 13.2358 13.2925 13.4558 12.6325L13.5811 12.2566L13.5811 12.2566L13.5811 12.2566C13.7649 11.7054 13.8567 11.4298 14.057 11.2521C14.1075 11.2072 14.1625 11.1675 14.2211 11.1338C14.453 11 14.7435 11 15.3246 11H16C16.9319 11 17.3978 11 17.7654 10.8478C18.2554 10.6448 18.6448 10.2554 18.8478 9.76537C19 9.39782 19 8.93188 19 8Z"
          fill="white"
        />
        <path
          d="M14 3H15C16.8856 3 17.8284 3 18.4142 3.58579C19 4.17157 19 5.11438 19 7V15C19 16.8856 19 17.8284 18.4142 18.4142C17.8284 19 16.8856 19 15 19H5C3.11438 19 2.17157 19 1.58579 18.4142C1 17.8284 1 16.8856 1 15V7C1 5.11438 1 4.17157 1.58579 3.58579C2.17157 3 3.11438 3 5 3H6"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M6 7L10 10M10 10L14 7M10 10L10 1.74846e-07"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </>
  );
}

export default report;
