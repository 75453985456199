import { removeMobileFomrmatter } from "../../utils";

export const createAccount = (value) => {
  let error = {};

  if (!value.firstName || !value.firstName.trim()) {
    error.firstName = "Please enter first name";
  } else if (value.firstName && !/^[A-Za-z]+$/.test(value.firstName)) {
    error.firstName = "Please enter valid name";
  }

  if (!value.lastName || !value.lastName.trim()) {
    error.lastName = "Please enter last name";
  } else if (value.lastName && !/^[A-Za-z]+$/.test(value.lastName)) {
    error.lastName = "Please enter valid name";
  }

  if (!value.phoneNumber || !value.phoneNumber.trim()) {
    error.phoneNumber = "Please enter phone number";
  } else if (removeMobileFomrmatter(value.phoneNumber, true).length !== 10) {
    error.phoneNumber = "Should not be less than 10 digits";
  }
  if (!value.email) {
    error.email = "Please enter email";
  }
  if (
    value.email &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address";
  }

  if (!value.password) {
    error.password = "Please enter password";
  } else if (
    value.password &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.password
    )
  ) {
    error.password =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }

  if (!value.confirmPassword) {
    error.confirmPassword = "Please enter confirm password";
  } else if (
    value.confirmPassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.confirmPassword
    )
  ) {
    error.confirmPassword =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  } else if (value.password !== value.confirmPassword) {
    error.confirmPassword = "Confirm password must match with password";
  }

  return error;
};

export const createGuestUserAccount = (value) => {
  let error = {};

  if (!value.firstName || !value.firstName.trim()) {
    error.firstName = "Please enter first name";
  } else if (value.firstName && !/^[A-Za-z]+$/.test(value.firstName)) {
    error.firstName = "Please enter valid name";
  }

  if (!value.lastName || !value.lastName.trim()) {
    error.lastName = "Please enter last name";
  } else if (value.lastName && !/^[A-Za-z]+$/.test(value.lastName)) {
    error.lastName = "Please enter valid name";
  }

  if (!value.phoneNumber || !value.phoneNumber.trim()) {
    error.phoneNumber = "Please enter phone number";
  } else if (
    value.phoneNumber &&
    removeMobileFomrmatter(value.phoneNumber, true).length !== 10
  ) {
    error.phoneNumber = "Should not be less than 10 digits";
  }
  if (!value.email) {
    error.email = "Please enter email";
  }
  if (
    value.email &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address";
  }
  return error;
};

export const login = (value) => {
  let error = {};

  if (!value.email) {
    error.email = "Email is required";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }
  if (!value.password || !value.password.trim()) {
    error.password = "Password is required";
  }
  return error;
};

export const forgotPasswordValiations = (value) => {
  let error = {};

  if (!value.email) {
    error.email = "Email is required";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }
  return error;
};

export const resetPasswordValidation = (value) => {
  let error = {};
  if (!value.password) {
    error.password = "New password is required";
  } else if (
    value.password &&
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?()_&])[A-Za-z\d@$!%*?()_&]{8,20}$/.test(
      value.password
    )
  ) {
    error.password =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }
  if (!value.Confirmpassword) {
    error.Confirmpassword = "Confirm password is required";
  } else if (
    value.Confirmpassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.Confirmpassword
    )
  ) {
    error.Confirmpassword =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  } else if (value.password !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm password must match with new password";
  }
  return error;
};

export const changePasswordValidation = (value) => {
  let error = {};
  if (!value.currPassword) {
    error.currPassword = "Current password is required";
  }

  if (!value.newPassword) {
    error.newPassword = "New password is required";
  } else if (
    value.newPassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.newPassword
    )
  ) {
    error.newPassword =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }

  if (!value.confirmPassword) {
    error.confirmPassword = "Confirm password is required";
  } else if (
    value.confirmPassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.confirmPassword
    )
  ) {
    error.confirmPassword =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  } else if (value.newPassword !== value.confirmPassword) {
    error.confirmPassword = "Confirm password must match with new password";
  }

  return error;
};

export const contactUsFormValiations = (value) => {
  let error = {};

  if (!value.name || !value.name.trim()) {
    error.name = "Please Enter Name";
  }

  if (!value.email) {
    error.email = "Please Enter Email Address";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email Address.";
  }

  if (!value.message || !value.message.trim()) {
    error.message = "Please Enter Message ";
  }
  return error;
};
