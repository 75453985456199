import React from "react";
import Lightroom from "react-lightbox-gallery";
import "./image.scss";

function Image({ mediaImage, from }) {
  let mediaImgs = [];
  if (from === "community") {
    mediaImgs = mediaImage?.map((img) => {
      return { src: img.url };
    });
  } else
    mediaImgs = mediaImage?.map((img) => {
      return { src: img?.url };
    });

  let settings = {
    columnCount: {
      default: 2,
      mobile: 3,
      tab: 4,
    },
    mode: "dark",
  };

  return (
    <>
      <div className="imageGallery">
        {mediaImgs?.length > 0 ? (
          <Lightroom
            images={mediaImgs}
            className="imageBorder"
            settings={settings}
          />
        ) : (
          <h4 className="notFound mt-10 mb-10 d-inlineBlock">
            No media image found
          </h4>
        )}
      </div>
    </>
  );
}

export default Image;
