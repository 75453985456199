import React from "react";

function EmployeeId() {
  return (
    <>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="12"
          rx="2"
          stroke="#4F4F4F"
          fill="none"
        />
        <path d="M11 4L17 4" stroke="#4F4F4F" strokeLinecap="round" />
        <path d="M11 6H17" stroke="#4F4F4F" strokeLinecap="round" />
        <path d="M11 8H14" stroke="#4F4F4F" strokeLinecap="round" />
        <path
          d="M3.11293 9.85295C3.30285 9.32137 3.72133 8.85164 4.30348 8.51662C4.88562 8.18159 5.5989 8 6.33268 8C7.06646 8 7.77974 8.18159 8.36189 8.51662C8.94403 8.85164 9.36252 9.32137 9.55243 9.85295"
          stroke="#4F4F4F"
          strokeLinecap="round"
          fill="none"
        />
        <ellipse
          rx="1.66667"
          ry="1.66667"
          transform="matrix(-1 0 0 1 6.33236 4.66667)"
          stroke="#4F4F4F"
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </>
  );
}

export default EmployeeId;
