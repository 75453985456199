import React from "react";
import ScrollBar from "react-perfect-scrollbar";
import EventCard from "../../EventCard";

const StrategicEvents = (props) => {
  const { eventList } = props;
  return (
    <>
      <ScrollBar component="div">
        <div className="content">
          <div className="upcomingEvent">
            {eventList?.length ? (
              eventList?.map((event, ind) => (
                <EventCard
                  event={event}
                  key={event?._id}
                  isEvent={true}
                  classname="upcomingButton"
                  from="upcoming"
                  comingFrom="profile"
                />
              ))
            ) : (
              <h4 className="notFound mb-10">No Events Found</h4>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
};

export default StrategicEvents;
