import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderAboutPageDetails } from "../../actions/about";
import AboutContent from "../../components/common/aboutContent";
import AboutSec from "../../components/common/About_sec";
import BannerSec from "../../components/common/Banner_sec";
import LeadershipCard from "../../components/common/LeadershipCard";
import "./about.scss";

function About() {
  const dispatch = useDispatch();
  const { aboutPageDetails } = useSelector((state) => state.about);
  const { mediaURL } = useSelector((state) => state.metaReducer);

  useEffect(() => {
    dispatch(renderAboutPageDetails(true));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* banner */}
      <section className="about-banner">
        <div className="cotainer-main">
          <p>About Us</p>
        </div>
      </section>

      {/* about rosi */}
      <section className="aboutRosi">
        <div className="container-main">
          <AboutContent
            className="aboutSec"
            contentImage="/images/content-1.png"
            image={aboutPageDetails?.aboutUsList?.image1}
            contentHeading="ABOUT ROSI"
            contentTitle={aboutPageDetails?.aboutUsList?.title}
            contentDescription={
              aboutPageDetails?.aboutUsList?.titleDescription1
            }
            from="about"
          />
          <div className="rosiTogether">
            <div className="togetherImage">
              <figure className="mainImage imageBorder">
                <img
                  src={`${mediaURL}aboutLogo/${aboutPageDetails?.aboutUsList?.image2}`}
                  alt="contentf_image"
                />
              </figure>
            </div>
            <h3>{aboutPageDetails?.aboutUsList?.title2}</h3>
            <div className="togetherText">
              {aboutPageDetails?.aboutUsList?.titleDescription2
                ?.split("\n")
                .map(function (item, idx) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      {/* rosi name */}
      <section className="rosiName">
        <div className="container-main">
          <AboutSec
            SectionTitle="The Rosi Name"
            // SectionHeading={[
            //   "How Important Is Gene",
            //   <span key={1}>rosi</span>,
            //   "ty To Us?",
            // ]}
            SectionHeading={[
              "GENE",
              <span key={1}>ROSI</span>,
              "TY IS THE NAME OF THE GAME",
            ]}
            SectionTitle1="So Much So That It’s In Our Name."
            SectionPara="Rosi isn’t just a catchy name; it’s a tribute to all of the generous do-gooders who selflessly give their time, talent and treasure that breathes life into the charitable organizations that make the world a better place.  Whether you’re a frequent donor or occasional volunteer, your actions are driven by the very word that inspired us… generosity"
          />
        </div>
      </section>

      {/* section content */}
      <section className="aboutContent">
        <div className="container-main">
          {aboutPageDetails?.aboutUsList?.whyWeMatter?.map((data, id) => {
            return (
              <AboutContent
                key={data?._id}
                className={`aboutDesc${id % 2 === 1 ? "s" : ""}`}
                contentHeading="WHY WE MATTER"
                contentTitle={data?.title}
                contentDescription={data?.description}
                image={data?.image}
                from="about"
              />
            );
          })}
        </div>
      </section>

      {/* leadership */}
      <section className="leadership">
        <div className="container-main">
          <div className="leadership-wrap">
            <h3>Leadership</h3>
            <h2>Meet the Leaders of the Rosi Giving Platform</h2>
            <LeadershipCard leaderDetails={aboutPageDetails?.leaderShipList} />
          </div>
        </div>
      </section>

      {/* about donate */}
      <BannerSec
        BannerHeading="Meaningful Giving Made Easy"
        className="aboutDonate"
      >
        {/* <Link className="donateAbout" to="/charity">
          Donate Now
        </Link> */}
      </BannerSec>
    </>
  );
}

export default About;
