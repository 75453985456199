import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { renderField } from "../../components/forms";
import { ADD_CHARITY_DETAILS_STEPS_1 } from "../../constants/types";
import { registrationstep1 as validate } from "../validations/charityRegister";
import "../../pages/CharityRegistration/registration.scss";
import localforage from "localforage";
import User from "../../assets/svg/User";
import Email from "../../assets/svg/email";
import Lock from "../../assets/svg/lock";
import EmployeeName from "../../assets/svg/employeeName";
import Tookit from "../svg/tookit";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Steps1 = ({ setSteps, step, completedSteps, setCompletedSteps }) => {
  const dispatch = useDispatch();
  const [step1Data, setStep1Data] = useState("");

  useEffect(() => {
    localforage.getItem("charity_reg_step_1").then((res) => setStep1Data(res));
  }, []);

  const onSubmit = (value) => {
    localforage.setItem("curr_reg_step", 0);
    const data = {
      userName: value.UserName,
      charityName: value.CharityName,
      charityPersonName: value.CharityPersonName,
      charityEmailAddress: value.CharityEmailAddress,
      password: value.Password,
      confirmPassword: value.ConfirmPassword,
    };
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_1,
      payload: data,
    });
    const newCompleted = completedSteps;
    newCompleted[0] = true;
    setCompletedSteps(newCompleted);
    setSteps(1);
  };

  return (
    <>
      <div className="section-steps">
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 col-box">
            <Form
              onSubmit={onSubmit}
              const
              initialValues={{
                UserName: step1Data?.userName || "",
                CharityName: step1Data?.charityName || "",
                CharityPersonName: step1Data?.charityPersonName || "",
                CharityEmailAddress: step1Data?.charityEmailAddress || "",
                Password: step1Data?.password || "",
                ConfirmPassword: step1Data?.confirmPassword || "",
              }}
              validate={validate}
            >
              {({ handleSubmit, form }) => (
                <form
                  id="form-step0"
                  onSubmit={handleSubmit}
                  className="form-box"
                >
                  {/* <h2>Rosi Giving</h2> */}
                  <ul className="input-list stepForm">
                    <li>
                      <span className="tooltip-information" data-tooltip-id="username"><Tookit /></span>
                      <ReactTooltip
                        // style={{ width: '500px' }}
                        id="username"
                        place="top"
                        variant="success"
                        content="Only letters can be used (no number, spaces or special characters), and the Username should incorporate an abbreviation of the name of your Nonprofit organization."
                      />
                      <Field
                        name="UserName"
                        type="text"
                        placeholder="Nonprofit Username"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <User />
                      </Field>
                    </li>
                    <li>
                      <span className="tooltip-information" data-tooltip-id="charityName"><Tookit /></span>
                      <ReactTooltip
                        id="charityName"
                        place="top"
                        variant="success"
                        content="This must be the full name of the legal entity of your Nonprofit (no abbreviations, fictitious or DBA names). You should include the form of the nonprofit (such as Inc. Corp. LLC, etc.) in the legal name."
                      />
                      <Field
                        name="CharityName"
                        type="text"
                        placeholder="Name of Nonprofit"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <EmployeeName />
                      </Field>
                    </li>
                    <li>
                      <span className="tooltip-information" data-tooltip-id="representiveName"><Tookit /></span>
                      <ReactTooltip
                        id="representiveName"
                        place="top"
                        variant="success"
                        content="This should be the name of the individual who will be administering the Nonprofit’s Rosi account, communities, events, communications/posts and have access to the Nonprofits dashboard."
                      />
                      <Field
                        name="CharityPersonName"
                        type="text"
                        placeholder="Name of Nonprofit Representative"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <User />
                      </Field>
                    </li>
                    <li>
                      <span className="tooltip-information" data-tooltip-id="email"><Tookit /></span>
                      <ReactTooltip
                        id="email"
                        place="top"
                        variant="success"
                        content="This should be the email address of the Nonprofit Representative or a general email address of your Nonprofit that the Nonprofit Representative has access to. All emails from Rosi will be sent to this email address. It must be used to login to the Nonprofit’s Rosi account. NOTE: If the Nonprofit Representative intends to sign-up up for an individual Rosi account, they will not be able to use the same address."
                      />
                      <Field
                        name="CharityEmailAddress"
                        type="text"
                        placeholder="Nonprofit Representative Email address"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <Email />
                      </Field>
                    </li>
                    <li>
                      <span className="tooltip-information" data-tooltip-id="password"><Tookit /></span>
                      <ReactTooltip
                        id="password"
                        place="top"
                        variant="success"
                        content="These must be matching entries and consist of:

                        • Eight (8) Characters
                        
                        • One (1) Capital letter
                        
                        • One (1) Lower case letter
                        
                        • One (1) Number
                        
                        • One (1) Special Character"
                      />
                      <Field
                        name="Password"
                        type="password"
                        placeholder="Password"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <Lock />
                      </Field>
                    </li>
                    <li>
                      <span className="tooltip-information" data-tooltip-id="confirmPassword"><Tookit /></span>
                      <ReactTooltip
                        id="confirmPassword"
                        place="top"
                        variant="success"
                        content="These must be matching entries and consist of:

                        • Eight (8) Characters
                        
                        • One (1) Capital letter
                        
                        • One (1) Lower case letter
                        
                        • One (1) Number
                        
                        • One (1) Special Character"
                      />
                      <Field
                        name="ConfirmPassword"
                        type="Password"
                        placeholder="Confirm Password"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      >
                        <Lock />
                      </Field>
                    </li>
                    <li>
                      <div className="button-box">
                        <button type="submit" className="link-btn">
                          next step
                        </button>
                      </div>
                    </li>
                    <li>
                      {/* <div className="d-flex align-items-center justify-content-between inner-div registerCheck">
                        <label className="check-label rememberReg">
                          <span>Remember me</span>
                          <div>
                            <input
                              type="checkbox"
                              checked={isRemembered}
                              onClick={() => setIsRemembered((prev) => !prev)}
                            />
                            <span className="checkmark"></span>
                          </div>
                        </label>
                      </div> */}
                    </li>
                  </ul>
                  <div></div>

                  <span className="bottom-text">
                    {/* Already Have an account?{" "} */}
                    <a
                      href={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signin`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* Login */}
                      Click here if already a Nonprofit User
                    </a>
                  </span>

                  <span className="bottom-text">
                    <a
                      href={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signup`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* Register as Giving Partner */}
                      Click here to Register as Giving Partner
                    </a>
                  </span>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps1;
