import {
  API_ADD_CARD,
  API_ALL_TRANSACTION_PDF,
  API_CANCEL_SUBSCRIPTION,
  API_DELETE_CARD,
  API_LIST_OF_ALL_CARD,
  API_PAYMENT_WITH_SAVED_CARD,
  API_PAYMENT_WITH_UNSAVED_CARD,
  API_SUBSCRIPTION_DETAILS,
  API_TRANSECTION_LIST,
  API_TRANSECTION_RECIEPT,
  API_UPDATE_SUBSCRIPTION,
  API_UPDATE_SUBSCRIPTION_ITEMS,
  API_UPDATE_SUBSCRIPTION_PERCENTAGE,
} from "../constants/api";
import { API } from "../constants/types";

export const makePaymentWithUnsavedCardAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_PAYMENT_WITH_UNSAVED_CARD,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const makePaymentWithSavedCardAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_PAYMENT_WITH_SAVED_CARD,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const getAllCardListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_LIST_OF_ALL_CARD,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const addCardAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ADD_CARD,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const deleteCardAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_DELETE_CARD,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const renderTransationListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_TRANSECTION_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const cancelSubscriptionAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CANCEL_SUBSCRIPTION,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const subscriptionDetailAction = (
  subcriptionId,
  showLoader = false
) => ({
  type: API,
  payload: {
    url: `${API_SUBSCRIPTION_DETAILS}${subcriptionId}`,
    method: "GET",
    showLoader,
  },
});

export const getTransactionRecieptAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_TRANSECTION_RECIEPT,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const getAllTransactionPDFAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ALL_TRANSACTION_PDF,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const updateSubscriptionAction = (data, id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_UPDATE_SUBSCRIPTION}${id}`,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const updateSubscriptionItemsAction = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_UPDATE_SUBSCRIPTION_ITEMS}${id}`,
    method: "GET",
    showLoader,
  },
});

export const updateSubscriptionPercentageAction = (
  data,
  showLoader = false
) => ({
  type: API,
  payload: {
    url: `${API_UPDATE_SUBSCRIPTION_PERCENTAGE}`,
    method: "POST",
    data: data,
    showLoader,
  },
});
