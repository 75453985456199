/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./profileDetails.scss";
import { Form, Field } from "react-final-form";
import {
  renderTextInput,
  renderNumberInput,
  renderDate,
  EditSelectField,
  EditCityField,
} from "../../../../components/common/FormFields";
import Location from "../../../../assets/svg/location";
import "react-datepicker/dist/react-datepicker.css";
import User from "../../../../assets/svg/User";
import Email from "../../../../assets/svg/email";
import Mobile from "../../../../assets/svg/Mobile";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails, doVerifyEmail } from "../../../../actions/user";
import { firstTimeUserDetailValidation as validate } from "../../../../components/validations/user";
import { addTOCity, addTOState } from "../../../../actions/charityRegister";
import { renderNumberField, renderSelectField } from "../../../../components/forms";
import EmployeeId from "../../../../assets/svg/EmployeeId";
import EmployeeName from "../../../../assets/svg/employeeName";
import toast from "react-hot-toast";


function ProfileDetails({ id, setErrorMessages, setOtherDetails }) {
  const { userDetails } = useSelector((state) => state.user);

  const { countryList, stateList, cityList } = useSelector(
    (state) => state.metaReducer
  );
  const dispatch = useDispatch();
  const formRef = useRef();

  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",
    phone_code: "1",
  });
  const [selectedState, setselectedState] = useState();
  const [selectedCity, setselectedCity] = useState("");
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const fetchState = (data) => {
    dispatch(addTOState(data));
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data));
  };

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    let initialState = [];
    if (stateList?.length) {
      initialState = stateList.filter(
        (val) => val.label === userDetails?.addressObj?.state
      );
      if (initialState?.length) {
        setselectedState(initialState[0]);
        fetchCity({ stateId: initialState[0]?.value });
      }
    }
    // eslint-disable-next-line
  }, [userDetails, stateList]);

  useEffect(() => {
    let initialState = [];
    if (cityList?.length) {
      initialState = cityList.filter(
        (val) => val.label === userDetails?.addressObj?.city
      );
      if (initialState?.length) {
        setselectedCity(initialState[0]);
      } else {
        setselectedCity({ label: userDetails?.addressObj?.city, value: userDetails?.addressObj?.city })
      }
    }
  }, [userDetails, cityList]);

  const gender = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
    {
      value: 3,
      label: "Gender Variant/Non-Conforming",
    },
    {
      value: 4,
      label: "Prefer Not To Answer",
    },
  ];

  const onFormSubmit = (value) => {


    const genderEnume = () => {
      switch (value.gender.label) {
        case "Female":
          return 2;
        case "Male":
          return 1;
        case "Gender Variant/Non-Conforming":
          return 3;
        default:
          return 4;
      }
    };
    const data = {
      ...value,
      employeeCode: value?.employeeCode?.trim() ?? "",
      secondaryPhone: value?.secondaryPhone ? value?.secondaryPhone : "",
      // goal,
      userId: id,
      // gender: value.gender === "Female" ? 2 : 1,
      gender: gender[genderEnume() - 1].value,
      gamifyingBadgeId: "2143",
      // profileImage: profileImage,
      address: {
        streetName1: value?.streetName1,
        streetName2: value?.streetName2,
        city: selectedCity?.label || "",
        state: selectedState?.label || "",
        country: selectedCountry?.label || "",
        zipcode: value?.zipcode,
      },
    };
    delete data.givingPartnerName;
    dispatch(updateUserDetails(data))
      .then(() => {
        toast.success("User details save successfully.");
        setErrorMessages("");
        setIsEmailChanged(false);
      })
      .catch((err) => {
        setErrorMessages(err.message);
      });
  };

  const companyAddress = () => {
    return `${userDetails?.strategicPartnerAddressObj?.streetName
      ? userDetails?.strategicPartnerAddressObj?.streetName
      : ""
      }${userDetails?.strategicPartnerAddressObj?.streetName ? "," : ""} ${userDetails?.strategicPartnerAddressObj?.city
        ? userDetails?.strategicPartnerAddressObj?.city
        : ""
      }${userDetails?.strategicPartnerAddressObj?.city ? "," : ""} ${userDetails?.strategicPartnerAddressObj?.state
        ? userDetails?.strategicPartnerAddressObj?.state
        : ""
      }${userDetails?.strategicPartnerAddressObj?.state ? "," : ""} ${userDetails?.strategicPartnerAddressObj?.zipcode
        ? userDetails?.strategicPartnerAddressObj?.zipcode
        : ""
      }`;
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.initialize({
        firstName: userDetails?.firstName || "",
        middleName: userDetails?.middleName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        phoneNumber: userDetails?.phoneNumber || "",
        secondaryEmail: userDetails?.secondaryEmail || "",
        secondaryPhone: userDetails?.secondaryPhone || "",
        streetName1: userDetails?.addressObj?.streetName1 || "",
        streetName2: userDetails?.addressObj?.streetName2 || "",
        city: userDetails?.addressObj?.city || "",
        state: userDetails?.addressObj?.state || "",
        zipcode: userDetails?.addressObj?.zipcode || "",
        country: userDetails?.addressObj?.country || "",
        gender: userDetails?.gender && gender[userDetails?.gender - 1],
        birthDate: userDetails?.birthDate
          ? new Date(userDetails?.birthDate)
          : "",
        employeeCode: userDetails?.employeeCode?.toString(),
        givingPartnerName: userDetails?.givingPartnerName,
        workEmail: userDetails?.workEmail,
        employeeAddress: userDetails?.strategicPartnerAddressObj
          ? companyAddress()
          : "",
      });
    }
  }, [userDetails]);

  const verifyEmailHandler = (e) => {
    if (userDetails?.isEmailVerify) {
      e.preventDefault();
    } else
      dispatch(doVerifyEmail(id)).then((res) => toast.success(res.message));
  };

  const userDetailsJSX = () => (
    <div className="heightContent">
      <div className="personalContent">
        <h3>personal Details</h3>
        <div className="personalForm">
          <ul>
            <li className="name">
              <Field
                name="firstName"
                type="text"
                component={renderTextInput}
                placeholder="First Name*"
                containerClasses="form-group"
                extraClass="requiredField"
              >
                <User />
              </Field>
              <Field
                name="middleName"
                type="text"
                component={renderTextInput}
                placeholder="Middle Name"
                containerClasses="form-group"
              >
                <User />
              </Field>
              <Field
                name="lastName"
                type="text"
                component={renderTextInput}
                placeholder="Last Name*"
                containerClasses="form-group"
                extraClass="requiredField"
              >
                <User />
              </Field>
            </li>
            <li className="email">
              <Field
                name="email"
                type="text"
                component={renderTextInput}
                // disabled={userDetails?.isEmailVerify}
                setIsEmailChanged={setIsEmailChanged}
                placeholder="Email*"
                containerClasses="form-email form-group"
                extraClass="requiredField"
              >
                {!isEmailChanged ? (
                  <h5
                    className={
                      userDetails?.isEmailVerify ? "verified" : "verify"
                    }
                    onClick={verifyEmailHandler}
                  >
                    <button onClick={(e) => e.preventDefault()}>
                      {userDetails?.isEmailVerify ? "Verified" : "Verify"}
                    </button>
                  </h5>
                ) : (
                  <></>
                )}
                <Email />
              </Field>
              <Field
                name="secondaryEmail"
                type="text"
                component={renderTextInput}
                placeholder="Secondary Email Address"
                containerClasses="form-group"
              >
                <Email />
              </Field>
            </li>
            <li className="mobile">
              <Field
                name="phoneNumber"
                disabled={true}
                component={renderNumberInput}
                placeholder="Phone Number"
                containerClasses="form-group"
              >
                <Mobile />
              </Field>
              <Field
                name="secondaryPhone"
                component={renderNumberInput}
                placeholder="Secondary Phone Number "
                containerClasses="form-group"
              >
                <Mobile />
              </Field>
            </li>
            <li className="gender">
              <Field
                name="gender"
                options={gender}
                placeholder="Gender*"
                component={renderSelectField}
                extraClass="requiredField"
                className="basic-multi-select"
                isMulti={false}
              />
              <Field
                name="birthDate"
                component={renderDate}
                containerClasses="form-group"
                extraClass="requiredField"
                placeholder="Birthdate*"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="locationContent">
        <h3>Location Details</h3>
        <div className="locationForm">
          <ul>
            <li className="street">
              <Field
                name="streetName1"
                type="text"
                component={renderTextInput}
                placeholder="Address Line 1"
                containerClasses="form-group"
              ></Field>
            </li>
            <li className="street">
              <Field
                name="streetName2"
                type="text"
                component={renderTextInput}
                placeholder="Address Line 2"
                containerClasses="form-group"
              ></Field>
            </li>
            <li className="form-middle">
              <EditSelectField
                placeholder="State"
                isStateField={true}
                data={selectedState}
                setselectedCity={setselectedCity}
                setselectedState={setselectedState}
                className="form-grp col-grp formGrp dropdownGrp"
                inputclass="input-box slectStep"
                options={stateList}
                isMulti={false}
                style={{ border: "none" }}
              />
              <EditCityField
                placeholder="City"
                data={selectedCity}
                setselectedCity={setselectedCity}
                className="form-grp col-grp formGrp dropdownGrp"
                inputclass="input-box"
                options={cityList}
                isMulti={false}
                style={{ border: "none" }}
              />
            </li>
            <li className="form-last">
              <Field
                name="zipcode"
                type="number"
                component={renderTextInput}
                placeholder="Zip Code*"
                containerClasses="form-group"
                extraClass="requiredField"
              >
                <Location />
              </Field>
              <EditSelectField
                name="country"
                placeholder="Country"
                isCountryField={true}
                data={selectedCountry}
                setselectedCountry={setselectedCountry}
                className="form-grp col-grp formGrp disabledLast"
                inputclass="input-box slectStep"
                options={countryList}
                disabled={true}
                isMulti={false}
                style={{ border: "none" }}
              />
            </li>
          </ul>
        </div>
      </div>
      {userDetails.userType === 2 && (
        <div className="WorkDetails">
          <h3>Giving Partner Details</h3>
          <div className="workForm">
            <ul>
              <li>
                <Field
                  name="givingPartnerName"
                  type="text"
                  disabled={true}
                  component={renderTextInput}
                  placeholder="Giving Partner Name"
                  containerClasses="form-email form-group"
                  className="disabledLast"
                >
                  <User />
                </Field>
                <Field
                  name="workEmail"
                  type="text"
                  disabled={true}
                  component={renderTextInput}
                  placeholder="Enter Your Work Email Here"
                  containerClasses="form-email form-group"
                  className="disabledLast"
                >
                  <Email />
                </Field>
              </li>
              <li>
                <Field
                  name="employeeCode"
                  // type="text"
                  component={renderNumberField}
                  formatStyle={"##########"}
                  placeholder="Employee Code"
                  extraClass='employeCodeInp'
                >
                  <EmployeeId />
                </Field>
                <Field
                  name="employeeAddress"
                  type="text"
                  disabled={true}
                  component={renderTextInput}
                  placeholder="Enter Your Address"
                  containerClasses="form-group"
                  extraClass="requiredField"
                >
                  <EmployeeName />
                </Field>
              </li>
            </ul>
          </div>
        </div>
      )}
      <button type="submit" className="save">Save Changes</button>
    </div>
  );

  return (
    <>
      <div className="profileDetail">
        <Form
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          onSubmit={onFormSubmit}
          validate={validate}
          initialValues={{}}
        >
          {({ handleSubmit, form, values }) => {
            formRef.current = form;

            return (
              <>
                <form onSubmit={handleSubmit}>{userDetailsJSX()}</form>
              </>
            );
          }}
        </Form>
      </div>
    </>
  );
}

export default ProfileDetails;
