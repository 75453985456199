/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomModal from "../../../../components/common/Modal";
import PostInput from "../../../../components/common/PostInput";
import "./details.scss";
import { rendertextarea } from "../../../../components/forms/index";
import { useDispatch, useSelector } from "react-redux";
import {
  communityPostListAction,
  createPostAction,
  deletePostAction,
  doUploadVideo,
} from "../../../../actions/post";
import ImgUpload from "../../../../components/common/img-upload";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { RenderVideoField } from "../../../../components/common/Video-upload";
import Post from "../../../../components/common/Post";
import toast from "react-hot-toast";
import { doUploadImage } from "../../../../actions/charityRegister";
import { getVideoUrl } from "../../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";

function Details({ isAssociated }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const communityId = id;
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { commuityPostList } = useSelector((state) => state.post);
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [postType, setPostType] = useState("");
  const [active, setActive] = useState("public");
  const [isMyPost, setIsMyPost] = useState(false);
  const [currPostDetails, setCurrPostDetails] = useState("");
  const [page, setPage] = useState(1);

  //image video state
  const [image, setImage] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [oldPostImages, setOldPostImages] = useState();

  const [videoArray, setVideoArray] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (communityId) {
      dispatch(
        communityPostListAction(
          communityId,
          { page: 1, limit: 20 },
          isMyPost,
          true
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  const required = (value) => {
    if (postType === "article") {
      if (!value) {
        return "Please enter text";
      }
      if (value && !value.trim()) {
        return "Please enter valid text";
      }
      // return (value || value.trim() ? undefined : 'Required')
      return undefined;
    }
    return undefined;
  };

  const radioButtonHandler = (e) => {
    setActive(e.target.name);
  };

  const uploadVideoFiles = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const formSubmitHandler = (value) => {
    let data = {
      type: 3,
      userId,
      communityId,
      description: value?.postContent,
      isPrivate: active === "public" ? false : true,
      hidePost: false,
    };
    if (postType === "photo") {
      let imgArr = [];
      let len = postImages.length;
      if (len === 0) {
        toast.error("Please attach atleast one image");
        return;
      }
      postImages.forEach((image, ind) => {
        if (!image?.name) {
          const payload = { image: [image], moduleName: "postImages" };
          dispatch(doUploadImage(payload)).then((res) => {
            len = len - 1;
            imgArr.push({
              name: res.data.name,
              url: mediaURL + "postImages/" + res.data.name,
            });
            setImage((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: mediaURL + "postImages/" + res.data.name,
              },
            ]);
            if (len === 0) {
              data = { ...data, type: 1, image: imgArr };
              createPostHandler(data);
            }
          });
        }
        return "";
      });
    } else if (postType === "video") {
      if (videoSourceFiles?.length === 0) {
        toast.error("Please attach a video");
        return;
      }
      const videoPayload = [];
      Promise.allSettled(uploadVideoFiles()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(mediaURL, "postImages", value.value?.data?.name),
              thumbnail: value.value?.data.thumbnail,
            });
          }
          return null;
        });
        data = { ...data, type: 2, video: videoPayload, image: [] };
        createPostHandler(data);
      });
    } else createPostHandler(data);
  };

  const createPostHandler = (data) => {
    dispatch(createPostAction(data)).then((res) => {
      if (res.statusCode === 400) {
        toast.error(res.message);
      }
      setPage(1);
      setisLoading(true);
      dispatch(
        communityPostListAction(
          communityId,
          { page: 1, limit: 20 },
          isMyPost,
          true
        )
      )
        .then(() => setisLoading(false))
        .catch(() => setisLoading(false));
      setEditIsOpen((prev) => !prev);
      setImage([]);
      setVideoArray([]);
      setPostImages([]);
      setVideoSourceFiles([]);
      setVideoSourceURLs([]);
    });
  };

  const deletePostHandler = () => {
    dispatch(deletePostAction(currPostDetails?._id)).then(() => {
      dispatch(
        communityPostListAction(communityId, { page, limit: 10 }, isMyPost)
      );
      setCurrPostDetails("");
      setModalIsOpen((prev) => !prev);
    });
  };

  const closeAddPostModalHandler = () => {
    setEditIsOpen((prev) => !prev);
    setImage([]);
    setPostImages([]);
    setVideoArray([]);
    setVideoSourceFiles([]);
    setVideoSourceURLs([]);
  };

  const switchToMyPostHandler = () => {
    setIsMyPost((prev) => !prev);
    setisLoading(true);
    setPage(1);
    setTimeout(() => {
      dispatch(
        communityPostListAction(
          communityId,
          { page: 1, limit: 20 },
          !isMyPost,
          true
        )
      )
        .then(() => setisLoading(false))
        .catch(() => setisLoading(false));
    }, 400);
  };

  const fetchData = () => {
    dispatch(
      communityPostListAction(
        communityId,
        { page: page + 1, limit: 20 },
        isMyPost
      )
    );

    setPage((prev) => prev + 1);
  };

  return (
    <>
      {isAssociated && (
        <PostInput
          setEditIsOpen={setEditIsOpen}
          setPostType={setPostType}
          communityId={communityId}
          setArgs={() => setPage(1)}
          isMyPost={isMyPost}
        />
      )}
      <div className="detailsHeader">
        {isAssociated ? (
          <h2>{isMyPost ? "My Posts" : "All Posts"}</h2>
        ) : (
          <h2>{"All Post"}</h2>
        )}
        {isAssociated === true && userId && (
          <div className="detailsSwitch">
            <span>Switch to</span>
            <button onClick={() => switchToMyPostHandler()}>{`${
              !isMyPost ? "My Posts" : "All Posts"
            }`}</button>
          </div>
        )}
      </div>

      {!isLoading && commuityPostList?.communityPostList?.length ? (
        <InfiniteScroll
          dataLength={commuityPostList?.communityPostList?.length}
          next={() => fetchData()}
          hasMore={true}
        >
          {commuityPostList?.communityPostList?.map((post, ind) => (
            <Post
              post={post}
              communityId={communityId}
              key={post._id}
              isMyPost={isMyPost}
              isLoading={isLoading}
              setisLoading={setisLoading}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <h4 className="notFound">No Post Found</h4>
      )}
      <CustomModal
        className="postDeleteModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <h3>Are you sure you want to delete this post?</h3>
        <p>
          Are you sure you want to delete this post, after this action this post
          will permanentely deleted from server.
        </p>
        <div className="postModal">
          <button>Cancel</button>
          <button onClick={deletePostHandler}>Delete</button>
        </div>
      </CustomModal>
      <CustomModal
        className="postEditModal"
        isOpen={editIsOpen}
        closeModal={() => closeAddPostModalHandler()}
        title={`Add Post`}
      >
        <Form onSubmit={formSubmitHandler}>
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Field
                  name="postContent"
                  component={rendertextarea}
                  className="textarea"
                  placeholder="Type here"
                  validate={required}
                ></Field>
                {postType === "photo" && (
                  <ImgUpload
                    isPost={true}
                    multiple={true}
                    setImg={setImage}
                    imgs={image}
                    setImages={setPostImages}
                    images={postImages}
                    oldImages={oldPostImages}
                    setOldImages={(images) => setOldPostImages(images)}
                    maxNumber={5}
                    moduleName="postImages"
                    postPage={true}
                    callUploadAPI={false}
                  />
                )}
                {postType === "video" && (
                  <RenderVideoField
                    oneVideoValidation={true}
                    isMultiple={false}
                    moduleName="postImages"
                    videoArray={videoArray}
                    setVideoArray={setVideoArray}
                    videoSourceURLs={videoSourceURLs}
                    setVideoSourceURLs={setVideoSourceURLs}
                    videoSourceFiles={videoSourceFiles}
                    setVideoSourceFiles={setVideoSourceFiles}
                  />
                )}
                <ul>
                  <li>
                    <div className="forgot">
                      <div className="remember">
                        <input
                          className="check"
                          type="checkbox"
                          id="public"
                          value="public"
                          name="public"
                          checked={active === "public"}
                          onChange={radioButtonHandler}
                        />
                        <label htmlFor="public">Public Post</label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="forgot">
                      <div className="remember">
                        <input
                          className="check"
                          type="checkbox"
                          id="private"
                          value="private"
                          name="private"
                          checked={active === "private"}
                          onChange={radioButtonHandler}
                        />
                        <label htmlFor="private">Private Post</label>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="editButton">
                  <button
                    type="button"
                    onClick={() => closeAddPostModalHandler()}
                  >
                    Cancel
                  </button>
                  <button type="submit">Post</button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
}

export default Details;
