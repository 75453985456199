import React, { useEffect } from "react";
import CustomTab from "../../../../components/common/Tabs";
import "./favorite.scss";
import CharityFavorite from "../../../../components/common/profileFavorite/charityFavorite";
import CauseFavorite from "../../../../components/common/profileFavorite/causeFavorite";
import { useDispatch, useSelector } from "react-redux";
import { renderFavList } from "../../../../actions/favourite";
import CommunityFavorite from "../../../../components/common/profileFavorite/communityFavorite";

function Favorite() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { favouriteList } = useSelector((state) => state.favourite);

  useEffect(() => {
    dispatch(renderFavList({ userId }));
    // eslint-disable-next-line
  }, []);

  const data = [
    {
      heading: "Nonprofits",
      body: (
        <CharityFavorite
          charityFavList={favouriteList?.charityFvrtData?.charityFvrtList}
        />
      ),
    },
    {
      heading: "Communities",
      body: <CommunityFavorite />,
    },
    {
      heading: "Causes",
      body: (
        <CauseFavorite
          causeFavList={favouriteList?.causeFvrtData?.causesFvrtList}
        />
      ),
    },
  ];

  return (
    <>
      <div className="favoriteTab">
        <CustomTab className="tabsForFavorite" data={data} />
      </div>
    </>
  );
}

export default Favorite;
