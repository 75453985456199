import {
  API_CREATE_EMPLOYEE,
  API_EMPLOYEE_POST_LIST,
  API_STRATEGIC_DETAIL,
  API_STRATEGIC_MEMBER_LIST,
  API_STRATEGIC_MUTUAL_FRIEND,
  API_STRATEGIC_MUTUAL_LIST,
  API_STRATEGIC_PARTNER_LIST,
} from "../constants/api";
import {
  API,
  EMPLOYEE_POST_LIST_F,
  EMPLOYEE_POST_LIST_S,
  STRATEGIC_DETAIL_F,
  STRATEGIC_DETAIL_S,
} from "../constants/types";

export const strategiclistAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_PARTNER_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const employecreateAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CREATE_EMPLOYEE,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const strategicDetailAction = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_STRATEGIC_DETAIL}${id}`,
    method: "GET",
    showLoader,
    success: (data) => ({
      type: STRATEGIC_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_DETAIL_F,
      payload: data,
    }),
  },
});

export const strategicMutualListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_MUTUAL_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const strategicListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_MEMBER_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const strategicMutualFriendListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_MUTUAL_FRIEND,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const employeePostAction = (
  data,
  id,
  isOwnPost,
  showLoader = false,
  reset = false
) => ({
  type: API,
  payload: {
    url: `${API_EMPLOYEE_POST_LIST}${id}/${isOwnPost}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: EMPLOYEE_POST_LIST_S,
      payload: { ...data, reset },
    }),
    error: (data) => ({
      type: EMPLOYEE_POST_LIST_F,
      payload: data,
    }),
  },
});
