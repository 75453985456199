import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addToBasketAction } from "../../actions/cart";
import {
  renderCharityCommunity,
  renderCharityDetails,
} from "../../actions/charity";
import { addCharityToFav, removeFav } from "../../actions/favourite";
import Heart from "../../assets/svg/heart";
import Link from "../../assets/svg/link";
import Approve from "../../assets/svg/approve";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import CustomTab from "../../components/common/Tabs";
import { toAbsoluteUrl } from "../../utils";
import { formatPhoneNumber, formatTaxId } from "../../utils/reusable";
import Events from "../Community Details/CommunityTabs/Events";
import "./charityDetails.scss";
import Media from "./TabsContent/Media";
import Rating from "./TabsContent/Rating";
import TabCommunity from "./TabsContent/TabCommunity";
import TabCause from "./TabsContent/TabsCause";
import Telegram from "../../assets/svg/telegram";
import { BASKET_COUNT_S } from "../../constants/types";

const bread = [
  {
    name: "Nonprofit List",
    link: "/charity",
    isActive: false,
  },
  {
    name: "Nonprofit Details",
    link: "/charity_details",
    isActive: true,
  },
];

function CharityDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { charityDetails, charityCommunityList } = useSelector(
    (state) => state.charity
  );
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const { id } = useParams();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

  const [readDesc, setReadDesc] = useState(true);
  const [isFav, setIsFav] = useState();

  useEffect(() => {
    setIsFav(charityDetails?.isFavorite);
  }, [charityDetails?.isFavorite]);

  useEffect(() => {
    dispatch(renderCharityDetails({ _id: id }, true)).then((res) => {
      dispatch(
        renderCharityCommunity({ charityId: res?.data?.charityId }, true)
      );
    });
    // eslint-disable-next-line
  }, [id]);

  let tabs = [
    {
      heading: "Events",
      body: charityDetails?._id && (
        <Events id={charityDetails?._id} from="charity" />
      ),
    },
    {
      heading: "Rating",
      body: (
        <Rating
          ratingType={charityDetails?.ratingType}
          emcompassDetais={charityDetails?.encomObj}
          starDetails={charityDetails?.startRatingObj}
          encompassAvg={charityDetails?.encompassAvg}
          starRatingAvg={charityDetails?.startRatingObj?.ratingAvg}
          transperancyBenchmark={charityDetails?.transperancyBenchmark}
          governanceBenchmark={charityDetails?.governanceBenchmark}
          gradeReceive={charityDetails?.gradeReceive}
          guideStar={charityDetails?.guideStar}
          isNavigator={charityDetails?.isNavigator}
        />
      ),
    },
    {
      heading: "Related Causes",
      body: <TabCause causeList={charityDetails?.relatedCause} />,
    },
    {
      heading: "Join Community",
      body: (
        <TabCommunity communityList={charityCommunityList} charityId={id} />
      ),
    },
    {
      heading: "Media",
      body: (
        <Media
          mediaImage={charityDetails?.mediaImage}
          mediaVideo={charityDetails?.mediaVideo}
        />
      ),
    },
  ];

  if (charityDetails?.isNavigator === true) {
    tabs.splice(0, 1);
    tabs.splice(-3);
  }

  const handleBasketButtonClick = (e) => {
    e.stopPropagation();
    if (!userId) {
      toast.error("Please login to add this charity in the basket");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (charityDetails?.isBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          charityId: charityDetails?._id,
          type: "charity",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        dispatch({
          type: BASKET_COUNT_S,
          payload: basketCount + 1,
        });
        toast.success("Charity added to basket successfully.");
        dispatch(renderCharityDetails({ _id: id }));
      })
      .catch(() => toast.error("Something went wrong"));
  };

  const handleFavClick = (e) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (!isFav) {
      const payload = { userId, charityId: charityDetails?._id };
      dispatch(addCharityToFav(payload)).then(() => {
        setIsFav(true);
      });
    } else {
      const payload = { _id: userId, charityId: charityDetails?._id };
      dispatch(removeFav(payload)).then(() => {
        setIsFav(false);
      });
    }
  };

  const charityAddress = () => {
    return `${
      charityDetails?.addressObj?.street
        ? charityDetails?.addressObj?.street
        : ""
    }${charityDetails?.addressObj?.street ? "," : ""}
    ${
      charityDetails?.addressObj?.street2
        ? charityDetails?.addressObj?.street2
        : ""
    }
    ${charityDetails?.addressObj?.street2 ? "," : ""}
     ${
       charityDetails?.addressObj?.city ? charityDetails?.addressObj?.city : ""
     }${charityDetails?.addressObj?.city ? "," : ""} ${
      charityDetails?.addressObj?.state ? charityDetails?.addressObj?.state : ""
    }${charityDetails?.addressObj?.state ? "," : ""} 
        ${
          charityDetails?.addressObj?.zipcode
            ? charityDetails?.addressObj?.zipcode
            : ""
        }`;
  };

  const renderData = () => {
    if (
      charityDetails?.ratingType === "NonRating" &&
      charityDetails?.guideStar === 5
    ) {
      return tabs.filter((tab) => tab.heading !== "Rating");
    } else {
      return tabs;
    }
  };

  const onMessageBtnClick = (e) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "charity",
        sendToId: `${charityDetails?._id}`,
        type: "charity",
        name: charityDetails?.charityName,
      },
    });
  };

  return (
    <section className="charitydetails">
      <div className="container">
        <div className="breadcrumbscharity">
          <Breadcrumbs list={bread} />
        </div>
      </div>
      <div className="container">
        <div className="charityDetailsWrap">
          <div className="charitySidebar">
            {/* detail-card */}
            <div className="detailCard">
              <figure className="detailsFigure">
                {charityDetails?.logo && charityDetails?.logo !== "string" ? (
                  <img
                    // src={toAbsoluteUrl(charityDetails?.logo)}
                    src={`${mediaURL}charityLogo/${charityDetails?.logo}`}
                    // src={`${placeholder}`}
                    alt="detailsImage"
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl("/images/placeholder.png")}
                    alt="placeholder"
                  />
                )}
              </figure>
              <div className="cardUser">
                {charityDetails?.tagLine && (
                  <div className="maintag">
                    <p>{charityDetails?.tagLine}</p>
                  </div>
                )}
                <div className="nameWIcon">
                  <h3>{charityDetails?.charityName}</h3>
                  {charityDetails?.isNavigator === false && (
                    <figure>
                      <Approve />
                    </figure>
                  )}
                </div>
                <div className="cardusername">
                  {charityDetails?.charityPersonName !==
                    charityDetails?.charityName && (
                    <div className="fullUserName">
                      <img
                        src={toAbsoluteUrl("/images/user.png")}
                        alt="username"
                      />
                      <h5>{charityDetails?.charityPersonName}</h5>
                    </div>
                  )}
                </div>
                {charityDetails?.description &&
                  charityDetails?.description !== "string" && (
                    <p
                      className={!readDesc ? "newline" : "readLess"}
                      dangerouslySetInnerHTML={{
                        __html: charityDetails?.description,
                      }}
                    ></p>
                  )}
                <div className="member">
                  <button
                    className={
                      charityDetails?.description?.length < 155 ? "hideBtn" : ""
                    }
                    onClick={() => setReadDesc((prev) => !prev)}
                  >
                    {readDesc ? "Read More" : "Read Less"}
                  </button>
                </div>
              </div>
              <div className="cardDetails">
                <div className="cardAddress">
                  <div className="location">
                    <img
                      src={toAbsoluteUrl("/images/location.svg")}
                      alt="location"
                    />
                    <address>{charityAddress()}</address>
                  </div>
                </div>
                <div className="cardContact">
                  {charityDetails?.email &&
                  charityDetails?.email?.includes("@rosigiving.com") ===
                    false ? (
                    <div className="mail">
                      <img
                        src={toAbsoluteUrl("/images/email.svg")}
                        alt="location"
                      />
                      <h4>
                        <a href={`mailto: ${charityDetails?.email}`}>
                          {charityDetails?.email}
                        </a>
                      </h4>
                    </div>
                  ) : null}
                  {charityDetails?.phoneNo ? (
                    <div className="mobile">
                      <a
                        href={`tel: ${charityDetails?.phoneNo}`}
                        className="phnNumber"
                      >
                        <img
                          src={toAbsoluteUrl("/images/telephone.svg")}
                          alt="location"
                        />
                        <h4>
                          {formatPhoneNumber(
                            charityDetails?.phoneNo?.toString()
                          )}
                        </h4>
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="localDetails">
                  {charityDetails?.websiteUrl && (
                    <div className="website">
                      <Link />
                      <h4>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            charityDetails?.websiteUrl
                              ?.toString()
                              ?.indexOf("http") !== -1
                              ? charityDetails?.websiteUrl
                              : `https://${charityDetails?.websiteUrl}`
                          }`}
                        >
                          {charityDetails?.websiteUrl}
                        </a>
                      </h4>
                    </div>
                  )}
                </div>
                <div className="type">
                  {charityDetails?.charityType ? (
                    <div className="nation">
                      <img
                        src={toAbsoluteUrl("/images/nation.svg")}
                        alt="location"
                      />
                      <h4>{charityDetails?.charityType}</h4>
                    </div>
                  ) : null}
                  {charityDetails?.amount ? (
                    <div className="money">
                      <img
                        src={toAbsoluteUrl("/images/Money_light.png")}
                        alt="location"
                      />
                      <h4>{`$${charityDetails?.amount
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h4>
                    </div>
                  ) : null}
                </div>
                <div className="type">
                  <div className="nation">
                    <img src={toAbsoluteUrl("/images/id.png")} alt="location" />
                    <h4>{formatTaxId(charityDetails?.taxId)}</h4>
                  </div>
                </div>
              </div>
              <div className="cardButton">
                <button className="basket" onClick={handleBasketButtonClick}>
                  {!charityDetails?.isBasket ? "Add To Basket" : "Go To Basket"}
                </button>
                <button
                  className={`${isFav ? "charityHeartActive" : "charityHeart"}`}
                  onClick={handleFavClick}
                >
                  <Heart />
                  {/* <img src={toAbsoluteUrl("/images/heart.svg")} alt="heart" /> */}
                </button>
                {userId && charityDetails?.isNavigator === false && (
                  <button
                    className="telegramButtons"
                    onClick={onMessageBtnClick}
                  >
                    <Telegram />
                  </button>
                )}
              </div>
            </div>

            {/* research */}
            {/* <div className="research">
                <h3>{charityDetails?.charityName}</h3>
                <p className={!readDesc ? "" : "readLess"}>
                  {charityDetails?.description}
                </p>
                <div className="member">
                  <div className="member-list">
                    <div className="member-images">
                      {charityDetails?.charityFavMemberImg?.map(
                        (image, ind) => (
                          <figure key={ind}>
                            {image ? (
                              <img
                                src={mediaURL + "userProfilePic/" + image.image}
                                alt="user"
                              />
                            ) : (
                              <img
                                src={toAbsoluteUrl("/images/placeholder.png")}
                                alt="placeholder"
                              />
                            )}
                          </figure>
                        )
                      )}
                    </div>
                    <span className="member-count">
                      {charityDetails?.charityFavMemberCount} Favorites
                    </span>
                  </div>
                  <button
                    className={
                      charityDetails?.description?.length < 155 ? "hideBtn" : ""
                    }
                    onClick={() => setReadDesc((prev) => !prev)}
                  >
                    {readDesc ? "Read More" : "Read Less"}
                  </button>
                </div>
              </div> */}

            {/* share */}
            <Share />
          </div>
          <div className="charityContent">
            <CustomTab className="charityTab" data={renderData()} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CharityDetails;
