import React, { useRef, useState } from "react";
import { RenderImage } from ".";
import { getImageUrl, toAbsoluteUrl } from "../../utils";
import Down from "../../assets/svg/down";
import { clearechatHistoryAction } from "../../actions/chat copy";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useDetectOutsideClick } from "../../Hooks/useDetectOutsideClick";
import { getChatHistory, getChatUserList } from "../../utils/socket";

const ChatHeader = ({ activeUser, mediaURL, onClickBack }) => {
  const chatReducer = useSelector((state) => state.chat);
  const userData = JSON.parse(localStorage.getItem("webUser"));
  let userId = userData?.data?._id || "";
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const [opensubmenu, setopensubmenu] = useState(false);
  const handlemodualname = () => {
    if (activeUser?.userDetails?.type === "charity") {
      return "charityLogo";
    } else if (activeUser?.userDetails?.type === "user") {
      return "userProfilePic";
    } else if (activeUser?.userDetails?.type === "strategic") {
      return "strategicLogo";
    } else if (activeUser?.userDetails?.type === "admin") {
      return "adminImages";
    }
  };
  const hadleclearechat = () => {
    dispatch(clearechatHistoryAction(activeUser?.roomId)).then((res) => {
      setopensubmenu(false);
      toast.success("Clear chat successfully!");
      getChatUserList({
        page: 1,
        limit: chatReducer.userListlimit,
        userId: userId,
      });
      getChatHistory({
        userId: userId,
        roomId: activeUser?.roomId,
        page: 1,
        limit: 20,
      });
    });
  };
  useDetectOutsideClick(modalRef, () => setopensubmenu(false));
  return (
    <div className="active-chat-user">
      <span className="chat-back" onClick={onClickBack}>
        <Down />
      </span>
      <RenderImage
        className="border-50"
        filePath={
          activeUser?.userDetails?.profileImage
            ? getImageUrl(
                mediaURL,
                handlemodualname(),
                activeUser?.userDetails?.profileImage
              )
            : toAbsoluteUrl("/images/userLogo.png")
        }
      />
      <div className="chat-nm-desc">
        <strong>{activeUser?.userDetails?.name || "-"}</strong>
      </div>
      <div className="dropdownAvtar" ref={modalRef}>
        <figure onClick={() => setopensubmenu((pre) => !pre)()}>
          <img
            src={toAbsoluteUrl("/images/profile.svg")}
            alt="profile"
            width="5px"
            height="5px"
          />
        </figure>
        {opensubmenu && (
          <ul className="profileDropdown">
            <li onClick={hadleclearechat}>Clear Chat</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default ChatHeader;
