import React from "react";

function location() {
  return (
    <>
      <svg
        width="17"
        height="19"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8.5C16 13.518 10.5117 16.9027 8.92489 17.7764C8.65683 17.924 8.34317 17.924 8.07511 17.7764C6.48831 16.9027 1 13.518 1 8.5C1 4 4.63401 1 8.5 1C12.5 1 16 4 16 8.5Z"
          stroke="#4F4F4F" fill="none"
        />
        <circle cx="8.5" cy="8.5" r="3.5" stroke="#4F4F4F" fill="none" />
      </svg>
    </>
  );
}

export default location;