import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import Star from "./tabs/star.js";
import "./tabs.scss";
import Tookit from "../svg/tookit.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Steps4 = ({
  setSteps,
  step,
  setclass,
  setcompleted,
  completedSteps,
  setCompletedSteps,
  setErrorMessages,
}) => {
  const [tab, settab] = useState(0);

  const prev = () => {
    setSteps(2);
  };

  const renderComponent = (tab) => {
    // if (tab === 0) {
    //   return (
    //     <Encompass
    //       setcompleted={setcompleted}
    //       setSteps={setSteps}
    //       onPrevClick={prev}
    //       setclass={setclass}
    //       step={step}
    //       completedSteps={completedSteps}
    //       setCompletedSteps={setCompletedSteps}
    //       setErrorMessages={setErrorMessages}
    //     />
    //   );
    // } else
    if (tab === 0) {
      return (
        <Star
          showStarRating={true}
          onPrevClick={prev}
          setcompleted={setcompleted}
          step={step}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
          setErrorMessages={setErrorMessages}
          from="starRating"
        />
      );
    } else {
      return (
        <Star
          showStarRating={false}
          onPrevClick={prev}
          setcompleted={setcompleted}
          step={step}
          setSteps={setSteps}
          completedSteps={completedSteps}
          setCompletedSteps={setCompletedSteps}
          setErrorMessages={setErrorMessages}
          from="noRating"
        />
        // <NoRating
        //   onPrevClick={prev}
        //   setcompleted={setcompleted}
        //   step={step}
        //   setSteps={setSteps}
        //   completedSteps={completedSteps}
        //   setCompletedSteps={setCompletedSteps}
        //   setErrorMessages={setErrorMessages}
        // />
      );
    }
  };
  return (
    <>
      <div className="section-steps">
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 col-box">
            <label className="label-text navigator"> Encompass Rating : </label>
            <div className="tab-main-box">
              <div className="tab-box">
                {/* <div className="tab-div" onClick={() => settab(0)}>
                  <label className="check-label ratings-reg">
                    Encompass Rating
                    <input type="radio" defaultChecked={true} name="Rating" />
                    <span className="checkmark"></span>
                  </label>
                </div> */}
                <p>Do you have a Navigator Encompass Rating?{" "}<span className="tooltip-information-wrap" data-tooltip-id="charityNavigator"><Tookit /></span>
                  <ReactTooltip
                    id="charityNavigator"
                    place="top"
                    variant="success"
                    content="If you have a Charity Navigator rating, please indicate it here. Enter only the score (expressed as a percentage) for the overall rating and any of the four subs-cores (called beacons by Charity Navigator). The stars will be determined based on the scores you enter. If there are sub-scores where there is no scores assigned, “Not Evaluated” will be noted."
                  /></p>
                <div className="tab-div" onClick={() => settab(0)}>
                  <label className="check-label ratings-reg">
                    Yes
                    <input type="radio" name="Rating" defaultChecked={true} />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="tab-div" onClick={() => settab(2)}>
                  <label className="check-label ratings-reg">
                    No
                    <input type="radio" name="Rating" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="panel-box">{renderComponent(tab)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps4;
