import React from "react";
import StarRatings from "react-star-ratings";

function CustomStar(props) {
  const { rating, starDimension, starSpacing, color, numberOfStars } = props;

  return (
    <>
      <StarRatings
        rating={rating}
        starDimension={starDimension}
        starSpacing={starSpacing}
        starRatedColor={color ? color : "#234ae0"}
        starHoverColor={color ? color : "#234ae0"}
        numberOfStars={numberOfStars ? numberOfStars : 4}
      />
    </>
  );
}

export default CustomStar;
