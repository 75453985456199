import {
  API_BADGE_LIST,
  API_DONATION_GOAL,
  API_UPDATE_USER_DETAILS,
  API_UPLOAD_USER_PROFILE,
  API_USER_DETAILS,
  API_VERIFY_EMAIL,
} from "../constants/api";
import {
  ADD_GOAL_F,
  ADD_GOAL_S,
  API,
  FETCH_USER_DETAILS_F,
  FETCH_USER_DETAILS_S,
  UPDATE_USER_DETAILS_F,
  UPDATE_USER_DETAILS_S,
} from "../constants/types";

export const fetchUserDetails = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: API_USER_DETAILS,
    method: "POST",
    data: id,
    showLoader,
    success: (data) => ({
      type: FETCH_USER_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: FETCH_USER_DETAILS_F,
      payload: data,
    }),
  },
});

export const setDonationGoalAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_DONATION_GOAL,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: ADD_GOAL_S,
      payload: data,
    }),
    error: (data) => ({
      type:ADD_GOAL_F,
      payload: data,
    }),
  },
});

export const uploadProfilePicAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_UPLOAD_USER_PROFILE,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const updateUserDetails = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_UPDATE_USER_DETAILS,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: UPDATE_USER_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: UPDATE_USER_DETAILS_F,
      payload: data,
    }),
  },
});

export const doVerifyEmail = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_VERIFY_EMAIL}/${id}`,
    method: "POST",
    showLoader,
  },
});

export const badgeListAction = (data, id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_BADGE_LIST}${id}`,
    method: "GET",
    showLoader,
  },
});
