import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import CardSignin from "../../components/common/Card_signin";
import { renderTextInput } from "../../components/common/FormFields";
import Swiper from "../../components/common/Swiper";
import SwiperText from "../../components/common/SwiperText";
import "./../SignIn/signin.scss";
import Eye from "../../assets/svg/eye";
import "./resetPassword.scss";
import { useDispatch } from "react-redux";
import { doResetPass } from "../../actions/auth";
import { resetPasswordValidation as validate } from "../../components/validations/signIn";
import { useNavigate, useParams } from "react-router-dom";
import EyeLock from "../../assets/svg/eyeLock";

function ResetPassword() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [error, setError] = useState("");

  const formSubmitHandler = (value) => {
    const data = { token: params?.id, newPassword: value?.password };
    dispatch(doResetPass(data))
      .then(() => navigate("/signin"))
      .catch((err) => {
        setError(err.message);
      });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleShowPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <>
      <section className="signin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Reset Password">
              <Form onSubmit={formSubmitHandler} validate={validate}>
                {({ handleSubmit }) => (
                  <>
                    <form className="form" onSubmit={handleSubmit}>
                      <ul>
                        <li>
                          <Field
                            name="password"
                            type={passwordShown ? "text" : "password"}
                            component={renderTextInput}
                            placeholder="New Password"
                            containerClasses="form-group form-password"
                            id="01"
                          >
                            <figure
                              className="passwordShow"
                              onClick={togglePasswordVisiblity}
                            >
                              {passwordShown ? <Eye /> : <EyeLock />}
                            </figure>
                          </Field>
                        </li>
                        <li>
                          <Field
                            name="Confirmpassword"
                            type={confirmPasswordShown ? "text" : "password"}
                            component={renderTextInput}
                            placeholder="Confirm New Password"
                            containerClasses="form-group form-password"
                            id="02"
                          >
                            <figure
                              className="passwordShow"
                              onClick={toggleShowPasswordVisiblity}
                            >
                              {confirmPasswordShown ? <Eye /> : <EyeLock />}
                            </figure>
                          </Field>
                        </li>
                        {error && (
                          <h4 style={{ color: "red", margin: "10px" }}>
                            {error}
                          </h4>
                        )}
                        <li className="saveButton">
                          <button>Save</button>
                        </li>
                      </ul>
                    </form>
                  </>
                )}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
