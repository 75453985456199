import React from "react";

function Mobile() {
  return (
    <>
      <svg
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 0.5H3.5V1C3.5 1.00908 3.5 1.01815 3.5 1.0272C3.49995 1.23924 3.49991 1.4453 3.52276 1.61526C3.54852 1.80686 3.61129 2.0255 3.79289 2.20711C3.9745 2.38871 4.19314 2.45148 4.38474 2.47724C4.5547 2.50009 4.76076 2.50005 4.97279 2.5C4.98185 2.5 4.99092 2.5 5 2.5H7C7.00908 2.5 7.01815 2.5 7.02721 2.5C7.23924 2.50005 7.4453 2.50009 7.61526 2.47724C7.80686 2.45148 8.0255 2.38871 8.20711 2.20711C8.38871 2.0255 8.45148 1.80686 8.47724 1.61526C8.50009 1.4453 8.50005 1.23924 8.5 1.0272C8.5 1.01815 8.5 1.00908 8.5 1V0.5H8H4Z"
          stroke="#4F4F4F"
          fill="none"
        />
        <rect
        className="react"
          x="0.5"
          y="0.5"
          width="11"
          height="19"
          rx="1.5"
          stroke="#4F4F4F"
          fill="none"
        />
        <circle cx="6" cy="16" r="1" fill="#4F4F4F" />
      </svg>
    </>
  );
}

export default Mobile;
