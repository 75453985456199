import React from "react";
import "./postCard.scss";

function PostCard(props) {
  const { children } = props;

  return (
    <>
      <div className="postCard">{children}</div>
    </>
  );
}

export default PostCard;
