import React from "react";
import { toAbsoluteUrl } from "../../../utils";
import "./ratingMedals.scss";

function RatingMedals({
  transperancyBenchmark,
  governanceBenchmark,
  gradeReceive,
  guideStar,
}) {
  let starType = "";

  switch (guideStar) {
    case 1:
      starType = "Bronze";
      break;
    case 2:
      starType = "Silver";
      break;
    case 3:
      starType = "Gold";
      break;
    case 4:
      starType = "Platinum";
      break;
    default:
  }

  return (
    <>
      <div className="ratingSeal">
        {starType !== "" && (
          <div className="sealHeading">
            <h3>Guidestar/Candid Seal</h3>
          </div>
        )}
        {starType !== "" && (
          <div className="sealLogo">
            <figure>
              <img src={toAbsoluteUrl("/images/bronze.png")} alt="bronze" />
            </figure>
            <h4>{`${starType} Seal`}</h4>
          </div>
        )}
      </div>
    </>
  );
}

export default RatingMedals;
