import {
  HOMEPAGE_DETAILS_F,
  HOMEPAGE_DETAILS_S,
  SEARCH_ITEM_F,
  SEARCH_ITEM_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  homepageDetails: {},
  searchItemsList: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOMEPAGE_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        homepageDetails: action.payload.data,
      };
    case HOMEPAGE_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        homepageDetails: {},
      };
    case SEARCH_ITEM_S:
      return {
        ...state,
        searchItemsList: action.payload.data,
      };
    case SEARCH_ITEM_F:
      return {
        ...state,
        searchItemsList: [],
      };
    default:
      return state;
  }
};

export default homeReducer;
