import React from "react";
import { useSelector } from "react-redux";
import "./aboutContent.scss";
import { toAbsoluteUrl } from "../../../utils/index";

function AboutContent(props) {
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const modualname = () => {
    if (props.from === "giving") {
      return "givingPartnerimage/";
    } else {
      return "aboutLogo/";
    }
  };
  return (
    <>
      <div className={`${props.className} mainAboutContent`}>
        <div className="contentImage">
          <figure className="mainImage imageBorder">
            {props.image ? (
              <img src={mediaURL + modualname() + props.image} alt="content" />
            ) : (
              <img
                src={toAbsoluteUrl("/images/placeholder.png")}
                alt="placeholder"
              />
            )}
          </figure>
        </div>
        <div className="contentText">
          <h3>{props.contentTitle}</h3>
          {props.from === "about" ? (
            props.contentDescription?.split("\n").map(function (item, idx) {
              return (
                <span key={idx}>
                  {item}
                  <br />
                </span>
              );
            })
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: props.contentDescription }}
            ></div>
          )}

          {/* <p>{props.contentDescriptions}</p> */}
        </div>
      </div>
    </>
  );
}

export default AboutContent;
