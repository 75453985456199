import React from "react";
import { Field, Form } from "react-final-form";
import User from "../../../assets/svg/User";
import { renderField } from "../../forms";
import CustomModal from "../Modal";
import { cardValidations as validate } from "../../validations/payment";
import { renderNumberInput } from "../FormFields";
import * as CryptoJS from "crypto-js";
import { addCardAction, getAllCardListAction } from "../../../actions/payment";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const AddCardModal = ({ modalIsOpen, setModalIsOpen, setCardList }) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const onAddCardHandler = (values) => {
    let payload = {
      userId,
    };
    const encryptedData = {
      number: values?.cardNumber?.split(" ").join(""),
      exp_month: values?.expiry?.substr(0, 2),
      exp_year: "20" + values?.expiry?.substr(5),
      cvc: values?.cvv.trim(),
      cardOwnerName: values?.user,
    };

    async function encryptData(data) {
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_ENCRYPT_KEY
      );
      return ciphertext.toString();
    }

    encryptData(encryptedData).then((res) => {
      payload = { ...payload, data: res };

      dispatch(addCardAction(payload))
        .then((res) => {
          toast.success(res.message);
          setModalIsOpen((prev) => !prev);
          dispatch(getAllCardListAction({ userId })).then((res) =>
            setCardList(res.data.listCards)
          );
        })
        .catch((res) => toast.error(res.message));
    });
  };
  return (
    <CustomModal
      title="Add Card Details"
      className="cardModal"
      isOpen={modalIsOpen}
      closeModal={() => {
        setModalIsOpen((prev) => !prev);
      }}
    >
      <Form onSubmit={onAddCardHandler} validate={validate}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ul>
              <li>
                <Field
                  name="user"
                  type="text"
                  placeholder="User Name"
                  component={renderField}
                  className="form-grp formGroup"
                  inputclass="input-box"
                >
                  <User />
                </Field>
              </li>
              <li>
                <Field
                  name="cardNumber"
                  type="number"
                  placeholder="Card Number"
                  containerClasses="formGroup form-grp form-group"
                  component={renderNumberInput}
                  numberFormat={"#### #### #### ####"}
                />
              </li>
              <li>
                <div className="modalForms">
                  <Field
                    name="expiry"
                    type="number"
                    placeholder="Expiry Date"
                    containerClasses="form-group form-grp"
                    component={renderNumberInput}
                    numberFormat={"## / ##"}
                  />
                  <Field
                    name="cvv"
                    type="number"
                    placeholder="CVV Number"
                    containerClasses="form-group form-grp"
                    component={renderNumberInput}
                    numberFormat={"####"}
                  />
                </div>
              </li>
              {/* <li className="cardTerms">
                <label className="check-label rememberReg">
                  <h5>
                    I agree to <Link to="/terms">terms and Condition </Link>of
                    Rosi Giving, Inc.
                  </h5>
                  <div>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onClick={() => setIsChecked((prev) => !prev)}
                    />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li> */}
              <li>
                <button type="submit" className="cardDonateButton">
                  Add
                </button>
              </li>
            </ul>
          </form>
        )}
      </Form>
    </CustomModal>
  );
};

export default AddCardModal;
