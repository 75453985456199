import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { badgeListAction } from "../../../../actions/user";
import { toAbsoluteUrl } from "../../../../utils";
import "./badges.scss";

function Badges() {
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const [badgeList, setBadgeList] = useState();

  useEffect(() => {
    dispatch(
      badgeListAction(
        {
          page: 1,
          limit: 1000000,
        },
        userId
      )
    ).then((res) => {
      setBadgeList(res?.data?.badgesList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
      <div className="badge">
        <ScrollBar component="div">
          <div className="badgeScroll">
            <div className="badgeDetails">
              {badgeList?.length > 0 ? (
                badgeList?.map((badge, ind) => (
                  <div className="badgeCard" key={badge?._id}>
                    <figure>
                      {badge?.image && badge?.image !== "string" ? (
                        <img
                          src={`${mediaURL}badgemanagement/${badge?.image}`}
                          alt="badge"
                        />
                      ) : (
                        <img
                          src={toAbsoluteUrl("/images/placeholder.png")}
                          alt="badge"
                        />
                      )}
                    </figure>
                    <p>{badge.title}</p>
                  </div>
                ))
              ) : (
                <p className="notFound paddingBadge">No Badges earned yet</p>
              )}
            </div>
          </div>
        </ScrollBar>
      </div>
  );
}

export default Badges;
