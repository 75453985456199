import {
  RENDER_CAUSE_LIST_S,
  RENDER_CAUSE_LIST_F,
  CAUSE_DETAILS_S,
  CAUSE_DETAILS_F,
  CAUSE_COMMUNITY_LIST_S,
  CAUSE_COMMUNITY_LIST_F,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  causeList: [],
  causeDetails: {},
  causeCommunityList: {},
};

const causeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENDER_CAUSE_LIST_S: {
      let list = state?.causeList || [];
      if (action.payload.reset === true || action.payload?.page === 1) {
        list = action.payload.data?.causesList || [];
      } else {
        list = [...list, ...(action.payload?.data?.causesList || [])];
      }
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        causeList: list,
      };
    }
    case RENDER_CAUSE_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action?.payload?.message,
        causeList: [],
      };
    case CAUSE_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: action?.payload?.message,
        message: action.payload.message,
        causeDetails: action?.payload?.data,
      };
    case CAUSE_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        causeDetails: {},
      };
    case CAUSE_COMMUNITY_LIST_S:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        causeCommunityList: action.payload.data?.joinCommunitiesDetails || [],
      };
    case CAUSE_COMMUNITY_LIST_F:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.message,
        causeCommunityList: [],
      };
    default:
      return state;
  }

  // switch (action.type) {
  //   case RENDER_CAUSE_LIST_S:
  //     let list = state?.causeList;
  //     if (action.payload.reset === true || action.payload?.page === 1) {
  //       list = action.payload.data?.causesList;
  //     } else {
  //       list = [...list, ...action?.payload?.data?.causesList];
  //     }
  //     return {
  //       ...state,
  //       loading: false,
  //       error: null,
  //       message: action.payload.message,
  //       causeList: list,
  //     };
  //   case RENDER_CAUSE_LIST_F:
  //     return {
  //       ...state,
  //       loading: false,
  //       error: action.payload.message,
  //       message: action?.payload?.message,
  //       causeList: [],
  //     };
  //   case CAUSE_DETAILS_S:
  //     return {
  //       ...state,
  //       loading: false,
  //       error: action?.payload?.message,
  //       message: action.payload.message,
  //       causeDetails: action?.payload?.data,
  //     };
  //   case CAUSE_DETAILS_F:
  //     return {
  //       ...state,
  //       loading: false,
  //       error: action.payload.message,
  //       message: action.payload.message,
  //       causeDetails: {},
  //     };
  //   case CAUSE_COMMUNITY_LIST_S:
  //     return {
  //       ...state,
  //       loading: false,
  //       message: action.payload.message,
  //       causeCommunityList: action.payload.data.joinCommunitiesDetails,
  //     };
  //   case CAUSE_COMMUNITY_LIST_F:
  //     return {
  //       ...state,
  //       loading: false,
  //       message: action.payload.message,
  //       error: action.payload.message,
  //       causeCommunityList: {},
  //     };
  //   default:
  //     return state;
  // }
};

export default causeReducer;
