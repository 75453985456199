import { API_CONTACT_US } from "../constants/api";
import { API } from "../constants/types";

export const contectUsAction = (data) => ({
  type: API,
  payload: {
    url: API_CONTACT_US,
    method: "POST",
    data: data,
  },
});
