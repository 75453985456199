import React, { useEffect, useState } from "react";
import AboutSec from "../../components/common/About_sec";
import "./home.scss";
import { toAbsoluteUrl } from "../../utils";
import CauseCard from "../../components/common/CauseCard";
import CharityCard from "../../components/common/CharityCard";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { renderHomePageDetails, searchAction } from "../../actions/home";
import localforage from "localforage";
import "../../components/common/About_sec/about_sec.scss";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Search from "../../components/common/Search";
import HomeRegistration from "../../components/common/home_registration";
import Approve from "../../assets/svg/approve";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { homepageDetails, searchItemsList } = useSelector(
    (state) => state.home
  );
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const [searchTexts, setSearchTexts] = useState("");
  const [charitySearchTexts, setCharitySearchTexts] = useState("");
  const [causeSearchTexts, setCauseSearchTexts] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [nonProfitSearchItems, setNonProfitSearchItems] = useState([]);
  const [causeSearchItems, setCauseSearchItems] = useState([]);

  const bannerTitle = homepageDetails?.homepage?.bannerTitle;
  const bannerDescription = homepageDetails?.homepage?.bannerDescription;
  const isEmpty = !bannerTitle || !bannerDescription;

  useEffect(() => {
    // localforage storege
    localforage.removeItem("charity_reg_step_1");
    localforage.removeItem("charity_reg_step_2");
    localforage.removeItem("charity_reg_step_3");
    localforage.removeItem("charity_reg_step_4");
    localforage.removeItem("curr_reg_step");
    dispatch(renderHomePageDetails(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const itms = searchItemsList.map((itm) => {
      if (itm.type === "Nonprofit") {
        const nonProfit = {
          ...itm,
          name: `${itm.name} ${itm?.taxId ? itm?.taxId : ""}`,
          // updatedName: `${itm.name} (Nonprofit)`,
          updatedName: (
            <div className="searchItm">
              <div>{`${itm.name} (Nonprofit)`}</div>{" "}
              {itm.isNavigator === false && (
                <figure className="charityRight">
                  <Approve />
                </figure>
              )}
            </div>
          ),
        };
        return nonProfit;
      }

      const cause = {
        ...itm,
        name: `${itm.name} (Cause)`,
        updatedName: `${itm.name} (Cause)`,
      };
      return cause;
    });
    setSearchItems(itms);
  }, [searchItemsList]);

  useEffect(() => {
    const charity = searchItems.filter((itm) => itm.type === "Nonprofit");
    const cause = searchItems.filter((itm) => itm.type === "cause");
    setNonProfitSearchItems(charity);
    setCauseSearchItems(cause);
  }, [searchItems]);

  const finalCauseList = homepageDetails?.cause?.map((cause, id) => {
    return {
      id: cause?._id,
      causeImage: cause?.images?.url,
      causeText: cause?.causename,
      isFavorite: cause?.isFavorite,
    };
  });

  useEffect(() => {
    if (searchTexts.length >= 3) {
      dispatch(
        searchAction({
          search: searchTexts.replace("-", ""),
          type: "",
          isNavigator: true,
        })
      );
    }
    // } else {
    //   dispatch(searchAction({ search: "", type: "" }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTexts]);

  useEffect(() => {
    if (charitySearchTexts.length >= 3) {
      dispatch(
        searchAction({
          search: charitySearchTexts.replace("-", ""),
          type: "charity",
          isNavigator: true,
        })
      );
    }
    // } else {
    //   dispatch(searchAction({ search: "", type: "charity" }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charitySearchTexts]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    setTimeout(() => scrollToTop(), 0);
  }, []);

  useEffect(() => {
    if (causeSearchTexts.length >= 3) {
      dispatch(
        searchAction({
          search: causeSearchTexts,
          type: "cause",
          isNavigator: false,
        })
      );
    }
    // else {
    //   dispatch(searchAction({ search: "", type: "cause" }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [causeSearchTexts]);

  const handleOnSelect = (item) => {
    if (item.type === "Nonprofit") navigate(`/charity_details/${item.id}`);
    else navigate(`/cause_details/${item.id}`);
  };

  const handleOnSearch = (string, results) => {
    setSearchTexts(string);
  };

  const handleOnSearchCharity = (string, results) => {
    setCharitySearchTexts(string);
  };

  const handleOnSearchCause = (string, results) => {
    setCauseSearchTexts(string);
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item.updatedName}
        </span>
      </>
    );
  };

  return (
    <>
      {/* banner-section */}
      <section className="banner">
        <div className="home-banner">
          {homepageDetails?.homepage?.bannerImage?.[0] ? (
            <img
              src={
                mediaURL +
                "homepage/" +
                homepageDetails?.homepage?.bannerImage?.[0]
              }
              alt="banner"
            />
          ) : (
            <img
              src={toAbsoluteUrl("/images/placeholder.png")}
              alt="placeholder"
            />
          )}
        </div>
        <div className="container">
          <div className={`banner-content ${isEmpty ? "empty-banner" : ""}`}>
            {bannerTitle && <h1>{bannerTitle}</h1>}
            {bannerDescription && <p>{bannerDescription}</p>}
            {/* <h1>{homepageDetails?.homepage?.bannerTitle}</h1>
            <p>{homepageDetails?.homepage?.bannerDescription}</p> */}
            {/* <div className="search"> */}
            {/* <input
                className="banner-search"
                type="text"
                placeholder="Enter charity name"
                name="searchCharity"
              />
              <span>
                <SearchW />
              </span> */}
            {/* <div className="banner-search">
              <ReactSearchAutocomplete
                items={searchItems}
                onSelect={handleOnSelect}
                onSearch={handleOnSearch}
                autoFocus
                formatResult={formatResult}
                placeholder="Search Nonprofit, Cause & Tax Id/EIN"
              />
            </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* about-us */}
      <section className="about-sec">
        <div className="container-main">
          <div className="banner-search">
            <ReactSearchAutocomplete
              items={searchItems}
              onSelect={handleOnSelect}
              onSearch={handleOnSearch}
              autoFocus
              formatResult={formatResult}
              placeholder="Search Nonprofit, Cause & Tax ID/EIN"
            />
          </div>
          <AboutSec
            SectionTitle="ABOUT US"
            SectionHeading={homepageDetails?.homepage?.aboutTitle}
            SectionTitle1={homepageDetails?.homepage?.aboutSubTitle}
            SectionPara={homepageDetails?.homepage?.aboutDescription}
          />
        </div>
      </section>

      {/* register today */}
      {/* <section className="register-section">
        <div className="container-main">
          <div className="reg-wrap">
            <div className="reg-left">
              <h4>Register Today!</h4>
              <h3>Follow these 3 steps & you’ll be all set!</h3>
              <figure>
                <img
                  src={toAbsoluteUrl("/images/donate-box.png")}
                  alt="donate-box"
                />
              </figure>
              <Link to="/registration" className="reg-button">
                Register Now
              </Link>
            </div>
            <div className="reg-right">
              <CardRegister />
            </div>
          </div>
        </div>
      </section> */}

      {/* registration-section */}
      <section className="register-section">
        <div className="container-main">
          <ul>
            <HomeRegistration
              labelText="Rosi Givers"
              registerContent={homepageDetails?.homepage?.individualUser}
              buttonText="Register as a Rosi Giver"
              registrationPath="/registration"
            />
            <HomeRegistration
              labelText="Nonprofits"
              registerContent={homepageDetails?.homepage?.nonProfits}
              buttonText="Register as a Nonprofit"
              registrationPath="/charity-registration"
            />
            <HomeRegistration
              labelText="Giving Partners"
              givingpartner={true}
              registerContent={homepageDetails?.homepage?.givingPartners}
              registrationPath={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signup`}
              buttonText="Register as a Giving Partner"
            />
          </ul>
        </div>
      </section>

      {/* contact us */}
      {/* <section className="contact-us">
        <div className="container">
          <div className="contact-wrapper">
            <h2>At Rosi, we're all about empowering you through choice.</h2>
            <Link to="/contact-us" className="contact-button">
              Contact Us
            </Link>
          </div>
        </div>
      </section> */}

      {/* steps */}
      {/* <section className="homeSteps">
        <div className="container-main">
          <div className="homeSteps-wrap">
            <div className="homeSteps-title">
              <h3>SIMPLE STEPS</h3>
              <h1>
                Becoming A Registered User Is Easy And The Smart Way To Go!
              </h1>
            </div>
            <div className="homeSteps-steps">
              <div className="fourSteps">
                <HomeSteps />
              </div>
              <div className="stepsImage">
                <figure>
                  <img src={toAbsoluteUrl("/images/steps.png")} alt="steps" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* charity */}
      <section className="charity">
        <div className="container-main">
          <div className="charityText">
            {/* <h3>FEATURE NONPROFIT</h3> */}
            <h2>Explore Nonprofits</h2>
            <div className="nonProfit-search">
              <Search
                placeholder="Search Nonprofits by Name or Tax ID/EIN"
                items={nonProfitSearchItems}
                type="nonprofit"
                handleOnSearch={handleOnSearchCharity}
              />
            </div>
          </div>
          <div className="charitiesCard">
            {homepageDetails?.charity?.map((item, ind) => (
              <CharityCard charities={item} key={item?._id} from="homepage" />
            ))}
          </div>
          <Link className="view" to="/charity">
            View All
          </Link>
        </div>
      </section>

      {/* register now */}
      {/* <section className="register-banner">
        <BannerSec
          className="home_register"
          BannerHeading="want to become a nonprofit person?"
          BannerButton="Register Now"
        >
          <Link className="registerHome" to="/charity-registration">
            Register Now
          </Link>
        </BannerSec>
      </section> */}

      {/* causes */}
      <section className="causes">
        <div className="container-main">
          <div className="causeText">
            {/* <h3>FEATURE CAUSES</h3> */}
            <h2>Find Your Causes</h2>
            <div className="cause-search">
              <Search
                placeholder="Search Causes"
                items={causeSearchItems}
                type="cause"
                handleOnSearch={handleOnSearchCause}
              />
            </div>
          </div>
          <div className="causesCard">
            {finalCauseList?.map((cause) => (
              <CauseCard cause={cause} key={cause.id} from="homepage" />
            ))}
          </div>
          <Link className="view" to="/cause">
            View All
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home;
