import React from "react";
import "./about_sec.scss";

const AboutSec = ({
  SectionTitle,
  SectionHeading,
  SectionTitle1,
  SectionPara,
}) => {
  return (
    <>
      <div className="main-about">
        <div className="section-left">
          {/* <h4>{SectionTitle}</h4> */}
          <h1>{SectionHeading}</h1>
        </div>
        <div className="section-right">
          {/* <h5>{SectionTitle1}</h5> */}
          <p>{SectionPara}</p>
        </div>
      </div>
    </>
  );
};

export default AboutSec;
