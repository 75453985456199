/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./../SignIn/signin.scss";
import Swiper from "../../components/common/Swiper";
import CardSingin from "../../components/common/Card_signin";
import { Form, Field } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import { createAccount as validate } from "../../components/validations/signIn";
import {
  renderNumberInput,
  renderTextInput,
} from "../../components/common/FormFields";
import { useDispatch } from "react-redux";
import { doRegister } from "../../actions/auth";
import SwiperText from "../../components/common/SwiperText";
import User from "../../assets/svg/User";
import Email from "../../assets/svg/email";
import Lock from "../../assets/svg/lock";
import Mobile from "../../assets/svg/Mobile";
import toast from "react-hot-toast";
import { getFCMToken, messaging } from "../../utils/firebase";
import { getToken } from "firebase/messaging";

function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // const [isTokenFound, setTokenFound] = useState(false);
  // const [getFcmToken, setFcmToken] = useState("");

  const is_ios_webview = window
    ? /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
        window.navigator.userAgent
      )
    : true;

  // useEffect(() => {
  //   if (!is_ios_webview) {
  //     getFCMToken(setTokenFound, setFcmToken);
  //     if (isTokenFound && getFcmToken) {
  //       localStorage.setItem("fcmtoken", getFcmToken);
  //     }
  //   }
  // }, [is_ios_webview, isTokenFound, getFcmToken]);

  const onFormSubmit = async (data) => {
    let token = "";
    try {
      if (!is_ios_webview) {
        token = await getToken(messaging, {
          vapidKey:
            "BBNMq4fCdftBI6wQTe2Y7hCP2WbAt-9cfWucar88PXY_ViAMv2nynzLzNsChCqCrvg9YXYlYIRc4oao4uJIUsfI",
        });
      }
    } catch (err) {
      console.log("err: ", err);
    }
    if (!isChecked) {
      toast.error("You must agree with the Terms of Use");
      return;
    }
    const payload = {
      ...data,
      userType: 1,
      // gender: "male",
      fcmToken: token,
      deviceType: "web",
      phoneNumber: `${data.phoneNumber?.replace(/[^\d]/g, "")}`,
      countryCode: "+1",
    };
    dispatch(doRegister(payload))
      .then((res) => {
        navigate("/verification", {
          state: { phoneNumber: data.phoneNumber },
          replace: true,
        });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <section className="signin">
      <div className="container-main">
        <div className="signin-wrapper">
          <div className="signinSwiper">
            <Swiper />
            <SwiperText />
          </div>
          <CardSingin title="Registration">
            <Form onSubmit={onFormSubmit} validate={validate}>
              {({ handleSubmit }) => (
                <>
                  <form className="form" onSubmit={handleSubmit}>
                    <ul>
                      <li className="main-input">
                        <Field
                          name="firstName"
                          type="text"
                          component={renderTextInput}
                          placeholder="First Name"
                          containerClasses="form-group"
                        >
                          <User />
                        </Field>
                      </li>
                      <li className="main-input">
                        <Field
                          name="lastName"
                          type="text"
                          component={renderTextInput}
                          placeholder="Last Name"
                          containerClasses="form-group"
                        >
                          <User />
                        </Field>
                      </li>
                      <li className="main-input">
                        <Field
                          name="email"
                          type="text"
                          component={renderTextInput}
                          placeholder="Email Address"
                          containerClasses="form-group"
                        >
                          <Email />
                        </Field>
                      </li>
                      <li className="main-input">
                        <Field
                          name="phoneNumber"
                          type="number"
                          component={renderNumberInput}
                          placeholder="Phone Number"
                          containerClasses="form-group"
                        >
                          <Mobile />
                        </Field>
                      </li>
                      <li className="main-input">
                        <Field
                          name="password"
                          type="password"
                          component={renderTextInput}
                          placeholder="Password"
                          containerClasses="form-group"
                        >
                          <Lock />
                        </Field>
                      </li>
                      <li className="main-input">
                        <Field
                          name="confirmPassword"
                          type="password"
                          component={renderTextInput}
                          placeholder="Confirm Password"
                          containerClasses="form-group"
                        >
                          <Lock />
                        </Field>
                      </li>
                    </ul>
                    <div className="address-terms">
                      <div className="basketCheckbox">
                        <label className="check-label rememberReg">
                          <h5>
                            I agree to the{" "}
                            <Link to="/terms" target="_blank">
                              Terms of Use{" "}
                            </Link>
                            of the Rosi Giving Platform.
                          </h5>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={true}
                              onChange={() => ""}
                              checked={isChecked}
                              // defaultChecked={isChecked}
                              onClick={() => setIsChecked((prev) => !prev)}
                            />
                            <span className="checkmark"></span>
                          </div>
                        </label>
                      </div>
                    </div>
                    {/* <ul>
                        <li>
                          <div className="forgot">
                            <div className="remember">
                              <input
                                className="check"
                                type="checkbox"
                                id="rem"
                                defaultChecked={isRemembered}
                                onChange={() =>
                                  setIsRemembered((prev) => !prev)
                                }
                              />
                              <label htmlFor="rem">Remember Me</label>
                            </div>
                          </div>
                        </li>
                      </ul> */}
                    {error && (
                      <h4 style={{ color: "red", margin: "10px" }}>{error}</h4>
                    )}
                    <ul>
                      <li className="sign-btn">
                        <button className="buttonSubmit" type="submit">
                          Complete Registration
                        </button>
                      </li>
                      <li className="signin-account">
                        <h5>
                          Already Have An Account ?
                          <Link to="/signin">Sign In</Link>
                        </h5>
                      </li>
                    </ul>
                  </form>
                </>
              )}
            </Form>
          </CardSingin>
        </div>
      </div>
    </section>
  );
}

export default Registration;
