import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { renderRadioButton } from "../../../components/forms";
import "../tabs.scss";
import { doRegister, doUploadImage } from "../../../actions/charityRegister";
import StarRatings from "react-star-ratings";
import {
  ADD_CHARITY_DETAILS_STEPS_1,
  ADD_CHARITY_DETAILS_STEPS_2,
  ADD_CHARITY_DETAILS_STEPS_3,
  ADD_CHARITY_DETAILS_STEPS_4,
} from "../../../constants/types";
import localforage from "localforage";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Tookit from "../../svg/tookit";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Star = (props) => {
  const {
    completedSteps,
    setCompletedSteps,
    setErrorMessages,
    showStarRating,
    from,
  } = props;
  const { step_1, step_2, step_3 } = useSelector(
    (state) => state.charityRegister
  );
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [impactResults, setimpactResults] = useState();
  const [cultureCommunity, setcultureCommunity] = useState();
  const [leadershipAdaptability, setleadershipAdaptability] = useState();
  const [accountabilityFinance, setaccountabilityFinance] = useState();
  const [overAllRating, setOverAllRating] = useState();

  const [impactResultsErrorMes, setimpactResultsErrorMes] = useState("");
  const [cultureCommunityErrorMes, setcultureCommunityErrorMes] = useState("");
  const [leadershipAdaptabilityErrorMes, setleadershipAdaptabilityErrorMes] =
    useState("");
  const [accountabilityFinanceErrorMes, setaccountabilityFinanceErrorMes] =
    useState("");
  const [ovarAllRatingErrorMes, setOverAllRatingErrorMes] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const addAllValueToRedux = async () => {
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_1,
      payload: await localforage.getItem("charity_reg_step_1"),
    });
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_2,
      payload: await localforage.getItem("charity_reg_step_2"),
    });
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_3,
      payload: await localforage.getItem("charity_reg_step_3"),
    });
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_4,
      payload: await localforage.getItem("charity_reg_step_4"),
    });
  };

  useEffect(() => {
    setError();
  }, [from]);

  useEffect(() => {
    addAllValueToRedux();
    // eslint-disable-next-line
  }, []);

  const uploadCharityLogo = () => {
    const payload = { image: step_2?.charitylogo, moduleName: "charityLogo" };
    return dispatch(doUploadImage(payload));
  };

  const uploadOtherImages = () => {
    return step_2?.charityimages?.map((image, ind) => {
      const payload = { image: [image], moduleName: "charityImages" };
      return dispatch(doUploadImage(payload));
    });
  };

  const onSubmit = (value) => {
    if (
      from !== "noRating" &&
      !impactResults &&
      !cultureCommunity &&
      !leadershipAdaptability &&
      !accountabilityFinance &&
      !overAllRating
    ) {
      setError("Please choose atleast one rating");
      return;
    }
    if (!value.radio) {
      setError("Please select Guidestar / Candid seal.");
      return;
    }

    if (
      impactResultsErrorMes ||
      accountabilityFinanceErrorMes ||
      cultureCommunityErrorMes ||
      leadershipAdaptabilityErrorMes ||
      ovarAllRatingErrorMes
    ) {
      return;
    }

    if (!isChecked) {
      toast.error("You must agree with the Terms of Use");
      return;
    }

    const phoneno = step_2?.phoneno;
    const data = {
      starRating: {
        impactResults: impactResults,
        accountabilityFinance: accountabilityFinance,
        cultureCommunity: cultureCommunity,
        leadershipAdaptability: leadershipAdaptability,
        ratingAvg: overAllRating,
      },
      ratingType: 2,
      guideStar: Number(value.radio),
    };

    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_4,
      payload: data,
    });
    const step2 = {
      description: step_2?.description,
      brief_description: step_2?.brief_description,
      phoneno: step_2?.phoneno,
      tagline: step_2?.tagline,
      logo: step_2?.logo,
      mediaImage: step_2?.mediaImage,
      // address: step_2.address,
      address: {
        floor: step_2?.address?.floor,
        number: step_2?.address?.number,
        street: step_2?.address?.street,
        street2: step_2?.address?.street2,
        city: step_2?.address?.city?.label,
        state: step_2?.address?.state?.label,
        zipcode: step_2?.address?.zipcode,
        country: step_2?.address?.country?.label,
      },
      countryCode: step_2?.countryCode,
    };
    const step3 = {
      ...step_3,
      categoryId: step_3?.category?.value,
      charityFilter: step_3?.charityFilter?.value,
      relatedCause: step_3?.relatedCause?.map((cause) => cause.value),
    };
    let finalData = {};
    if (phoneno)
      finalData = {
        ...step_1,
        ...step2,
        ...step3,
        ...data,
        isCreatedByAdmin: false,
        phoneno,
      };
    else {
      finalData = {
        ...step_1,
        ...step2,
        ...step3,
        ...data,
        isCreatedByAdmin: false,
      };
    }

    if (from === "noRating") {
      delete finalData.starRating;
      finalData = {
        ...finalData,
        ratingType: 3,
      };
    }
    let charityLogo = null;
    let charityImages = [];
    if (step_2?.charitylogo || step_2?.charityimages) {
      const promises = [];
      if (step_2?.charitylogo) promises.push(uploadCharityLogo());
      if (step_2?.charityimages) promises.push(...uploadOtherImages());

      Promise.allSettled(promises).then((res) => {
        if (res[0]?.status === "fulfilled") {
          charityLogo = res[0].value?.data?.name;
        }
        res
          .filter((_, ind) => ind !== 0)
          .forEach((value) => {
            if (value?.status === "fulfilled") {
              charityImages.push({
                name: value.value?.data?.name,
                url: mediaURL + "charityImages/" + value.value?.data?.name,
              });
            }
            return null;
          });
        finalData = {
          ...finalData,
          logo: charityLogo,
          mediaImage: charityImages,
        };
        doRegisterHandler(finalData);
      });
    } else {
      doRegisterHandler(finalData);
    }
    const newCompleted = completedSteps;
    newCompleted[3] = true;
    setCompletedSteps(newCompleted);
  };

  const doRegisterHandler = (finalData) => {
    dispatch(doRegister(finalData))
      .then(() => {
        props.setSteps(4);
        props.setcompleted(true);
        if (props?.setclass) {
          props.setclass(true);
        }
        localforage.removeItem("charity_reg_step_1");
        localforage.removeItem("charity_reg_step_2");
        localforage.removeItem("charity_reg_step_3");
        localforage.removeItem("charity_reg_step_4");
        localforage.removeItem("curr_reg_step");

        setErrorMessages([]);
        setError("");
      })
      .catch((err) => {
        setError("");
        setErrorMessages(err.message);
      });
  };

  const RatingValue = (value) => {
    if (value >= 90) {
      return 4;
    } else if (value < 90 && value >= 75) {
      return 3;
    } else if (value < 75 && value >= 60) {
      return 2;
    } else if (value < 60 && value >= 50) {
      return 1;
    } else {
      return 0;
    }
  };

  const onChangeCommon = (inputType, e) => {
    if (inputType === "0") {
      setOverAllRating(Number(e.target.value));
      RatingValue(Number(e.target.value));
      if (
        0 > e.target.value ||
        e.target.value > 100 ||
        e.target.value.toString().includes(".") ||
        isNaN(e.target.value)
      ) {
        setOverAllRatingErrorMes("Please Enter Valid Number");
      } else {
        setOverAllRatingErrorMes("");
      }
    }
    if (inputType === "1") {
      setimpactResults(Number(e.target.value));
      RatingValue(Number(e.target.value));
      if (
        0 > e.target.value ||
        e.target.value > 100 ||
        e.target.value.toString().includes(".") ||
        isNaN(e.target.value)
      ) {
        setimpactResultsErrorMes("Please Enter Valid Number");
      } else {
        setimpactResultsErrorMes("");
      }
    }
    if (inputType === "2") {
      setaccountabilityFinance(Number(e.target.value));
      RatingValue(Number(e.target.value));
      if (
        0 > e.target.value ||
        e.target.value > 100 ||
        e.target.value.toString().includes(".") ||
        isNaN(e.target.value)
      ) {
        setaccountabilityFinanceErrorMes("Please Enter Valid Number");
      } else setaccountabilityFinanceErrorMes("");
    }
    if (inputType === "3") {
      setcultureCommunity(Number(e.target.value));
      RatingValue(Number(e.target.value));
      if (
        0 > e.target.value ||
        e.target.value > 100 ||
        e.target.value.toString().includes(".") ||
        isNaN(e.target.value)
      ) {
        setcultureCommunityErrorMes("Please Enter Valid Number");
      } else setcultureCommunityErrorMes("");
    }
    if (inputType === "4") {
      setleadershipAdaptability(Number(e.target.value));
      RatingValue(Number(e.target.value));
      if (
        0 > e.target.value ||
        e.target.value > 100 ||
        e.target.value.toString().includes(".") ||
        isNaN(e.target.value)
      ) {
        setleadershipAdaptabilityErrorMes("Please Enter Valid Number");
      } else setleadershipAdaptabilityErrorMes("");
    }
  };
  return (
    <>
      <div className="section-steps star-section">
        <div className="col-12 pl-0 pr-0">
          <Form
            onSubmit={onSubmit}
            //validate={values}
            initialValues={{
              // governanceBenchmark:
              //   {
              //     value: props?.ratingDetails?.charityId,
              //     label: governanceBenchmarkLable,
              //   } || "",
              // gradeReceive:
              //   {
              //     value: props?.ratingDetails?.charityId,
              //     label: gradeReceiveLable,
              //   } || "",
              // transperancyBenchmark:
              //   {
              //     value: props?.ratingDetails?.charityId,
              //     label: transperancyBenchmarkLable,
              //   } || "",
              radio: props?.ratingDetails?.guideStar?.toString(),
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="form-box">
                {showStarRating && (
                  <div className="rating-box">
                    <div className="rating-inner-box">
                      <div className="overAll-rating-wrap">
                        <div className="overAll-rating">
                          <span>Overall Star Rating:</span>
                          <StarRatings
                            rating={RatingValue(overAllRating)}
                            starDimension="30px"
                            starSpacing="5px"
                            starRatedColor="#234ae0"
                            numberOfStars={4}
                          />
                        </div>
                        <div className="overall-score">
                          <span>Overall Score:</span>
                          {/* <span className="percentage-counting">
                          {(impactResults +
                            accountabilityFinance +
                            cultureCommunity +
                            leadershipAdaptability) /
                            4 || 0}
                          %
                        </span> */}
                          <div className="scoring-wrap">
                            <div className="score-addon-rating">
                              <input
                                className="score-counting"
                                value={overAllRating ? overAllRating : null}
                                type="text"
                                onChange={(e) => {
                                  onChangeCommon("0", e);
                                }}
                              />
                              <strong>%</strong>
                            </div>
                            <span className="all-scoring-error">
                              {ovarAllRatingErrorMes}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="rating-innerbox">
                        <div className="inner-boxs">
                          <label className="label-text"></label>
                          <span className="star-ratings">Star Rating :</span>
                          <span className="counting-wrap">Score :</span>
                        </div>
                        <div className="inner-boxs">
                          <label className="label-text">Impact & Results</label>
                          <StarRatings
                            rating={RatingValue(impactResults)}
                            starDimension="30px"
                            starSpacing="5px"
                            starRatedColor="#234ae0"
                            starHoverColor="#234ae0"
                            numberOfStars={4}
                            className="step-ratings"

                          // initialRating={RatingValue(impactResults)}
                          // changeRating={RatingValue(impactResults)}
                          />
                          <div className="counting-wrap">
                            <div className="reg-addon-rating">
                              <input
                                className="percentage-counting"
                                value={impactResults ? impactResults : null}
                                type="text"
                                onChange={(e) => onChangeCommon("1", e)}
                              />
                              <strong>%</strong>
                            </div>
                            <span className="rating-error">
                              {impactResultsErrorMes}
                            </span>
                          </div>
                        </div>
                        <div className="inner-boxs">
                          <label className="label-text">
                            Accountability & Finance{" "}
                          </label>
                          <StarRatings
                            numberOfStars={4}
                            rating={RatingValue(accountabilityFinance)}
                            starDimension="30px"
                            starSpacing="5px"
                            starRatedColor="#234ae0"
                            starHoverColor="#234ae0"
                            initialRating={RatingValue(accountabilityFinance)}
                          // changeRating={accountabilityRatingHandler}
                          />
                          <div className="counting-wrap">
                            <div className="reg-addon-rating">
                              <input
                                className="percentage-counting"
                                value={
                                  accountabilityFinance
                                    ? accountabilityFinance
                                    : null
                                }
                                type="text"
                                onChange={(e) => {
                                  onChangeCommon("2", e);
                                }}
                              />
                              <strong>%</strong>
                            </div>
                            <span className="rating-error">
                              {accountabilityFinanceErrorMes}
                            </span>
                          </div>
                        </div>
                        <div className="inner-boxs">
                          <label className="label-text">
                            Culture & Community{" "}
                          </label>
                          <StarRatings
                            numberOfStars={4}
                            rating={RatingValue(cultureCommunity)}
                            starDimension="30px"
                            starSpacing="5px"
                            starRatedColor="#234ae0"
                            starHoverColor="#234ae0"
                            initialRating={RatingValue(cultureCommunity)}
                          // changeRating={accountabilityRatingHandler}
                          />
                          <div className="counting-wrap">
                            <div className="reg-addon-rating">
                              <input
                                className="percentage-counting"
                                value={
                                  cultureCommunity ? cultureCommunity : null
                                }
                                type="text"
                                onChange={(e) => onChangeCommon("3", e)}
                              />
                              <strong>%</strong>
                            </div>
                            <span className="rating-error">
                              {cultureCommunityErrorMes}
                            </span>
                          </div>
                        </div>
                        <div className="inner-boxs">
                          <label className="label-text">
                            Leadership & Adaptability{" "}
                          </label>
                          <StarRatings
                            numberOfStars={4}
                            rating={RatingValue(leadershipAdaptability)}
                            starDimension="30px"
                            starSpacing="5px"
                            starRatedColor="#234ae0"
                            starHoverColor="#234ae0"
                            initialRating={RatingValue(leadershipAdaptability)}
                          // changeRating={accountabilityRatingHandler}
                          />
                          <div className="counting-wrap">
                            <div className="reg-addon-rating">
                              <input
                                className="percentage-counting"
                                value={
                                  leadershipAdaptability
                                    ? leadershipAdaptability
                                    : null
                                }
                                type="text"
                                onChange={(e) => onChangeCommon("4", e)}
                              />
                              <strong>%</strong>
                            </div>
                            <span className="rating-error">
                              {leadershipAdaptabilityErrorMes}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <ul className="input-list">
                  <li>
                    <label className="navigator">
                      {" "}
                      Guidestar / Candid Seal :{" "}
                      <span className="tooltip-information-wrap" data-tooltip-id="candidSeal"><Tookit /></span>
                      <ReactTooltip
                        id="candidSeal"
                        place="top"
                        variant="success"
                        content="If you have a Seal from Guidestar/Candid, please indicate it here or specify that you have no Seal. Seals to choose from are: Bronze, Silver, Gold or Platinum."
                      />
                    </label>
                    <div className="checkbox-list">
                      <Field
                        name="radio"
                        type="radio"
                        value="1"
                        className="check-label label-text"
                        component={renderRadioButton}
                      >
                        Bronze Seal
                      </Field>

                      <Field
                        name="radio"
                        type="radio"
                        value="2"
                        className="check-label label-text"
                        component={renderRadioButton}
                      >
                        Silver Seal
                      </Field>

                      <Field
                        name="radio"
                        type="radio"
                        value="3"
                        className="check-label label-text"
                        component={renderRadioButton}
                      >
                        Gold Seal
                      </Field>

                      <Field
                        name="radio"
                        type="radio"
                        value="4"
                        className="check-label label-text"
                        component={renderRadioButton}
                      >
                        Platinum Seal
                      </Field>

                      <Field
                        name="radio"
                        type="radio"
                        value="5"
                        className="check-label label-text"
                        component={renderRadioButton}
                      // validate={(value) =>
                      //   required(value, "We Do Not have a Seal")
                      // }
                      >
                        We Do Not have a Seal
                      </Field>
                    </div>
                    <p className="verification_error">{error}</p>
                  </li>
                  {/* <li>
                    <label className="label-text navigator">
                      {" "}
                      Charity Watch :{" "}
                    </label>
                    <div className="div-checkbox">
                      <div className="inner-checkbox">
                        <label className="label-text">
                          {" "}
                          1. What is the grade receive ?
                        </label>
                        {/* <Select options={options} /> */}
                  {/*<Field
                          name="gradeReceive"
                          component={renderSelectField}
                          validate={(value) => required(value, "Grade Receive")}
                          className="dropdownGrp"
                          options={gradeReceiveOptions}
                          isMulti={false}
                        />*}
                      </div>
                      <div className="inner-checkbox">
                        <label className="label-text">
                          {" "}
                          2. Does the Nonprofit organization meet governance benchmarks?
                        </label>
                        {/* <Select className='select-box' options={options2} /> */}
                  {/*<Field
                          name="governanceBenchmark"
                          component={renderSelectField}
                          validate={(value) =>
                            required(value, "Governance Benchmark")
                          }
                          className="select-box dropdownGrp"
                          options={governanceBenchmarkOptions}
                          isMulti={false}
                        />}
                      </div>
                      <div className="inner-checkbox">
                        <label className="label-text">
                          {" "}
                          3. Does the Nonprofit organization meet transparency benchmarks?
                        </label>
                        {/* <Select className='select-box' options={options3} /> */}
                  {/*<Field
                          name="transperancyBenchmark"
                          component={renderSelectField}
                          validate={(value) =>
                            required(value, "Transperancy Benchmark")
                          }
                          className="select-box dropdownGrp"
                          options={transperancyBenchmarkOptions}
                          isMulti={false}
                        />}
                      </div>
                    </div>
                  </li> */}
                </ul>
                <div className="address-terms">
                  <div className="basketCheckbox">
                    <label className="check-label rememberReg">
                      <h5>
                        I agree to the{" "}
                        <Link to="/terms" target="_blank">
                          Terms of Use{" "}
                        </Link>
                        of the Rosi Giving Platform.
                      </h5>
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={isChecked}
                          onClick={() => setIsChecked((prev) => !prev)}
                        />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-between w-100 btn-boxs">
                  <div className="button-box">
                    <button
                      type="button"
                      className="link-btn"
                      onClick={() => {
                        props.onPrevClick();
                        props.setclass(false);
                      }}
                    >
                      prev
                    </button>
                  </div>
                  <div className="button-box">
                    <button type="submit" className="link-btn">
                      Done
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default Star;
