import React from "react";

function Telephone() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1488 9.98898L14.3501 12.083C14.6839 12.4005 14.6839 12.9328 14.3501 13.2503C12.672 14.8466 10.0974 15.0281 8.21193 13.6829L7.04363 12.8494C5.32421 11.6227 3.78764 10.1581 2.47992 8.49941L1.74586 7.56836C0.30054 5.73516 0.488701 3.10297 2.18009 1.494C2.47099 1.21727 2.92779 1.21727 3.2187 1.494L5.44182 3.60879C5.85618 4.00296 5.85618 4.66371 5.44182 5.05787L4.69647 5.76691C4.55148 5.90483 4.51568 6.12223 4.60878 6.29936C5.64397 8.26884 7.28946 9.84987 9.29873 10.8055L9.41897 10.8627C9.59281 10.9454 9.79962 10.9124 9.93909 10.7797L10.7704 9.98898C11.1565 9.6217 11.7627 9.6217 12.1488 9.98898Z"
          stroke="#4F4F4F" fill="none"
        />
      </svg>
    </>
  );
}

export default Telephone;
