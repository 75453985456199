import React from "react";

import "./banner_sec.scss";

const BannerSec = ({ BannerHeading, className, children }) => {
  return (
    <>
      {/* <div className='banner-sec {className}'> */}
      <div className={`banner-sec ${className}`}>
        {/* <h3>OPEN YOUR HEART</h3> */}
        <h2>{BannerHeading}</h2>
        {children}
      </div>
    </>
  );
};

export default BannerSec;
