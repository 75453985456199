import { STRATEGIC_DETAIL_S, STRATEGIC_DETAIL_F } from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  strategicDetails: {},
};

const strategicReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGIC_DETAIL_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        strategicDetails: action.payload.data,
      };
    case STRATEGIC_DETAIL_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        strategicDetails: {},
      };
    default:
      return state;
  }
};

export default strategicReducer;
