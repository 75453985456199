import React from "react";
import CustomTab from "../../../../components/common/Tabs";
import PastEvent from "./Past Event";
import UpcomingEvent from "./Upcoming Event";
import "./events.scss";

function Events({ id, from }) {
  const data = [
    {
      heading: "Upcoming Events",
      body: <UpcomingEvent id={id} from={from} />,
    },
    {
      heading: "Past Events",
      body: <PastEvent id={id} from={from} />,
    },
  ];

  return (
    <>
      <div className="eventTab">
        <CustomTab className="tabsForEvent" data={data} />
      </div>
    </>
  );
}

export default Events;
