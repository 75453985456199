import React from "react";
import CustomTab from "../../../../components/common/Tabs";
import Image from "./Image";
import "./media.scss";
import Video from "./Video";

function Media({ mediaImage, mediaVideo }) {
  const data = [
    {
      heading: "Image",
      body: <Image mediaImage={mediaImage} />,
    },
    {
      heading: "Video",
      body: <Video mediaVideo={mediaVideo} moduleName="charityImages/" />,
    },
  ];
  return (
    <>
      <div className="media">
        <CustomTab className="mediaTab" data={data} />
      </div>
    </>
  );
}

export default Media;
