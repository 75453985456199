import { API_GIVING_PARTNER_DETAILS } from "../constants/api";
import {
  API,
  GIVING_PARTNER_DETAILS_F,
  GIVING_PARTNER_DETAILS_S,
} from "../constants/types";

export const givingPartnerDetailsAction = (showLoader = false) => ({
  type: API,
  payload: {
    url: API_GIVING_PARTNER_DETAILS,
    method: "GET",
    // data: data,
    showLoader,
    success: (data) => ({
      type: GIVING_PARTNER_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: GIVING_PARTNER_DETAILS_F,
      payload: data,
    }),
  },
});
