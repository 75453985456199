import {
  FAQ_CATEGORY_LIST_F,
  FAQ_CATEGORY_LIST_S,
  FAQ_LIST_F,
  FAQ_LIST_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  faqCategory: {},
  faqList: {},
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_CATEGORY_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        faqCategory: action.payload.data,
      };
    case FAQ_CATEGORY_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        faqCategory: {},
      };
    case FAQ_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        faqList: action.payload.data,
      };
    case FAQ_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        faqList: {},
      };
    default:
      return state;
  }
};

export default faqReducer;
