import React from "react";

function Time() {
  return (
    <>
      <svg
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2139 7.49958H8.72986C8.59179 7.49958 8.47986 7.38765 8.47986 7.24958V4.71387"
          stroke="#22841a"
          strokeLinecap="round"
        />
        <path
          d="M8.47989 14.5C12.3625 14.5 15.5145 11.3685 15.5145 7.5C15.5145 3.63151 12.3625 0.5 8.47989 0.5C4.5973 0.5 1.44531 3.63151 1.44531 7.5C1.44531 11.3685 4.5973 14.5 8.47989 14.5Z"
          stroke="#6DAF5F"
          fill="none"
        />
      </svg>
    </>
  );
}

export default Time;
