import React, { useEffect } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { renderCommunityList } from "../../../../actions/community";
import CommunityCard from "../../communityCard";
import "./communityFavorite.scss";

function CommunityFavorite({ charityFavList }) {
  const dispatch = useDispatch();
  const { allCommunityList } = useSelector((state) => state.community);

  const fetchData = () => {
    dispatch(renderCommunityList({ limit: 100000, page: 1 }));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScrollBar component="div">
        <div className="favoriteScroll">
          <div className="favoriteCommunity">
            {allCommunityList?.list?.length > 0 ? (
              allCommunityList?.list?.map((community) => (
                <CommunityCard
                  classname="associatedButton"
                  community={community}
                  isAssociated={true}
                  key={community?._id}
                  setData={allCommunityList?.list}
                  page={1}
                  limit={100000}
                />
              ))
            ) : (
              <p className="verification_error">
                You don't have any favorite community
              </p>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default CommunityFavorite;
