import moment from "moment";
import React, { useState } from "react";
import Cancle from "../../assets/svg/cancle";
import { notficationdelete, notificationlist } from "../../actions/notificaion";
import NotificationBell from "../../assets/svg/notification";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const NotificationData = ({ item, idx }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [readDesc, setReadDesc] = useState(true);

  const handledelete = (id) => {
    dispatch(notficationdelete(id)).then(() =>
      dispatch(
        notificationlist(
          {
            page: 1,
            limit: 10,
          },
          false,
          true
        )
      )
    );
  };

  const handleNotification = (item) => {
    if (item?.notification[0].data?.causes) {
      navigate(`/new-add-cause/${item?._id}`);
    } else if (item?.notification[0]?.data?.charities) {
      navigate(`/new-add-charity/${item?._id}`);
    }
  };

  return (
    <>
      <div className="notificationCard" key={item?.idx}>
        <figure className="notificationIcon">
          <NotificationBell />
        </figure>
        <div
          className="notificationData"
          onClick={() => handleNotification(item)}
        >
          <p>{item?.notification?.[0]?.title}</p>
          <h5>{moment(item?.createdDate).format("MM/DD/YYYY hh:mm A")}</h5>
          {item?.notification?.[0]?.data?.adminDescription ? (
            <strong>{item?.notification?.[0]?.data?.adminDescription}</strong>
          ) : (
            item?.notification?.[0]?.body
              ?.split("\n")
              .map(function (item, idx) {
                return (
                  <strong key={idx}>
                    {item}
                    <br />
                  </strong>
                );
              })
          )}
          <br />
          <br />
          {item?.notification?.[0]?.data?.adminDescription ? (
            !readDesc && item?.notification?.[0]?.data?.details?.length > 0 ? (
              item?.notification?.[0]?.data?.details?.map(function (item, idx) {
                return (
                  <>
                    <strong
                      key={idx}
                      className={`${"notification-bold"} ${
                        !readDesc ? "" : "readLess"
                      }`}
                    >
                      {`- ${item}`}
                      <br />
                    </strong>
                  </>
                );
              })
            ) : (
              item?.notification?.[0]?.data?.details?.length >= 5 &&
              item?.notification?.[0]?.data?.details
                ?.slice(0, 4)
                ?.map(function (item, idx) {
                  return (
                    <>
                      <strong key={idx} className={!readDesc ? "" : "readLess"}>
                        {`- ${item}`}
                        <br />
                      </strong>
                    </>
                  );
                })
            )
          ) : (
            <></>
          )}

          <div className="read-btn">
            <button
              className={
                item?.notification?.[0]?.data?.details?.length < 5 ||
                !item?.notification?.[0]?.data?.adminDescription
                  ? "hideBtn"
                  : ""
              }
              onClick={(e) => {
                e.stopPropagation();
                setReadDesc((prev) => !prev);
              }}
            >
              {readDesc ? "Read More" : "Read Less"}
            </button>
          </div>
        </div>
        <button
          className="cancleButton"
          onClick={() => handledelete(item?._id)}
        >
          <Cancle />
        </button>
      </div>
    </>
  );
};

export default NotificationData;
