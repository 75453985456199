import React from "react";
import "./formField.scss";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Birthday from "../../../assets/svg/birthday";
import moment from "moment";
import store from "../../../store";
import { addTOCity } from "../../../actions/charityRegister";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';



export const renderTextInput = ({
  input,
  type,
  children,
  placeholder,
  containerClasses,
  disabled,
  extraClass,
  imageUrl,
  imageAlt,
  setQuickCashActiveBtn,
  setIsEmailChanged,
  from,
  meta: { touched, error },
}) => (
  <>
    <div className="forms">
      <div
        className={`${touched && error && extraClass ? extraClass : containerClasses
          }`}
      >
        <input
          {...input}
          disabled={disabled}
          onChange={(e) => {
            if (setQuickCashActiveBtn) {
              setQuickCashActiveBtn(0);
            }
            if (setIsEmailChanged) {
              setIsEmailChanged(true);
            }
            input.onChange(e);
          }}
          onWheel={(e) => e.target.blur()}
          // value={input.value}
          placeholder={placeholder}
        />
        <span>{children}</span>
      </div>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </div>
  </>
);

export const renderNumberInput = ({
  input,
  type,
  placeholder,
  containerClasses,
  disabled,
  children,
  imageUrl,
  imageAlt,
  numberFormat,
  meta: { touched, error },
}) => (
  <>
    <div className="forms">
      <div className={containerClasses}>
        <NumberFormat
          {...input}
          type={type}
          format={numberFormat ? numberFormat : "(###) ###-####"}
          disabled={disabled}
          placeholder={placeholder}
        />
        <span>{children}</span>
      </div>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </div>
  </>
);

export const renderSelectField = ({
  input,
  options,
  className,
  isMulti,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  classname,
  defaultValue,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <Select
        {...input}
        value={input.value}
        // defaultValue={{ value: "Help Girl Child", label: "Help Girl Child" }}
        isMulti={isMulti}
        onChange={(e) => {
          if (isCountryField) {
            mutator("state", "");
            setselectedCountry(e);
            input.onChange(e);
          } else if (isStateField) {
            mutator("city", "");
            setselectedState(e);
            input.onChange(e);
          } else {
            input.onChange(e);
          }
        }}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
        className={classname}
      // defaultInputValue={defaultValue}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderSelect = ({
  input,
  name,
  option,
  list,
  placeholder,
  id,
  className,
  extraClass,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className="forms">
        <div
          className={`${touched && error && extraClass ? extraClass : "form-group"
            } formGroup`}
        >
          <select {...input} className={className}>
            <option value="">{placeholder}</option>
            {list.map((value, i) => (
              <option key={value?.value} value={value.option}>
                {" "}
                {value.name}{" "}
              </option>
            ))}
          </select>
        </div>
        <div className="input-error">
          {touched && error && (
            <span className="mb-0 text-right text-danger">{error}</span>
          )}
        </div>
      </div>
    </>
  );
};

export const renderDate = ({
  input,
  input: { value, onChange },
  name,
  placeholder,
  containerClasses,
  extraClass,
  setMinDate,
  setDateSelectedMessage,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className="forms">
        <div
          className={`${touched && error && extraClass ? extraClass : containerClasses
            }`}
        >
          <DatePicker
            name={name}
            placeholderText={placeholder}
            maxDate={new Date()}
            minDate={setMinDate ? new Date() : null}
            selected={value ? value : null}
            showYearDropdown
            yearDropdownItemNumber={80}
            scrollableYearDropdown
            onChange={(date) => {
              if (moment(date).isValid()) {
                onChange(date);
              } else {
                onChange(null);
              }

              if (setDateSelectedMessage) {
                setDateSelectedMessage(
                  "If you select date Then Amount will Deduct Today"
                );
              }
            }}
          // onKeyDown={(e) => {
          //   e.preventDefault();
          // }}
          />
          <Birthday />
        </div>
        <div className="input-error">
          {touched && error && (
            <span className="mb-0 text-right text-danger">{error}</span>
          )}
        </div>
      </div>
    </>
  );
};

export const EditSelectField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <Select
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            setselectedCity("");
            await store.dispatch(addTOCity({ stateId: e?.value }));
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
        styles={{
          height: "500px",
        }}
      />
    </div>
  </>
);

export const EditCityField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <CreatableSelect
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            setselectedCity("");
            await store.dispatch(addTOCity({ stateId: e?.value }));
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
        styles={{
          height: "500px",
        }}
      />
    </div>
  </>
);

export const renderNumberWithCommas = ({
  input,
  type,
  children,
  placeholder,
  containerClasses,
  disabled,
  extraClass,
  imageUrl,
  imageAlt,
  setQuickCashActiveBtn,
  from,
  meta: { touched, error },
}) => (
  <>
    <div className="forms">
      <div
        className={`${touched && error && extraClass ? extraClass : containerClasses
          }`}
      >
        <input
          {...input}
          disabled={disabled}
          onChange={(e) => {
            e = e.target.value?.toString().replace(/,/g, "");
            e = e
              ?.toString()
              // .split(".")[0]
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (setQuickCashActiveBtn) {
              setQuickCashActiveBtn(0);
            }
            input.onChange(e);
          }}
          value={input.value}
          placeholder={placeholder}
        />
        <span>{children}</span>
      </div>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </div>
  </>
);
