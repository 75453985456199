import {
  ADD_GOAL_F,
  ADD_GOAL_S,
  FETCH_USER_DETAILS_F,
  FETCH_USER_DETAILS_S,
  UPDATE_USER_DETAILS_F,
  UPDATE_USER_DETAILS_S,
} from "../constants/types";

const initialState = {
  userDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_S:
    case UPDATE_USER_DETAILS_S:
    case ADD_GOAL_S:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload.data },
      };
    case FETCH_USER_DETAILS_F:
      return { ...state, userDetails: {} };
    case UPDATE_USER_DETAILS_F:
    case ADD_GOAL_F:
      return { ...state, userDetails: { ...state.userDetails } };
    default:
      return state;
  }
};

export default userReducer;
