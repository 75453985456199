import React from "react";
import CustomTab from "../../../../components/common/Tabs";
import Image from "../../../Charity Details/TabsContent/Media/Image";
import Video from "../../../Charity Details/TabsContent/Media/Video";
import "./eventMedia.scss";

function CommunityMedia({ images, videos }) {
  const data = [
    {
      heading: "Image",
      body: <Image mediaImage={images} from="community" />,
    },
    {
      heading: "Video",
      body: <Video mediaVideo={videos} moduleName="communityImages/" />,
    },
  ];
  return (
    <>
      <div className="eventMedia">
        <CustomTab className="mediaTab" data={data} />
      </div>
    </>
  );
}

export default CommunityMedia;
