import React from "react";

const Tookit = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z"
          fill="black"
          fillOpacity="0.85"
        />
        <path
          d="M6.34354 4.59375C6.34354 4.7678 6.41268 4.93472 6.53575 5.05779C6.65882 5.18086 6.82574 5.25 6.99979 5.25C7.17383 5.25 7.34075 5.18086 7.46382 5.05779C7.5869 4.93472 7.65604 4.7678 7.65604 4.59375C7.65604 4.4197 7.5869 4.25278 7.46382 4.12971C7.34075 4.00664 7.17383 3.9375 6.99979 3.9375C6.82574 3.9375 6.65882 4.00664 6.53575 4.12971C6.41268 4.25278 6.34354 4.4197 6.34354 4.59375ZM7.32791 6.125H6.67166C6.61151 6.125 6.56229 6.17422 6.56229 6.23438V9.95312C6.56229 10.0133 6.61151 10.0625 6.67166 10.0625H7.32791C7.38807 10.0625 7.43729 10.0133 7.43729 9.95312V6.23438C7.43729 6.17422 7.38807 6.125 7.32791 6.125Z"
          fill="black"
          fillOpacity="0.85"
        />
      </svg>
    </>
  );
};

export default Tookit;
