import React from "react";

function Birthday() {
  return (
    <>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.96342 3H13.0366C13.9483 2.99999 14.6832 2.99998 15.2612 3.07768C15.8612 3.15836 16.3665 3.33096 16.7678 3.73223C17.169 4.13351 17.3416 4.63876 17.4223 5.23883C17.5 5.81681 17.5 6.55168 17.5 7.4634V8.16667H0.500001L0.5 7.46342C0.499987 6.55169 0.499976 5.81681 0.577683 5.23883C0.65836 4.63876 0.830956 4.13351 1.23223 3.73223C1.63351 3.33096 2.13876 3.15836 2.73883 3.07768C3.31681 2.99998 4.05169 2.99999 4.96342 3ZM2.87208 4.06877C2.3829 4.13453 2.12385 4.25483 1.93934 4.43934C1.75483 4.62385 1.63453 4.8829 1.56877 5.37208C1.50922 5.81501 1.50122 6.38469 1.50016 7.16667H16.4998C16.4988 6.38469 16.4908 5.81501 16.4312 5.37208C16.3655 4.8829 16.2452 4.62385 16.0607 4.43934C15.8762 4.25483 15.6171 4.13453 15.1279 4.06877C14.6244 4.00106 13.9569 4 13 4H5C4.04306 4 3.37565 4.00106 2.87208 4.06877Z"
          fill="#4F4F4F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.96342 3H13.0366C13.9483 2.99999 14.6832 2.99998 15.2612 3.07768C15.8612 3.15836 16.3665 3.33096 16.7678 3.73223C17.169 4.13351 17.3416 4.63876 17.4223 5.23883C17.5 5.81681 17.5 6.55168 17.5 7.4634V12.0366C17.5 12.9483 17.5 13.6832 17.4223 14.2612C17.3416 14.8612 17.169 15.3665 16.7678 15.7678C16.3665 16.169 15.8612 16.3416 15.2612 16.4223C14.6832 16.5 13.9483 16.5 13.0366 16.5H4.96343C4.0517 16.5 3.31681 16.5 2.73883 16.4223C2.13876 16.3416 1.63351 16.169 1.23223 15.7678C0.830956 15.3665 0.65836 14.8612 0.577683 14.2612C0.499976 13.6832 0.499987 12.9483 0.5 12.0366V7.46342C0.499987 6.55169 0.499976 5.81681 0.577683 5.23883C0.65836 4.63876 0.830956 4.13351 1.23223 3.73223C1.63351 3.33096 2.13876 3.15836 2.73883 3.07768C3.31681 2.99998 4.05169 2.99999 4.96342 3ZM2.87208 4.06877C2.3829 4.13453 2.12385 4.25483 1.93934 4.43934C1.75483 4.62385 1.63453 4.8829 1.56877 5.37208C1.50106 5.87565 1.5 6.54306 1.5 7.5V12C1.5 12.9569 1.50106 13.6244 1.56877 14.1279C1.63453 14.6171 1.75483 14.8762 1.93934 15.0607C2.12385 15.2452 2.3829 15.3655 2.87208 15.4312C3.37565 15.4989 4.04306 15.5 5 15.5H13C13.9569 15.5 14.6244 15.4989 15.1279 15.4312C15.6171 15.3655 15.8762 15.2452 16.0607 15.0607C16.2452 14.8762 16.3655 14.6171 16.4312 14.1279C16.4989 13.6244 16.5 12.9569 16.5 12V7.5C16.5 6.54306 16.4989 5.87565 16.4312 5.37208C16.3655 4.8829 16.2452 4.62385 16.0607 4.43934C15.8762 4.25483 15.6171 4.13453 15.1279 4.06877C14.6244 4.00106 13.9569 4 13 4H5C4.04306 4 3.37565 4.00106 2.87208 4.06877Z"
          fill="#4F4F4F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5543 0.400391C4.88567 0.400391 5.1543 0.66902 5.1543 1.00039L5.1543 5.16706C5.1543 5.49843 4.88567 5.76706 4.5543 5.76706C4.22292 5.76706 3.9543 5.49843 3.9543 5.16706L3.9543 1.00039C3.9543 0.66902 4.22293 0.40039 4.5543 0.400391Z"
          fill="#4F4F4F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4449 0.400391C13.7763 0.400391 14.0449 0.66902 14.0449 1.00039L14.0449 5.16706C14.0449 5.49843 13.7763 5.76706 13.4449 5.76706C13.1135 5.76706 12.8449 5.49843 12.8449 5.16706L12.8449 1.00039C12.8449 0.66902 13.1136 0.40039 13.4449 0.400391Z"
          fill="#4F4F4F"
        />
      </svg>
    </>
  );
}

export default Birthday;
