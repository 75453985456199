import React, { useState, useRef } from "react";

function Share() {
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  };

  return (
    <>
      <div className="share">
        <h3>Share With Your Friends</h3>
        <p>
          Click On This button to copy the link and share the details with your
          friends to join the event and community.
        </p>
        <div className="copy-text">
          <input
            type="text"
            ref={textAreaRef}
            defaultValue={window.location.href}
          />
          <button onClick={copyToClipboard}>{copySuccess}</button>
        </div>
      </div>
    </>
  );
}

export default Share;
