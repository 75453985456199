/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  renderCauseEventList,
  renderCharityEventList,
  renderEventList,
  renderStrategicEventList,
} from "../../../../../actions/event";
import EventCard from "../../../../../components/common/EventCard";

function UpcomingEvent({ id, from }) {
  const dispatch = useDispatch();
  const { eventList } = useSelector((state) => state.event);

  useEffect(() => {
    if (from === "cause") {
      dispatch(
        renderCauseEventList(
          id,
          { page: 1, limit: 1000000, type: "Upcoming" },
          true
        )
      );
    } else if (from === "charity") {
      dispatch(
        renderCharityEventList(
          id,
          {
            page: 1,
            limit: 1000000,
            type: "Upcoming",
          },
          true
        )
      );
    } else if (from === "strategic") {
      dispatch(
        renderStrategicEventList(
          id,
          {
            page: 1,
            limit: 1000000,
            type: "Upcoming",
          },
          true
        )
      );
    } else {
      dispatch(
        renderEventList(
          {
            page: 1,
            limit: 1000000,
            type: "Upcoming",
            communityId: id,
          },
          true
        )
      );
    }
  }, []);
  return (
    <>
      <ScrollBar component="div">
        <div className="content">
          <div className="upcomingEvent">
            {eventList?.length ? (
              eventList?.map((event, ind) => (
                <EventCard
                  communityId={id}
                  event={event}
                  key={event?._id}
                  isEvent={true}
                  classname="upcomingButton"
                  from="upcoming"
                  comingFrom={from}
                />
              ))
            ) : (
              <h4 className="notFound mb-10">No Upcoming Event Found</h4>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default UpcomingEvent;
