import {
  CHARITY_COMMUNITY_LIST_F,
  CHARITY_COMMUNITY_LIST_S,
  CHARITY_DETAILS_F,
  CHARITY_DETAILS_S,
  RENDER_CHARITY_LIST_F,
  RENDER_CHARITY_LIST_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  charityList: {},
  charityDetails: {},
  charityCommunityList: {},
};

const charityReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENDER_CHARITY_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        charityList: action.payload.data,
      };
    case RENDER_CHARITY_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        charityList: {},
      };
    case CHARITY_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        charityDetails: action.payload.data,
      };
    case CHARITY_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        charityDetails: {},
      };
    case CHARITY_COMMUNITY_LIST_S:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        charityCommunityList: action.payload.data.joinCommunitiesDetails,
      };
    case CHARITY_COMMUNITY_LIST_F:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.message,
        charityCommunityList: {},
      };
    default:
      return state;
  }
};

export default charityReducer;
