/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import Location from "../../assets/svg/location";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import {
  EditCityField,
  EditSelectField,
  renderNumberInput,
  renderTextInput,
} from "../../components/common/FormFields";
import User from "../../assets/svg/User";
import Card from "../../assets/svg/card";
import { renderField, renderSelectField } from "../../components/forms";
import { toAbsoluteUrl } from "../../utils";
import "./rosiBasket.scss";
import { useDispatch, useSelector } from "react-redux";
import { addToBasketAction, getBasketDetailsAction } from "../../actions/cart";
import BasketItem from "../../components/common/BasketItem";
import Birthday from "../../assets/svg/birthday";
import {
  addTOCity,
  addTOCountry,
  addTOState,
} from "../../actions/charityRegister";
import * as CryptoJS from "crypto-js";
import {
  getAllCardListAction,
  makePaymentWithSavedCardAction,
  makePaymentWithUnsavedCardAction,
} from "../../actions/payment";
import toast from "react-hot-toast";
import { paymentFormValidations as validate } from "../../components/validations/payment";
import CustomModal from "../../components/common/Modal";
import AddCardModal from "../../components/common/AddCardCard";
import Right from "../../assets/svg/right";
import ScrollBar from "react-perfect-scrollbar";
import { doLogout } from "../../actions/auth";
import { BASKET_COUNT_S } from "../../constants/types";

const bread = [
  {
    name: "Rosi basket",
    link: "/basket",
    isActive: true,
  },
];

function RosiBasket() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const userType = JSON.parse(localStorage.getItem("webUser"))?.data?.userType;
  const { stateList, cityList } = useSelector((state) => state.metaReducer);
  const formRef = useRef();
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [quickCashActiveBtn, setQuickCashActiveBtn] = useState(0);
  const [isDistributeEqually, setIsDistributeEqually] = useState(true);
  const [basketInfo, setBasketInfo] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [finalModal, setFinalModal] = useState(false);
  const [addressError, setAddressError] = useState({
    city: false,
    state: false,
  });

  const [country, setCountry] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",

    phone_code: "1",
  });
  const [selectedState, setselectedState] = useState();
  const [selectedCity, setselectedCity] = useState("");
  const [paymentType, setPaymentType] = useState(1);

  const fetchCountry = (id) => {
    dispatch(addTOCountry()).then((res) => {
      const data = res.data;
      let countryArr = [];
      data.map((val) =>
        countryArr.push({
          value: val.id,
          label: val.name,
          phone_code: val.phone_code,
        })
      );
      setCountry(countryArr);
    });
  };

  const fetchState = (data) => {
    dispatch(addTOState(data));
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data));
  };

  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
    // eslint-disable-next-line
  }, [selectedCountry]);

  // useEffect(() => {
  //   fetchCity({ stateId: selectedState?.value });
  //   // eslint-disable-next-line
  // }, [selectedState]);
  useEffect(() => {
    let initialState = [];
    if (stateList?.length) {
      initialState = stateList.filter(
        (val) => val.label === basketInfo?.addressObj?.state
      );
      if (initialState?.length) {
        setselectedState(initialState[0]);
        fetchCity({ stateId: initialState[0]?.value });
      }
    }
    // eslint-disable-next-line
  }, [basketInfo, stateList]);

  useEffect(() => {
    let initialState = [];
    if (cityList?.length) {
      initialState = cityList.filter(
        (val) => val.label === basketInfo?.addressObj?.city
      );
      if (initialState?.length) {
        setselectedCity(initialState[0]);
      }
    }
  }, [basketInfo, cityList]);

  const getBasketDetails = async () => {
    dispatch(getBasketDetailsAction({ _id: userId })).then((res) => {
      setBasketInfo(res?.data);
    });
  };

  useEffect(() => {
    getBasketDetails();
    dispatch(getAllCardListAction({ userId })).then((res) =>
      setCardList(res.data.listCards)
    );
  }, []);

  useEffect(() => {
    if (isDistributeEqually && basketInfo?.basketDetails?.length) {
      let lockedItemCnt = 0;
      const lockedPer = 0;
      const equalPer =
        (100 - lockedPer) / (basketInfo?.basketDetails?.length - lockedItemCnt);
      let extraPer =
        (100 - lockedPer) % (basketInfo?.basketDetails?.length - lockedItemCnt);

      const payload = {
        userId,
        basket: basketInfo?.basketDetails?.map((item, ind) => {
          item = { ...item, isLocked: false };
          if (item?.isLocked === false && (equalPer || extraPer)) {
            let allowedPercent = Math.floor(equalPer);
            if (extraPer) {
              allowedPercent++;
              extraPer--;
            }
            return { ...item, allowedPercent };
          }
          return item;
        }),
      };

      dispatch(addToBasketAction(payload)).then(() => getBasketDetails());
    }
  }, [isDistributeEqually, basketInfo?.basketDetails?.length]);

  const distributeEquallyHandler = () => {
    setIsDistributeEqually((prev) => !prev);
  };

  const setAmount = (args, state, { setIn, changeValue }) => {
    const field = state.fields["manualAmount"];
    field.change(args[0].target.value);
    state.formState.submitFailed = true;
  };

  const onFormSubmit = (values) => {
    if (!isChecked) {
      toast.error("You must agree with the Terms of Use");
      return;
    }
    if (cardList?.length > 0 && !selectedCardId) {
      toast.error("Please select card to continue payment process");
      return;
    }
    if (!selectedState?.label) {
      setAddressError({ ...addressError, state: true });
      return;
    }
    if (!selectedCity?.label) {
      setAddressError({ ...addressError, city: true });
      return;
    }
    const isAnyItemHasZeroPer = basketInfo?.basketDetails?.reduce(
      (acc, item) => {
        if (item.allowedPercent === 0) {
          acc = true;
        }
        return acc;
      },
      false
    );

    if (isAnyItemHasZeroPer) {
      toast.error("Charity/Community can't has zero percentage");
      return;
    }

    const totalPer = basketInfo?.basketDetails?.reduce(
      (acc, item) => acc + item.allowedPercent,
      0
    );

    if (totalPer < 100) {
      toast.error("Please set 100% percentage");
      return;
    }

    let payload = {
      userId,
      paymentType,
      amount: Number(values.manualAmount.toString().replace(/,/g, "")),
      address: {
        // addressLine1: values.steet,
        addressLine1: values?.streetName1,
        addresLine2: values?.streetName2,
        city: selectedCity?.label || "",
        state: selectedState?.label || "",
        country: selectedCountry?.label || "",
        zipcode: values.zip,
      },
    };

    if (cardList?.length > 0 && basketInfo?.basketDetails) {
      payload = { ...payload, cardId: selectedCardId };
      dispatch(makePaymentWithSavedCardAction(payload, true))
        .then((res) => {
          dispatch({
            type: BASKET_COUNT_S,
            payload: 0,
          });
          getBasketDetails();
          setIsDistributeEqually(false);
          setFinalModal((prev) => !prev);
          setQuickCashActiveBtn(0);

          if (formRef?.current?.restart) {
            formRef.current?.restart({});
          }
        })
        .catch((res) => toast.error(res.message));
      return;
    }

    const encryptedData = {
      number: values?.cardNumber?.split(" ").join(""),
      exp_month: values?.expiry?.substr(0, 2),
      exp_year: "20" + values?.expiry?.substr(5),
      cvc: values?.cvv,
      cardOwnerName: values?.user,
    };

    async function encryptData(data) {
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_ENCRYPT_KEY
      );
      return ciphertext.toString();
    }

    encryptData(encryptedData).then((res) => {
      payload = { ...payload, data: res };
      if (basketInfo?.basketDetails) {
        dispatch(makePaymentWithUnsavedCardAction(payload, true))
          .then((res) => {
            dispatch({
              type: BASKET_COUNT_S,
              payload: 0,
            });
            getBasketDetails();
            setFinalModal((prev) => !prev);
            setQuickCashActiveBtn(0);

            if (formRef?.current?.restart) {
              formRef.current?.restart({});
            }
          })
          .catch((res) => toast.error(res.message));
      } else toast.error("Please Add Charity/Community");
    });
  };

  const doneBtnClickHandler = () => {
    if (userType === 3) dispatch(doLogout());
    navigate(`/profile_details`, {
      state: { userID: userId, defaultIndex: 0 },
    });
  };

  const donateAgainClickHandler = () => {
    navigate("/");
  };

  return (
    <>
      <section className="rosiBasket">
        <div className="container">
          <div className="breadcrumbsBasket">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="rosiBasketWrap">
            {/* sidebar */}
            <div className="basketSidebar">
              <h1 className="basketTitle">Payment Process</h1>
              <div className="basketSidebarWrap">
                <h3>Select or Enter the Amount you want to donate</h3>
                <Form
                  onSubmit={onFormSubmit}
                  mutators={{
                    setAmount,
                    setValue: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value);
                    },
                  }}
                  validate={(value) =>
                    validate(value, cardList?.length ? false : true)
                  }
                  initialValues={{
                    streetName1: basketInfo?.addressObj?.streetName1 || "",
                    streetName2: basketInfo?.addressObj?.streetName2 || "",
                    city: basketInfo?.addressObj?.city || "",
                    state: basketInfo?.addressObj?.state || "",
                    zip: basketInfo?.addressObj?.zipcode || "",
                    // country: basketInfo?.addressObj?.country || "",
                    country: selectedCountry || "",
                  }}
                >
                  {({ handleSubmit, form, values }) => {
                    return (
                      <>
                        <form onSubmit={handleSubmit}>
                          {userType !== 3 && (
                            <ul className="basket-radio">
                              <li>
                                <div
                                  className="forgot"
                                  onClick={() => setPaymentType(1)}
                                >
                                  <div className="remember">
                                    <input
                                      className="check"
                                      type="radio"
                                      id="public"
                                      name="public"
                                      defaultChecked
                                      value={1}
                                    />
                                    <label htmlFor="public">
                                      One - time Payment
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div
                                  className="forgot"
                                  onClick={() => setPaymentType(2)}
                                >
                                  <div className="remember">
                                    <input
                                      className="check"
                                      type="radio"
                                      id="private"
                                      name="public"
                                      value={2}
                                    />
                                    <label htmlFor="private">
                                      Recurring Payment
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          )}
                          <div className="paymentProcess">
                            <div className="rosiBasketCash">
                              <h3>Quick Cash</h3>
                              <div className="cashButtonGroup">
                                <button
                                  type="button"
                                  value={10}
                                  className={
                                    quickCashActiveBtn === 1
                                      ? "selected"
                                      : "cashButton"
                                  }
                                  onClick={(e) => {
                                    form.mutators.setAmount(e);
                                    setQuickCashActiveBtn(1);
                                  }}
                                >
                                  $ 10
                                </button>
                                <button
                                  type="button"
                                  value={25}
                                  className={
                                    quickCashActiveBtn === 2
                                      ? "selected"
                                      : "cashButton"
                                  }
                                  onClick={(e) => {
                                    form.mutators.setAmount(e);
                                    setQuickCashActiveBtn(2);
                                  }}
                                >
                                  $ 25
                                </button>
                                <button
                                  type="button"
                                  value={50}
                                  className={
                                    quickCashActiveBtn === 3
                                      ? "selected"
                                      : "cashButton"
                                  }
                                  onClick={(e) => {
                                    form.mutators.setAmount(e);
                                    setQuickCashActiveBtn(3);
                                  }}
                                >
                                  $ 50
                                </button>
                                <button
                                  type="button"
                                  value={100}
                                  className={
                                    quickCashActiveBtn === 4
                                      ? "selected"
                                      : "cashButton"
                                  }
                                  onClick={(e) => {
                                    form.mutators.setAmount(e);
                                    setQuickCashActiveBtn(4);
                                  }}
                                >
                                  $ 100
                                </button>
                                <button
                                  type="button"
                                  value={200}
                                  className={
                                    quickCashActiveBtn === 5
                                      ? "selected"
                                      : "cashButton"
                                  }
                                  onClick={(e) => {
                                    form.mutators.setAmount(e);
                                    setQuickCashActiveBtn(5);
                                  }}
                                >
                                  $ 200
                                </button>
                              </div>
                            </div>
                            <div className="rosiInputOne"></div>
                            {/* rosiinputtwo */}
                            <div className="rosiInputStepTwo">
                              <div className="manualAmount">
                                <label>
                                  Donation Amount (Add Amount Manually)
                                </label>
                                <Field
                                  name="manualAmount"
                                  type="number"
                                  component={renderTextInput}
                                  containerClasses="form-group"
                                  setQuickCashActiveBtn={setQuickCashActiveBtn}
                                >
                                  <h5>$</h5>
                                </Field>
                                <p className="inputNote">
                                  Minimum donation of $10 is required
                                </p>
                              </div>
                              <div className="secondMethod">
                                <h3>Address Details</h3>
                                <ul>
                                  <li>
                                    <Field
                                      name="streetName1"
                                      type="text"
                                      placeholder="Address Line 1"
                                      component={renderField}
                                      className="form-grp basketGrp"
                                      inputclass="input-box"
                                    >
                                      <Location />
                                    </Field>
                                  </li>
                                  <li>
                                    <Field
                                      name="streetName2"
                                      type="text"
                                      placeholder="Address Line 2"
                                      component={renderField}
                                      className="form-grp basketGrp"
                                      inputclass="input-box"
                                    >
                                      <Location />
                                    </Field>
                                  </li>
                                  <li className="listInput">
                                    <div className="basketFields">
                                      <EditSelectField
                                        placeholder="State"
                                        isStateField={true}
                                        data={selectedState}
                                        setselectedCity={setselectedCity}
                                        setselectedState={setselectedState}
                                        className="form-grp col-grp formGrp dropdownGrp"
                                        inputclass="input-box slectStep"
                                        options={stateList}
                                        isMulti={false}
                                        style={{ border: "none" }}
                                      />
                                      {addressError.state && (
                                        <div className="input-error">
                                          <span className="mb-0 text-danger">
                                            Please select state
                                          </span>
                                        </div>
                                      )}
                                      {/* <Field
                                        name="state"
                                        type="text"
                                        placeholder="State"
                                        isStateField={true}
                                        setselectedState={setselectedState}
                                        component={renderSelectField}
                                        mutator={form.mutators.setValue}
                                        options={state}
                                        isMulti={false}
                                        className="form-grp basketGrp"
                                        inputclass="input-box"
                                      >
                                        <Location />
                                      </Field> */}
                                    </div>
                                    <div className="basketFields">
                                      <EditCityField
                                        placeholder="City"
                                        data={selectedCity}
                                        setselectedCity={setselectedCity}
                                        className="form-grp col-grp formGrp dropdownGrp"
                                        inputclass="input-box"
                                        options={cityList}
                                        isMulti={false}
                                        style={{ border: "none" }}
                                      />
                                      {addressError.city && (
                                        <div className="input-error">
                                          <span className="mb-0 text-danger">
                                            Please select city
                                          </span>
                                        </div>
                                      )}
                                      {/* <Field
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        component={renderSelectField}
                                        className="form-grp basketGrp"
                                        inputclass="input-box"
                                        isMulti={false}
                                        options={city}
                                        containerClasses="form-group"
                                      >
                                        <Location />
                                      </Field> */}
                                    </div>
                                  </li>
                                  <li className="listInput">
                                    <div className="basketFields">
                                      <Field
                                        name="zip"
                                        type="number"
                                        placeholder="Zip"
                                        component={renderNumberInput}
                                        numberFormat={"#####"}
                                        containerClasses="form-group"
                                      >
                                        <Location />
                                      </Field>
                                    </div>
                                    <div className="basketFields">
                                      <Field
                                        name="country"
                                        type="text"
                                        placeholder="Country"
                                        isCountryField={true}
                                        setselectedCountry={setselectedCountry}
                                        component={renderSelectField}
                                        mutator={form.mutators.setValue}
                                        options={country}
                                        disabled={true}
                                        isMulti={false}
                                        className="form-grp basketGrp"
                                        inputclass="input-box"
                                      >
                                        <Location />
                                      </Field>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              {cardList?.length === 0 ? (
                                <div className="secondMethod">
                                  <h3>Payment Method</h3>
                                  <ul>
                                    <li>
                                      <Field
                                        name="user"
                                        type="text"
                                        placeholder="User Name"
                                        component={renderField}
                                        className="form-grp basketGrp toUpperCase"
                                        inputclass="input-box"
                                      >
                                        <User />
                                      </Field>
                                    </li>
                                    <li>
                                      <div className="paymentGrp">
                                        <Field
                                          name="cardNumber"
                                          type="number"
                                          placeholder="Card Number"
                                          component={renderNumberInput}
                                          numberFormat={"#### #### #### ####"}
                                          containerClasses="form-group"
                                        >
                                          <Card />
                                        </Field>
                                      </div>
                                    </li>
                                    <li className="listInput">
                                      <div className="basketFields">
                                        <Field
                                          name="expiry"
                                          type="number"
                                          placeholder="Expiry Date (MM / YY)"
                                          component={renderNumberInput}
                                          numberFormat={"## / ##"}
                                          containerClasses="form-group"
                                        >
                                          <Birthday />
                                        </Field>
                                      </div>
                                      <div className="basketFields">
                                        <Field
                                          name="cvv"
                                          type="number"
                                          placeholder="CVV Number"
                                          component={renderTextInput}
                                          numberFormat={"###"}
                                          containerClasses="form-group"
                                        >
                                          <Card />
                                        </Field>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ) : (
                                <div className="paymentMethod">
                                  Payment Method <h3>Payment Method</h3>
                                  <div className="paymentMethodwrap">
                                    {cardList.map((card, ind) => (
                                      <div
                                        className={`visaPayment ${selectedCardId === card?.cardId
                                          ? "paymentActive"
                                          : ""
                                          }`}
                                        key={card?._id}
                                        onClick={() =>
                                          setSelectedCardId(card?.cardId)
                                        }
                                      >
                                        <figure>
                                          <img
                                            src={toAbsoluteUrl(
                                              "/images/credit-card.svg"
                                            )}
                                            alt="visaLogo"
                                          />
                                        </figure>
                                        <ul className="paymentName">
                                          <li>
                                            <span>{card?.crandBrandName}</span>
                                            **** {card?.cardNumber}
                                          </li>
                                          {/* <li className="credit">Debit Card</li> */}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                  <button
                                    type="button"
                                    className="add"
                                    onClick={() =>
                                      setModalIsOpen((prev) => !prev)
                                    }
                                  >
                                    Add
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="address-terms">
                            <div className="basketCheckbox">
                              <label className="check-label rememberReg">
                                <h5>
                                  I agree to the{" "}
                                  <Link to="/terms" target="_blank">
                                    Terms of Use{" "}
                                  </Link>
                                  of the Rosi Giving Platform.
                                </h5>
                                <div>
                                  <input
                                    type="checkbox"
                                    defaultChecked={isChecked}
                                    onClick={() =>
                                      setIsChecked((prev) => !prev)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </div>
                              </label>
                            </div>
                          </div>
                          <button type="submit" className="donateAmount">
                            Donate{" "}
                            {values?.manualAmount
                              ? "$" + values?.manualAmount
                              : ""}{" "}
                            Amount
                          </button>
                        </form>
                      </>
                    );
                  }}
                </Form>
              </div>
            </div>
            <div className="basketContent">
              <h3 className="basketTitle">Donation Summary</h3>
              <div className="summary-button">
                {userType !== 3 && (
                  <Link to="/profile_details" state={{ defaultIndex: 1 }}>
                    Go to Favorites to Fill the Basket
                  </Link>
                )}
              </div>
              <div>
                {basketInfo?.basketDetails?.length ? (
                  <div className="basketContentWrap">
                    <div className="basketContentHeader">
                      <h3>Nonprofit/Communities</h3>
                      <h3>Percent Allocated</h3>
                    </div>
                    <div className="basketContentBody">
                      <ScrollBar component="div">
                        <div className="basketScroll">
                          {basketInfo?.basketDetails?.map((item, ind) => (
                            <BasketItem
                              key={item._id}
                              item={item}
                              setBasketInfo={setBasketInfo}
                              basketInfo={basketInfo}
                              basketId={basketInfo?._id}
                              getBasketDetails={getBasketDetails}
                              setIsDistributeEqually={setIsDistributeEqually}
                              paymentType={paymentType}
                            />
                          ))}
                        </div>
                      </ScrollBar>
                    </div>
                    {
                      <div className="basketContentFooter">
                        <div className="summaryCheckBox">
                          <label className="check-label rememberReg">
                            <h4>Distribute Equally</h4>
                            <div>
                              <input
                                type="checkbox"
                                checked={isDistributeEqually}
                                onClick={() => distributeEquallyHandler()}
                              />
                              <span className="checkmark"></span>
                            </div>
                          </label>
                        </div>
                        <p>
                          Click here if you want to distribute evenly across all
                          nonprofits/communities.
                        </p>
                      </div>
                    }
                  </div>
                ) : (
                  <div className="summary-error-box">
                    <h4 className="notFound descPadding">Uh-oh!</h4>
                    <h4 className="notFound descPadding">
                      Your basket is empty.
                    </h4>
                    {userType !== 3 && (
                      <h4 className="notFound descPadding">
                        Go to your{" "}
                        <Link to="/profile_details" state={{ defaultIndex: 1 }}>
                          Favourites
                        </Link>{" "}
                        and fill it back up!
                      </h4>
                    )}
                  </div>
                )}
                <div className="basketHelp">
                  <div className="helpContent">
                    <h3>Need our help?</h3>
                    <p>
                      Email us if you have any questions during the donation
                      process
                    </p>
                  </div>
                  <button className="baketHelpButton">
                    <a href={`mailto: support@rosigiving.com}`}>
                      support@rosigiving.com
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddCardModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setCardList={setCardList}
        />
      </section>
      <CustomModal
        className="donationModal"
        isOpen={finalModal}
        closeModal={() => {
          setFinalModal((prev) => !prev);
        }}
      >
        <div className="donationRight">
          <Right />
        </div>
        <h2>Thank you For Donating!</h2>
        <p>
          Your receipt will be sent to the email on record and is available in
          the "Donation" section of your Rosi profile
        </p>
        <div className="donationButtonGrpModal">
          <button className="againButton" onClick={donateAgainClickHandler}>
            Donate Again
          </button>
          <button className="doneButton" onClick={doneBtnClickHandler}>
            Done
          </button>
          {/* <button className="doneButton"></button> */}
        </div>
      </CustomModal>
    </>
  );
}

export default RosiBasket;
