import { API_GLOBAL_SEARCH, API_HOMEPAGE_DETAILS } from "../constants/api";
import {
  API,
  HOMEPAGE_DETAILS_F,
  HOMEPAGE_DETAILS_S,
  SEARCH_ITEM_F,
  SEARCH_ITEM_S,
} from "../constants/types";

export const renderHomePageDetails = (showLoader = false) => ({
  type: API,
  payload: {
    url: API_HOMEPAGE_DETAILS,
    method: "GET",
    showLoader,
    success: (data) => ({
      type: HOMEPAGE_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: HOMEPAGE_DETAILS_F,
      payload: data,
    }),
  },
});

export const searchAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_GLOBAL_SEARCH,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: SEARCH_ITEM_S,
      payload: data,
    }),
    error: (data) => ({
      type: SEARCH_ITEM_F,
      payload: data,
    }),
  },
});
