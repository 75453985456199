/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomModal from "../common/Modal";
import PostInput from "../common/PostInput";
import "../../pages/Community Details/CommunityTabs/Details/details.scss";
import { rendertextarea } from "../forms";
import { useDispatch, useSelector } from "react-redux";
import {
  createPostAction,
  deletePostAction,
  doUploadVideo,
} from "../../actions/post";
import ImgUpload from "../common/img-upload";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { RenderVideoField } from "../common/Video-upload";
import Post from "../common/Post";
import toast from "react-hot-toast";
import { employeePostAction } from "../../actions/strategic";
import { doUploadImage } from "../../actions/charityRegister";
import { getVideoUrl } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";

function StrategicPosts({ communityId }) {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { employeePostList } = useSelector((state) => state.post);
  const employeeId = JSON.parse(localStorage.getItem("employeeId"));
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [postType, setPostType] = useState("");
  const [isMyPost, setIsMyPost] = useState(false);
  const [currPostDetails, setCurrPostDetails] = useState("");

  //image video state
  const [image, setImage] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [oldPostImages, setOldPostImages] = useState();

  const [videoArray, setVideoArray] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (employeeId) {
      const payload = {
        page: 1,
        limit: 20,
      };
      if (isMyPost) {
        payload.isEmployee = "true";
      }
      dispatch(employeePostAction(payload, employeeId, isMyPost, true, true));
    }
    // eslint-disable-next-line
  }, [employeeId]);

  const required = (value) => {
    if (postType === "article") {
      if (!value) {
        return "Please enter text";
      }
      if (value && !value.trim()) {
        return "Please enter valid text";
      }
      // return (value || value.trim() ? undefined : 'Required')
      return undefined;
    }
    return undefined;
  };

  const uploadVideoFiles = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const formSubmitHandler = (value) => {
    let data = {
      type: 3,
      userId,
      description: value?.postContent,
      isPrivate: false,
      hidePost: false,
      strategicPartnerId: employeeId,
      employeeId: userId,
      createdBy: 5,
    };

    if (postType === "photo") {
      let imgArr = [];
      let len = postImages.length;
      if (len === 0) {
        toast.error("Please attach atleast one image");
        return;
      }
      postImages.forEach((image, ind) => {
        if (!image?.name) {
          const payload = { image: [image], moduleName: "postImages" };
          dispatch(doUploadImage(payload)).then((res) => {
            len = len - 1;
            imgArr.push({
              name: res.data.name,
              url: mediaURL + "postImages/" + res.data.name,
            });
            setImage((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: mediaURL + "postImages/" + res.data.name,
              },
            ]);
            if (len === 0) {
              data = { ...data, type: 1, image: imgArr };
              createPostHandler(data);
            }
          });
        }
        return "";
      });
    } else if (postType === "video") {
      if (videoSourceFiles?.length === 0) {
        toast.error("Please attach a video");
        return;
      }
      const videoPayload = [];
      Promise.allSettled(uploadVideoFiles()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(mediaURL, "postImages", value.value?.data?.name),
              thumbnail: value.value?.data.thumbnail,
            });
          }
          return null;
        });
        data = { ...data, type: 2, video: videoPayload, image: [] };
        createPostHandler(data);
      });
    } else createPostHandler(data);
  };

  const createPostHandler = (data) => {
    dispatch(createPostAction(data)).then((res) => {
      if (res.statusCode === 400) {
        toast.error(res.message);
      }
      setisLoading(true);
      const payload = {
        page: 1,
        limit: 20,
      };
      setPage(1);
      if (isMyPost) payload.isEmployee = "true";
      dispatch(employeePostAction(payload, employeeId, isMyPost, true, true))
        .then(() => setisLoading(false))
        .catch(() => setisLoading(false));
      setEditIsOpen((prev) => !prev);
      setImage([]);
      setVideoArray([]);
      setPostImages([]);
      setVideoSourceFiles([]);
      setVideoSourceURLs([]);
    });
  };

  const closeAddPostModalHandler = () => {
    setEditIsOpen((prev) => !prev);
    setImage([]);
    setPostImages([]);
    setVideoArray([]);
    setVideoSourceFiles([]);
    setVideoSourceURLs([]);
  };

  const deletePostHandler = () => {
    dispatch(deletePostAction(currPostDetails?._id)).then(() => {
      dispatch(employeePostAction({ page: 1, limit: 4 }, employeeId, isMyPost));
      setCurrPostDetails("");
      setModalIsOpen((prev) => !prev);
    });
  };

  const switchToMyPostHandler = () => {
    setIsMyPost((prev) => !prev);
    setisLoading(true);
    setTimeout(() => {
      let payload = {
        page: 1,
        limit: 20,
      };
      if (!isMyPost) {
        payload = { ...payload, isEmployee: "true" };
      }
      dispatch(employeePostAction(payload, employeeId, !isMyPost, true, true))
        .then(() => setisLoading(false))
        .catch(() => setisLoading(false));
    }, 400);
  };

  const fetchData = () => {
    const payload = {
      page: page + 1,
      limit: 20,
    };
    if (isMyPost) {
      payload.isEmployee = "true";
    }
    dispatch(employeePostAction(payload, employeeId, isMyPost));

    setPage((prev) => prev + 1);
  };

  return (
    <>
      <PostInput
        setEditIsOpen={setEditIsOpen}
        setPostType={setPostType}
        communityId={communityId}
        isMyPost={isMyPost}
        setArgs={() => setPage(1)}
        from="employeePost"
      />
      <div className="detailsHeader">
        <h2>{isMyPost ? "My Posts" : "All Posts"}</h2>
        {userId && (
          <div className="detailsSwitch">
            <span>Switch to</span>
            <button onClick={() => switchToMyPostHandler()}>{`${
              !isMyPost ? "My Posts" : "All Posts"
            }`}</button>
          </div>
        )}
      </div>
      {!isLoading && employeePostList?.employeePostList?.length ? (
        <InfiniteScroll
          dataLength={employeePostList?.employeePostList?.length}
          next={() => fetchData()}
          hasMore={true}
        >
          {employeePostList?.employeePostList?.map((post, ind) => (
            <Post
              post={post}
              communityId={communityId}
              key={post._id}
              isMyPost={isMyPost}
              isLoading={isLoading}
              setisLoading={setisLoading}
              from="employeePost"
            />
          ))}
        </InfiniteScroll>
      ) : (
        <h4 className="notFound">No Post Found</h4>
      )}
      <CustomModal
        className="postDeleteModal"
        isOpen={modalIsOpen}
        closeModal={() => closeAddPostModalHandler()}
      >
        <h3>Are you sure you want to delete this post?</h3>
        <p>
          Are you sure you want to delete this post, after this action this post
          will permanentely deleted from server.
        </p>
        <div className="postModal">
          <button>Cancel</button>
          <button onClick={deletePostHandler}>Delete</button>
        </div>
      </CustomModal>
      <CustomModal
        className="postEditModal"
        isOpen={editIsOpen}
        closeModal={() => {
          setEditIsOpen((prev) => !prev);
          setImage([]);
          setVideoArray([]);
        }}
        title={`Add Post`}
      >
        <Form onSubmit={formSubmitHandler}>
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Field
                  name="postContent"
                  component={rendertextarea}
                  className="textarea"
                  placeholder="Type here"
                  validate={required}
                ></Field>
                {postType === "photo" && (
                  <ImgUpload
                    isPost={true}
                    multiple={true}
                    setImg={setImage}
                    imgs={image}
                    setImages={setPostImages}
                    images={postImages}
                    oldImages={oldPostImages}
                    setOldImages={(images) => setOldPostImages(images)}
                    maxNumber={5}
                    moduleName="postImages"
                    postPage={true}
                    callUploadAPI={false}
                  />
                )}
                {postType === "video" && (
                  <RenderVideoField
                    oneVideoValidation={true}
                    isMultiple={false}
                    moduleName="postImages"
                    videoArray={videoArray}
                    setVideoArray={setVideoArray}
                    videoSourceURLs={videoSourceURLs}
                    setVideoSourceURLs={setVideoSourceURLs}
                    videoSourceFiles={videoSourceFiles}
                    setVideoSourceFiles={setVideoSourceFiles}
                  />
                )}
                <div className="editButton">
                  <button
                    type="button"
                    onClick={() => closeAddPostModalHandler()}
                  >
                    Cancel
                  </button>
                  <button type="submit">Post</button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
}

export default StrategicPosts;
