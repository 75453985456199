import { NOTIFICTION_LIST_F, NOTIFICTION_LIST_S } from "../constants/types";

const initialState = {
  notifictionlist: [],
};

const NotifictionReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICTION_LIST_S:
      let list = state?.notifictionlist;
      if (action.payload.reset === true || action.payload?.page === 1) {
        list = action.payload.data;
      } else {
        list = [...list, ...action.payload.data];
      }
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        notifictionlist: list,
      };
    case NOTIFICTION_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action?.payload?.message,
        notifictionlist: [],
      };
    default:
      return state;
    // case HOMEPAGE_DETAIL_S:
    //     return {
    //         ...state, homepagedetail: action.payload.data
    //     }
    // case HOMEPAGE_UPDATE_F:
    //     return {
    //         ...state, homepagedetail: action.payload.message
    //     }
  }
};
export default NotifictionReducer;
