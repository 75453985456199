import {
  BASKET_COUNT_F,
  BASKET_COUNT_S,
  CITY_S,
  CITY_F,
  COUNTRY_S,
  FETCH_META_F,
  FETCH_META_S,
  STATE_S,
  CHAT_NOTI_COUNT_S
} from "../constants/types";

const initialState = {
  mediaURL: undefined,
  placeholder: undefined,
  error: undefined,
  cityList: [],
  countryList: [],
  stateList: [],
  basketCount: 0,
  notificationCount: null,
};

const metaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_META_S:
      return {
        ...state,
        error: undefined,
        mediaURL: action.payload.data.mediaURL,
        placeholder: action.payload.data.placeholder,
        basketCount: action.payload.data.basketCount,
      };
    case FETCH_META_F:
      return {
        ...state,
        error: action.payload.error,
        mediaURL: undefined,
        placeholder: undefined,
      };
    case CHAT_NOTI_COUNT_S:
      return {
        ...state,
        notificationCount: action.payload.data?.newNotificationCount,
      };
    case CITY_S:
      return {
        ...state,
        cityList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };
    case CITY_F:
      return {
        ...state,
        cityList: [],
      };
    case STATE_S:
      return {
        ...state,
        stateList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };
    case COUNTRY_S:
      return {
        ...state,
        countryList: action.payload.data?.length
          ? action.payload.data.map((val) => {
              return { value: val.id, label: val.name };
            })
          : [],
      };
    case BASKET_COUNT_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        basketCount: action.payload,
      };
    case BASKET_COUNT_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        basketCount: 0,
      };
    default:
      return state;
  }
};

export default metaReducer;
