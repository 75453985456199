import React from "react";

const GoToBasket = () => {
  return (
    <>
      <svg
        width="25"
        height="28"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9568 8.64029C18.6763 4.38849 14.7698 1 10 1C5.07914 1 1.04317 4.58274 1 9.05036C1 9.39569 1.02158 11.2086 1.02158 11.2086C1.02158 15.5683 5.03597 19.1295 10 19.1295C14.964 19.1295 18.9784 15.5683 19 11.2302C19 11.0791 19 10.9065 19 10.6906C18.9784 9.58992 18.9784 9.26618 18.9568 8.64029ZM10 18.1583C6.61151 18.1583 3.71942 16.3237 2.55396 13.7338C4.17266 15.8705 6.91367 17.295 10 17.295C13.1079 17.295 15.8489 15.8705 17.4676 13.6906C16.3022 16.3022 13.4101 18.1583 10 18.1583ZM18.0072 9.15828C18.0072 13.1079 14.4029 16.3237 10 16.3237C5.57554 16.3237 1.97122 13.1079 1.94964 9.13669C1.94964 5.18705 5.55396 1.97122 9.97842 1.97122C14.3813 1.97122 17.9856 5.16547 18.0072 9.09352C18.0072 9.11511 18.0072 9.13669 18.0072 9.15828Z"
          fill="white"
        />
        <path
          d="M10.7598 8.41253V6.45431C11.2298 6.58486 11.6736 6.81984 12.0392 7.18537L12.8225 6.21932C12.3003 5.69713 11.6214 5.3577 10.812 5.22715V4.0261H10.5509V4H9.63708V5.17494C8.17494 5.30548 7.2611 6.21932 7.2611 7.36815C7.2611 8.83029 8.46214 9.24804 9.63708 9.53524V11.7285C8.82768 11.5979 8.17494 11.1802 7.75718 10.7102L7 11.7285C7.60052 12.3551 8.46214 12.8251 9.63708 12.9034V14H10.1854H10.7859V12.8773C12.3003 12.6423 12.9791 11.7023 12.9791 10.6057C12.9791 9.19582 11.8825 8.72585 10.7598 8.41253ZM9.61097 8.09921C9.01044 7.94256 8.59269 7.70757 8.59269 7.26371C8.59269 6.76762 8.98433 6.4282 9.61097 6.34987V8.09921ZM10.7859 11.7024V9.87468C11.282 10.0574 11.6214 10.2924 11.6214 10.7363C11.6214 11.1279 11.3864 11.5457 10.7859 11.7024Z"
          fill="white"
        />
      </svg>

      {/* <svg
        width="16"
        height="22"
        viewBox="0 0 350 480"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M175.002 0C149.541 0 125.123 10.1143 107.12 28.1177C89.1162 46.1212 79.0019 70.5392 79.0019 96V112H65.7219C52.2518 111.986 39.2952 117.167 29.5494 126.466C19.8037 135.765 14.0198 148.464 13.4019 161.92L0.761945 425.12C0.437922 432.198 1.55036 439.268 4.0322 445.904C6.51404 452.54 10.3139 458.605 15.2027 463.733C20.0915 468.861 25.9681 472.946 32.4781 475.741C38.9882 478.537 45.997 479.986 53.0819 480H296.922C304.007 479.986 311.016 478.537 317.526 475.741C324.036 472.946 329.912 468.861 334.801 463.733C339.69 458.605 343.49 452.54 345.972 445.904C348.454 439.268 349.566 432.198 349.242 425.12L336.602 161.92C335.984 148.464 330.2 135.765 320.454 126.466C310.709 117.167 297.752 111.986 284.282 112H271.002V96C271.002 70.5392 260.888 46.1212 242.884 28.1177C224.881 10.1143 200.463 0 175.002 0ZM111.002 96C111.002 79.0261 117.745 62.7475 129.747 50.7452C141.749 38.7428 158.028 32 175.002 32C191.976 32 208.254 38.7428 220.257 50.7452C232.259 62.7475 239.002 79.0261 239.002 96V112H111.002V96ZM84.7619 179.04C84.7619 175.875 85.7003 172.782 87.4584 170.151C89.2165 167.52 91.7154 165.469 94.639 164.258C97.5626 163.047 100.78 162.73 103.883 163.347C106.987 163.965 109.838 165.489 112.076 167.726C114.313 169.964 115.837 172.815 116.455 175.919C117.072 179.022 116.755 182.239 115.544 185.163C114.333 188.087 112.282 190.585 109.651 192.344C107.02 194.102 103.926 195.04 100.762 195.04C96.5185 195.04 92.4488 193.354 89.4482 190.354C86.4477 187.353 84.7619 183.283 84.7619 179.04ZM233.242 179.04C233.242 175.875 234.18 172.782 235.938 170.151C237.697 167.52 240.195 165.469 243.119 164.258C246.043 163.047 249.26 162.73 252.363 163.347C255.467 163.965 258.318 165.489 260.556 167.726C262.793 169.964 264.317 172.815 264.935 175.919C265.552 179.022 265.235 182.239 264.024 185.163C262.813 188.087 260.762 190.585 258.131 192.344C255.5 194.102 252.406 195.04 249.242 195.04C244.998 195.04 240.929 193.354 237.928 190.354C234.928 187.353 233.242 183.283 233.242 179.04Z"
          fill="#22841A"
        />
      </svg> */}
    </>
  );
};

export default GoToBasket;
