import {
  API_ADD_TO_BASKET,
  API_BASKET_COUNT,
  API_BASKET_DETAILS,
  API_REMOVE_ITEM_FROM_BASKET,
} from "../constants/api";
import { API, BASKET_COUNT_F, BASKET_COUNT_S } from "../constants/types";

export const addToBasketAction = (data) => ({
  type: API,
  payload: {
    url: API_ADD_TO_BASKET,
    method: "POST",
    data: data,
    showLoader: false,
  },
});

export const getBasketDetailsAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_BASKET_DETAILS,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const removeItemFromBasketAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_REMOVE_ITEM_FROM_BASKET,
    method: "POST",
    data: data,
    showLoader,
  },
});
export const basketCountAction = (id) => ({
  type: API,
  payload: {
    url: `${API_BASKET_COUNT}${id}`,
    method: "GET",
    // data: data,
    showLoader: false,
    success: (data) => ({
      type: BASKET_COUNT_S,
      payload: data,
    }),
    error: (data) => ({
      type: BASKET_COUNT_F,
      payload: data,
    }),
  },
});
