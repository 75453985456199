import React from "react";

function Email() {
  return (
    <>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect className="react" x="1" y="1" width="16" height="12" rx="2" stroke="#4F4F4F" fill="none" />
        <path
          d="M1 4L8.10557 7.55279C8.66863 7.83431 9.33137 7.83431 9.89443 7.55279L17 4"
          stroke="#4F4F4F"
          fill="none"
        />
      </svg>
    </>
  );
}

export default Email;
