import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./search.scss";

const Search = (props) => {
  const { items, placeholder, handleOnSearch } = props;
  const navigate = useNavigate();

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item.updatedName}
        </span>
      </>
    );
  };

  const handleOnSelect = (item) => {
    if (item.type === "Nonprofit") navigate(`/charity_details/${item.id}`);
    else navigate(`/cause_details/${item.id}`);
  };

  return (
    <>
      <div className="common-search">
        <ReactSearchAutocomplete
          items={items ? items : []}
          autoFocus
          formatResult={formatResult}
          onSelect={handleOnSelect}
          placeholder={placeholder}
          onSearch={handleOnSearch}
        />
      </div>
    </>
  );
};

export default Search;
