import React from "react";

const Send = () => {
  return (
    <>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.57841 2.43373L3.50463 6.98817H9.21951C9.70566 6.98817 10.0998 7.3252 10.0998 7.74096C10.0998 8.1567 9.70566 8.49374 9.21951 8.49374H3.50463L2.57841 13.0481L17.059 7.74096L2.57841 2.43373ZM1.87076 7.74096L0.785018 2.40204C0.697245 1.97043 0.860553 1.52929 1.22279 1.21951C1.67792 0.830293 2.36431 0.717244 2.95593 0.934076L18.6861 6.69926C19.1733 6.87785 19.4892 7.28759 19.4892 7.74096C19.4892 8.19431 19.1733 8.60406 18.6861 8.78265L2.95593 14.5478C2.36431 14.7647 1.67792 14.6516 1.22279 14.2624C0.860553 13.9526 0.697244 13.5115 0.785018 13.0799L1.87076 7.74096Z"
          fill="#8E8E93"
        />
      </svg>
    </>
  );
};

export default Send;
