import { API_CHAT_CREATE_ROOM } from "../constants/api";
import { API } from "../constants/types";

export const createChatRoomAction = (data) => ({
  type: API,
  payload: {
    url: API_CHAT_CREATE_ROOM,
    method: "POST",
    data: data,
  },
});
