import { RENDER_FAV_LIST_F, RENDER_FAV_LIST_S } from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  favouriteList: {},
};

const favouriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENDER_FAV_LIST_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        favouriteList: action.payload.data,
      };
    case RENDER_FAV_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        favouriteList: {},
      };
    default:
      return state;
  }
};

export default favouriteReducer;
