import React, { useRef } from "react";
import Swiper from "../../components/common/Swiper";
import { useDispatch } from "react-redux";
import SwiperText from "../../components/common/SwiperText";
import CardSignin from "../../components/common/Card_signin";
import { Field, Form } from "react-final-form";
import "./contactUs.scss";
import { renderTextInput } from "../../components/common/FormFields";
import User from "../../assets/svg/User";
import Mail from "../../assets/svg/email";
import { rendertextarea } from "../../components/forms";
import { contactUsFormValiations as validate } from "../../components/validations/signIn";
import { contectUsAction } from "../../actions/contectUs";
import { toast } from "react-hot-toast";

function ContactUs() {
  const dispatch = useDispatch();
  const formRef = useRef();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const onFormSubmit = (value) => {
    const payload = { ...value, userId, userType: 1 };
    dispatch(contectUsAction(payload)).then((res) => {
      toast.success(res.message);
      if (formRef?.current?.restart) {
        formRef.current?.restart({});
      }
    });
  };

  return (
    <>
      <section className="signin contactUs">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Contact Rosi">
              <Form onSubmit={onFormSubmit} validate={validate}>
                {({ handleSubmit, form }) => {
                  formRef.current = form;
                  return (
                    <>
                      <form className="form" onSubmit={handleSubmit}>
                        <ul>
                          <li className="main-input">
                            <Field
                              name="name"
                              type="text"
                              component={renderTextInput}
                              placeholder="Name"
                              containerClasses="form-group"
                            >
                              <User />
                            </Field>
                          </li>
                          <li className="main-input">
                            <Field
                              name="email"
                              type="text"
                              component={renderTextInput}
                              placeholder="Email"
                              containerClasses="form-group"
                            >
                              <Mail />
                            </Field>
                          </li>
                          <li>
                            <Field
                              name="message"
                              placeholder="Enter Your Message Here"
                              className="form-grp textarea"
                              component={rendertextarea}
                            ></Field>
                          </li>
                          <li className="sign-btn">
                            <button className="buttonSubmit">
                              Send Message
                            </button>
                          </li>
                        </ul>
                      </form>
                    </>
                  );
                }}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
