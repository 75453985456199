import React from "react";
import ScrollBar from "react-perfect-scrollbar";
import CauseCard from "../../CauseCard";
import "./causeFavorite.scss";

function CauseFavorite({ causeFavList }) {
  let finalCauseList = [];
  causeFavList?.map((item) =>
    finalCauseList.push({
      id: item?.causeId,
      causeImage: item?.images?.url,
      causeText: item?.causeName,
      isFavorite: true,
    })
  );
  return (
    <>
      <ScrollBar component="div">
        <div className="favoriteScroll">
          <div className="FavoriteCause">
            {finalCauseList?.length > 0 ? (
              finalCauseList?.map((cause) => (
                <CauseCard cause={cause} key={cause?.id} from="favSection" />
              ))
            ) : (
              <p className="verification_error">
                You don't have any favorite cause
              </p>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default CauseFavorite;
