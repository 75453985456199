/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import CustomTab from "../../components/common/Tabs";
import { getVideoUrl, toAbsoluteUrl } from "../../utils";
import "./post.scss";
import PostInput from "../../components/common/PostInput";
import CustomModal from "../../components/common/Modal";
import { rendertextarea } from "../../components/forms";
import PostTab from "../../components/common/PostTab";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import {
  createPostAction,
  doUploadVideo,
  getUserDetailsAction,
  postListAction,
} from "../../actions/post";
import ImgUpload from "../../components/common/img-upload";
import { RenderVideoField } from "../../components/common/Video-upload";
import toast from "react-hot-toast";
import PostList from "../../components/common/PostList";
import { doUploadImage } from "../../actions/charityRegister";

const bread = [
  {
    name: "Chat & Feed",
    link: "/post",
    isActive: true,
  },
];

const posting = [
  {
    id: 1,
    attendingCount: 18,
    countType: "Requested",
  },
  {
    id: 2,
    attendingCount: 4,
    countType: "Approved",
  },
  {
    id: 3,
    attendingCount: 3,
    countType: "Total Feed",
  },
];

function Post() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [postType, setPostType] = useState("");
  const [isMyPost, setIsMyPost] = useState();

  //image video state
  const [image, setImage] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [oldPostImages, setOldPostImages] = useState();
  const [videoArray, setVideoArray] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);

  const [userDetails, setUserDetails] = useState();
  const [args, setArgs] = useState({
    page: 1,
    limit: 20,
  });

  const tabs = [
    {
      heading: "Rosi Friends",
      body: (
        <PostTab
          postHeaderName="Rosi Friends"
          postButtonTitle="Send Message"
          from="friendDonor"
          setUserDetails={setUserDetails}
          userDetails={userDetails}
        />
      ),
    },
    {
      heading: "Requested Friends",
      body: (
        <PostTab
          postHeaderName="Requested Friends"
          from="requestedFriend"
          setUserDetails={setUserDetails}
          userDetails={userDetails}
        />
      ),
    },
    {
      heading: "Find Friends",
      body: (
        <PostTab
          postHeaderName="Find Friends"
          postButtonTitle="Send Request"
          from="userDonor"
        />
      ),
    },
  ];
  const feedTypes = [
    {
      heading: "Community Post",
      body: (
        <PostList setIsMyPost={setIsMyPost} args={args} setArgs={setArgs} />
      ),
    },
    {
      heading: "My Post",
      body: (
        <PostList
          type={true}
          setIsMyPost={setIsMyPost}
          args={args}
          setArgs={setArgs}
        />
      ),
    },
    {
      heading: "Friend's Post",
      body: (
        <PostList
          type={false}
          setIsMyPost={setIsMyPost}
          args={args}
          setArgs={setArgs}
        />
      ),
    },
  ];
  useEffect(() => {
    if (!userId) {
      navigate(`/signin?${window.location.pathname}`);
    }
  }, []);

  useEffect(() => {
    dispatch(getUserDetailsAction(userId, true)).then((res) =>
      setUserDetails(res.data)
    );
  }, []);

  const required = (value) => {
    if (postType === "article") {
      if (!value) {
        return "Please enter text";
      }
      if (value && !value.trim()) {
        return "Please enter valid text";
      }
      // return (value || value.trim() ? undefined : 'Required')
      return undefined;
    }
    return undefined;
  };

  const uploadVideoFiles = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const formSubmitHandler = (value) => {
    let data = {
      type: 3,
      userId,
      description: value?.postContent,
      isPrivate: false,
    };
    if (postType === "photo") {
      let imgArr = [];
      let len = postImages.length;
      if (len === 0) {
        toast.error("Please attach atleast one image");
        return;
      }
      postImages.forEach((image, ind) => {
        if (!image?.name) {
          const payload = { image: [image], moduleName: "postImages" };
          dispatch(doUploadImage(payload)).then((res) => {
            len = len - 1;
            imgArr.push({
              name: res.data.name,
              url: mediaURL + "postImages/" + res.data.name,
            });
            setImage((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: mediaURL + "postImages/" + res.data.name,
              },
            ]);
            if (len === 0) {
              data = { ...data, type: 1, image: imgArr };
              createPostHandler(data);
            }
          });
        }
        return "";
      });
    } else if (postType === "video") {
      if (videoSourceFiles?.length === 0) {
        toast.error("Please attach a video");
        return;
      }
      const videoPayload = [];
      Promise.allSettled(uploadVideoFiles()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(mediaURL, "postImages", value.value?.data?.name),
              thumbnail: value.value?.data.thumbnail,
            });
          } else {
            toast.error(value?.reason?.message);
            return;
          }
          return null;
        });
        if (videoPayload.length === 0) {
          setEditIsOpen((prev) => !prev);
          setVideoArray([]);
          setVideoSourceFiles([]);
          setVideoSourceURLs([]);
          return;
        }

        data = { ...data, type: 2, video: videoPayload, image: [] };
        createPostHandler(data);
      });
    } else {
      createPostHandler(data);
    }
  };

  const createPostHandler = (data) => {
    dispatch(createPostAction(data)).then(() => {
      dispatch(getUserDetailsAction(userId)).then((res) => {
        setUserDetails(res.data);
      });
      setTimeout(() => {
        // dispatch(postListAction({ page: 1, limit: 1000 }, isMyPost))
        dispatch(
          postListAction({ page: 1, limit: 20 }, isMyPost, true, false)
        ).then(() => {
          setArgs({
            page: 1,
            limit: 20,
          });
        });
      }, 400);
      setEditIsOpen((prev) => !prev);
      setImage([]);
      setVideoArray([]);
      setPostImages([]);
      setVideoSourceFiles([]);
      setVideoSourceURLs([]);
    });
  };

  const closeAddPostModalHandler = () => {
    setEditIsOpen((prev) => !prev);
    setImage([]);
    setPostImages([]);
    setVideoArray([]);
    setVideoSourceFiles([]);
    setVideoSourceURLs([]);
  };

  return (
    <>
      <section className="post">
        <div className="container">
          <div className="breadcrumbsPost">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="postWrap">
            <div className="postSidebar">
              {/* account */}
              <div className="accountPost">
                <div className="postAccountDetails">
                  <figure>
                    {userDetails?.profileImage &&
                    userDetails?.profileImage !== "string" ? (
                      <img
                        src={
                          mediaURL +
                          "userProfilePic/" +
                          userDetails?.profileImage
                        }
                        alt="user"
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )}
                  </figure>
                  <h3>
                    {userDetails?.firstName + " " + userDetails?.lastName}
                  </h3>
                </div>
                <div className="postAccountCount">
                  <div key={posting.id} className="attendingPostCount">
                    <span>{userDetails?.requestedFriend}</span>
                    <h4>Requested</h4>
                  </div>
                  <div key={posting.id} className="attendingPostCount">
                    <span>{userDetails?.totalFrd}</span>
                    <h4>Approved</h4>
                  </div>
                  <div key={posting.id} className="attendingPostCount">
                    <span>{userDetails?.totalFedd}</span>
                    <h4>Total Feed</h4>
                  </div>
                </div>
              </div>
              {/* postTabbing */}
              <div className="postTabbing">
                <CustomTab
                  className="communityTab"
                  data={tabs}
                  setUserDetails={setUserDetails}
                  userDetails={userDetails}
                />
              </div>
              {/* badge */}
              {/* <div className="badge">
                <div className="badgeWrap">
                  <h3>Badges</h3>
                  <figure>
                    <img
                      src={toAbsoluteUrl("/images/postBadge.svg")}
                      alt="post badge"
                    />
                  </figure>
                </div>
                <div className="badgecontent">
                  <h2>platinum Cup post</h2>
                  <p>
                    Has posted more than 100 post on their FeedHas posted more
                    than 100 post on their FeedHas posted more than 100 post on
                    their FeedHas posted more than 100 post on their Feed
                  </p>
                  <Line
                    percent={10}
                    trailWidth={4}
                    trailColor="#CCCCCC"
                    strokeWidth={4}
                    strokeColor="rgba(109, 175, 95, 0.8)"
                  />
                  <Link
                    to="/profile_details"
                    state={{ userID: user?.data._id, defaultIndex: 2 }}
                  >
                    View More
                  </Link>
                </div>
              </div> */}
              {/* share */}
              <Share />
            </div>
            <div className="postContent">
              <PostInput
                setEditIsOpen={setEditIsOpen}
                setPostType={setPostType}
                isMyPost={isMyPost}
                setArgs={() => setArgs({ ...args, page: 1 })}
                setUserDetails={setUserDetails}
                from="postModule"
              />
              {/* <div className="detailsHeader">
                <h2>{isMyPost ? "My Posts" : "Donor’s Friends Post"}</h2>
                {userId && (
                  <div className="detailsSwitch">
                    <span>Switch to</span>
                    <button onClick={() => switchToMyPostHandler()}>{`${
                      !isMyPost ? "My Posts" : "Donor’s Friends Post"
                    }`}</button>
                  </div>
                )}
              </div> */}
              <CustomTab className="tabsForpost" data={feedTypes} />
            </div>
          </div>
        </div>
      </section>

      <CustomModal
        className="postDeleteModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <h3>Are you sure you want to delete this post?</h3>
        <p>
          Are you sure you want to delete this post, after this action this post
          will permanentely deleted from server.
        </p>
        <div className="postModal">
          <button>Cancel</button>
          <button>Delete</button>
        </div>
      </CustomModal>

      <CustomModal
        className="postEditModal"
        isOpen={editIsOpen}
        closeModal={() => closeAddPostModalHandler()}
        title="Add post"
      >
        <Form onSubmit={formSubmitHandler}>
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Field
                  name="postContent"
                  component={rendertextarea}
                  className="textarea"
                  placeholder="Type here"
                  validate={required}
                ></Field>
                {postType === "photo" && (
                  <ImgUpload
                    isPost={true}
                    multiple={true}
                    setImg={setImage}
                    imgs={image}
                    setImages={setPostImages}
                    images={postImages}
                    oldImages={oldPostImages}
                    setOldImages={(images) => setOldPostImages(images)}
                    maxNumber={5}
                    moduleName="postImages"
                    postPage={true}
                    callUploadAPI={false}
                  />
                )}
                {postType === "video" && (
                  <RenderVideoField
                    oneVideoValidation={true}
                    isMultiple={false}
                    moduleName="postImages"
                    videoArray={videoArray}
                    setVideoArray={setVideoArray}
                    videoSourceURLs={videoSourceURLs}
                    setVideoSourceURLs={setVideoSourceURLs}
                    videoSourceFiles={videoSourceFiles}
                    setVideoSourceFiles={setVideoSourceFiles}
                  />
                )}
                <div className="editButton">
                  <button
                    type="button"
                    onClick={() => closeAddPostModalHandler()}
                  >
                    Cancel
                  </button>
                  <button>Post</button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
}

export default Post;
