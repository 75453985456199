import {
  COMMUNITY_DETAILS_F,
  COMMUNITY_DETAILS_S,
  RENDER_ALL_COMMUNITY_F,
  RENDER_ALL_COMMUNITY_S,
  RENDER_NOT_ASSO_COMMUNITY_F,
  RENDER_NOT_ASSO_COMMUNITY_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  allCommunityList: [],
  notAssoCommunityList: [],
  communityDetails: {},
};

const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENDER_ALL_COMMUNITY_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        allCommunityList: action.payload.data,
      };
    case RENDER_ALL_COMMUNITY_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        allCommunityList: {},
      };
    case RENDER_NOT_ASSO_COMMUNITY_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        notAssoCommunityList: action.payload.data,
      };
    case RENDER_NOT_ASSO_COMMUNITY_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        notAssoCommunityList: {},
      };
    case COMMUNITY_DETAILS_S:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        communityDetails: action.payload.data,
      };
    case COMMUNITY_DETAILS_F:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.message,
        communityDetails: {},
      };
    default:
      return state;
  }
};

export default communityReducer;
