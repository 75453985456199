import React from "react";
import Modal from "react-modal";
import Cancle from "../../../assets/svg/cancle";
import "./modal.scss";

function CustomModal(props) {
  const {
    closeModal,
    isOpen,
    title,
    children,
    className,
    submitModal,
    selectedContribution,
    selectedCharityType,
  } = props;

  const submitHandler = () => {
    closeModal();
    submitModal();
  };

  return (
    <>
      <Modal
        className={`custom-modal ${className}`}
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          closeModal();
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <h3>{title}</h3>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            <Cancle />
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button
            className="submit"
            onClick={submitHandler}
            disabled={
              selectedCharityType === false && selectedContribution === false
            }
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}

export default CustomModal;
