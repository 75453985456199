import "./index.scss";

export const Loading = () => {
  return (
    <div className="container-loading">
      <div className="spinner"></div>
      <div className="loader"></div>
    </div>
  );
};
