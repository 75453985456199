/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cmsDetailsAction } from "../../actions/cms";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import InformationPage from "../../components/common/informationPage";

const bread = [
  {
    name: "Privacy Policy",
    link: "/privacyPolicy",
    isActive: true,
  },
];

function PrivacyPolicy() {
  const privacyContain = useSelector((state) => state.cmsReducer.cmsDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cmsDetailsAction("634539891fb356a539facb90"));
  }, []);

  return (
    <>
      <section className="terms">
        <div className="container">
          <div className="breadcrumbsTerms">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <InformationPage
            pageTitle="Privacy Policy"
            classname="termCondition"
            datePageName="Privacy Policy"
            date={privacyContain?.updatedDate}
            paraOne={privacyContain?.content}
          />
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
