import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addCauseToFav,
  removeFav,
  renderFavList,
} from "../../../actions/favourite";
import "./causeCard.scss";
import Heart from "../../../assets/svg/heart";
import { toAbsoluteUrl } from "../../../utils/index";
import toast from "react-hot-toast";

function CauseCard(props) {
  const { cause, from } = props;
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;
  const dispatch = useDispatch();

  const [isFav, setIsFav] = useState(cause?.isFavorite);

  const handleCardClick = (e) => {
    e.preventDefault();
    navigate(`/cause_details/${cause.id}`);
  };

  const handleFavClick = (e) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }

    if (from === "favSection") {
      if (!isFav) {
        const payload = { userId, causeId: cause?.id };
        dispatch(addCauseToFav(payload)).then(() => {
          setIsFav(true);
          dispatch(renderFavList({ userId }));
        });
      } else {
        const payload = { _id: userId, causeId: cause?.id };
        dispatch(removeFav(payload)).then(() => {
          setIsFav(false);
          dispatch(renderFavList({ userId }));
        });
      }
      return;
    }

    if (from === "homepage") {
      if (!isFav) {
        const payload = { userId, causeId: cause?.id };
        dispatch(addCauseToFav(payload)).then(() =>
          // dispatch(renderHomePageDetails())
          setIsFav(true)
        );
      } else {
        const payload = { _id: userId, causeId: cause?.id };
        dispatch(removeFav(payload)).then(() =>
          // dispatch(renderHomePageDetails())
          setIsFav(false)
        );
      }
      return;
    }

    if (!isFav) {
      const payload = { userId, causeId: cause?.id };
      dispatch(addCauseToFav(payload)).then(() =>
        // dispatch(renderCauseList({ page: 1, limit: 100 }))
        setIsFav(true)
      );
    } else {
      const payload = { _id: userId, causeId: cause?.id };
      dispatch(removeFav(payload)).then(() =>
        // dispatch(renderCauseList({ page: 1, limit: 100 }))
        setIsFav(false)
      );
    }
  };

  return (
      <div className="causeCard" onClick={(e) => handleCardClick(e)}>
        <div className="causemainContent">
          <div className="causeImage">
            <figure>
              {cause.causeImage && cause?.causeImage !== "string" ? (
                <img src={cause.causeImage} alt="causes" />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
          </div>
          <div className="causeContent">
            <h4>{cause.causeText}</h4>
          </div>
        </div>
        <div className="causeButtons">
          {/* <Link className="basket" to="" onClick={handleBasketButtonClick}>
              Add To Basket
            </Link> */}
          <button
            className={`${isFav ? "causeHeartActive" : "causeHeart"}`}
            onClick={handleFavClick}
          >
            <Heart />
          </button>
        </div>
      </div>
  );
}

export default CauseCard;
