import React from "react";
import "./communityList.scss";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CustomTab from "../../components/common/Tabs";
import Assosiated from "./communityTabs/Associated";
import NotAssociated from "./communityTabs/NotAssociated";
import { useParams } from "react-router-dom";

const bread = [
  {
    name: "Community List",
    link: "/community",
    isActive: true,
  },
];

const data = [
  {
    heading: "Associated",
    key: 1,
    body: <Assosiated />,
  },
  {
    heading: "Not Associated",
    key: 2,
    body: <NotAssociated />,
  },
];

function CommunityList() {
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;
  const params = useParams();

  return (
    <>
      <section className="communityList">
        <div className="container">
          <div className="breadcrumbsCommunity">
            <Breadcrumbs list={bread} />
          </div>
          <div className={token ? "communityHeader" : "communityheader"}>
            <h3>Community List</h3>

            <div className="communityTab">
              {token ? (
                <CustomTab
                  defaultIndex={params.id ? params.id - 1 : 0}
                  className="communityListTab"
                  data={data}
                  from="community"
                />
              ) : (
                <Assosiated guestUser={true} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CommunityList;
