import React from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import Email from "../../assets/svg/email";
import Lock from "../../assets/svg/lock";
import CardSignin from "../../components/common/Card_signin";
import { renderTextInput } from "../../components/common/FormFields";
import Swiper from "../../components/common/Swiper";
import SwiperText from "../../components/common/SwiperText";
import "./employeeLogin.scss";

function EmployeeLogin() {
  const onFormSubmit = (data) => {};

  return (
    <>
      <section className="signin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Sign In">
              <Form onSubmit={onFormSubmit}>
                {({ handleSubmit }) => (
                  <>
                    <form className="form" onSubmit={handleSubmit}>
                      <ul>
                        <li className="main-input">
                          <Field
                            name="email"
                            type="text"
                            component={renderTextInput}
                            placeholder="Email"
                            containerClasses="form-group"
                          >
                            <Email />
                          </Field>
                        </li>
                        <li className="main-pass">
                          <Field
                            name="password"
                            type="password"
                            component={renderTextInput}
                            placeholder="Password"
                            containerClasses="form-group"
                          >
                            <Lock />
                          </Field>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <div className="forgot">
                            {/* <div className="remember">
                              <input
                                className="check"
                                type="checkbox"
                                id="rem"
                                defaultChecked={isRemembered}
                                onChange={() =>
                                  setIsRemembered((prev) => !prev)
                                }
                              />
                              <label htmlFor="rem">Remember Me</label>
                            </div> */}
                            <div className="pass">
                              <Link to="/forgot_password">
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li className="sign-btn">
                          <button className="buttonSubmit" type="submit">
                            Sign In As Associate
                          </button>
                        </li>
                        {/* <li className="signin-account">
                          <h5>
                            Didn’t Have An account ?
                            <Link to="/employee-registration">Sign Up</Link>
                          </h5>
                        </li> */}
                      </ul>
                    </form>
                  </>
                )}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmployeeLogin;
