import { ABOUTPAGE_DETAILS_F, ABOUTPAGE_DETAILS_S } from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  aboutPageDetails: {},
};

const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUTPAGE_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        aboutPageDetails: action.payload.data,
      };
    case ABOUTPAGE_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        aboutPageDetails: {},
      };
    default:
      return state;
  }
};

export default aboutReducer;
