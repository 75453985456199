import React from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import RelatedCharityCard from "../../../../components/common/RelatedCharityCard";
import "./relatedCharity.scss";

function RelatedCharity({ charityList, causeId }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="tabrelated">
        <div className="tabRelatedHeader">
          <h3>Related Nonprofits</h3>
          <button onClick={() => navigate("/charity")}>View All</button>
        </div>
        <div className="tabCharityBodyWrap">
          <ScrollBar component="div">
            <div className="content">
              {charityList?.length > 0 ? (
                charityList?.map((charity) => (
                  <RelatedCharityCard
                    charity={charity}
                    key={charity?._id}
                    causeId={causeId}
                  />
                ))
              ) : (
                <h4 className="notFound">No Charity Found</h4>
              )}
            </div>
          </ScrollBar>
        </div>
      </div>
    </>
  );
}

export default RelatedCharity;
