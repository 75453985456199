import React from "react";
import { RenderImage } from ".";
import { dateFormatChat, getImageUrl, toAbsoluteUrl } from "../../utils";
import Linkify from "react-linkify";

export const ChatSender = ({ mediaURL, userDetails, message, createdDate }) => {
  return (
    <div className="chat-msg sender">
      <div className="chat-sender-content">
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <span>{message}</span>
        </Linkify>
        <small>{dateFormatChat(createdDate)}</small>
      </div>
    </div>
  );
};

export const ChatReciver = ({
  mediaURL,
  userDetails,
  message,
  sendBy,
  createdDate,
}) => {
  const handlemodualname = (type) => {
    if (type === "charity") {
      return "charityLogo";
    } else if (type === "user") {
      return "userProfilePic";
    } else if (type === "admin") {
      return "adminImages";
    } else if (type === "strategic") {
      return "strategicLogo";
    } else {
      return "";
    }
  };
  return (
    <div className="chat-msg receiver">
      <RenderImage
        className="border-50"
        filePath={
          sendBy?.[0]?.profileImage
            ? getImageUrl(
                mediaURL,
                handlemodualname(sendBy?.[0]?.type),
                sendBy?.[0]?.profileImage
              )
            : toAbsoluteUrl("/imagprofileImagees/userLogo.png")
        }
      />
      <div className="chat-receiver-content">
        <div className="chat-message">
          {/* <h3>{sendBy?.[0]?.name}</h3> */}
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <span>{message}</span>
          </Linkify>
        </div>
        <small>{dateFormatChat(createdDate)}</small>
      </div>
    </div>
  );
};

const ChatBody = (props) => {
  const { sendId, userId } = props;
  if (userId === sendId) return <ChatSender {...props} />;
  else return <ChatReciver {...props} />;
};

export default ChatBody;
