import {
  API_COMMENT_REPLY,
  API_COMMUNITY_POST_LIST,
  API_CREATE_POST,
  API_DELETE_POST,
  API_HIDE_POST,
  API_JOIN_COMMUNITY_POST,
  API_LIKE_UNLIKE_COMMENTS,
  API_LIKE_UNLIKE_POST,
  API_POST_COMMENT,
  API_POST_LIST,
  API_RENDER_COMMENTS,
  API_REPORT_POST,
  API_SHARE_POST,
  API_USER_POST_LIST,
  API_USER_PROFILE_DETAILS,
  API_VIDEO_UPLOAD,
} from "../constants/api";
import {
  API,
  COMMUNITY_POST_COMMENT_LIST_F,
  COMMUNITY_POST_COMMENT_LIST_S,
  COMMUNITY_POST_LIST_F,
  COMMUNITY_POST_LIST_S,
  JOIN_COMMUNITY_POST_LIST_F,
  JOIN_COMMUNITY_POST_LIST_S,
  POST_DETAILS_F,
  POST_DETAILS_S,
  POST_LIST_F,
  POST_LIST_S,
  USER_DETAILS_F,
  USER_DETAILS_S,
} from "../constants/types";

export const communityPostListAction = (
  id,
  data,
  isOwnPost,
  reset = false
) => ({
  type: API,
  payload: {
    url: `${API_COMMUNITY_POST_LIST}${id}/${isOwnPost}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_POST_LIST_S,
      payload: { ...data, reset },
    }),
    error: (data) => ({
      type: COMMUNITY_POST_LIST_F,
      payload: data,
    }),
  },
});

export const postListAction = (
  data,
  isOwnPost,
  showLoader = false,
  reset = false
) => ({
  type: API,
  payload: {
    url: `${API_POST_LIST}${isOwnPost}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: POST_LIST_S,
      payload: { ...data, reset },
    }),
    error: (data) => ({
      type: POST_LIST_F,
      payload: data,
    }),
  },
});

export const joinCommunityPostListAction = (
  data,
  showLoader = false,
  reset = false
) => ({
  type: API,
  payload: {
    url: API_JOIN_COMMUNITY_POST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: JOIN_COMMUNITY_POST_LIST_S,
      payload: { ...data, reset },
    }),
    error: (data) => ({
      type: JOIN_COMMUNITY_POST_LIST_F,
      payload: data,
    }),
  },
});

export const createPostAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CREATE_POST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const editPostAction = (id, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_CREATE_POST}/${id}`,
    method: "PATCH",
    data: data,
    showLoader,
  },
});

export const likeUnlikePostAction = (postId, isLiked, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_LIKE_UNLIKE_POST}${postId}/${isLiked}`,
    method: "PATCH",
    showLoader,
  },
});

export const likeUnlikeCommentAction = (commentId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_LIKE_UNLIKE_COMMENTS}${commentId}`,
    method: "PATCH",
    showLoader,
  },
});

export const hidePostAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_HIDE_POST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const deletePostAction = (postId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_DELETE_POST}${postId}`,
    method: "DELETE",
    showLoader,
  },
});

export const reportPostAction = (postId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_REPORT_POST}${postId}`,
    method: "PATCH",
    showLoader,
  },
});

export const sharePostAction = (postId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_SHARE_POST}${postId}`,
    method: "POST",
    showLoader,
    success: (data) => ({
      type: POST_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: POST_DETAILS_F,
      payload: data,
    }),
  },
});

export const createCommentAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_POST_COMMENT,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const creatCommentReplyAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_COMMENT_REPLY,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const getUserDetailsAction = (userId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_USER_PROFILE_DETAILS}${userId}`,
    method: "POST",
    showLoader,
  },
});

export const getUserPostListAction = (userId, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_USER_POST_LIST}${userId}`,
    method: "POST",
    showLoader,
    success: (data) => ({
      type: USER_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: USER_DETAILS_F,
      payload: data,
    }),
  },
});

export const renderCommentAction = (postId, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_RENDER_COMMENTS}${postId}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: COMMUNITY_POST_COMMENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_POST_COMMENT_LIST_F,
      payload: data,
    }),
  },
});

export const doUploadVideo = (data) => {
  let formData = new FormData();

  for (let key in data) {
    if (
      (data[key] && Array.isArray(data[key])) ||
      typeof data[key] === "object"
    ) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key]) {
      formData.append(key, data[key]);
    }
  }
  formData.delete("video");

  if (data.video) {
    // && data.image instanceof Blob
    formData.append("video", data.video, data.video.name);
  } else {
    formData.delete("video");
  }

  return {
    type: API,
    payload: {
      url: API_VIDEO_UPLOAD,
      method: "POST",
      data: formData,
    },
  };
};
