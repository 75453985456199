import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderAllUpcomingEventList } from "../../../../actions/event";
import FavEvents from "../../../../components/common/MyEvents/MyEvents";
import CommunityEvents from "../../../../components/common/MyEvents/CommunityEvents";
import StrategicEvents from "../../../../components/common/MyEvents/StrategicEvents";
import CustomTab from "../../../../components/common/Tabs";

const MyEvents = () => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { allUpcomingEventList } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(renderAllUpcomingEventList({ _id: userId }));
    // eslint-disable-next-line
  }, []);

  let data = [
    {
      heading: "My Events",
      body: <FavEvents />,
    },
    {
      heading: "Community Events",
      body: (
        <CommunityEvents eventList={allUpcomingEventList[0]?.communityEvent} />
      ),
    },
    {
      heading: "Giving Partner Events",
      body: (
        <StrategicEvents
          eventList={allUpcomingEventList[0]?.givingPartnerEvent}
        />
      ),
    },
  ];

  return (
    <>
      <div className="favoriteTab">
        <CustomTab className="tabsForFavorite" data={data} />
      </div>
    </>
  );
};

export default MyEvents;
