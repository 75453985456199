/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import {
  deleteCardAction,
  getAllCardListAction,
} from "../../../../actions/payment";
import AddCardModal from "../../../../components/common/AddCardCard";
import CustomModal from "../../../../components/common/Modal";
import "./cardDetails.scss";

function CardDetails() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState();

  useEffect(() => {
    dispatch(getAllCardListAction({ userId })).then((res) =>
      setCardList(res.data.listCards)
    );
  }, []);

  const openModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  const openDeleteModalHandler = (e, cardId) => {
    e.stopPropagation();
    setSelectedCardId(cardId);
    setIsDeleteModalOpen((prev) => !prev);
  };

  const deleteCardHandler = () => {
    dispatch(deleteCardAction({ cardId: selectedCardId }))
      .then((res) => {
        toast.success(res.message);
        setIsDeleteModalOpen((prev) => !prev);
        dispatch(getAllCardListAction({ userId })).then((res) =>
          setCardList(res.data.listCards)
        );
      })
      .catch((res) => {
        toast.error(res.message);
      });
  };

  return (
    <>
      {/* <p className='notFound'>Cooming Soon!</p> */}
      <div className="cardDetails">
        <div className="cardDetailsWrap">
          <div className="cardDetailsHeader">
            <span>My Card Details</span>
            <button className="addCard" onClick={openModal}>
              Add New Card
            </button>
          </div>
          <div className="cardDetailsBody">
            <ScrollBar component="div">
              <div className="content">
                {cardList?.length > 0 ? (
                  cardList.map((card) => {
                    return (
                      <div className="paymentCard" key={card?._id}>
                        <div className="CardName">
                          <span>Name on card</span>
                          <h5>{card.cardOwnName}</h5>
                        </div>
                        <div className="cardNumber">
                          <span>Card number</span>
                          <h5>**** {card.cardNumber}</h5>
                        </div>
                        <div className="expiryDate">
                          <span>Expiry date</span>
                          <h5>
                            {card?.expiryMonth?.toString() +
                              "/" +
                              card?.expiryYear?.toString().substr(2)}
                          </h5>
                        </div>
                        <div className="deleteButton">
                          <button
                            onClick={(e) =>
                              openDeleteModalHandler(e, card?.cardId)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="notFound cardPadding">no card added</p>
                )}
              </div>
            </ScrollBar>
          </div>
        </div>
      </div>

      <AddCardModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setCardList={setCardList}
      />

      <CustomModal
        className="postDeleteModal"
        isOpen={isDeleteModalOpen}
        closeModal={() => {
          setIsDeleteModalOpen((prev) => !prev);
        }}
      >
        <h3>Are you sure you want to delete this card ?</h3>
        <p>
          Are you sure you want to delete this card, after this action this card
          will permanentely deleted from server.
        </p>
        <div className="postModal">
          <button onClick={() => setIsDeleteModalOpen((prev) => !prev)}>
            Cancel
          </button>
          <button onClick={deleteCardHandler}>Delete</button>
        </div>
      </CustomModal>
    </>
  );
}

export default CardDetails;
