/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import "./faq.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { FaqCategoryList, FaqList } from "../../actions/faq";
import { useDispatch, useSelector } from "react-redux";
import Linkify from "react-linkify";
function Faq() {
  const faqButton = useSelector((state) => state.faqReducer.faqCategory);
  const faqcontent = useSelector((state) => state.faqReducer.faqList);
  const dispatch = useDispatch();
  const [categoryid, setCategoryid] = useState("");
  const arg = {
    page: 1,
    limit: 100000000000,
  };
  const [faqarg, setFaqArg] = useState({
    page: 1,
    limit: 1000000,
    faqCategoryId: null,
  });
  const bread = [
    {
      name: "Frequently Asked Questions",
      link: "/faq",
      isActive: true,
    },
  ];

  useEffect(() => {
    dispatch(FaqCategoryList(arg));
  }, []);

  useEffect(() => {
    if (faqarg?.faqCategoryId) {
      dispatch(FaqList(faqarg));
    }
  }, [faqarg]);

  const onhandleFaqList = (faqButton) => {
    setCategoryid(faqButton?._id);
    setFaqArg({ ...faqarg, faqCategoryId: faqButton?._id });
  };

  return (
    <>
      <section className="faq">
        <div className="container">
          <div className="breadcrumbsFaq">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <h3 className="pageTitle">Frequently Asked Questions</h3>
          <div className="faqContent">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              className="accordion"
            >
              {faqButton?.faqCategoryList?.length ? (
                faqButton?.faqCategoryList.map((faqButton) => (
                  <AccordionItem className="accordionItem" key={faqButton._id}>
                    <AccordionItemHeading
                      style={{ position: "relative" }}
                      onClick={() => onhandleFaqList(faqButton)}
                    >
                      <AccordionItemButton
                        className={`${faqButton?._id === categoryid
                          ? "openAccordionButton"
                          : "accordionButton"
                          } accordion__button`}
                      >
                        <span>{faqButton?.name}</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {faqcontent?.faqList?.length &&
                      faqButton?._id === categoryid
                      ? faqcontent?.faqList.map((faqcontent, i) => (
                        <AccordionItemPanel key={faqcontent._id}>
                          <Accordion allowZeroExpanded={true}>
                            <AccordionItem
                              className="accordionWrapItem"
                              key={faqcontent._id}
                            >
                              <AccordionItemHeading>
                                <AccordionItemButton className="accordionQuestion accordion__button">
                                  <span>{faqcontent?.title}</span>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="accordionPanel">
                                
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                 <p
                                  className="newline"
                                >{ faqcontent?.description}</p>
                                </Linkify>
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </AccordionItemPanel>
                      ))
                      : ""}
                  </AccordionItem>
                ))
              ) : (
                <h4 className="notFound"> FAQ not Found</h4>
              )}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
