import React from "react";
import { Link } from "react-router-dom";
import "./homeRegistration.scss";

const HomeRegistration = (props) => {
  return (
    <>
      <li className="home-registration">
        <h1>{props.labelText}</h1>
        <div className="registration-content">
          {props.registerContent ? (
            <div
              dangerouslySetInnerHTML={{ __html: props.registerContent }}
            ></div>
          ) : (
            ""
          )}
        </div>
        {props.givingpartner ? (
          <a
            href={`${process.env.REACT_APP_CHARITY_PUBLIC_URL}signup`}
            target="_blank" rel="noreferrer"
          >
            {props.buttonText}
          </a>
        ) : (
          <Link to={props.registrationPath}>{props.buttonText}</Link>
        )}
      </li>
    </>
  );
};

export default HomeRegistration;
