import { API_FAQ_CATEGORY_LIST, API_FAQ_LIST } from "../constants/api";
import {
  API,
  FAQ_CATEGORY_LIST_F,
  FAQ_CATEGORY_LIST_S,
  FAQ_LIST_F,
  FAQ_LIST_S,
} from "../constants/types";

export const FaqCategoryList = (data) => ({
  type: API,
  payload: {
    url: API_FAQ_CATEGORY_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: FAQ_CATEGORY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_CATEGORY_LIST_F,
      payload: data,
    }),
  },
});

export const FaqList = (data) => ({
  type: API,
  payload: {
    url: API_FAQ_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: FAQ_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: FAQ_LIST_F,
      payload: data,
    }),
  },
});
