import React from "react";

function eyeLock() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
      >
        <circle cx="10" cy="7" r="3.5" stroke="#4F4F4F" />
        <path
          d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36427 13 3.23206 9.78986 1.81197 8.06571C1.42381 7.59444 1.22973 7.35881 1.22973 7C1.22973 6.64119 1.42381 6.40556 1.81197 5.93429C3.23206 4.21014 6.36427 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z"
          stroke="#4F4F4F"
        />
      </svg>
    </>
  );
}

export default eyeLock;
