import React from "react";

function ProfileCount(props) {
  const { className, donation } = props;

  return (
    <>
      <div className={className}>
        <h3>{donation.count}</h3>
        <h4>{donation.title}</h4>
      </div>
    </>
  );
}

export default ProfileCount;
