/* eslint-disable react-hooks/exhaustive-deps */
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import {
  connectUser,
  getChatHistory,
  getChatUserList,
  resetChat,
  resetChatHistory,
  sendMessage,
  socket,
} from "../../utils/socket";
import { scrolltoBottomFun, toAbsoluteUrl } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_ACTIVE_USER,
  CHAT_LIMIT_CHANGE,
  USER_LIST_LIMIT_CHANGE,
} from "../../constants/types";
import Send from "../../assets/svg/send";
import Search from "../../assets/svg/search";
import ChatUserList from "./ChatSidebar";
import "./chat.scss";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { createChatRoomAction } from "../../actions/chat";

// To start scroll from bottom for chat list
scrolltoBottomFun("chatListId");

const bread = [
  {
    name: "Messages",
    link: "/message",
    isActive: true,
  },
];
const Chat = () => {
  // Fetch user data from localstorage
  const userData = JSON.parse(localStorage.getItem("webUser"));
  let userId = userData?.data?._id || "";
  const location = useLocation();
  const dispatch = useDispatch();
  const chatReducer = useSelector((state) => state?.chat);
  const mediaURL = useSelector((state) => state.metaReducer.mediaURL);
  const activeUser = useSelector((state) => state.chat.activeUser);

  const [messageText, setmessageText] = useState("");
  const [searchText, setsearchText] = useState("");
  const [chatViewMobile, togglechatViewMobile] = useState(true);
  const [chatRoom, setChatRoom] = useState();

  // useEffect(() => {
  //   return () => {
  //     socket.removeAllListeners();
  //   };
  // }, []);

  useEffect(() => {
    if (!chatReducer.isConnected) {
      connectUser(userId);
    } else getChatUserList({ page: 1, limit: 10, userId: userId });

    return () => {
      resetChat();
    };
  }, [chatReducer.isConnected]);

  useEffect(() => {
    if (userId && location?.state?.sendToId) {
      let payload = {
        userTo: location?.state?.sendToId,
        userFrom: userId,
        userFromType: 1,
      };

      if (location?.state?.type === "admin") {
        payload = {
          userTo: userId,
          userFrom: location?.state?.sendToId,
          userFromType: 4,
        };
      } else if (location?.state?.type === "stategic") {
        payload = { ...payload, userFromType: 3 };
      } else if (location?.state?.type === "charity") {
        payload = { ...payload, userFromType: 2 };
      }
      dispatch(createChatRoomAction(payload)).then((res) => {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
          search: location?.state?.name,
        });
        setChatRoom(res.data[0]);
        setmessageText("");
        getChatHistory({
          userId: userId,
          roomId: res.data[0]._id,
          page: 1,
          limit: 20,
        });
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.sendToId, location?.state?.from]);

  useEffect(() => {
    socket.on("server-receive-message", (res) => {
      if (res?.roomId === activeUser?.roomId) {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
        getChatHistory({
          userId: userId,
          roomId: activeUser?.roomId,
          page: 1,
          limit: 20,
        });
        scrolltoBottomFun("chatListId");
      } else {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      }
    });

    return () => {
      socket.removeListener("server-receive-message");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  useEffect(() => {
    if (chatReducer.activeUser.roomId) {
      resetChatHistory();
      setmessageText("");
      getChatHistory({
        userId: userId,
        roomId: activeUser.roomId,
        page: chatReducer.page,
        limit: chatReducer.limit,
      });
      setTimeout(() => {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatReducer.activeUser]);

  useEffect(() => {
    //condition remove bec last text not trigar event
    // if (searchText)
    getChatUserList({
      page: 1,
      limit: 10,
      userId: userId,
      search: searchText,
    });
  }, [searchText]);

  const onSendMessage = (e) => {
    e.preventDefault();

    if (messageText?.trim().length) {
      sendMessage(
        { userId: userId, roomId: activeUser?.roomId, message: messageText },
        () => {
          setmessageText("");

          getChatHistory({
            userId: userId,
            roomId: activeUser?.roomId,
            page: 1,
            limit: 20,
          });
          scrolltoBottomFun("chatListId");
        }
      );
    }
  };

  const onUserClick = (data) => {
    setChatRoom();
    togglechatViewMobile(false);
    dispatch({ type: CHAT_ACTIVE_USER, payload: data });
  };

  const fetchUserList = (arg) => {
    getChatUserList({
      userId: userId,
      page: 1,
      limit: +chatReducer.userListlimit + 10,
    });
    dispatch({
      type: USER_LIST_LIMIT_CHANGE,
      payload: +chatReducer.userListlimit + 10,
    });
  };

  const fetchChatList = (arg) => {
    getChatHistory({
      userId: userId,
      roomId: activeUser.roomId,
      page: chatReducer.page,
      limit: +chatReducer.limit + 15,
      from: "fetchChatList",
    });
    dispatch({
      type: CHAT_LIMIT_CHANGE,
      payload: +chatReducer.limit + 15,
    });
  };

  return (
    <>
      <section className="chat">
        <div className="container">
          <div className="breadcrumbsMessage">
            <Breadcrumbs list={bread} />
          </div>

          <div className="container">
            <div className="chat-header">
              <h3>Messages</h3>
            </div>
            <div className="chat-wrap">
              {/* Chat User Search */}
              <div className="messageHeader">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searchText}
                    onChange={(e) => {
                      setsearchText(e.target.value);
                    }}
                  />
                  <Search />
                </div>
              </div>
              <div className="messageContent">
                {/* Chat Side bar */}
                <div
                  className={`chat-list-wrap ${
                    chatViewMobile ? "active" : "inactive"
                  } `}
                >
                  {/* Chat User Search */}

                  {/* Chat Side bar */}
                  <div className="scroll-style scroll-wrapper" id="userListId">
                    <ul className="chat-listing">
                      <InfiniteScroll
                        dataLength={chatReducer?.chatUserList?.length}
                        next={fetchUserList}
                        scrollableTarget={"userListId"}
                        hasMore={true}
                      >
                        <div>
                          {chatReducer?.chatUserList?.length ? (
                            chatReducer?.chatUserList?.map((val, i) => {
                              if (val?.roomId === chatRoom?._id) {
                                onUserClick(val);
                              }
                              return (
                                <ChatUserList
                                  key={val?.roomId}
                                  mediaURL={mediaURL}
                                  chatReducer={chatReducer}
                                  data={val}
                                  onUserClick={onUserClick}
                                />
                              );
                            })
                          ) : (
                            <div className="emptyChatList">
                              <h4>No Chat Available</h4>
                            </div>
                          )}
                        </div>
                      </InfiniteScroll>
                    </ul>
                  </div>
                </div>

                {/* Chat Body */}
                {activeUser?.roomId ? (
                  <div
                    className={`chat-body-wrap ${
                      chatViewMobile ? "inactive" : "active"
                    } `}
                  >
                    <div className="chat-body-header">
                      <ChatHeader
                        mediaURL={mediaURL}
                        activeUser={activeUser}
                        onClickBack={() => togglechatViewMobile(true)}
                      />
                      {/* <Dropdown overlay={menu} trigger={['click']}>
                      <span onClick={(e) => e.preventDefault()} className="chat-dot"><MoreOutlined /></span>
                  </Dropdown> */}
                    </div>
                    {/* Chat Header */}

                    {/* Chat Body */}
                    <div className="chat-body-main">
                      <div
                        className="scroll-style reverse-scroll-wrapper"
                        id="chatListId"
                      >
                        <InfiniteScroll
                          dataLength={chatReducer?.chatList?.length}
                          next={fetchChatList}
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                          }}
                          inverse={true}
                          scrollableTarget={"chatListId"}
                          hasMore={true}
                        >
                          <div className="chat-body ">
                            {chatReducer?.chatList.map((val) => {
                              return (
                                <ChatBody
                                  {...val}
                                  key={val._id}
                                  mediaURL={mediaURL}
                                  userId={userId}
                                />
                              );
                            })}
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>

                    <div className="chat-footer">
                      <form>
                        <div className="footerInputGrp">
                          <input
                            type="text"
                            className="input-box"
                            placeholder="Type Your Message "
                            value={messageText}
                            onChange={(e) => {
                              setmessageText(e.target.value);
                            }}
                          />
                          <div className="chat-action">
                            <button
                              type="submit"
                              onClick={onSendMessage}
                              className="send-btn"
                            >
                              <Send />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  // when no user selected UI
                  <div className="chat-placeholder">
                    <div>
                      <RenderImage
                        filePath={toAbsoluteUrl("/images/logo.svg")}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const RenderImage = ({ filePath, className, className2 }) => {
  return (
    <figure>
      <img
        className={`${className} ${className2 ? className2 : ""}`}
        src={filePath}
        alt="user"
        onError={(e) => (e.target.src = toAbsoluteUrl("/images/userLogo.png"))}
      />
    </figure>
  );
};

export default Chat;
