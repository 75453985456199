import React from "react";

function NotificationBell() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.67785 5.98807C6.00265 2.74006 8.73579 0.266602 12 0.266602V0.266602C15.2642 0.266602 17.9973 2.74005 18.3221 5.98807L18.6518 9.28413C18.7014 9.78012 18.7262 10.0281 18.7656 10.2709C18.9224 11.2353 19.2545 12.1629 19.7455 13.0077C19.8691 13.2203 20.0074 13.4277 20.2839 13.8424L21.4271 15.5572C22.3396 16.9259 22.7958 17.6103 22.5132 18.1385C22.2305 18.6666 21.408 18.6666 19.763 18.6666H4.23703C2.59201 18.6666 1.7695 18.6666 1.48684 18.1385C1.20419 17.6103 1.66044 16.9259 2.57293 15.5572L3.7161 13.8424C3.9926 13.4277 4.13085 13.2203 4.25445 13.0077C4.74549 12.1629 5.07762 11.2353 5.23439 10.2709C5.27385 10.0281 5.29865 9.78012 5.34825 9.28413L5.67785 5.98807Z"
          fill="white"
        />
        <path
          d="M9.77915 21.69C9.91019 21.8123 10.1989 21.9203 10.6006 21.9974C11.0023 22.0744 11.4945 22.1162 12.0008 22.1162C12.5071 22.1162 12.9993 22.0744 13.4009 21.9974C13.8026 21.9203 14.0914 21.8123 14.2224 21.69"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default NotificationBell;
