/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./transactionDetails.scss";
import Search from "../../../../assets/svg/search";
import Report from "../../../../assets/svg/report";
import Birthday from "../../../../assets/svg/birthday";
import CustomModal from "../../../../components/common/Modal";
import { DateRangePicker } from "react-date-range";
import moment from "moment/moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toAbsoluteUrl } from "../../../../utils/index";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscriptionAction,
  getAllTransactionPDFAction,
  getTransactionRecieptAction,
  renderTransationListAction,
  subscriptionDetailAction,
  updateSubscriptionAction,
} from "../../../../actions/payment";
import Cancle from "../../../../assets/svg/cancle";
import { Field, Form } from "react-final-form";
import {
  renderDate,
  renderTextInput,
} from "../../../../components/common/FormFields";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function TransactionDetails() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subIsOpen, setSubIsOpen] = useState(false);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [currData, setCurrData] = useState();
  const [updatedSubscriptionData, setUpdatedSubscriptionData] = useState();
  const [isSubscriptionId, setIsSubscriptionId] = useState("");
  const [dateSelectedMessage, setDateSelectedMessage] = useState();
  const [totalRecords, settotalRecords] = useState(0);
  const [args, setArgs] = useState({
    page: 1,
    limit: 10,
    search: "",
    userId,
  });

  const [dateRange, setDateRange] = useState({
    startDate: new Date(moment().subtract(1, "week")),
    endDate: new Date(),
  });

  useEffect(() => {
    let payload = args;
    if (dateRange?.key) {
      payload = {
        ...payload,
        activeFrom: `${moment(dateRange?.startDate).format(
          "YYYY-MM-DD"
        )} 00:00:00`,
        activeTo: `${moment(dateRange?.endDate).format("YYYY-MM-DD")} 23:59:59`,
      };
    }
    dispatch(renderTransationListAction(payload)).then((res) => {
      settotalRecords(res?.data?.total_records || 0);
      setTransactionList(res?.data?.transactionList);
      setIsDateModalOpen(false);
    });
  }, [args]);

  const onSubmitDateRange = () => {
    let payload = args;
    payload = {
      ...payload,
      activeFrom: `${moment(dateRange?.startDate).format(
        "YYYY-MM-DD"
      )} 00:00:00`,
      activeTo: `${moment(dateRange?.endDate).format("YYYY-MM-DD")} 23:59:59`,
    };
    setArgs(payload);
  };

  const onDateChange = (params) => {
    setDateRange(params?.selection);
  };

  const searchTransactionInput = (e) => {
    setArgs({ ...args, search: e.target.value });
  };

  const customStyles = {
    rows: {
      style: {
        position: "relative",
        zIndex: "600",
      },
    },
    headCells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
        position: "absulate",
        zIndex: "555",
      },
    },
  };

  const totalItems = (row) => {
    let total = 0;
    if (row?.charity?.[0]?.percentageWiseAmount) {
      total += row.charity.length;
    }

    if (row?.community?.[0]?.percentageWiseAmount) {
      total += row.community.length;
    }

    return total;
  };

  const downloadTransactionReceiptHandler = (e, transactionId) => {
    e.stopPropagation();
    dispatch(getTransactionRecieptAction({ transactionId }, true)).then((res) =>
      window.open(mediaURL + res.data.url)
    );
  };

  const generateReportHandler = (e) => {
    dispatch(
      getAllTransactionPDFAction(
        {
          userId,
          activeFrom: `${moment(dateRange?.startDate).format(
            "YYYY-MM-DD"
          )} 00:00:00`,
          activeTo: `${moment(dateRange?.endDate).format(
            "YYYY-MM-DD"
          )} 23:59:59`,
        },
        true
      )
    ).then((res) => window.open(mediaURL + res.data.pdfUrl));
  };

  const subscriptionmodel = (row) => {
    setSubIsOpen((prev) => !prev);
    setIsSubscriptionId(row.subscriptionId);
  };

  const openSubcriptionUpdateModal = (row) => {
    dispatch(subscriptionDetailAction(row?.subscriptionId)).then((res) =>
      setUpdatedSubscriptionData(res.data)
    );
    setUpdateIsOpen((prev) => !prev);
    setCurrData(row);
  };

  const columns = [
    {
      id: 1,
      name: <h3>Trans ID</h3>,
      selector: (row) => (
        <span
          onClick={() => {
            setCurrData(row);
            setModalIsOpen((prev) => !prev);
          }}
        >
          {row.orderId}
        </span>
      ),
      transactionId: "transactionId",
    },
    {
      id: 2,
      name: <h3>Date & Time</h3>,
      selector: (row) => (
        <span
          onClick={() => {
            setCurrData(row);
            setModalIsOpen((prev) => !prev);
          }}
        >{`${moment(row?.createdDate).format("MM-DD-YYYY")} ${moment(
          row?.createdDate
        ).format("hh:mm A")}`}</span>
      ),
    },
    {
      id: 3,
      name: <h3>Nonprofits / Communities</h3>,
      selector: (row) => (
        <p
          className="tableCount"
          onClick={() => {
            setCurrData(row);
            setModalIsOpen((prev) => !prev);
          }}
        >
          {totalItems(row)}
        </p>
      ),
    },
    {
      id: 4,
      name: <h3>Amount</h3>,
      selector: (row) => (
        <span
          onClick={() => {
            setCurrData(row);
            setModalIsOpen((prev) => !prev);
          }}
        >{`$${row?.amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
      ),
    },
    {
      id: 5,
      name: <h3>Payment</h3>,
      selector: (row) => (
        <span
          className={
            row.isSubscription === false && row.paymentMethod !== "card"
              ? "paymentColor"
              : ""
          }
          onClick={() => {
            setCurrData(row);
            setModalIsOpen((prev) => !prev);
          }}
        >
          {row.paymentMethod === "card"
            ? `Card (${
                row.cardBrandName.charAt(0).toUpperCase() +
                row.cardBrandName.slice(1)
              })`
            : "Subscription"}
        </span>
      ),
    },
    {
      id: 6,
      name: <h3>Action</h3>,
      selector: (row) => (
        <div className="download">
          <button
            className="commonRound whiteButton"
            onClick={(e) =>
              downloadTransactionReceiptHandler(e, row.transactionId)
            }
          >
            <Report />
          </button>
          {row.paymentMethod === "subscription" &&
            row.isSubscription === true && (
              <button
                className="commonRound whiteButton"
                onClick={() => openSubcriptionUpdateModal(row)}
              >
                <img src={toAbsoluteUrl("/images/edit.svg")} alt="edit" />
              </button>
            )}
          {row.paymentMethod === "subscription" &&
            row.isSubscription === true && (
              <button
                className="commonRound cancelSub"
                onClick={() => subscriptionmodel(row)}
              >
                <Cancle />
              </button>
            )}
        </div>
      ),
    },
  ];

  const onUpdateSubscriptionFormSubmit = (value) => {
    let payload = {
      ...value,
      amount: Number(
        Number(value?.amount?.toString().replace(/,/g, ""))?.toFixed(2)
      ),
      subscriptionId: currData?.subscriptionId,
      userId,
    };
    if (value?.billDate) {
      payload = {
        ...payload,
        billDate: moment().unix(new Date()),
      };
    }

    dispatch(updateSubscriptionAction(payload, currData?.subscriptionId, true))
      .then(() => {
        setUpdateIsOpen((prev) => !prev);
        setTimeout(() => {
          dispatch(renderTransationListAction(args)).then((res) =>
            setTransactionList(res?.data?.transactionList)
          );
        }, 2000);
        setDateSelectedMessage("");
      })
      .catch((err) => {
        toast.error(err.message);
        setUpdateIsOpen((prev) => !prev);
      });
  };

  const handlePerRowsChange = async (newPerPage) => {
    setArgs({ ...args, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArgs({ ...args, page: page });
  };

  return (
    <>
      <div className="transactionDetail">
        <div className="transactionDetailWrap">
          <div className="transactionDetailHeader">
            <div className="transactionFormGroup">
              <input
                type="text"
                placeholder="Search Transaction ID"
                onChange={searchTransactionInput}
              />
              <Search />
            </div>
            <div className="transactionButtons">
              <div
                className="transcationRange"
                onClick={() => setIsDateModalOpen((prev) => !prev)}
              >
                {args?.activeFrom ? (
                  <span>
                    {`${moment(dateRange?.startDate).format(
                      "MMM D, YYYY"
                    )} to ${moment(dateRange?.endDate).format("MMM D, YYYY")}`}
                  </span>
                ) : (
                  <>
                    <Birthday />
                    <span>Select Range</span>
                  </>
                )}
              </div>
              <div className="generateButton">
                <button onClick={generateReportHandler}>
                  <Report />
                </button>
              </div>
            </div>
          </div>
          <div className="transactionDetailBody">
            <DataTable
              border
              columns={columns}
              data={transactionList}
              pagination
              customStyles={customStyles}
              dense
              onRowClicked={(row) => {
                setCurrData(row);
                setModalIsOpen((prev) => !prev);
              }}
              paginationServer
              paginationTotalRows={totalRecords}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>

      <CustomModal
        className="rangeModal"
        isOpen={isDateModalOpen}
        closeModal={() => {
          setIsDateModalOpen((prev) => !prev);
        }}
        title="Select Date Range"
      >
        <div className="customDatePickerWidth">
          <DateRangePicker
            ranges={[
              {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                key: "selection",
              },
            ]}
            maxDate={new Date()}
            rangeColors={["#22841a"]}
            onChange={(e) => onDateChange(e)}
            showMonthArrow={true}
            color="green"
            staticRanges={[]}
            inputRanges={[]}
            className="dateRange"
          />
        </div>
        <div className="donationButtonGroup">
          <button
            className="doneButton"
            state={{ userID: userId, defaultIndex: 0 }}
            onClick={() => setIsDateModalOpen((prev) => !prev)}
          >
            Cancel
          </button>
          <button className="againButton" onClick={() => onSubmitDateRange()}>
            Apply
          </button>
          {/* <button className="doneButton"></button> */}
        </div>
      </CustomModal>

      <CustomModal
        className="donationdetailsModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
        title="Donation Details"
      >
        <div className="donationMainModalDetails">
          <div className="body-leftDetails">
            <ul>
              <li>
                <h1>Receipt for</h1>
                <h3>{`${currData?.paymentMethod} ${
                  currData?.paymentMethod === "card"
                    ? "(" + currData?.cardBrandName + ")"
                    : ""
                }`}</h3>
              </li>
              <li>
                <h1>Gross Donation</h1>
                <h3>{`$${currData?.amount
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h3>
              </li>
              <li>
                <h1>date</h1>
                <h3>{moment(currData?.createdDate).format("MMM D, YYYY")}</h3>
              </li>
              <li>
                <h1>transaction ID</h1>
                <h3>{currData?.orderId}</h3>
              </li>
            </ul>
          </div>
          <ScrollBar component="div">
            <div className="donationModalScroll">
              <div className="body-rightDetails">
                {currData?.charity?.[0]?.percentageWiseAmount !== null &&
                  currData?.charity?.map((charityItem, ind) => (
                    <div className="modalCause" key={charityItem?._id}>
                      <figure>
                        {charityItem?.logo && charityItem?.logo !== "string" ? (
                          <img
                            src={`${mediaURL}charityLogo/${charityItem?.logo}`}
                            alt="charity"
                          />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        )}
                      </figure>
                      <div className="modalCauseDetails">
                        <h3>nonprofit</h3>
                        <p>{charityItem?.name}</p>
                      </div>
                      <div className="donationCountButton">
                        <span>{charityItem?.paymentPercent}%</span>
                      </div>
                    </div>
                  ))}
                {currData?.community?.[0]?.percentageWiseAmount !== null &&
                  currData?.community?.map((communityItem, ind) => (
                    <div className="modalCause" key={communityItem?._id}>
                      <figure>
                        {communityItem?.logo && communityItem?.logo !=="string" ? (
                          <img
                            src={`${mediaURL}communityLogo/${communityItem?.logo}`}
                            alt="community"
                          />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        )}
                      </figure>
                      <div className="modalCauseDetails">
                        <h3>community</h3>
                        <p>{communityItem?.name}</p>
                      </div>
                      <div className="donationCountButton">
                        <span>{communityItem?.paymentPercent}%</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </ScrollBar>
        </div>
        {currData?.paymentMethod !== "card" && (
          <div className="updateDonationButton">
            {currData?.isSubscription !== false ? (
              <Link to={`/donationDetails/${currData?.subscriptionId}`}>
                Update
              </Link>
            ) : (
              <h5>Canceled Subscription</h5>
            )}
          </div>
        )}
      </CustomModal>

      <CustomModal
        className="subscriptionModal"
        isOpen={subIsOpen}
        closeModal={() => {
          setSubIsOpen((prev) => !prev);
        }}
      >
        <div className="subBodyDetails">
          <p>are you sure you want to cancel this subscription?</p>
        </div>
        <div className="subButtonGrp">
          <button className="subCancel" onClick={() => setSubIsOpen(false)}>
            cancel
          </button>
          <button
            onClick={(e) => {
              const payload = {
                subscriptionId: isSubscriptionId,
                userId: userId,
              };
              dispatch(cancelSubscriptionAction(payload, true)).then(() => {
                setSubIsOpen(false);
                dispatch(renderTransationListAction(args)).then((res) =>
                  setTransactionList(res?.data?.transactionList)
                );
              });
            }}
          >
            OK
          </button>
        </div>
      </CustomModal>

      <CustomModal
        className="updateSub"
        isOpen={updateIsOpen}
        closeModal={() => {
          setUpdateIsOpen((prev) => !prev);
          setDateSelectedMessage("");
        }}
        title="Update Subscription"
      >
        <Form
          onSubmit={onUpdateSubscriptionFormSubmit}
          initialValues={{
            amount: updatedSubscriptionData?.amount
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            // billDate: currData?.createdDate
            //   ? new Date(currData?.createdDate)
            //   : "",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="updateForm">
                  <ul>
                    <li>
                      <label>subscription date</label>
                      <Field
                        name="billDate"
                        component={renderDate}
                        setMinDate={true}
                        containerClasses="form-group"
                        extraClass="requiredField"
                        placeholder="Subscription Date"
                        setDateSelectedMessage={setDateSelectedMessage}
                      />
                    </li>
                    <li>
                      <label>subscription Amount</label>
                      <Field
                        name="amount"
                        type="number"
                        component={renderTextInput}
                        placeholder="Subscription Amount"
                        containerClasses="form-group"
                        extraClass="requiredField"
                        validate={(value) => {
                          if (!value || Number(value) <= 0) {
                            return "Amount is required";
                          } else if (!/^[0-9,.]+$/.test(value)) {
                            return "Only digit allow";
                          } else if (
                            Number(value.toString().replace(/,/g, "")) < 10
                          ) {
                            return "Amount should not be less than $10";
                          } else return undefined;
                        }}
                      >
                        <h5>$</h5>
                      </Field>
                    </li>
                  </ul>
                </div>
                {dateSelectedMessage && (
                  <span className="modalNote">{dateSelectedMessage}</span>
                )}
                <div className="subButtonGrp">
                  <button
                    type="button"
                    className="subCancel"
                    onClick={() => {
                      setUpdateIsOpen((prev) => !prev);
                      setDateSelectedMessage("");
                    }}
                  >
                    cancel
                  </button>
                  <button>Update</button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
}

export default TransactionDetails;
