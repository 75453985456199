// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import {
//   API_KEY,
//   APPID,
//   AUTH_DOMAIN,
//   MEASURMENTID,
//   MESSAGINGID,
//   PROJECT_ID,
//   STORAGE_BUCKET,
//   VAPIDKEY,
// } from "../constants/types";

// const firebaseConfig = {
//   // apiKey: API_KEY,
//   // authDomain: AUTH_DOMAIN,
//   // projectId: PROJECT_ID,
//   // storageBucket: STORAGE_BUCKET,
//   // messagingSenderId: MESSAGINGID,
//   // appId: APPID,
//   // measurementId: MEASURMENTID,
//   apiKey: "AIzaSyChNnUzyC8vo_BOfWE38qA2FqTiAZK9q3w",
//   authDomain: "rosi-giving.firebaseapp.com",
//   projectId: "rosi-giving",
//   storageBucket: "rosi-giving.appspot.com",
//   messagingSenderId: "368796439084",
//   appId: "1:368796439084:web:03d01e5afb1f931f48f789",
//   measurementId: "G-9TJ6JW0LJY",
// };

// const firebaseApp = initializeApp(firebaseConfig);
// export const messaging = getMessaging(firebaseApp);

// export const getFCMToken = (setTokenFound, setFcmToken) => {
//   return getToken(messaging, {
//     vapidKey:
//       "BBNMq4fCdftBI6wQTe2Y7hCP2WbAt-9cfWucar88PXY_ViAMv2nynzLzNsChCqCrvg9YXYlYIRc4oao4uJIUsfI",
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         setTokenFound(true);
//         setFcmToken(currentToken);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         setTokenFound(false);
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       // catch error while creating client token
//     });
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  API_KEY,
  APPID,
  AUTH_DOMAIN,
  MEASURMENTID,
  MESSAGINGID,
  PROJECT_ID,
  STORAGE_BUCKET,
  VAPIDKEY,
} from "../constants/types";

const firebaseConfig = {
  // apiKey: API_KEY,
  // authDomain: AUTH_DOMAIN,
  // projectId: PROJECT_ID,
  // storageBucket: STORAGE_BUCKET,
  // messagingSenderId: MESSAGINGID,
  // appId: APPID,
  // measurementId: MEASURMENTID,
  apiKey: "AIzaSyChNnUzyC8vo_BOfWE38qA2FqTiAZK9q3w",
  authDomain: "rosi-giving.firebaseapp.com",
  projectId: "rosi-giving",
  storageBucket: "rosi-giving.appspot.com",
  messagingSenderId: "368796439084",
  appId: "1:368796439084:web:03d01e5afb1f931f48f789",
  measurementId: "G-9TJ6JW0LJY",
};
let is_ios_web_view = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
  navigator.userAgent
);

const firebaseApp = !is_ios_web_view && initializeApp(firebaseConfig);
export const messaging = !is_ios_web_view && getMessaging(firebaseApp);

export const getFCMToken = (setTokenFound, setFcmToken) => {
  if (!is_ios_web_view) {
    return getToken(messaging, {
      vapidKey:
        "BBNMq4fCdftBI6wQTe2Y7hCP2WbAt-9cfWucar88PXY_ViAMv2nynzLzNsChCqCrvg9YXYlYIRc4oao4uJIUsfI",
    })
      .then((currentToken) => {
        console.log("currentToken: ", currentToken);
        if (currentToken) {
          setTokenFound(true);
          setFcmToken(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        // catch error while creating client token
      });
  } else {
    setFcmToken("");
  }
};

export const onMessageListener = () => {
  if (!is_ios_web_view)
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
};
