import { API } from "../constants/types";
import {
  API_ACCEPT_REJECT_REQUEST,
  API_FRIEND_LIST,
  API_GET_ALL_USER_LIST,
  API_MUTUAL_FRIEND_LIST,
  API_PENDING_FRIEND_REQUEST,
  API_SEND_FRIEND_REQUEST,
  API_UNFRIEND_FRIEND,
} from "../constants/api";

export const sendFriendRequestAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_SEND_FRIEND_REQUEST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const pendingFriendRequestAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_PENDING_FRIEND_REQUEST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const acceptRejectFriendRequestAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ACCEPT_REJECT_REQUEST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const renderFriendListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_FRIEND_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const renderAllUserListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_GET_ALL_USER_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const renderMutualFriendListAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_MUTUAL_FRIEND_LIST,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const unfriendFriendAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_UNFRIEND_FRIEND,
    method: "POST",
    data: data,
    showLoader,
  },
});
