import { LOGIN_S, LOGIN_F, LOGOUT } from "../constants/types";
import localforage from "localforage";

const initialState = {
  user: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_S:
      localStorage.setItem("webUser", JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    case LOGIN_F:
    case LOGOUT:
      localStorage.removeItem("webUser");
      localforage.removeItem("userName");
      localStorage.removeItem("profilePic");
      localStorage.removeItem("employeeId");
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
