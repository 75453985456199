import {
  GIVING_PARTNER_DETAILS_F,
  GIVING_PARTNER_DETAILS_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  givingPartners: {},
};

const givingPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GIVING_PARTNER_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        givingPartners: action.payload.data,
      };
    case GIVING_PARTNER_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        givingPartners: {},
      };
    default:
      return state;
  }
};

export default givingPartnerReducer;
