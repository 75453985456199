export const API = "API";
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const { REACT_APP_APIKEY: API_KEY } = process.env;
export const { REACT_APP_AUTHDOMAIN: AUTH_DOMAIN } = process.env;
export const { REACT_APP_PROJECT_ID: PROJECT_ID } = process.env;
export const { REACT_APP_STORAGEBUCKET: STORAGE_BUCKET } = process.env;
export const { REACT_APP_MESSAGING_SENDING_ID: MESSAGINGID } = process.env;
export const { REACT_APP_APPID: APPID } = process.env;
export const { REACT_APP_VAPIDKEY: VAPIDKEY } = process.env;
export const { REACT_APP_MEASURMENTID: MEASURMENTID } = process.env;

export const LOGIN_S = "LOGIN_SUCCESS";
export const LOGIN_F = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FETCH_META_S = "FETCH_META_S";
export const FETCH_META_F = "FETCH_META_F";

export const CHAT_NOTI_COUNT_S = "CHAT_NOTI_COUNT_SUCCESS";
export const CHAT_NOTI_COUNT_F = "CHAT_NOTI_COUNT_FAIL";

export const REGISTRATION_S = "REGISTRATION_SUCCESS";
export const REGISTRATION_F = "REGISTRATION_FAIL";

export const OTP_VERIFICATION_S = "OTP_VERIFICATION_S";
export const OTP_VERIFICATION_F = "OTP_VERIFICATION_F";

export const FETCH_USER_DETAILS_S = "FETCH_USER_DETAILS_S";
export const FETCH_USER_DETAILS_F = "FETCH_USER_DETAILS_F";

export const ADD_GOAL_S = "ADD_GOAL_S";
export const ADD_GOAL_F = "ADD_GOAL_F";

export const UPDATE_USER_DETAILS_S = "UPDATE_USER_DETAILS_S";
export const UPDATE_USER_DETAILS_F = "UPDATE_USER_DETAILS_F";

export const RENDER_CHARITY_LIST_S = "RENDER_CHARITY_LIST_S";
export const RENDER_CHARITY_LIST_F = "RENDER_CHARITY_LIST_F";

export const CHARITY_DETAILS_S = "CHARITY_DETAILS_S";
export const CHARITY_DETAILS_F = "CHARITY_DETAILS_F";

export const CHARITY_COMMUNITY_LIST_S = "CHARITY_COMMUNITY_LIST_SUCCESS";
export const CHARITY_COMMUNITY_LIST_F = "CHARITY_COMMUNITY_LIST_FAIL";

export const RENDER_CAUSE_LIST_S = "RENDER_CAUSE_LIST_S";
export const RENDER_CAUSE_LIST_F = "RENDER_CAUSE_LIST_F";

export const CAUSE_DETAILS_S = "CAUSE_DETAILS_S";
export const CAUSE_DETAILS_F = "CAUSE_DETAILS_F";

export const CAUSE_COMMUNITY_LIST_S = "CAUSE_COMMUNITY_LIST_SUCCESS";
export const CAUSE_COMMUNITY_LIST_F = "CAUSE_COMMUNITY_LIST_FAIL";

export const HOMEPAGE_DETAILS_S = "HOMEPAGE_DETAILS_S";
export const HOMEPAGE_DETAILS_F = "HOMEPAGE_DETAILS_F";

export const ABOUTPAGE_DETAILS_S = "ABOUTPAGE_DETAILS_S";
export const ABOUTPAGE_DETAILS_F = "ABOUTPAGE_DETAILS_F";

export const SEARCH_ITEM_S = "SEARCH_ITEM_SUCCESS";
export const SEARCH_ITEM_F = "SEARCH_ITEM_FAIL";

//charity register type

export const ADD_CHARITY_DETAILS_STEPS_1 = "ADD_CHARITY_DETAILS_STEPS_1";
export const ADD_CHARITY_DETAILS_STEPS_2 = "ADD_CHARITY_DETAILS_STEPS_2";
export const ADD_CHARITY_DETAILS_STEPS_3 = "ADD_CHARITY_DETAILS_STEPS_3";
export const ADD_CHARITY_DETAILS_STEPS_4 = "ADD_CHARITY_DETAILS_STEPS_4";

export const CAUSE_S = "CAUSE_SUCCESS";
export const CAUSE_F = "CAUSE_FAIL";

export const CATEGORY_S = "CATEGORY_S";
export const CATEGORY_F = "CATEGORY_F";

export const COUNTRY_S = "COUNTRY_S";
export const COUNTRY_F = "COUNTRY_F";

export const STATE_S = "STATE_S";
export const STATE_F = "STATE_F";

export const CITY_S = "CITY_S";
export const CITY_F = "CITY_F";

// community
export const RENDER_ALL_COMMUNITY_S = "RENDER_ALL_COMMUNITY_S";
export const RENDER_ALL_COMMUNITY_F = "RENDER_ALL_COMMUNITY_F";

export const RENDER_NOT_ASSO_COMMUNITY_S = "RENDER_NOT_ASSO_COMMUNITY_S";
export const RENDER_NOT_ASSO_COMMUNITY_F = "RENDER_NOT_ASSO_COMMUNITY_F";

export const COMMUNITY_DETAILS_S = "COMMUNITY_DETAILS_S";
export const COMMUNITY_DETAILS_F = "COMMUNITY_DETAILS_F";

// Fvaourity
export const RENDER_FAV_LIST_S = "RENDER_FAV_LIST_SUCCESS";
export const RENDER_FAV_LIST_F = "RENDER_FAV_LIST_FAIL";

//event
export const RENDER_EVENT_LIST_S = "RENDER_EVENT_LIST_SUCCESS";
export const RENDER_EVENT_LIST_F = "RENDER_EVENT_LIST_FAIL";

export const RENDER_CAUSE_EVENT_LIST_S = "RENDER_CAUSE_EVENT_LIST_SUCESS";
export const RENDER_CAUSE_EVENT_LIST_F = "RENDER_CAUSE_EVENT_LIST_FAIL";

export const RENDER_STRATEGIC_EVENT_LIST_S =
  "RENDER_STRATEGIC_EVENT_LIST_SUCCESS";
export const RENDER_STRATEGI_EVENT_LIST_F = "RENDER_STRATEGIC_EVENT_LIST_FAIL";

export const RENDER_MY_EVENT_LIST_S = "RENDER_MY_EVENT_LIST_SUCCESS";
export const RENDER_MY_EVENT_LIST_F = "RENDER_MY_EVENT_LIST_FAIL";

export const RENDER_ALL_UPCOMING_EVENT_LIST_S =
  "RENDER_ALL_UPCOMING_EVENT_LIST_SUCESS";
export const RENDER_ALL_UPCOMING_EVENT_LIST_F =
  "RENDER_ALL_UPCOMING_EVENT_LIST_FAIL";

export const EVENT_DETAILS_S = "EVENT_DETAILS_SUCCESS";
export const EVENT_DETAILS_F = "EVENT_DETAILS_FAIL";

// Post
export const COMMUNITY_POST_LIST_S = "COMMUNITY_POST_LIST_SUCCESS";
export const COMMUNITY_POST_LIST_F = "COMMUNITY_POST_LIST_FAIL";

export const JOIN_COMMUNITY_POST_LIST_S = "JOIN_COMMUNITY_POST_LIST_SUCCESS";
export const JOIN_COMMUNITY_POST_LIST_F = "JOIN_COMMUNITY_POST_LIST_FAIL";

export const POST_LIST_S = "POST_LIST_SUCCESS";
export const POST_LIST_F = "POST_LIST_FAIL";

export const POST_DETAILS_S = "POST DETAILS_SUCCESS";
export const POST_DETAILS_F = "POST_DETAILS_FAIL";

export const USER_DETAILS_S = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_F = "USER_DETAILS_FAIL";

//post comments
export const COMMUNITY_POST_COMMENT_LIST_S = "COMMUNITY_POST_COMMENT_LIST_S";
export const COMMUNITY_POST_COMMENT_LIST_F = "COMMUNITY_POST_COMMENT_LIST_F";

// Chat Types

export const USER_CONNECTION_STATUS = "USER_CONNECTION_STATUS";

export const CHAT_USER_LIST_S = "CHAT_USER_LIST_S";
export const CHAT_USER_LIST_F = "CHAT_USER_LIST_F";

export const CHAT_LIST_S = "CHAT_LIST_S";
export const CHAT_LIST_F = "CHAT_F";

export const CHAT_PAGE_CHANGE = "CHAT_PAGE_CHANGE";
export const CHAT_LIMIT_CHANGE = "CHAT_LIMIT_CHANGE";

export const USER_LIST_LIMIT_CHANGE = "USER_LIST_LIMIT_CHANGE";

export const CHAT_ACTIVE_USER = "CHAT_ACTIVE_USER";

// Basket
export const BASKET_COUNT_S = "BASKET_COUNT_S";
export const BASKET_COUNT_F = "BASKET_COUNT_F";

// CMS
export const CMS_DETAILS_S = "CMS_DETAILS_S";
export const CMS_DETAILS_F = "CMS_DETAILS_F";

// faq
export const FAQ_CATEGORY_LIST_S = "FAQ_CATEGORY_LIST_S";
export const FAQ_CATEGORY_LIST_F = "FAQ_CATEGORY_LIST_F";

export const FAQ_LIST_S = "FAQ_LIST_S";
export const FAQ_LIST_F = "FAQ_LIST_F";

//strategic
export const EMPLOYEE_POST_LIST_S = "EMPLOYEE_POST_LIST_SUCCESS";
export const EMPLOYEE_POST_LIST_F = "EMPLOYEE_POST_LIST_FAIL";

export const STRATEGIC_DETAIL_S = "STRATEGIC_DETAIL_SUCCESS";
export const STRATEGIC_DETAIL_F = "STRATEGIC_DETAIL_FAIL";

// giving partner
export const GIVING_PARTNER_DETAILS_S = "GIVING_PARTNER_DETAILS_SUCCESS";
export const GIVING_PARTNER_DETAILS_F = "GIVING_PARTNER_DETAILS_FAIL";

//NOTIFICTION
export const NOTIFICTION_LIST_S = "NOTIFICTION_LIST_S";
export const NOTIFICTION_LIST_F = "NOTIFICTION_LIST_F";
