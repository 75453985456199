/* eslint-disable no-unused-vars */
import React, { Suspense, useState } from "react";
import { isSupported, onMessage } from "firebase/messaging";
import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../store";
import Routes from "./routes";
import ScrollToTop from "../components/common/scroll";
import Loader from "../components/common/loader";
import { setupAxios, toAbsoluteUrl } from "../utils";
import toast from "react-hot-toast";
import { messaging } from "../utils/firebase";
const { PUBLIC_URL } = process.env;
setupAxios(axios, store);

const is_ios_webview = window
  ? /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
      window.navigator.userAgent
    )
  : true;

const AppContainer = () => {
  const [notificationTrigger, setNotificationTrigger] = useState(false);
  const openNotification = (notificationdata, placement) => {
    toast(
      <div>
        <div>{notificationdata?.notification?.body}</div>
      </div>,
      {
        duration: 4000,
        position: "bottom-right",

        // Styling
        style: {},
        className: "",
        icon: (
          <img
            src={toAbsoluteUrl("/images/favicon.png")}
            alt="logo"
            height="30px"
          />
        ),

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      }
    );
  };

  // function isIos() {
  //   console.log("window", window);
  //   return typeof window === "undefined" ? true : false;
  // }

  // if (isIos()) {
  //   isSupported().then(() => {
  //     onMessage(messaging, (payload) => {
  //       console.log("messaging: ", messaging);
  //       setNotificationTrigger((prev) => !prev);
  //       openNotification(payload, "top");
  //     });
  //   });
  // } else {
  // }

  // isSupported()
  //   .then(() => {
  //     onMessage(messaging, (payload) => {
  //       setNotificationTrigger((prev) => !prev);
  //       openNotification(payload, "top");
  //     });
  //   })
  //   .catch(() => null);

  if (!is_ios_webview)
    onMessage(messaging, (payload) => {
      setNotificationTrigger((prev) => !prev);
      openNotification(payload, "top");
    });

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader isSuspense />}>
        <Loader>
          <BrowserRouter basename={PUBLIC_URL}>
            <ScrollToTop>
              <Routes notificationTrigger={notificationTrigger} />
            </ScrollToTop>
          </BrowserRouter>
        </Loader>
      </Suspense>
    </Provider>
  );
};

export default AppContainer;
