import React from "react";

function User() {
  return (
    <>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8.5"
          cy="4"
          r="3.5"
          stroke="#4F4F4F"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M1.34913 12.9479C1.98883 10.6034 4.41473 9.5 6.845 9.5H10.155C12.5853 9.5 15.0112 10.6034 15.6509 12.9479C15.782 13.4287 15.8868 13.9489 15.9462 14.5015C16.0052 15.0507 15.5523 15.5 15 15.5H2C1.44772 15.5 0.994821 15.0507 1.05382 14.5015C1.1132 13.9489 1.21796 13.4287 1.34913 12.9479Z"
          stroke="#4F4F4F"
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </>
  );
}

export default User;
