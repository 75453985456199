/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { renderNotAssoCommunityList } from "../../../../actions/community";
import CommunityCard from "../../../../components/common/communityCard";
import Cancle from "../../../../assets/svg/cancle";
import Search from "../../../../assets/svg/search";
import { debounce } from "../../../../utils";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/common/LoderData";

function NotAssociated() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(window.location.search);
  const searchRef = useRef();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState({
    search: parsedQuery?.search || "",
  });

  const args = { limit: 12, search: "" };

  const setParams = (payload, from) => {
    const params = {};
    for (const key in payload) {
      if (payload[key]) {
        params[key] = payload[key];
      }
    }

    let queryUrl = queryString.stringify(params);
    navigate(`?${queryUrl}`);
  };

  const fetchData = () => {
    dispatch(
      renderNotAssoCommunityList(
        { ...args, page: page + 1, search: queryParams?.search },
        false
      )
    ).then((res) => {
      if (res?.data?.list?.length === 0) {
        setHasMore(false);
      } else setData([...data, ...res?.data?.list]);
    });
    setPage(page + 1);
  };

  const onChangeFun = (e) => {
    if (e.length >= 3 || e.length === 0)
      setQueryParams((prev) => {
        return {
          ...prev,
          search: e,
        };
      });
    setParams({ search: e });
  };

  const optimizedFn = debounce(onChangeFun);

  const randerCharityListHandler = (page = 1, isSearch = false) => {
    if (queryParams.search?.length < 3 && queryParams.search?.length > 0)
      return;
    let payload = {
      page: 1,
      limit: 12,
      search: queryParams.search?.replace("-", ""),
    };

    if (
      queryParams.search?.replace("-", "").length === 9 &&
      /^\d+$/.test(queryParams.search?.replace("-", ""))
    ) {
      payload = {
        ...payload,
        taxIdSearch: queryParams.search?.replace("-", ""),
      };
      delete payload.search;
    }

    if (queryParams.search?.length) {
      searchRef.current.value = queryParams.search;
      payload = { ...payload };
    }

    dispatch(renderNotAssoCommunityList(payload)).then((res) => {
      if (res?.data?.list.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (isSearch) setData(res?.data?.list);
        else setData((prev) => [...prev, ...res?.data?.list]);
      }
    });
  };
  useEffect(() => {
    randerCharityListHandler(1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.search]);

  return (
    <>
      <InfiniteScroll
        dataLength={data?.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<Loading />}
      >
        <div className="communityList-search">
          {/* <Search placeholder="Search Causes" /> */}
          <div className="community-search-formGrp">
            <span>
              <Search />
            </span>
            <input
              type="text"
              placeholder="Search Communities"
              ref={searchRef}
              onChange={(e) => optimizedFn(e.target.value)}
            />
            {queryParams.search && (
              <span
                className="cancle-cause"
                onClick={() => {
                  setQueryParams({ search: "" });
                  setParams({ search: "" });
                  searchRef.current.value = "";
                }}
              >
                <Cancle />
              </span>
            )}
          </div>
        </div>
        {data?.length ? (
          <div className="notAssociated">
            {data?.map((community, ind) => (
              <CommunityCard
                classname="notAsociatedButton"
                community={community}
                key={ind}
                setData={setData}
                page={page}
                randerCharityListHandler={randerCharityListHandler}
                search={queryParams.search?.replace("-", "")}
                limit={args?.limit}
              />
            ))}
          </div>
        ) : (
          <p className="notFound communityFound">No Community Found </p>
        )}
      </InfiniteScroll>
    </>
  );
}

export default NotAssociated;
