import React from "react";
import { Field } from "react-final-form";
import Select from "react-select";
import NumberFormat from "react-number-format";
import CreatableSelect from 'react-select/creatable';

export const renderField = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  children,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      {children}
      <input
        type={type}
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      />
    </div>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </>
);

export const renderNumberField = ({
  input,
  type,
  placeholder,
  formatStyle,
  className,
  inputclass,
  extraClass,
  readOnly,
  children,
  meta: { touched, error },
}) => (
  <>
    <div className={`gender-formGrp ${extraClass}`}>
      <div className={className}>
        {/* <input t1ype={type} {...input} readOnly={readOnly} className={inputclass} placeholder={placeholder} /> */}
        <NumberFormat
          {...input}
          format={formatStyle}
          className={inputclass}
          placeholder={placeholder}
        />
        {children}
      </div>
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const rendertextarea = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <textarea
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      ></textarea>
    </div>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </>
);

export const renderSelectField = ({
  input,
  options,
  className,
  isMulti,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  defaultValue,
  disabled,
  extraClass,
  style,
  meta: { touched, error },
}) => (
  <>
    <div className="gender-formGrp">
      <div
        className={`${touched && error && extraClass ? extraClass : className}`}
        style={style}
      >
        <Select
          {...input}
          value={input.value}
          // defaultValue={{ value: "Help Girl Child", label: "Help Girl Child" }}
          isMulti={isMulti}
          onChange={(e) => {
            if (isCountryField) {
              mutator("state", "");
              setselectedCountry(e);
              input.onChange(e);
            } else if (isStateField) {
              mutator("city", "");
              setselectedState(e);
              input.onChange(e);
            } else {
              input.onChange(e);
            }
          }}
          options={options}
          classNamePrefix="select"
          placeholder={placeholder}
          isDisabled={disabled}
        // defaultInputValue={defaultValue}
        />
      </div>
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderCityField = ({
  input,
  options,
  className,
  isMulti,
  mutator,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  setselectedState,
  defaultValue,
  disabled,
  extraClass,
  style,
  meta: { touched, error },
}) => (
  <>
    <div className="gender-formGrp">
      <div
        className={`${touched && error && extraClass ? extraClass : className}`}
        style={style}
      >
        <CreatableSelect
          {...input}
          value={input.value}
          // defaultValue={{ value: "Help Girl Child", label: "Help Girl Child" }}
          isMulti={isMulti}
          onChange={(e) => {
            if (isCountryField) {
              mutator("state", "");
              setselectedCountry(e);
              input.onChange(e);
            } else if (isStateField) {
              mutator("city", "");
              setselectedState(e);
              input.onChange(e);
            } else {
              input.onChange(e);
            }
          }}
          options={options}
          classNamePrefix="select"
          placeholder={placeholder}
          isDisabled={disabled}
        // defaultInputValue={defaultValue}
        />
      </div>
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);


export const renderRadioButton = ({
  input,
  type,
  value,
  className,
  children,
  meta: { touched, error },
}) => (
  <>
    <label className={className}>
      {children}
      <input {...input} />
      <span className="checkmark"></span>
    </label>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </>
);

export const FileField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <input
        {...input}
        type="file"
        onChange={({ target }) => onChange(target.files)} // instead of the default target.value
        {...props}
      />
    )}
  </Field>
);

export const renderSelect = ({
  input,
  name,
  option,
  list,
  placeholder,
  id,
  className,
  meta: { touched, error },
}) => {
  return (
    <>
      <select {...input} className={className}>
        {list.map((value, i) => (
          <option key={value?.name} value={value.option}>
            {" "}
            {value.name}{" "}
          </option>
        ))}
      </select>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </>
  );
};
