import React from "react";

function Right() {
  return (
    <>
      <svg
        width="72"
        height="56"
        viewBox="0 0 72 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.8918 1.43348C72.4138 3.58293 72.7158 7.36989 70.5664 9.89191L34.4555 52.262C34.437 52.2837 34.416 52.3086 34.3925 52.3363C34.1472 52.6259 33.6403 53.2245 33.0949 53.7107C32.3677 54.3588 30.9084 55.4311 28.7511 55.5292C26.5939 55.6272 25.0433 54.6917 24.2603 54.1121C23.6731 53.6775 23.114 53.1273 22.8435 52.8611C22.8176 52.8356 22.7943 52.8127 22.7739 52.7928L1.80518 32.29C-0.564139 29.9734 -0.606817 26.1746 1.70985 23.8053C4.02652 21.436 7.82527 21.3933 10.1946 23.71L28.1008 41.2183L61.4334 2.10808C63.5828 -0.413933 67.3698 -0.715961 69.8918 1.43348Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default Right;
