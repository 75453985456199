import { API_NOTIFICTION_LIST, API_NTIFICTION_DELETE } from "../constants/api";
import {
  API,
  NOTIFICTION_LIST_F,
  NOTIFICTION_LIST_S,
} from "../constants/types";

export const notficationdelete = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: API_NTIFICTION_DELETE + id,
    method: "DELETE",
    showLoader,
  },
});

export const notificationlist = (data, showLoader = false, reset = false) => ({
  type: API,
  payload: {
    url: API_NOTIFICTION_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: NOTIFICTION_LIST_S,
      payload: reset ? { ...data, reset } : data,
    }),
    error: (data) => ({
      type: NOTIFICTION_LIST_F,
      payload: data,
    }),
  },
});
