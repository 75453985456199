import React from "react";

function basketLockOpen() {
  return (
    <>
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 13.0986C0 11.213 0 10.2702 0.585786 9.68442C1.17157 9.09863 2.11438 9.09863 4 9.09863H12C13.8856 9.09863 14.8284 9.09863 15.4142 9.68442C16 10.2702 16 11.213 16 13.0986V15.0986C16 17.9271 16 19.3413 15.1213 20.22C14.2426 21.0986 12.8284 21.0986 10 21.0986H6C3.17157 21.0986 1.75736 21.0986 0.87868 20.22C0 19.3413 0 17.9271 0 15.0986V13.0986Z"
          fill="#6DAF5F"
        />
        <path
          d="M12.498 9.09845L12.5756 8.47786C12.8345 6.40627 11.9024 4.36589 10.1669 3.20548V3.20548C8.10045 1.82382 5.36536 1.99411 3.48629 3.62144L2.66797 4.33013"
          stroke="#6DAF5F"
          fill="none"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
}

export default basketLockOpen;
