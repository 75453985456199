import { removeMobileFomrmatter } from "../../utils";

export const firstTimeUserDetailModalValidation = (value) => {
  let error = {};
  if (!value.annualGoal || !value.annualGoal.trim()) {
    error.annualGoal = "Please Enter Annual Donation Goal";
  } else if (!/^[0-9,]+$/.test(value.annualGoal)) {
    error.annualGoal = "Decimal numbers are not allow";
  }
  if (!value.monthalyGoal || !value.monthalyGoal.trim()) {
    error.monthalyGoal = "Please Enter Monthaly Donation Goal";
  } else if (!/^[0-9,]+$/.test(value.monthalyGoal)) {
    error.monthalyGoal = "Decimal numbers are not allow";
  }
  if (
    value.annualGoal &&
    Number(value.annualGoal?.toString().replace(/\,/g, "")) <
      Number(value.monthalyGoal?.toString().replace(/\,/g, ""))
  ) {
    error.annualGoal = "Annual goal must be greater than monthaly goal";
  }
  return error;
};

export const firstTimeUserDetailValidation = (value) => {
  let error = {};

  if (!value.firstName || !value.firstName.trim()) {
    error.firstName = "Fist name is required";
  } else if (value.firstName && !/^[a-zA-Z\s]*$/.test(value.firstName)) {
    error.firstName = "Please enter valid name";
  }

  if (!value.lastName || !value.lastName.trim()) {
    error.lastName = "Last name is required";
  } else if (value.lastName && !/^[a-zA-Z\s]*$/.test(value.lastName)) {
    error.lastName = "Please enter valid name";
  }

  if (value.middleName && !/^[a-zA-Z]*$/.test(value.middleName)) {
    error.middleName = "Please enter valid name";
  }

  if (
    (value.phoneNumber &&
      value.phoneNumber?.[0] !== "(" &&
      value.phoneNumber?.length !== 10) ||
    (value.phoneNumber?.[0] === "(" && value.phoneNumber?.length !== 14)
  ) {
    error.phoneNumber = "Please enter valid phone number";
  }

  if (
    value?.secondaryPhone &&
    removeMobileFomrmatter(value.secondaryPhone, true).length !== 10
  ) {
    error.secondaryPhone = "Should not be less than 10 digits";
  }

  if (!value.email) {
    error.email = "Email is required";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }

  if (
    value.secondaryEmail &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.secondaryEmail
    )
  ) {
    error.secondaryEmail = "Please enter valid secondary email address.";
  }

  if (!value.birthDate) {
    error.birthDate = "Birth date is required";
  }
  if (!value?.gender) {
    error.gender = "Please select gender";
  }

  if (!value?.zipcode) {
    error.zipcode = "Please enter zipcode";
  } else if (value?.zipcode && value?.zipcode?.toString()?.length !== 5) {
    error.zipcode = "Zipcode must be five digits";
  }
  return error;
};

export const emaployeeAddValidation = (value) => {
  let error = {};
  if (!value?.email) {
    error.email = "Please Enter Work Email address";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value?.email
    )
  ) {
    error.email = "Please Enter valid Email address.";
  }
  if (!value.strategic) {
    error.strategic = "Please Select Giving Partner";
  }
  return error;
};
