import moment from "moment/moment";
import React from "react";

function InformationPage(props) {
  const { classname, pageTitle, datePageName, date, paraOne } = props;

  return (
    <>
      <h3 className="pageTitle">{pageTitle}</h3>
      <div className={classname}>
        {paraOne && (
          <div
            dangerouslySetInnerHTML={{ __html: paraOne }}
            className="para"
          ></div>
        )}
        <p className="updateDate">
          {datePageName} updated on : {moment(date).format("MM/DD/YYYY")}
        </p>
      </div>
    </>
  );
}

export default InformationPage;
