import React from "react";
import CustomTab from "../../../../components/common/Tabs";
import FriendList from "./Friend List";
import MutualFriend from "./Mutual Friend";
import "./member.scss";

function Member({ members, communityId, from }) {
  const data = [
    {
      heading: "Mutual Friend List",
      body: <MutualFriend communityId={communityId} />,
    },
    {
      heading: "Member List",
      body: (
        <FriendList communityId={communityId} employees={members} from={from} />
      ),
    },
  ];

  return (
    <>
      <div className="memberTab">
        <CustomTab className="tabsForMember" data={data} />
      </div>
    </>
  );
}

export default Member;
