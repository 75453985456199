import { CMS_DETAILS_F, CMS_DETAILS_S } from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  cmsDetails: {},
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CMS_DETAILS_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        cmsDetails: action.payload.data,
      };
    case CMS_DETAILS_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        cmsDetails: {},
      };
    default:
      return state;
  }
};

export default cmsReducer;
