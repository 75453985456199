/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ScrollBar from "react-perfect-scrollbar";
import MemberDetails from "../../../../../components/common/MemberDetails";
import { renderMutualFriendListAction } from "../../../../../actions/friend";
import { strategicMutualListAction } from "../../../../../actions/strategic";

function MutualFriend({ communityId }) {
  const dispatch = useDispatch();
  const strategicPartnerId = JSON.parse(localStorage.getItem("employeeId"));

  const [mutualFriendList, setMutualFriendList] = useState([]);

  useEffect(() => {
    if (communityId) {
      dispatch(renderMutualFriendListAction({ communityId })).then((res) =>
        setMutualFriendList(res?.data?.mutuaFrdlList)
      );
    } else {
      dispatch(
        strategicMutualListAction({
          strategicPartnerId,
        })
      ).then((res) => setMutualFriendList(res?.data?.mutuaFrdlList));
    }
  }, []);

  return (
    <>
      <ScrollBar component="div">
        <div className="content">
          <div className="members">
            {mutualFriendList?.length > 0 ? (
              mutualFriendList?.map((val) => (
                <MemberDetails
                  member={val}
                  key={val.id}
                  isMember={true}
                  classname="memberButton"
                  from="mutualList"
                />
              ))
            ) : (
              <h4 className="notFound mt-10">no mutual friend list found</h4>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default MutualFriend;
