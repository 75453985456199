import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import "./notification.scss";
import { notificationlist } from "../../actions/notificaion";
import { useDispatch, useSelector } from "react-redux";
import { chatNotificationCountAction } from "../../actions/meta";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../../components/common/LoderData";
import NotificationData from "./notificationData";

const bread = [
  {
    name: "Notification",
    link: "/notification",
    isActive: true,
  },
];
function Notification() {
  const notifictiodata = useSelector(
    (state) => state.NotifictionReducer.notifictionlist
  );
  const dispatch = useDispatch();
  const [args, setArgs] = useState({
    limit: 10,
    page: 0,
  });
  const [hasMore, setHasMore] = useState(true);

  const fetchData = (showLoader = false) => {
    dispatch(
      notificationlist({ ...args, page: args.page + 1 }, showLoader)
    ).then((res) => {
      dispatch(chatNotificationCountAction());
      if (res?.data?.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setArgs({ ...args, page: args.page + 1 });
      }
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="notification">
        <div className="container">
          <div className="breadcrumbsNotification">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          {notifictiodata?.length ? (
            <InfiniteScroll
              dataLength={notifictiodata?.length}
              next={() => fetchData(false)}
              hasMore={hasMore}
              loader={<Loading />}
            >
              <div key={notifictiodata?._id}>
                {notifictiodata?.map((item, id) => {
                  return <NotificationData item={item} idx={id} />;
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <h4 className="notFound">No Notification Found!!!</h4>
          )}
        </div>
      </section>
    </>
  );
}

export default Notification;
