import React from "react";

function basketLock() {
  return (
    <>
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 11C0 9.11438 0 8.17157 0.585786 7.58579C1.17157 7 2.11438 7 4 7H12C13.8856 7 14.8284 7 15.4142 7.58579C16 8.17157 16 9.11438 16 11V13C16 15.8284 16 17.2426 15.1213 18.1213C14.2426 19 12.8284 19 10 19H6C3.17157 19 1.75736 19 0.87868 18.1213C0 17.2426 0 15.8284 0 13V11Z"
          fill="#6DAF5F"
        />
        <path
          d="M12 6V5C12 2.79086 10.2091 1 8 1V1C5.79086 1 4 2.79086 4 5V6"
          stroke="#6DAF5F"
          fill="none"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
}

export default basketLock;
