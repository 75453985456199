import React, { useEffect, useState } from "react";
import "./../SignIn/signin.scss";
import Swiper from "../../components/common/Swiper";
import CardSignin from "../../components/common/Card_signin";
import { useLocation, useNavigate } from "react-router-dom";
import "./verification.scss";
import { toAbsoluteUrl } from "../../utils";
import SwiperText from "../../components/common/SwiperText";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import {
  doResetOTP,
  doVerifyOTP,
  doVerifyOTPOfEmployee,
} from "../../actions/auth";
import toast from "react-hot-toast";

function Verify() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [otpValue, setOtpValue] = useState("");
  const [timer, setTimer] = useState(50);
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    // return () => clearTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const onOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const OTPVerifyHandler = (e) => {
    e.preventDefault();

    if (location?.state?.from === "employeeSigninPage") {
      dispatch(
        doVerifyOTPOfEmployee({
          otp: otpValue,
          phoneNumber: `${location?.state?.phoneNumber?.replace(/[^\d]/g, "")}`,
          countryCode: "+1",
        })
      )
        .then((res) => {
          toast.success("Please check your mail");
          navigate("/");
        })
        .catch((err) => {
          setError(err.message);
          setOtpValue("");
        });
      return;
    }

    dispatch(
      doVerifyOTP({
        otp: otpValue,
        phoneNumber: `${location?.state?.phoneNumber?.replace(/[^\d]/g, "")}`,
        countryCode: "+1",
      })
    )
      .then((res) => {
        if (location?.state?.from === "employeeSigninPage") {
          navigate("/");
        } else
          navigate("/profile_details", {
            state: { userID: res.data._id, defaultIndex: 4 },
          });
      })
      .catch((err) => {
        setError(err.message);
        setOtpValue("");
      });
  };

  const resendOTPHandler = (e) => {
    e.preventDefault();
    setTimer(50);
    setOtpValue("");
    dispatch(
      doResetOTP({
        phoneNumber: `${location?.state?.phoneNumber?.replace(/[^\d]/g, "")}`,
        countryCode: "+1",
      })
    );
  };

  return (
    <>
      <section className="signin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Enter the Verification Code below">
              <form className="form">
                <ul>
                  <li className="verification-text">
                    <p>
                      We have sent a verification code to
                      <span>{location?.state?.phoneNumber}</span>
                    </p>
                  </li>
                </ul>
                <ul>
                  <li className="input-list">
                    <OtpInput
                      isInputNum={true}
                      value={otpValue}
                      onChange={onOtpChange}
                      numInputs={6}
                      shouldAutoFocus={true}
                      containerStyle={"otp-container"}
                      className="otp-input"
                      separator={<span></span>}
                    />
                  </li>
                  <li className="timer">
                    <div className="timer-wrap">
                      <img
                        src={toAbsoluteUrl("/images/timer.png")}
                        alt="timer"
                      />
                      <span>{`${
                        timer >= 10 ? `00:${timer}` : `00:0${timer}`
                      }`}</span>
                      <span>left</span>
                    </div>
                  </li>
                </ul>
                {error && (
                  <h4 className="verification_error" style={{ margin: "10px" }}>
                    {error}
                  </h4>
                )}
                <ul>
                  <li className="sign-btn">
                    {otpValue.length === 6 && (
                      <button
                        className="buttonSubmit"
                        onClick={OTPVerifyHandler}
                      >
                        Verify
                      </button>
                    )}
                    {/* <Link to="/">Confirm</Link> */}
                  </li>
                  {timer <= 0 && (
                    <li className="verification-footer">
                      <h5>
                        Didn’t Receive a Code?
                        <button onClick={resendOTPHandler}>
                          Click to Resend
                        </button>
                      </h5>
                    </li>
                  )}
                </ul>
              </form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default Verify;
