/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cmsDetailsAction } from "../../actions/cms";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import InformationPage from "../../components/common/informationPage";
import "./terms.scss";

const bread = [
  {
    name: "Terms of Use",
    link: "/terms",
    isActive: true,
  },
];

function TermsAndCondition() {
  const termsContain = useSelector((state) => state.cmsReducer.cmsDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cmsDetailsAction("63453de61fb356a539facb91"));
  }, []);

  return (
    <>
      <section className="terms">
        <div className="container">
          <div className="breadcrumbsTerms">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          {/* <p className="notFound">Cooming Soon!</p> */}
          <InformationPage
            pageTitle="Terms of Use"
            classname="termCondition"
            datePageName="Terms of Use"
            date={termsContain?.updatedDate}
            paraOne={termsContain?.content?.replace(/&nbsp;/g, " ")}
          />
        </div>
      </section>
    </>
  );
}

export default TermsAndCondition;
