import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  joinCommunityPostListAction,
  postListAction,
} from "../../../actions/post";
import PostCard from "../Post";
import { Loading } from "../LoderData";

const PostList = ({ type, setIsMyPost, args, setArgs }) => {
  const dispatch = useDispatch();
  const { postList, joinCommunityPostList } = useSelector(
    (state) => state.post
  );

  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const [allPosts, setAllPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (type === true) {
      setIsMyPost(true);
    } else if (type === false) {
      setIsMyPost(false);
    } else {
      setIsMyPost();
    }
  }, [type, setIsMyPost]);

  useEffect(() => {
    if (type === true || type === false) {
      setAllPosts(postList?.PostList);
    } else setAllPosts(joinCommunityPostList?.communityUserPostList);
  }, [postList, joinCommunityPostList, type]);

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (type === true || type === false)
      dispatch(postListAction({ page: 1, limit: 20 }, type, true, false));
    else {
      dispatch(
        joinCommunityPostListAction(
          { _id: userId, page: 1, limit: 20 },
          true,
          false
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const fetchData = () => {
    if (type === true || type === false)
      dispatch(
        postListAction({ ...args, page: args.page + 1 }, type, false)
      ).then((res) => {
        if (res?.data?.PostList?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    else {
      dispatch(
        joinCommunityPostListAction(
          { _id: userId, ...args, page: args.page + 1 },
          false
        )
      ).then((res) => {
        if (res?.data?.communityUserPostList?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }

    setArgs({ ...args, page: args.page + 1 });
  };

  return !isLoading && allPosts?.length ? (
    <InfiniteScroll
      dataLength={allPosts.length}
      next={() => fetchData()}
      hasMore={hasMore}
      loader={<Loading />}
    >
      {allPosts?.map((post) => {
        return (
          <PostCard
            post={post}
            key={post._id}
            isMyPost={type}
            from="postModule"
            setisLoading={setisLoading}
            isLoading={isLoading}
          />
        );
      })}
    </InfiniteScroll>
  ) : (
    <h4 className="notFound">No Post Found</h4>
  );
};

export default PostList;
