/* eslint-disable react-hooks/exhaustive-deps */
import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  renderNumberWithCommas,
  renderTextInput,
} from "../../components/common/FormFields";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CustomModal from "../../components/common/Modal";
import ProfileCount from "../../components/common/ProfileCount";
import CustomTab from "../../components/common/Tabs";
import "./firstTimeLogin.scss";
import Badges from "./ProfileTabsContent/Badges";
import Favorite from "./ProfileTabsContent/Favorite";
import ProfileDetails from "./ProfileTabsContent/ProfileDetails";
import { toAbsoluteUrl } from "../../utils";
import Location from "../../assets/svg/location";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDetails,
  setDonationGoalAction,
  uploadProfilePicAction,
} from "../../actions/user";
import {
  deleteAccount,
  deleteAccountAction,
  doLogout,
} from "../../actions/auth";
import ImgUpload from "../../components/common/img-upload";
import {
  emaployeeAddValidation,
  firstTimeUserDetailModalValidation as validate,
} from "../../components/validations/user";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import Email from "../../assets/svg/email";
import Donations from "./ProfileTabsContent/Donations";
import {
  employecreateAction,
  strategiclistAction,
} from "../../actions/strategic";
import { renderSelectField } from "../../components/forms";
import toast from "react-hot-toast";
import MyEvents from "./ProfileTabsContent/MyEvents";

const profile = [
  {
    name: "Profile Details",
    link: "/",
    isActive: true,
  },
];

function FirstTimeLogin() {
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const defaultIndex = location?.state?.defaultIndex;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
  const [strategicModalIsOpen, setStrategicModalIsOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState();
  const [logo, setLogo] = useState();
  const [oldProfile, setOldProfile] = useState();
  const [userLogo, setUserLogo] = useState();
  const [options, setOption] = useState();

  const donations = [
    {
      id: "01",
      count: userDetails?.userTotalDonation
        ? "$" +
          Math.round(userDetails?.userTotalDonation)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "$0",
      title: "Total Donation",
    },
    {
      id: "02",
      count: userDetails?.monthlyTotalDonation
        ? "$" +
          Math.round(userDetails?.monthlyTotalDonation)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "$0",
      title: "Monthly Donation",
    },
    {
      id: "03",
      count: userDetails?.yearTotalDonation
        ? "$" +
          Math.round(userDetails?.yearTotalDonation)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "$0",
      title: "Annual Donation",
    },
  ];

  const strategic = [
    {
      id: "01",
      count: userDetails?.totalCharityAssociated,
      title: "Total Nonprofit Supported",
    },
    {
      id: "02",
      count: userDetails?.totalCommunityAssociated,
      title: "Total Community Joined",
    },
    {
      id: "03",
      count: userDetails?.totalFeed,
      title: "Posts Created",
    },
    {
      id: "04",
      count: userDetails?.totalFriend,
      title: "Rosi Friends",
    },
    {
      id: "05",
      count: userDetails?.gamifyingBadges,
      title: "Badges",
    },
  ];

  const tabs = [
    {
      heading: "Donations",
      body: <Donations />,
    },
    {
      heading: "Favorites",
      body: <Favorite />,
    },
    {
      heading: "Badges",
      body: <Badges />,
    },
    {
      heading: "All Events",
      body: <MyEvents />,
    },
    {
      heading: "Profile Details",
      body: (
        <ProfileDetails
          id={id}
          // goal={goals}
          setErrorMessages={setErrorMessages}
          // profileImage={logo}
        />
      ),
    },
    // {
    //   heading: "Transaction Details",
    //   body: <TransactionDetails />,
    // },
    // {
    //   heading: "Card Details",
    //   body: <CardDetails />,
    // },
  ];

  localStorage.setItem("curr_reg_step", JSON.stringify(0));

  useEffect(() => {
    if (logo) {
      dispatch(
        uploadProfilePicAction({
          _id: id,
          profileImage: logo,
        })
      ).then(() => {
        toast.success("Proifle picture uploaded successfully");
      });
    }
  }, [logo]);

  useEffect(() => {
    dispatch(fetchUserDetails({ userId: id }, true)).then((res) => {
      const prevData = JSON.parse(localStorage.getItem("webUser"));
      let newData = {
        ...prevData,
        data: {
          ...prevData.data,
          userType: res.data.userType,
        },
      };

      localStorage.setItem("webUser", JSON.stringify(newData));

      localStorage.setItem(
        "userName",
        JSON.stringify(`${res.data.firstName} ${res.data.lastName}`)
      );
      localStorage.setItem(
        "profilePic",
        JSON.stringify(`${res.data.profileImage}`)
      );
      if (res?.data?.employerId) {
        localStorage.setItem("employeeId", JSON.stringify(res.data.employerId));
      }
      setOldProfile(res.data.profileImage);
    });
    // eslint-disable-next-line
  }, []);

  const openModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  const openStrategicModal = () => {
    if (userDetails?.isAssociated === "Approve") {
      navigate("/Giving_Partner_Details");
      return;
    }
    if (userDetails?.isAssociated === "Pending") {
      setRequestModalIsOpen((prev) => !prev);
      return;
    }
    setStrategicModalIsOpen((prev) => !prev);
    dispatch(
      strategiclistAction({
        column: "name",
        order: "asc",
        page: 1,
        limit: 1000000,
      })
    ).then((res) => {
      let strategic = [];

      res.data?.strategicPartnerList?.map((val) => {
        return strategic.push({ value: val._id, label: val.name });
      });

      setOption(strategic);
    });
  };

  const dropdownOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const onFormSubmit = (value) => {
    const monthly = Number(value.monthalyGoal.toString().replace(/,/g, ""));
    const annual = Number(value.annualGoal.toString().replace(/,/g, ""));

    const goal = { monthly, annual };

    const data = {
      _id: id,
      goal: goal,
    };
    dispatch(setDonationGoalAction(data))
      .then(() => {
        toast.success("Donation goals save successfully.");
      })
      .catch((err) => {
        setErrorMessages(err.message);
        toast.error("Something went wrong.");
      });

    setModalIsOpen((prev) => !prev);
  };

  const logoutHandler = () => {
    dispatch(doLogout()).then((res) => {
      toast.success(res?.message);
      window.location.reload();
    });
  };

  const deleteAccountHandler = () => {
    dispatch(deleteAccountAction(id)).then((res) => {
      toast.success(res?.message);
      window.location.reload();
    });
  };

  const transfer = {
    status: "Compile",
  };

  const getStepPosition = (transferStatus) => {
    return steps.findIndex(({ status }) => status === transferStatus);
  };

  const steps = [
    {
      id: 1,
      status: "Requested",
    },
    {
      id: 2,
      status: "Pending",
    },
    {
      id: 3,
      status: "Accepted",
    },
  ];

  const onSubmit = (value) => {
    const payload = {
      workEmail: value?.email,
      stratrgicPartnerId: value?.strategic?.value,
    };
    dispatch(employecreateAction(payload)).then(() => {
      dispatch(fetchUserDetails({ userId: id }));
      setRequestModalIsOpen(true);
      setStrategicModalIsOpen(false);
    });
  };

  const monthlyPercentage = () => {
    if (userDetails?.goal?.monthly) {
      const monthalyGoal = userDetails?.monthlyTotalDonation
        ? userDetails?.monthlyTotalDonation
        : 0;
      let per = (100 * monthalyGoal) / userDetails?.goal?.monthly;
      return per;
    }

    return 0;
  };

  const annualprcentage = () => {
    if (userDetails?.goal?.annual) {
      const annualGoal = userDetails?.yearTotalDonation
        ? userDetails?.yearTotalDonation
        : 0;
      let per = (100 * annualGoal) / userDetails?.goal?.annual;
      return per;
    }

    return 0;
  };

  const strategicPartnerBtnName = () => {
    if (userDetails?.isAssociated === "Approve") {
      return "View Giving Partner";
    } else if (userDetails?.isAssociated === "Pending") {
      return "View Track";
    } else {
      return "Associate with Giving Partner";
    }
  };

  return (
    <>
      <section className="profileDetails">
        <div className="container">
          <div className="breadcrumbsProfile">
            <Breadcrumbs list={profile} />
          </div>
        </div>
        <div className="profileDetailsWrap">
          <div className="container">
            <div className="profileContent">
              <div className="profileSidebar">
                <div className="profileMain">
                  <div className="profilemainWrap">
                    <ImgUpload
                      multiple={false}
                      image={logo}
                      oldImages={oldProfile}
                      setOldImages={(images) => setOldProfile(images)}
                      setImg={setLogo}
                      setImages={setUserLogo}
                      images={userLogo}
                      moduleName="userProfilePic"
                      location="profilePage"
                      callUploadAPI={true}
                    />
                    <div className="profileName">
                      <h2>
                        {userDetails?.firstName + " " + userDetails?.lastName}
                      </h2>
                      <h3>
                        <Location />
                        <address>
                          {`${
                            userDetails?.addressObj?.streetName1
                              ? userDetails?.addressObj?.streetName1
                              : ""
                          }${userDetails?.addressObj?.streetName1 ? "," : ""} ${
                            userDetails?.addressObj?.streetName2
                              ? userDetails?.addressObj?.streetName2
                              : ""
                          }${userDetails?.addressObj?.streetName2 ? "," : ""} ${
                            userDetails?.addressObj?.city
                              ? userDetails?.addressObj?.city
                              : ""
                          }${userDetails?.addressObj?.city ? "," : ""} ${
                            userDetails?.addressObj?.state
                              ? userDetails?.addressObj?.state
                              : ""
                          }${userDetails?.addressObj?.state ? "," : ""} 
                            ${
                              userDetails?.addressObj?.zipcode
                                ? userDetails?.addressObj?.zipcode
                                : ""
                            }`}
                        </address>
                      </h3>
                    </div>
                  </div>
                  <div className="dropdownAvtar">
                    <figure onClick={dropdownOpen}>
                      <img
                        src={toAbsoluteUrl("/images/profile.svg")}
                        alt="profile"
                      />
                    </figure>
                    {isMoreOpen && (
                      <ul className="profileDropdown">
                        <li onClick={() => navigate("/change_password")}>
                          Change Password
                        </li>
                        <li onClick={logoutHandler}>Log Out</li>
                        <li
                          onClick={() =>
                            setDeleteAccountModalOpen((prev) => !prev)
                          }
                        >
                          Delete Account
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className="donationCount">
                  {donations.map((donation, ind) => (
                    <ProfileCount
                      className="profileCount"
                      donation={donation}
                      key={donation.id}
                    />
                  ))}
                </div>
                <div className="progressCount">
                  <div className="barCount">
                    <div className="setMonthly">
                      <h3>
                        {userDetails?.goal?.monthly
                          ? "$" +
                            userDetails?.goal?.monthly
                              // ?.toFixed(2)
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "$0"}
                      </h3>
                      <h4>Monthly Goal</h4>
                      <Line
                        percent={monthlyPercentage()}
                        trailWidth={2}
                        trailColor="#E1E1E1"
                        strokeWidth={2}
                        strokeColor="#CE5139"
                      />
                    </div>
                    <div className="setMonthly setAnnual">
                      <h3>{`$${
                        userDetails?.goal?.annual
                          ? userDetails?.goal?.annual
                              // ?.toFixed(2)
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"
                      }`}</h3>
                      <h4>Annual Goal</h4>
                      <Line
                        percent={annualprcentage()}
                        strokeWidth={2}
                        strokeColor="#CE5139"
                        trailWidth={2}
                        trailColor="#E1E1E1"
                      />
                    </div>
                  </div>
                  <button onClick={openModal} className="goalButton">
                    {`${
                      userDetails?.goal?.monthly ? "Edit" : "Set"
                    } Your Donation Goal`}
                  </button>
                </div>
                <div className="strategicCount">
                  {strategic.map((strategic, ind) => (
                    <ProfileCount
                      className="strategyCount"
                      donation={strategic}
                      key={strategic?.id}
                    />
                  ))}
                </div>
                <button
                  className="strategicButton"
                  onClick={openStrategicModal}
                >
                  {strategicPartnerBtnName()}
                  {/* Associate with strategic partner */}
                </button>
              </div>
              <div className="profileContentWrap">
                <CustomTab
                  className="profileTab"
                  data={tabs}
                  defaultIndex={defaultIndex ? defaultIndex : 0}
                />
              </div>
            </div>
          </div>
        </div>

        {errorMessages?.length ? (
          <div className="errorProfile">
            <figure>
              <img
                src={toAbsoluteUrl("/images/exclanation.svg")}
                alt="exclanation"
              />
            </figure>
            {/* <h3>{errorMessages[0]}</h3> */}
            <h3>{errorMessages}</h3>
          </div>
        ) : (
          <div></div>
        )}
      </section>

      <CustomModal
        className="postDeleteModal"
        isOpen={deleteAccountModalOpen}
        closeModal={() => {
          setDeleteAccountModalOpen((prev) => !prev);
        }}
      >
        <h3>Are you sure you want to delete this account?</h3>
        <p>
          If you Delete account all your transaction details will be deleted.
        </p>
        <div className="postModal">
          <button onClick={() => setDeleteAccountModalOpen((prev) => !prev)}>
            Cancel
          </button>
          <button onClick={deleteAccountHandler}>Delete</button>
        </div>
      </CustomModal>

      <CustomModal
        title="associate with Giving Partner"
        className="strategicModal"
        isOpen={strategicModalIsOpen}
        closeModal={() => {
          setStrategicModalIsOpen((prev) => !prev);
        }}
      >
        <h3>associate with Giving Partner</h3>
        <Form onSubmit={onSubmit} validate={emaployeeAddValidation}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ul>
                <li>
                  <Field
                    name="strategic"
                    placeholder="Please Select Giving Partner"
                    component={renderSelectField}
                    options={options}
                    isMulti={false}
                    className="strategicModalSearch"
                  />
                </li>
                <li>
                  <Field
                    name="email"
                    type="email"
                    component={renderTextInput}
                    placeholder="Enter your work email here"
                    containerClasses="form-group"
                  >
                    <Email />
                  </Field>
                </li>
              </ul>
              <div className="strategicModalButton">
                <button
                  type="button"
                  onClick={() => setStrategicModalIsOpen((prev) => !prev)}
                >
                  Cancel
                </button>
                <button type="submit">Send</button>
              </div>
            </form>
          )}
        </Form>
      </CustomModal>

      <CustomModal
        title="Review Request"
        className="requestModal"
        isOpen={requestModalIsOpen}
        closeModal={() => {
          setRequestModalIsOpen((prev) => !prev);
        }}
      >
        <p>
          Your request will be reviewed by the Giving partner. you can access
          other features of the panel after the approval.
        </p>
        <div className="requestProgressBar">
          <ProgressBar
            width={500}
            height={20}
            percent={
              100 *
                ((getStepPosition(transfer.status) + 2) / (steps.length - 1)) -
              1
            }
            filledBackground="linear-gradient(to right, #41ad49, #41ad49)"
          >
            {steps.map((step, index, arr) => {
              return (
                <Step
                  position={100 * (index / arr.length)}
                  key={step.id}
                  transition="scale"
                  children={({ accomplished }) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        width: 28,
                        height: 28,
                        color: "black",
                        backgroundColor: "#7FC171",
                      }}
                    >
                      <span className="label">{step.status}</span>
                    </div>
                  )}
                />
              );
            })}
          </ProgressBar>
        </div>
        <button
          className="requestButton"
          type="button"
          onClick={() => {
            setRequestModalIsOpen((prev) => !prev);
          }}
        >
          Ok
        </button>
      </CustomModal>

      <CustomModal
        className="goalModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
        title="Donation Goals"
      >
        <Form
          onSubmit={onFormSubmit}
          validate={validate}
          initialValues={{
            monthalyGoal: userDetails?.goal?.monthly
              ? userDetails?.goal?.monthly
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "",
            annualGoal: userDetails?.goal?.annual
              ? userDetails?.goal?.annual
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "",
          }}
        >
          {({ handleSubmit, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="modalMonthly">
                  <Field
                    name="monthalyGoal"
                    type="text"
                    component={renderNumberWithCommas}
                    placeholder="Enter The Amount For Monthly Donation Goal"
                    containerClasses="form-group"
                    from="z"
                  >
                    <h5>$</h5>
                  </Field>
                  <div className="modalAchieve">
                    <p>{`$${
                      userDetails?.goal?.monthly -
                        userDetails?.monthlyTotalDonation >
                      0
                        ? userDetails?.goal?.monthly -
                          userDetails?.monthlyTotalDonation
                        : "00"
                    } Amount remaining to achieve monthly goal`}</p>
                    <Line
                      percent={monthlyPercentage()}
                      trailWidth={2}
                      trailColor="#E1E1E1"
                      strokeWidth={2}
                      strokeColor="#CE5139"
                    />
                  </div>
                </div>
                <div className="modalAnnualy">
                  <Field
                    name="annualGoal"
                    type="text"
                    component={renderNumberWithCommas}
                    placeholder="Enter The Amount For Annual Donation Goal"
                    containerClasses="form-group"
                  >
                    <h5>$</h5>
                  </Field>
                  <div className="modalAchieve">
                    <p>{`$${
                      userDetails.goal?.annual -
                        userDetails?.yearTotalDonation >
                      0
                        ? userDetails.goal?.annual -
                          userDetails?.yearTotalDonation
                        : "00"
                    } Amount remaining to achieve annual goal`}</p>
                    <Line
                      percent={annualprcentage()}
                      trailWidth={2}
                      trailColor="#E1E1E1"
                      strokeWidth={2}
                      strokeColor="#CE5139"
                    />
                  </div>
                </div>
                <div className="donationButton">
                  <button
                    className="cancel"
                    onClick={() => setModalIsOpen((prev) => !prev)}
                  >
                    Cancel
                  </button>
                  <button className="add" type="submit">
                    {`${userDetails.goal?.monthly ? "Edit" : "Add"}`}
                  </button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
}

export default FirstTimeLogin;
