import React, { useState } from "react";
import "./LeadershipCard.scss";
import { toAbsoluteUrl } from "../../../utils/index";
import CustomModal from "../Modal";
import { useSelector } from "react-redux";

function LeadershipCard({ leaderDetails }) {
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const [leaderInfo, setLeaderInfo] = useState({
    name: "",
    designation: "",
    desc: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const clickHandler = (leaderInfo) => {
    setLeaderInfo({
      ...leaderDetails,
      name: leaderInfo?.name,
      designation: leaderInfo?.designation,
      desc: leaderInfo?.description,
    });
    setModalIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="leadershipCard">
        {leaderDetails?.map((leaderInfo, ind) => (
          <div className="main-card" key={leaderInfo?._id}>
            <figure>
              {leaderInfo.images[0].name ? (
                <img
                  src={`${mediaURL}leadershipLogo/${leaderInfo.images[0].name}`}
                  alt="leader"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
            <h4>{leaderInfo.name}</h4>
            <p>{leaderInfo.designation}</p>
            <button onClick={() => clickHandler(leaderInfo)}>Learn More</button>
          </div>
        ))}
      </div>
      <CustomModal
        className="leaderModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <div className="leaderDesc">
          <h4>{leaderInfo?.name}</h4>
          <h5>{leaderInfo?.designation}</h5>
          {leaderInfo?.desc ? (
            <div dangerouslySetInnerHTML={{ __html: leaderInfo?.desc }}></div>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
    </>
  );
}

export default LeadershipCard;
