import {
  API_CAUSE_COMMUNITY_LIST,
  API_CAUSE_DETAILS,
  API_CAUSE_LIST,
  API_FETCHNOTIFICATION_LIST,
} from "../constants/api";
import {
  API,
  RENDER_CAUSE_LIST_S,
  RENDER_CAUSE_LIST_F,
  CAUSE_DETAILS_S,
  CAUSE_DETAILS_F,
  CAUSE_COMMUNITY_LIST_S,
  CAUSE_COMMUNITY_LIST_F,
} from "../constants/types";

export const renderCauseList = (
  payload,
  showLoader = false,
  reset = false
) => ({
  type: API,
  payload: {
    url: API_CAUSE_LIST,
    method: "POST",
    data: payload,
    showLoader,
    success: (data) => ({
      type: RENDER_CAUSE_LIST_S,
      payload: { ...data, page: payload?.page, reset },
    }),
    error: (data) => ({
      type: RENDER_CAUSE_LIST_F,
      payload: data,
    }),
  },
});

export const newCauseList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_FETCHNOTIFICATION_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_CAUSE_LIST_S,
      // payload: data,
      payload: {
        data: {
          causesList: data.data.list,
        },
      },
    }),
    error: (data) => ({
      type: RENDER_CAUSE_LIST_F,
      payload: data,
    }),
  },
});

export const renderCauseDetails = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CAUSE_DETAILS,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: CAUSE_DETAILS_S,
      payload: { ...data },
    }),
    error: (data) => ({
      type: CAUSE_DETAILS_F,
      payload: data,
    }),
  },
});

export const renderCauseCommunity = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CAUSE_COMMUNITY_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: CAUSE_COMMUNITY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CAUSE_COMMUNITY_LIST_F,
      payload: data,
    }),
  },
});
