import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils";

const Steps5 = () => {
  const navigate = useNavigate();

  const [timer, setTimer] = useState(3);

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        navigate("/");
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <>
      <section className="section-div">
        <div className="d-flex align-items-center">
          <div className="col-12">
            <div>
              <figure className="text-center figure-img">
                <img
                  src={toAbsoluteUrl("/images/login-left.svg")}
                  alt="left-img"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps5;
