import {
  API_LOGIN,
  API_REGISTRATION,
  API_VERIFY_OTP,
  API_RESEND_OTP,
  API_FORGOT_PASS,
  API_RESET_PASS,
  API_CHANGE_PASS,
  API_REFRESH_TOKEN,
  API_LOGOUT,
  API_DELETE_ACCOUNT,
} from "../constants/api";
import { API, LOGIN_S, LOGIN_F, LOGOUT } from "../constants/types";

export const doRegister = (data) => ({
  type: API,
  payload: {
    url: API_REGISTRATION,
    method: "POST",
    data: data,
  },
});

export const doLogin = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});

export const doVerifyOTPOfEmployee = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_VERIFY_OTP,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const doVerifyOTP = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_VERIFY_OTP,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});

export const doResetOTP = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_RESEND_OTP,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const doForgotPass = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_FORGOT_PASS,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const doResetPass = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_RESET_PASS,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const doChangePass = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHANGE_PASS,
    method: "POST",
    data: data,
    success: (data) => doLogout(),
    showLoader,
  },
});

export const doLogout = () => {
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  return {
    type: API,
    payload: {
      url: API_LOGOUT,
      method: "POST",
      data: { _id: userId, type: "user" },
      success: (data) => ({
        type: LOGOUT,
        payload: data,
      }),
      error: (data) => ({
        type: LOGOUT,
        payload: data,
      }),
    },
  };
};

export const deleteAccountAction = (userId) => {
  return {
    type: API,
    payload: {
      url: API_DELETE_ACCOUNT + userId,
      method: "DELETE",
      success: (data) => ({
        type: LOGOUT,
        payload: data,
      }),
      error: (data) => ({
        type: LOGOUT,
        payload: data,
      }),
    },
  };
};

export const Remembermereferesh = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_REFRESH_TOKEN,
    method: "POST",
    data: data,
    showLoader,
  },
});
