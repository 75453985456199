import React from "react";

function Card() {
  return (
    <>
      <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.898C1 2.87292 1 2.36038 1.20266 1.97025C1.37343 1.64149 1.64149 1.37343 1.97025 1.20266C2.36038 1 2.87292 1 3.898 1H15.102C16.1271 1 16.6396 1 17.0298 1.20266C17.3585 1.37343 17.6266 1.64149 17.7973 1.97025C18 2.36038 18 2.87292 18 3.898V10.102C18 11.1271 18 11.6396 17.7973 12.0298C17.6266 12.3585 17.3585 12.6266 17.0298 12.7973C16.6396 13 16.1271 13 15.102 13H3.898C2.87292 13 2.36038 13 1.97025 12.7973C1.64149 12.6266 1.37343 12.3585 1.20266 12.0298C1 11.6396 1 11.1271 1 10.102V3.898Z"
          stroke="#4F4F4F"
          fill="none"
        />
        <path d="M1 5L18 5" stroke="#4F4F4F" strokeLinecap="round" />
        <circle cx="4" cy="10" r="0.5" fill="#4F4F4F" stroke="#4F4F4F" />
      </svg>
    </>
  );
}

export default Card;
