import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendFriendRequestAction } from "../../../actions/friend";
import { toAbsoluteUrl, isUserLoggedIn } from "../../../utils";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { renderCommunityMemberListAction } from "../../../actions/community";
import { strategicDetailAction } from "../../../actions/strategic";

function MemberDetails(props) {
  const { member, classname, isCurrUser, setMembers, communityId, from } =
    props;
  const navigate = useNavigate();
  const employeeId = JSON.parse(localStorage.getItem("employeeId"));
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const dispatch = useDispatch();

  const clickHandler = () => {
    if (!isUserLoggedIn()) {
      navigate(`/signin?${window.location.pathname}`);
      toast.error("Please login to add this functionality");
      return;
    }
    if (member?.isFriend === "pending") {
      dispatch(sendFriendRequestAction({ friendId: member?.userId }))
        .then((res) => {
          toast.success(res.message);
          if (from === "strategicMutual") {
            dispatch(strategicDetailAction(employeeId));
            return;
          }
          dispatch(renderCommunityMemberListAction(communityId)).then((res) =>
            setMembers(res.data)
          );
        })
        .catch((res) => toast.error(res.message));
    } else if (member?.isFriend === "approved" || !member?.isFriend) {
      navigate("/chat", {
        state: {
          from: "post",
          sendToId: `${member?.userId}`,
          type: "user",
          name: member?.firstName + member?.lastName,
        },
      });
    }
  };

  const btnText = () => {
    if (
      member?.isFriend === "pending" ||
      (!member?.isFriend && from !== "mutualList")
    ) {
      return "Send Request";
    } else if (member?.isFriend === "requested") {
      return "Requested";
    } else {
      return "Send Message";
    }
  };

  const renderImage = (image, logo) => {
    return (
      <>
        {image || logo ? (
          <img
            src={`${mediaURL}userProfilePic/${image ? image : logo}`}
            alt="charity"
          />
        ) : (
          <img
            src={toAbsoluteUrl("/images/placeholder.png")}
            alt="placeholder"
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="memberDetails">
        <div
          className="memberDetailsWrap"
          onClick={() => navigate(`/user-profile/${member?.userId}`)}
        >
          <figure className="memberImage">
            {from === "mutualList"
              ? renderImage(member?.image, member?.logo)
              : renderImage(member?.profileImage, member?.logo)}
          </figure>
          <div className="memberName">
            <h3>{member?.firstName + " " + member?.lastName}</h3>
            <h4>{member?.city}</h4>
          </div>
        </div>
        {!isCurrUser && (
          <div
            className={
              member?.isFriend || from === "mutualList"
                ? classname
                : "friendButton"
            }
          >
            <button
              onClick={clickHandler}
              disabled={
                member?.isFriend ? member?.isFriend === "requested" : false
              }
            >
              {btnText()}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default MemberDetails;
