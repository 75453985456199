import React, { useEffect } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { renderMyEventList } from "../../../../actions/event";
import EventCard from "../../EventCard";

const FavEvents = (props) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const { myEventList } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(renderMyEventList({ _id: userId }));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ScrollBar component="div">
        <div className="content">
          <div className="upcomingEvent">
            {myEventList?.length ? (
              myEventList?.map((event, ind) => (
                <EventCard
                  event={event}
                  key={event?._id}
                  isEvent="myEvent"
                  classname="upcomingButton"
                  from="upcoming"
                  comingFrom="profile"
                />
              ))
            ) : (
              <h4 className="notFound mb-10">No Events Found</h4>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
};

export default FavEvents;
