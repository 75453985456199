import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import {
  renderField,
  renderNumberField,
  renderSelectField,
} from "../../components/forms";
import "../../pages/CharityRegistration/registration.scss";
import { ADD_CHARITY_DETAILS_STEPS_3 } from "../../constants/types";
import { addTOCause, addTOCategory } from "../../actions/charityRegister";
import { registrationstep3 as validate } from "../../components/validations/charityRegister";
import localforage from "localforage";
import Tookit from "../svg/tookit";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Steps3 = ({ setSteps, step, completedSteps, setCompletedSteps }) => {
  const dispatch = useDispatch();

  const [cause, setCause] = useState([]);
  const [category, setCategory] = useState([]);
  const [step3Data, setStep3Data] = useState("");

  useEffect(() => {
    localforage.getItem("charity_reg_step_3").then((res) => setStep3Data(res));
  }, []);

  const onSubmit = (value) => {
    localforage.setItem("curr_reg_step", 2);
    const data = {
      website: value.CharityWebsite,
      charityFilter: value.CharityFilter,
      charityContribution: value.CharityContribution,
      formedYear: Number(value?.CharityFormedyear),
      taxId: value.TaxId.replace(/-/g, ""),
      category: value?.CharityCategory,
      relatedCause: value?.causeList,
    };
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_3,
      payload: data,
    });
    const newCompleted = completedSteps;
    newCompleted[2] = true;
    setCompletedSteps(newCompleted);
    setSteps(3);
  };
  const prev = () => {
    setSteps(1);
  };

  const charityFilterOptions = [
    { value: 1, label: "International" },
    { value: 2, label: "National" },
    { value: 3, label: "Local" },
    // { value: 4, label: "Other" },
  ];

  const fetchCause = () => {
    dispatch(addTOCause({ page: 1, limit: 10000000, search: "" })).then(
      (res) => {
        const data = res.data;
        let causeArr = [];

        data?.causeFilter?.map((val) =>
          causeArr.push({ value: val._id, label: val.causename })
        );

        setCause(causeArr);
      }
    );
  };

  const fetchCategory = () => {
    dispatch(addTOCategory({ page: 1, limit: 10000000, search: "" })).then(
      (res) => {
        const data = res.data;
        let categoryArr = [];

        data?.categoryFilter?.map((val) =>
          categoryArr.push({ value: val._id, label: val.category_name })
        );
        setCategory(categoryArr);
      }
    );
  };

  useEffect(() => {
    fetchCause();
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="section-steps">
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 col-box">
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                CharityWebsite: step3Data?.website || "",
                CharityCategory: step3Data?.category || "",
                CharityFilter: step3Data?.charityFilter || "",
                CharityContribution: step3Data?.charityContribution || "",
                CharityFormedyear: step3Data?.formedYear || "",
                TaxId: step3Data?.taxId || "",
                causeList: step3Data?.relatedCause || "",
              }}
            >
              {({ handleSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className="form-box step-form-box"
                >
                  {/* <h2>Rosi Giving</h2> */}
                  <div className=" d-flex flex-wrap w-100">
                    <div className="col-md-6 col-box">
                      <ul className="input-list">
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Website{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="website"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="website"
                              place="top"
                              variant="success"
                              content="Please provide your Nonprofit’s website URL / web address. Ideally it should be directed to your Homepage or About Us page, although you many chose to direct to another page. If your Nonprofit doesn’t have an independent web page, a link to a Facebook / LinkedIn (or other media) will be sufficient. Providing a link to your Nonprofits Donate page is not allowed."
                            />
                          </label>
                          <Field
                            name="CharityWebsite"
                            type="text"
                            placeholder="Nonprofit Website"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Category{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="category"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="category"
                              place="top"
                              variant="success"
                              content="These are broad categories that your Nonprofit will fall under. There is a menu to choose from on the onboarding form. If your Nonprofit does not fit into one of the categories in the menu, please select “Other” and contact nonprofit support at nonprofit.support@rosigiving.com; this will not cause a delay in the onboarding process."
                            />
                          </label>
                          <Field
                            name="CharityCategory"
                            component={renderSelectField}
                            // validate={(value) => required(value, "Charity Category")}
                            className="basic-multi-select dropdownGrp"
                            options={category}
                            isMulti={false}
                          />
                        </li>

                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Scope of Nonprofit{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="scope"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="scope"
                              place="top"
                              variant="success"
                              content="This describes the communities served and / or the reach of your Nonprofit’s mission. There is a menu to choose from on the onboarding form. The choices are: Local, National, International or Other."
                            />
                          </label>
                          <Field
                            name="CharityFilter"
                            component={renderSelectField}
                            // validate={(value) => required(value, "Charity Category")}
                            className="basic-multi-select dropdownGrp"
                            options={charityFilterOptions}
                            isMulti={false}
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Total Revenue{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="revenue"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="revenue"
                              place="top"
                              variant="success"
                              content="Indicate the Total Revenue as presented on your last 990 or published financial statements. It does not have to be an exact amount, but instead round up to the nearest thousand dollars. If your Nonprofit files a 990-N (i.e., postcard), please indicate the amount under $50,000. Enter the digits only on the onboarding form."
                            />
                          </label>
                          <Field
                            name="CharityContribution"
                            type="number"
                            placeholder="Enter Nonprofit Total Revenue here"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-box">
                      <ul className="input-list">
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Formed year{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="year"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="year"
                              place="top"
                              variant="success"
                              content="Indicate here the year the Nonprofit was formed or the year the tax determination letter was received. Enter a four-digit number for the year."
                            />
                          </label>
                          <Field
                            name="CharityFormedyear"
                            // type="number"
                            placeholder="Enter the year the nonprofit was formed(YYYY)"
                            component={renderNumberField}
                            formatStyle={"####"}
                            className="form-grp"
                            inputclass="input-box"
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit EIN/Tax ID{" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="taxId"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="taxId"
                              place="top"
                              variant="success"
                              content="Indicate here the 9-digit EIN/Tax ID of your Nonprofit."
                            />
                          </label>
                          <Field
                            name="TaxId"
                            placeholder="Enter the 9 digit EIN/TaxID here"
                            component={renderNumberField}
                            formatStyle={"##-#######"}
                            className="form-grp"
                            inputclass="input-box"
                          />
                        </li>
                        <li>
                          <label className="label-text normalText">
                            {" "}
                            <span>*</span> Related Cause(s){" "}
                            <span
                              className="tooltip-information-wrap"
                              data-tooltip-id="cause"
                            >
                              <Tookit />
                            </span>
                            <ReactTooltip
                              id="cause"
                              place="top"
                              variant="success"
                              content="These are narrower ways to classify and describe your Nonprofit. These can be subsets of the category, specific communities served, cities/states/regions/countries, etc. There no right or wrong answer. There is a menu to choose from on the onboarding form. If one or more of your Nonprofit cause(s) is not included, please select “Other” and contact nonprofit support at nonprofit.support@rosigiving.com; this will not cause a delay in the onboarding process."
                            />
                          </label>
                          <Field
                            name="causeList"
                            component={renderSelectField}
                            // validate={(value) => required(value, "Cause Related")}
                            className="basic-multi-select dropdownGrp"
                            options={cause}
                            isMulti={true}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="button-box">
                        <button
                          type="button"
                          className="link-btn"
                          onClick={prev}
                        >
                          prev
                        </button>
                      </div>
                      <div className="button-box">
                        <button type="submit" className="link-btn">
                          next
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps3;
