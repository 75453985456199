/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CustomTab from "../../components/common/Tabs";
import { toAbsoluteUrl } from "../../utils";
import StrategicMedia from "../StrategicTabs/Media";
import Member from "../Community Details/CommunityTabs/Member";
import StrategicPosts from "../../components/strategicPosts";
import "./strategicDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { strategicDetailAction } from "../../actions/strategic";
import { formatPhoneNumber } from "../../utils/reusable";
import Link from "../../assets/svg/link";
import Events from "../Community Details/CommunityTabs/Events";
import { fetchUserDetails } from "../../actions/user";

function StrategicDetails() {
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const { strategicDetails } = useSelector((state) => state.strategic);
  const employeeId = JSON.parse(localStorage.getItem("employeeId"));
  const id = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const [readDesc, setReadDesc] = useState(true);

  useEffect(() => {
    dispatch(strategicDetailAction(employeeId));
    dispatch(fetchUserDetails({ userId: id })).then((res) => {
      const prevData = JSON.parse(localStorage.getItem("webUser"));
      let newData = {
        ...prevData,
        data: {
          ...prevData.data,
          userType: res.data.userType,
        },
      };

      localStorage.setItem("webUser", JSON.stringify(newData));
    });
  }, []);

  const data = [
    {
      heading: "Details",
      body: <StrategicPosts />,
    },
    {
      heading: "Events",
      // body: (
      //   <Events id={charityDetails?._id} from="charity" />
      // )
      body: employeeId && <Events id={employeeId} from="strategic" />,
    },
    {
      heading: "Members",
      body: (
        <Member members={strategicDetails?.memberList} from="strategicMutual" />
      ),
    },
    {
      heading: "Media",
      body: (
        <StrategicMedia
          images={strategicDetails?.mediaImage}
          videos={strategicDetails?.mediaVideo}
        />
      ),
    },
  ];

  const bread = [
    {
      name: "Giving Partner Details",
      link: "/Giving_Partner_Details",
      isActive: true,
    },
  ];

  const strategicAdd = () => {
    return (
      strategicDetails?.address?.streetName +
      ", " +
      strategicDetails?.address?.city +
      ", " +
      strategicDetails?.address?.state +
      ", " +
      strategicDetails?.address?.zipcode
    );
  };
  // const onMessageBtnClick = (e) => {
  //   e.stopPropagation();
  //   navigate("/chat", {
  //     state: {
  //       from: "Strategic",
  //       sendToId: strategicDetails?._id,

  //       type: "stategic",
  //       name: strategicDetails?.name,
  //     },
  //   });
  // };
  return (
      <section className="strategicDetail">
        <div className="container">
          <div className="breadcrumbsStrategicDetails">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <section className="strategicDetailsWrap">
            <div className="strategicDetailSidebar">
              {/* detail-card */}
              <div className="strategicDetailsCard">
                <figure className="strategicDetailsFigure">
                  {strategicDetails?.logo &&
                  strategicDetails?.logo !== "string" ? (
                    <img
                      src={mediaURL + "strategicLogo/" + strategicDetails?.logo}
                      alt="logo"
                    ></img>
                  ) : (
                    <img
                      src={toAbsoluteUrl("/images/placeholder.png")}
                      alt="placeholder"
                    />
                  )}
                </figure>
                <div className="strategicUser">
                  {/* <div className="d-flex flex-row-reverse align-content-between"> */}
                  <div className="tagline">
                    {strategicDetails?.tagline && (
                      <div className="maintag">
                        {/* <Tag /> */}
                        <h4>{strategicDetails?.tagline}</h4>
                      </div>
                    )}
                    {/* <button
                      className="telegramButton"
                      onClick={onMessageBtnClick}
                    >
                      <Telegram />
                    </button> */}
                  </div>
                  <div className="strategicUserName">
                    {/* <h5>{strategicDetails?.userName}</h5> */}
                    {/* <div className="strategicFullName">
                      <img
                        src={toAbsoluteUrl("/images/user.png")}
                        alt="username"
                      />
                      <h5>{strategicDetails?.ownerName}</h5>
                    </div> */}
                  </div>
                  <h3>{strategicDetails?.name}</h3>
                  <p className={!readDesc ? "" : "readLess"}>
                    {strategicDetails?.description}
                  </p>
                  <div className="member">
                    <button
                      className={
                        strategicDetails?.description?.length < 155
                          ? "hideBtn"
                          : ""
                      }
                      onClick={() => setReadDesc((prev) => !prev)}
                    >
                      {readDesc ? "Read More" : "Read Less"}
                    </button>
                  </div>
                </div>

                <div className="strategicCardDetails">
                  <div className="strategicFullName">
                    <img
                      src={toAbsoluteUrl("/images/user.png")}
                      alt="username"
                    />
                    <h5>{strategicDetails?.ownerName}</h5>
                  </div>
                  <div className="cardContact">
                    <div className="mail">
                      <img
                        src={toAbsoluteUrl("/images/email.svg")}
                        alt="location"
                      />
                      <h4>
                        <a href="mailto:support@email.com">
                          {strategicDetails?.emailAddress}
                        </a>
                      </h4>
                    </div>
                    {strategicDetails?.phoneNumber && (
                      <div className="mobile">
                        <a
                          href={`tel: ${strategicDetails?.phoneNumber}`}
                          className="phnNumber"
                        >
                          <img
                            src={toAbsoluteUrl("/images/telephone.svg")}
                            alt="location"
                          />
                          <h4>
                            {formatPhoneNumber(
                              strategicDetails?.phoneNumber?.toString()
                            )}
                          </h4>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="cardContact">
                    <div className="website">
                      <Link />
                      <h4>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            strategicDetails?.website
                              ?.toString()
                              ?.indexOf("http") !== -1
                              ? strategicDetails?.website
                              : `https://${strategicDetails?.website}`
                          }`}
                        >
                          {strategicDetails?.website}
                        </a>
                      </h4>
                    </div>
                  </div>

                  {strategicDetails?.address && (
                    <div className="strategicCardAdrees">
                      <div className="location">
                        <img
                          src={toAbsoluteUrl("/images/location.svg")}
                          alt="location"
                        />
                        <address>{strategicAdd()}</address>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* research */}
              {/* <div className="research">
                <h3>{strategicDetails?.name}</h3>
                <p className={!readDesc ? "" : "readLess"}>
                  {strategicDetails?.description}
                </p>
                <div className="member">
                  <div className="member-list">
                    <div className="member-images">
                      {strategicDetails?.memberImageList?.map((image, ind) => (
                        <figure key={ind}>
                          {image ? (
                            <img
                              src={mediaURL + "userProfilePic/" + image}
                              alt="user"
                            />
                          ) : (
                            <img
                              src={toAbsoluteUrl("/images/placeholder.png")}
                              alt="placeholder"
                            />
                          )}
                        </figure>
                      ))}
                    </div>
                    <span className="member-count">
                      {strategicDetails?.memberList?.length} Members
                    </span>
                  </div>
                  <button onClick={() => setReadDesc((prev) => !prev)}>
                    {readDesc ? "Read More" : "Read Less"}
                  </button>
                </div>
              </div> */}
            </div>
            <div className="strategicContent">
              <CustomTab className="strategicTab" data={data}></CustomTab>
            </div>
          </section>
        </div>
      </section>
  );
}

export default StrategicDetails;
