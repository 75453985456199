import {
  EVENT_DETAILS_F,
  EVENT_DETAILS_S,
  RENDER_CAUSE_EVENT_LIST_F,
  RENDER_CAUSE_EVENT_LIST_S,
  RENDER_STRATEGIC_EVENT_LIST_S,
  RENDER_STRATEGI_EVENT_LIST_F,
  RENDER_EVENT_LIST_F,
  RENDER_EVENT_LIST_S,
  RENDER_MY_EVENT_LIST_F,
  RENDER_MY_EVENT_LIST_S,
  RENDER_ALL_UPCOMING_EVENT_LIST_F,
  RENDER_ALL_UPCOMING_EVENT_LIST_S,
} from "../constants/types";

const initialState = {
  loading: undefined,
  message: undefined,
  error: undefined,
  eventList: {},
  myEventList: {},
  allUpcomingEventList: {},
  eventDetails: {},
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENDER_EVENT_LIST_S:
    case RENDER_CAUSE_EVENT_LIST_S:
    case RENDER_STRATEGIC_EVENT_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        eventList: action.payload.data?.eventFilter,
      };
    case RENDER_EVENT_LIST_F:
    case RENDER_CAUSE_EVENT_LIST_F:
    case RENDER_STRATEGI_EVENT_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        eventList: {},
      };
    case RENDER_MY_EVENT_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        myEventList: action.payload.data?.finalRes,
      };
    case RENDER_MY_EVENT_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        myEventList: {},
      };
    case RENDER_ALL_UPCOMING_EVENT_LIST_S:
      return {
        ...state,
        allUpcomingEventList: action.payload.data,
      };
    case RENDER_ALL_UPCOMING_EVENT_LIST_F:
      return {
        ...state,
        allUpcomingEventList: {},
      };
    case EVENT_DETAILS_S:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        eventDetails: action.payload.data,
      };
    case EVENT_DETAILS_F:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.message,
        eventDetails: {},
      };
    default:
      return state;
  }
};

export default eventReducer;
