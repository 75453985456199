/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ScrollBar from "react-perfect-scrollbar";
import { renderCommunityMemberListAction } from "../../../../../actions/community";
import MemberDetails from "../../../../../components/common/MemberDetails";
import "./../member.scss";

function FriendList({ communityId, employees, from }) {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const [members, setMembers] = useState([]);
  const [currUser, setCurrUser] = useState([]);

  useEffect(() => {
    if (!employees?.length) {
      dispatch(renderCommunityMemberListAction(communityId)).then((res) =>
        setMembers(res.data)
      );
    } else {
      setMembers(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (members.length)
      setCurrUser(members?.filter((member) => member?.userId === userId));
  }, [members]);

  return (
    <>
      <ScrollBar component="div">
        <div className="content">
          <div className="friends">
            {currUser?.map((member, ind) => (
              <MemberDetails
                member={member}
                key={member?._id}
                isMember={false}
                classname="friendButton"
                from={from}
                isCurrUser={member?.userId === userId}
              />
            ))}
            {members?.filter((member) => member?.userId !== userId)?.length >
              0 && members?.[0]?.firstName
              ? members
                  ?.filter((member) => member?.userId !== userId)
                  ?.map((member, ind) => (
                    <MemberDetails
                      member={member}
                      key={member?._id}
                      isMember={false}
                      from={from}
                      setMembers={setMembers}
                      communityId={communityId}
                      // classname="friendButton"
                      classname={
                        member?.isFriend === "pending"
                          ? "friendButton"
                          : "memberButton"
                      }
                      isCurrUser={member?.userId === userId}
                    />
                  ))
              : !currUser.length && (
                  <h4 className="notFound mt-10">no member list found</h4>
                )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default FriendList;
