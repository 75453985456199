import {
  API_ALL_UPCOMING_EVENT_LIST,
  API_EVENT_DETAILS,
  API_EVENT_LIST,
  API_EVENT_LIST_OF_CAUSE,
  API_EVENT_LIST_OF_CHARITY,
  API_JOIN_EVENT,
  API_MY_EVENT_LIST,
  API_STRATEGIC_EVENT,
  API_UPDATE_EVENT,
} from "../constants/api";
import {
  API,
  EVENT_DETAILS_F,
  EVENT_DETAILS_S,
  RENDER_ALL_UPCOMING_EVENT_LIST_F,
  RENDER_ALL_UPCOMING_EVENT_LIST_S,
  RENDER_CAUSE_EVENT_LIST_F,
  RENDER_CAUSE_EVENT_LIST_S,
  RENDER_EVENT_LIST_F,
  RENDER_EVENT_LIST_S,
  RENDER_MY_EVENT_LIST_F,
  RENDER_MY_EVENT_LIST_S,
  RENDER_STRATEGIC_EVENT_LIST_S,
  RENDER_STRATEGI_EVENT_LIST_F,
} from "../constants/types";

export const renderEventList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_EVENT_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const renderCauseEventList = (id, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_EVENT_LIST_OF_CAUSE}${id}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_CAUSE_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_CAUSE_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const renderStrategicEventList = (id, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_STRATEGIC_EVENT}${id}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_STRATEGIC_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_STRATEGI_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const renderMyEventList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_MY_EVENT_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_MY_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_MY_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const renderAllUpcomingEventList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ALL_UPCOMING_EVENT_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_ALL_UPCOMING_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_ALL_UPCOMING_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const renderCharityEventList = (id, data, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_EVENT_LIST_OF_CHARITY}${id}`,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_CAUSE_EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_CAUSE_EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const eventDetailsAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_EVENT_DETAILS,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: EVENT_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_DETAILS_F,
      payload: data,
    }),
  },
});

export const joinEventAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_JOIN_EVENT,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const updateEventStatusAction = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_UPDATE_EVENT,
    method: "PATCH",
    data: data,
    showLoader,
  },
});
