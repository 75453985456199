import {
  API_ADD_CAUSE_TO_FAV,
  API_ADD_CHARITY_TO_FAV,
  API_FAV_LIST,
  API_REMOVE_CHARITY_OR_CAUSE_TO_FAV,
} from "../constants/api";
import {
  API,
  RENDER_ALL_COMMUNITY_F,
  RENDER_FAV_LIST_S,
} from "../constants/types";

export const addCharityToFav = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_ADD_CHARITY_TO_FAV,
    method: "POST",
    data: data,
    showLoader,
  },
});

export const addCauseToFav = (data, showLoader = false) => ({
  type: API,
  payload: {
    showLoader,
    url: API_ADD_CAUSE_TO_FAV,
    method: "POST",
    data: data,
  },
});

export const renderFavList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_FAV_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_FAV_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_ALL_COMMUNITY_F,
      payload: data,
    }),
  },
});

export const removeFav = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_REMOVE_CHARITY_OR_CAUSE_TO_FAV,
    method: "POST",
    data: data,
    showLoader,
  },
});
