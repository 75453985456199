import React, { useState } from "react";
import CustomModal from "../../../../../components/common/Modal";
import { Player } from "video-react";
import { toAbsoluteUrl } from "../../../../../utils";
import "./video.scss";
import "video-react/styles/scss/video-react.scss";
import { useSelector } from "react-redux";
import VideoThumbnail from "react-video-thumbnail";

function Video({ mediaVideo, moduleName }) {
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currVideoURL, setCurrVideoURL] = useState("");

  const openModal = (ind) => {
    setModalIsOpen((prev) => !prev);
    setCurrVideoURL(mediaVideo?.[ind]?.url);
  };

  function generateThumbnail(url) {
    const videoElement = document.createElement("video");
    videoElement.src = url;
    videoElement.addEventListener("loadedmetadata", () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL();
      // code to save the thumbnailUrl or display it
    });
  }

  return (
    <>
      <div className="videoMedia">
        <div className="rowVideo">
          {mediaVideo?.length > 0 ? (
            mediaVideo?.map((video, ind) => {
              return (
                <div className="VideoColumn" key={video?._id}>
                  <figure
                    className="videoThumbnail"
                    onClick={() => openModal(ind)}
                  >
                    <img
                      src={toAbsoluteUrl("/images/placeholder.png")}
                      alt="placeholder"
                    />
                    {/* {video?.thumbnail ? (
                      // <img
                      //   src={`${mediaURL}${moduleName}videos/thumbnail/${video?.thumbnail}`}
                      //   alt="video"
                      // />
                      <VideoThumbnail
                        videoUrl="http://techslides.com/demos/sample-videos/small.mp4"
                        // videoUrl={video?.url}
                        thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                        width={120}
                        height={80}
                      />
                    ) : (
                      // generateThumbnail(video?.url)
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )} */}
                  </figure>
                  <div className="videoBgColor"></div>
                  <button className="play" onClick={() => openModal(ind)}>
                    <img src={toAbsoluteUrl("/images/play.png")} alt="play" />
                  </button>
                </div>
              );
            })
          ) : (
            <h4 className="notFound mt-10 mb-10 d-inlineBlock">
              No media video found
            </h4>
          )}
        </div>
      </div>

      <CustomModal
        className="videoModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <Player
          playsInline
          // poster="/assets/poster.png"
        >
          <source src={currVideoURL}></source>
        </Player>
      </CustomModal>
    </>
  );
}

export default Video;
