import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { toAbsoluteUrl } from "../../../utils";
import BasketLock from "../../../assets/svg/basketLock";
import BasketLockOpen from "../../../assets/svg/basketLockOpen";
import { useSelector } from "react-redux";

const SubscriptionItem = (props) => {
  const {
    item,
    basketInfo,
    setIsDistributeEqually,
    paymentType,
    setBasketInfo,
  } = props;
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  // const [isLocked, setIsLocked] = useState(item?.isLocked)
  const [percentage, setPercentage] = useState([]);
  const [maxPerLimit, setMaxPerLimit] = useState(0);

  useEffect(() => {
    const lockedPer = basketInfo?.reduce((lockedPer, item) => {
      if (item?.isLocked === true) {
        return lockedPer + item.paymentPercent;
      }
      return lockedPer;
    }, 0);
    setMaxPerLimit(100 - lockedPer);
    setPercentage([item?.paymentPercent]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketInfo]);

  const lockItemHandler = () => {
    const payload = {
      userId,
      basket: basketInfo?.map((a) => {
        if (a?.id === item?.id) {
          const isLockedKeyValue = a.isLocked;
          return {
            ...a,
            isLocked: !isLockedKeyValue,
            paymentPercent: percentage[0],
          };
        }
        return a;
      }),
    };
    setIsDistributeEqually(false);
    setBasketInfo(payload.basket);
  };

  const percentageChangeHandler = (per) => {
    let staticItemCnt = 0;
    const staticItem = basketInfo?.reduce((staticItem, a) => {
      if (a?.isLocked === true) {
        staticItemCnt++;
        return staticItem + a.paymentPercent;
      }
      if (a?.id === item?.id) {
        staticItemCnt++;
        return staticItem + per;
      }
      return staticItem;
    }, 0);

    const equalPer = (100 - staticItem) / (basketInfo?.length - staticItemCnt);
    let extraPer = (100 - staticItem) % (basketInfo?.length - staticItemCnt);

    const payload = {
      userId,
      basket: basketInfo?.map((a, ind) => {
        if (a?.isLocked === false && a?.id !== item?.id) {
          let paymentPercent = Math.floor(equalPer);
          if (extraPer) {
            paymentPercent++;
            extraPer--;
          }
          return { ...a, paymentPercent };
        }
        if (a?.id === item?.id) return { ...a, paymentPercent: per };
        return a;
      }),
    };

    setBasketInfo(payload.basket);
    setIsDistributeEqually(false);
  };

  const renderLogo = (moduleName) => {
    return item?.logo && item?.logo !=="string" ? (
      <img
        src={`${mediaURL}${moduleName}/${item?.logo}`}
        alt="backet charity"
      />
    ) : (
      <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
    );
  };

  return (
      <div className="basketWrapCard basketLockCard">
        <div className="basketSummary">
          <figure>
            {item?.type === "charity"
              ? renderLogo("charityLogo")
              : renderLogo("communityLogo")}
          </figure>
          <div className="naming">
            <h5>{item?.type === "charity" ? "Nonprofit" : "Community"}</h5>
            <p className="basketCharityName">{item?.name}</p>
            {item?.isAlreadySubscription && paymentType === 2 && (
              <p className="verification_error">
                You have already active subscription
              </p>
            )}
          </div>
        </div>
        <div className="basketPercent">
          <div className="basketPercentWrap">
            {percentage && (
              <Range
                step={1}
                min={0}
                max={100}
                draggableTrack={true}
                disabled={item?.isLocked}
                values={percentage}
                onFinalChange={(e) => percentageChangeHandler(e[0])}
                onChange={(values) =>
                  setPercentage(values >= maxPerLimit ? [maxPerLimit] : values)
                }
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      background: getTrackBackground({
                        values: percentage,
                        colors: ["#6daf5f", "#D5E2EA"],
                        min: 0,
                        max: 100,
                      }),
                      borderRadius: "10px",
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props, value, isDragged }) => {
                  return (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "42px",
                        width: "42px",
                        backgroundColor: "#22841A",
                      }}
                      disabled="30"
                      className="round"
                    />
                  );
                }}
              />
            )}
            <div className="isLock" onClick={() => lockItemHandler()}>
              {item?.isLocked ? <BasketLock /> : <BasketLockOpen />}
              <h6>{item?.isLocked ? "Locked" : "Unlocked"}</h6>
            </div>
          </div>
          <button className="percentButton">{percentage}%</button>
        </div>
      </div>
  );
};

export default SubscriptionItem;
