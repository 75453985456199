import { API_CHAT_NOTIFICATION_COUNT, API_META } from "../constants/api";
import {
  API,
  CHAT_NOTI_COUNT_F,
  CHAT_NOTI_COUNT_S,
  FETCH_META_F,
  FETCH_META_S,
} from "../constants/types";

export const storeMetaData = (showLoader = false) => ({
  type: API,
  payload: {
    url: API_META,
    method: "GET",
    showLoader,
    success: (data) => ({
      type: FETCH_META_S,
      payload: data,
    }),
    error: (data) => ({
      type: FETCH_META_F,
      payload: data,
    }),
  },
});

export const chatNotificationCountAction = (showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHAT_NOTIFICATION_COUNT,
    method: "GET",
    showLoader,
    success: (data) => ({
      type: CHAT_NOTI_COUNT_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHAT_NOTI_COUNT_F,
      payload: data,
    }),
  },
});
