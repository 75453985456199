import React from "react";
import CustomTab from "../../../../components/common/Tabs";
import CardDetails from "../CardDetails";
import TransactionDetails from "../TransactionDetails";
import "./donation.scss";

const Donations = () => {
  const Tabs = [
    {
      heading: "Transactions",
      body: <TransactionDetails />,
    },
    {
      heading: "Cards",
      body: <CardDetails />,
    },
  ];
  return (
    <div className="donationTab">
      <CustomTab className="tabsForDonation" data={Tabs} />
    </div>
  );
};

export default Donations;
