import React from "react";

function Share() {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.954 1.0327C14.5303 1.10808 13.9512 1.29972 13.1072 1.58106L5.14856 4.23393C3.87373 4.65887 2.94588 4.96853 2.2869 5.24577C1.60225 5.5338 1.33041 5.7409 1.23213 5.89635C0.922623 6.38592 0.922623 7.00993 1.23213 7.49949C1.33041 7.65494 1.60225 7.86204 2.2869 8.15008C2.94588 8.42731 3.87373 8.73697 5.14856 9.16191C5.16875 9.16864 5.18866 9.17527 5.20832 9.18182C5.65951 9.33206 5.97145 9.43593 6.24042 9.61067C6.53398 9.80138 6.78421 10.0516 6.97492 10.3452C7.14966 10.6141 7.25353 10.9261 7.40377 11.3773C7.41031 11.3969 7.41695 11.4168 7.42368 11.437C7.84862 12.7119 8.15828 13.6397 8.43551 14.2987C8.72355 14.9833 8.93065 15.2552 9.0861 15.3535C9.57566 15.663 10.1997 15.663 10.6892 15.3535C10.8447 15.2552 11.0518 14.9833 11.3398 14.2987C11.6171 13.6397 11.9267 12.7119 12.3517 11.437L15.0045 3.47843C15.2859 2.63439 15.4775 2.05532 15.5529 1.63158C15.6289 1.20422 15.5539 1.10713 15.5162 1.06939C15.4785 1.03166 15.3814 0.956672 14.954 1.0327ZM14.7789 0.048154C15.2887 -0.0425326 15.824 -0.0369955 16.2233 0.362287C16.6226 0.761569 16.6281 1.29694 16.5374 1.80672C16.4476 2.31151 16.2312 2.96067 15.9668 3.75387L15.9532 3.79466L13.3003 11.7533L13.2927 11.7761C12.877 13.0233 12.5555 13.9878 12.2616 14.6865C11.9767 15.3637 11.6712 15.9158 11.2236 16.1987C10.4077 16.7146 9.36766 16.7146 8.55172 16.1987C8.10417 15.9158 7.79867 15.3637 7.51376 14.6865C7.21986 13.9879 6.89839 13.0234 6.48267 11.7763L6.47499 11.7533C6.29706 11.2195 6.23266 11.0382 6.13634 10.8899C6.02192 10.7138 5.87178 10.5637 5.69564 10.4492C5.54739 10.3529 5.36613 10.2885 4.83233 10.1106L4.80931 10.1029C3.56216 9.68721 2.59773 9.36573 1.89912 9.07183C1.2219 8.78692 0.66984 8.48142 0.386887 8.03387C-0.128962 7.21793 -0.128962 6.17791 0.386887 5.36197C0.66984 4.91442 1.2219 4.60892 1.89912 4.32401C2.59774 4.03011 3.56218 3.70863 4.80935 3.2929L4.83233 3.28525L12.7909 0.632379L12.8318 0.618767C13.6249 0.354352 14.2741 0.137949 14.7789 0.048154Z"
          fill="#4F4F4F"
        />
      </svg>
    </>
  );
}

export default Share;
