import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newCauseList, renderCauseList } from "../../actions/cause";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CauseCard from "../../components/common/CauseCard";
import "./causeList.scss";
import Search from "../../assets/svg/search";
import Cancle from "../../assets/svg/cancle";
import { debounce } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { Loading } from "../../components/common/LoderData";

const bread = [
  {
    name: "Find Your Cause",
    link: "/cause",
    isActive: true,
  },
];

function CauseList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();

  const { causeList } = useSelector((state) => state.cause);
  const parsedQuery = queryString.parse(window.location.search);

  const [args, setArgs] = useState({
    limit: 12,
    page: 1,
    search: parsedQuery?.search,
    isNavigator: false,
  });
  const [hasMore, setHasMore] = useState(true);
  const searchRef = useRef();

  useEffect(() => {
    if (parsedQuery?.search) searchRef.current.value = parsedQuery?.search;
  }, [parsedQuery]);

  const setParams = (payload, from) => {
    const params = {};
    for (const key in payload) {
      if (payload[key]) {
        params[key] = payload[key];
      }
    }

    let queryUrl = queryString.stringify(params);
    navigate(`?${queryUrl}`);
  };

  const finalCauseList = causeList?.map((cause, id) => {
    return {
      id: cause?._id,
      causeImage: cause?.image?.url,
      causeText: cause?.causeName,
      isFavorite: cause?.isFavorite,
    };
  });

  const onChangeFun = (e) => {
    if (e.length >= 3 || e.length === 0) setArgs({ ...args, search: e });
    setParams({ search: e });
  };

  const optimizedFn = debounce(onChangeFun);

  const fetchData = (showLoader = false) => {
    if (id?.id) {
      dispatch(
        newCauseList({
          ...args,
          page: args.page,
          notificationId: id?.id,
          isFlag: false,
        })
      ).then((res) => {
        if (res?.data?.list?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      });
    } else {
      dispatch(
        renderCauseList({ ...args, page: args.page + 1 }, showLoader)
      ).then((res) => {
        if (res?.data?.causesList?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }
    setArgs({ ...args, page: args.page + 1 });
  };

  const renderCauseListHandler = () => {
    const payload = {
      ...args,
      page: 1,
    };
    if (args.search?.length) {
      payload.search = args.search;
      payload.isNavigator = true;
      setArgs(payload);
    }
    const resetList = args.search?.length ? true : false;
    if (id?.id) {
      dispatch(
        newCauseList({
          ...args,
          page: args.page,
          notificationId: id?.id,
          isFlag: false,
        })
      ).then((res) => {
        if (res?.data?.list?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      });
    } else {
      dispatch(renderCauseList(payload, false, resetList)).then((res) => {
        if (res?.data?.charityFilter?.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }
  };

  useEffect(() => {
    renderCauseListHandler();
    // eslint-disable-next-line
  }, [args.search]);

  return (
    <>
      <section className="causeList">
        <div className="container">
          <div className="breadcrumbsCause">
            <Breadcrumbs list={bread} />
          </div>
          <div className="cause-header">
            <h3>Find Your Cause</h3>
          </div>
          <div className="causeList-search">
            <div className="cause-search-formGrp">
              <span>
                <Search />
              </span>
              <input
                type="text"
                placeholder="Search Causes"
                ref={searchRef}
                onChange={(e) => optimizedFn(e.target.value)}
              />
              {args.search && (
                <span
                  className="cancle-cause"
                  onClick={() => {
                    setArgs({
                      ...args,
                      search: "",
                      page: 1,
                      isNavigator: false,
                    });
                    navigate("?");
                    setHasMore(true);
                    searchRef.current.value = "";
                  }}
                >
                  <Cancle />
                </span>
              )}
            </div>
          </div>
          {finalCauseList?.length ? (
            <InfiniteScroll
              dataLength={finalCauseList?.length}
              next={() => fetchData(false)}
              hasMore={hasMore}
              loader={<Loading />}
            >
              <div className="cause-wrap">
                {finalCauseList?.map((cause, id) => (
                  <CauseCard cause={cause} key={cause?.id} />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <p className="notFound">No Nonprofit List Found</p>
          )}
        </div>
      </section>
    </>
  );
}

export default CauseList;
