import patterns from "../../utils/patterns";

export const login = (value) => {
  let error = {};

  if (!value.email) {
    error.email = "Email is required";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }
  if (!value.password || !value.password.trim()) {
    error.password = "Password is required";
  }
  return error;
};

export const registrationstep1 = (value) => {
  let error = {};
  if (!value.UserName || !value.UserName.trim()) {
    error.UserName = "Please enter Nonprofit Username";
  }
  if (!value.CharityName || !value.CharityName.trim()) {
    error.CharityName = "Please enter Name of Nonprofit";
  }

  if (!value.CharityPersonName || !value.CharityPersonName.trim()) {
    error.CharityPersonName = "Please enter Name of Nonprofit Representative";
  }
  if (!value.CharityEmailAddress || !value.CharityEmailAddress.trim()) {
    error.CharityEmailAddress = "Please enter Nonprofit Email Address";
  } else if (
    value.CharityEmailAddress &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.CharityEmailAddress
    )
  ) {
    error.CharityEmailAddress = "Please enter valid Nonprofit Email address";
  }

  if (!value.Password) {
    error.Password = "Please enter Password";
  } else if (
    value.Password &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.Password
    )
  ) {
    error.Password =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }

  if (!value.ConfirmPassword) {
    error.ConfirmPassword = "Please enter Confirm Password";
  } else if (
    value.ConfirmPassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.ConfirmPassword
    )
  ) {
    error.ConfirmPassword =
      "Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  } else if (
    value.ConfirmPassword.length < 6 ||
    value.ConfirmPassword.length > 20
  ) {
    error.ConfirmPassword =
      "Confirm Password length must be between 6 to 20 character";
  } else if (value.Password !== value.ConfirmPassword) {
    error.ConfirmPassword = "Confirm password must match with Password";
  }
  return error;
};

export const registrationstep2 = (value) => {
  let error = {};
  if (!value.CharityDescription || !value.CharityDescription.trim()) {
    error.CharityDescription = "Please enter Nonprofit Full Description";
  }
  if (!value.CharityBriefDescription || !value.CharityBriefDescription.trim()) {
    error.CharityBriefDescription = "Please enter Nonprofit Brief Description";
  } else if (value.CharityBriefDescription.length > 150) {
    error.CharityBriefDescription =
      "Nonprofit brief description character shoud be 150";
  }
  if (!value.PhoneNumber) {
    error.PhoneNumber = "Please enter Nonprofit Phone Number";
  } else if (
    value.PhoneNumber &&
    value.PhoneNumber[0] !== "(" &&
    value?.PhoneNumber?.toString()?.length !== 10
  ) {
    error.PhoneNumber = "Please enter valid Nonprofit Phone Number";
  } else if (
    value.PhoneNumber &&
    value.PhoneNumber[0] === "(" &&
    (value.PhoneNumber.length !== 14 || value.PhoneNumber[13] === " ")
  ) {
    error.PhoneNumber = "Please enter valid Nonprofit Phone Numberr";
  }

  if (!value.CharityAddress || !value.CharityAddress.trim()) {
    error.CharityAddress = "Please enter Nonprofit Mailing  Address";
  }
  if (!value.country) {
    error.country = "Please enter country";
  }
  if (!value.state) {
    error.state = "Please select state";
  }
  if (!value.city) {
    error.city = "Please select city";
  }

  if (!value.pincode || !value.pincode.trim()) {
    error.pincode = "Please enter zipcode";
  } else if (!patterns.numeric.test(value.pincode)) {
    error.pincode = "Zipcode shoud be 5 digit";
  }
  return error;
};

export const registrationstep3 = (value) => {
  let error = {};
  if (!value.CharityWebsite || !value.CharityWebsite.trim()) {
    error.CharityWebsite = "Please enter Nonprofit Website";
  } else if (
    value.CharityWebsite &&
    !value.CharityWebsite.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    )
  ) {
    error.CharityWebsite = "Enter valid Nonprofit Website";
  }

  if (!value.CharityFilter) {
    error.CharityFilter = "Please enter Scope of Nonprofit";
  }
  if (!value.CharityContribution || !value.CharityContribution.trim()) {
    error.CharityContribution = "Please enter  Nonprofit Total Revenue";
  } else if (!patterns.numeric.test(value.CharityContribution)) {
    error.CharityContribution = "Please enter valid  Nonprofit Total Revenue";
  }

  if (!value.CharityFilter) {
    error.CharityFilter = "Please select Scope of Nonprofit";
  }

  if (!value.CharityCategory) {
    error.CharityCategory = "Please select Nonprofit Category";
  }

  if (!value.causeList) {
    error.causeList = "Please select Related Cause(s)";
  }

  if (!value.TaxId) {
    error.TaxId = "Please enter Nonprofit EIN/Tax ID";
  } else if (value.TaxId.trim().replace(/-/g, "").length !== 9) {
    error.TaxId = "Nonprofit EIN/Tax ID should be 9 digit";
  }
  if (!value.CharityFormedyear) {
    error.CharityFormedyear = "Please enter Nonprofit Formed year";
  } else if (value.CharityFormedyear < 1000) {
    error.CharityFormedyear = "Please enter valid Nonprofit Formed year";
  }
  return error;
};
export const registrationstep4 = (value) => {
  let error = {};
  if (value.radio) {
    error.radio = "Please enter radio";
  }
};
