/* eslint-disable react-hooks/exhaustive-deps */
// import React, { lazy, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import {
//   Routes,
//   Route,
//   useLocation,
//   redirect,
//   Navigate,
// } from "react-router-dom";
// import { chatNotificationCountAction, storeMetaData } from "../actions/meta";
// import { basketCountAction } from "../actions/cart";
// import Layout from "../components/layout/index";
// import AuthGuard from "../components/auth";
// import LayoutForSignin from "../components/layoutForSignin";

// const About = lazy(() => import("../pages/About"));
// const CauseDetail = lazy(() => import("../pages/CauseDetails"));
// const CauseList = lazy(() => import("../pages/CauseList"));
// const CharityDetails = lazy(() => import("../pages/Charity Details"));
// const CharityList = lazy(() => import("../pages/CharityList"));
// const FirstTimeLogin = lazy(() => import("../pages/FirstTimeLogin"));
// const Forgot_password = lazy(() => import("../pages/Forgot password"));
// const Home = lazy(() => import("../pages/Home/index"));
// const Verify = lazy(() => import("../pages/Otp Verify"));
// const Registration = lazy(() => import("../pages/Registration"));
// const CharityRegistration = lazy(() => import("../pages/CharityRegistration"));
// const ResetPassword = lazy(() => import("../pages/Reset Password"));
// const CommunityList = lazy(() => import("../pages/Community List"));
// const CommunityDetails = lazy(() => import("../pages/Community Details"));
// const EventDetails = lazy(() => import("../pages/EventDetails"));
// const ChangePassword = lazy(() => import("../pages/Change PassWord"));
// const Post = lazy(() => import("../pages/Post"));
// const RosiBasket = lazy(() => import("../pages/RosiBasket"));
// const TermsAndCondition = lazy(() => import("../pages/TermsAndCondition"));
// const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
// const EmployeeLogin = lazy(() => import("../pages/EmployeeLogin"));
// const EmployeeRegistration = lazy(() =>
//   import("../pages/EmployeeRegistration")
// );
// const StrategicDetails = lazy(() => import("../pages/strategicDetails"));
// const Notification = lazy(() => import("../pages/Notification"));
// const Faq = lazy(() => import("../pages/FAQ"));
// const ContactUs = lazy(() => import("../pages/ContactUs"));
// const GuestInSignin = lazy(() => import("../pages/GuestSignIn"));
// const Chat = lazy(() => import("../pages/Chat"));
// const UserProfile = lazy(() => import("../pages/UserProfile"));
// const PostDetails = lazy(() => import("../pages/PostDetails"));
// const DonationDetails = lazy(() => import("../pages/Donation Details"));
// const GivingPartner = lazy(() => import("../pages/Giving Partner"));
// const SignIn = lazy(() => import("../pages/SignIn"));

import React, { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { chatNotificationCountAction, storeMetaData } from "../actions/meta";
import AuthGuard from "../components/auth";
import LayoutForSignin from "../components/layoutForSignin";
import About from "../pages/About";
import CauseDetail from "../pages/CauseDetails";
import CauseList from "../pages/CauseList";
import CharityDetails from "../pages/Charity Details";
import CharityList from "../pages/CharityList";
import FirstTimeLogin from "../pages/FirstTimeLogin";
import Forgot_password from "../pages/Forgot password";
import Home from "../pages/Home/index";
import Verify from "../pages/Otp Verify";
import Registration from "../pages/Registration";
import CharityRegistration from "../pages/CharityRegistration";
import ResetPassword from "../pages/Reset Password";
import CommunityList from "../pages/Community List";
import CommunityDetails from "../pages/Community Details";
import EventDetails from "../pages/EventDetails";
import ChangePassword from "../pages/Change PassWord";
import Post from "../pages/Post";
import RosiBasket from "../pages/RosiBasket";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import EmployeeLogin from "../pages/EmployeeLogin";
import StrategicDetails from "../pages/strategicDetails";
import Notification from "../pages/Notification";
import Faq from "../pages/FAQ";
import ContactUs from "../pages/ContactUs";
import GuestInSignin from "../pages/GuestSignIn";
import UserProfile from "../pages/UserProfile";
import PostDetails from "../pages/PostDetails";
import DonationDetails from "../pages/Donation Details";
import GivingPartner from "../pages/Giving Partner";
import Chat from "../pages/Chat";

const EmployeeRegistration = lazy(() =>
  import("../pages/EmployeeRegistration")
);
const Layout = lazy(() => import("../components/layout/index"));
const SignIn = lazy(() => import("../pages/SignIn"));

const Routing = ({ notificationTrigger }) => {
  const user = JSON.parse(localStorage.getItem("webUser"));
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) dispatch(chatNotificationCountAction());
  }, [notificationTrigger]);

  useEffect(() => {
    dispatch(storeMetaData());
  }, [authUser]);

  return (
    <Routes>
      <Route path="/" element={<LayoutForSignin />}>
        <Route path="/signin" replace element={<SignIn from={location} />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgot_password" element={<Forgot_password />} />
        <Route path="/verification" element={<Verify />} />
        <Route path="/reset_password/:id" element={<ResetPassword />} />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/employee-signin" element={<EmployeeLogin />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/employee-registration/:id"
          element={<EmployeeRegistration />}
        />
        <Route path="/guest-signin" element={<GuestInSignin />} />
        <Route path="user-profile/:id" element={<UserProfile />} />
        <Route path="post-details/:id" element={<PostDetails />} />
      </Route>
      <Route
        path="/charity-registration"
        element={<CharityRegistration />}
      ></Route>
      <Route path="/" element={<Layout />}>
        <Route index exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/charity" element={<CharityList />}></Route>
        <Route path="/new-add-charity/:id" element={<CharityList />}></Route>

        <Route path="/charity_details/:id" element={<CharityDetails />} />
        <Route path="/cause" element={<CauseList />} />
        <Route path="/new-add-cause/:id" element={<CauseList />} />

        <Route path="/cause_details/:id" element={<CauseDetail />} />
        <Route path="/community/:id" element={<CommunityList />} />
        <Route
          path="/profile_details"
          element={
            <AuthGuard>
              <FirstTimeLogin />
            </AuthGuard>
          }
        />
        <Route path="/community_details/:id" element={<CommunityDetails />} />
        <Route path="/event_details/:id" element={<EventDetails />} />
        <Route path="/post" element={<Post />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/basket" element={<RosiBasket />} />
        <Route
          path="/chat"
          element={
            <AuthGuard>
              <Chat />
            </AuthGuard>
          }
        />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route
          path="/notification"
          element={
            <AuthGuard>
              <Notification />
            </AuthGuard>
          }
        />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route
          index
          path="/Giving_Partner_Details"
          element={
            <AuthGuard>
              <StrategicDetails />
            </AuthGuard>
          }
        />
        <Route path="/donationDetails/:id" element={<DonationDetails />} />
        <Route path="/giving_partner" element={<GivingPartner />}></Route>
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Routing;
