import React from "react";
import { useSelector } from "react-redux";
import { RenderImage } from ".";
import { dateFormatChat, getImageUrl } from "../../utils";

const ChatUserList = ({
  onUserClick,
  data,
  chatReducer,
  createdDate,
  history,
}) => {
  const mediaURL = useSelector((state) => state.metaReducer.mediaURL);
  const handlemodualname = (data) => {
    if (data === "charity") {
      return "charityLogo";
    } else if (data === "user") {
      return "userProfilePic";
    } else if (data === "strategic") {
      return "strategicLogo";
    } else if (data === "admin") {
      return "adminImages";
    }
  };

  return (
    <li
      onClick={() => onUserClick(data)}
      className={`${data?.lastMessage?.isRead === false ? "notReadList" : ""}`}
    >
      <div
        className={`chat-user ${
          chatReducer?.activeUser?.roomId === data?.roomId ? "active-chat" : ""
        } `}
      >
        <div className="rosiChatWrap">
          <RenderImage
            className="border-50"
            className2={data?.userDetails?.type === "admin" ? "imgClass" : ""}
            filePath={getImageUrl(
              mediaURL,
              handlemodualname(data?.userDetails?.type),
              data?.userDetails?.profileImage
            )}
          />
          <div className="chat-nm-desc">
            <strong>{data.userDetails?.name || ""}</strong>
            {data?.lastMessage?.isCleared === true ? (
              ""
            ) : (
              <p>{data?.lastMessage?.message || ""}</p>
            )}
          </div>
        </div>
        <div className="rosi-time">
          <div className="chat-time">
            {data?.lastMessage?.isCleared === true ? (
              ""
            ) : (
              <span>{dateFormatChat(data?.lastMessage?.createdDate)}</span>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatUserList;
