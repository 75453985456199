import React from "react";
import ScrollBar from "react-perfect-scrollbar";
import CharityCard from "../../CharityCard";
import "./charityFavorite.scss";

function CharityFavorite({ charityFavList }) {
  let finalCharityList = [];
  charityFavList?.map((item, ind) =>
    finalCharityList.push({
      _id: item?.charityId,
      logo: item?.logo,
      charityName: item?.charityName,
      brief_description: item?.briefDescription,
      isFavorite: true,
      isBasket: item?.isBasket,
      isNavigator: item?.isNavigator,
    })
  );
  return (
    <>
      <ScrollBar component="div">
        <div className="favoriteScroll">
          <div className="FavoriteCharity">
            {finalCharityList.length > 0 ? (
              finalCharityList?.map((charity) => (
                <CharityCard
                  charities={charity}
                  key={charity?._id}
                  from="favSection"
                />
              ))
            ) : (
              <p className="verification_error">
                You don't have any favorite nonprofit
              </p>
            )}
          </div>
        </div>
      </ScrollBar>
    </>
  );
}

export default CharityFavorite;
