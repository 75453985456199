import React from "react";

function approve() {
  return (
    <>
      <svg
        width="512"
        height="512"
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_569_734)">
          <path
            d="M240.039 19.8967C248.83 25.7257 263.215 25.7257 271.993 19.8967L295.207 4.47265C303.993 -1.36435 315.8 1.15465 321.45 10.0637L336.382 33.6126C342.032 42.5166 355.165 48.3636 365.563 46.6046L393.047 41.9476C403.448 40.1866 413.222 47.2796 414.753 57.7146L418.829 85.2477C420.375 95.6847 429.985 106.386 440.205 109.018L467.175 115.974C477.397 118.616 483.431 129.091 480.592 139.25L473.122 166.017C470.286 176.175 474.722 189.858 482.988 196.417L504.881 213.784C513.145 220.343 514.399 232.352 507.674 240.485L489.9 261.94C483.177 270.065 481.675 284.366 486.557 293.715L499.465 318.391C504.349 327.745 500.625 339.235 491.176 343.924L466.208 356.317C456.759 361.009 449.576 373.466 450.234 383.993L451.959 411.787C452.627 422.309 444.535 431.297 433.99 431.747L406.158 432.925C395.621 433.376 383.991 441.834 380.307 451.726L370.625 477.81C366.941 487.704 355.908 492.625 346.1 488.744L320.17 478.494C310.368 474.61 296.301 477.598 288.918 485.137L269.444 505.028C262.058 512.562 249.983 512.562 242.592 505.038L223.087 485.134C215.699 477.602 201.632 474.615 191.824 478.496L165.907 488.746C156.1 492.63 145.066 487.709 141.39 477.812L131.685 451.728C128.006 441.839 116.376 433.378 105.834 432.927L78.0118 431.749C67.4668 431.298 59.3848 422.31 60.0428 411.789L61.7838 383.995C62.4388 373.471 55.2458 361.011 45.7988 356.319L20.8388 343.926C11.3928 339.236 7.65781 327.749 12.5448 318.393L25.4528 293.717C30.3368 284.368 28.8348 270.068 22.1018 261.95L4.32682 240.467C-2.40119 232.347 -1.14118 220.338 7.11981 213.779L29.0178 196.412C37.2788 189.853 41.7208 176.178 38.8868 166.015L31.3988 139.237C28.5628 129.079 34.5988 118.611 44.8108 115.985L71.8008 109.014C82.0128 106.385 91.6358 95.6837 93.1768 85.2467L97.2498 57.7136C98.7938 47.2786 108.563 40.1856 118.961 41.9466L146.448 46.6036C156.849 48.3626 169.98 42.5156 175.622 33.6116L190.547 10.0627C196.189 1.15365 208.004 -1.36535 216.797 4.47165L240.039 19.8967Z"
            fill="#6DAF5F"
          />
          <path
            d="M421.65 256.28C421.65 347.767 347.479 421.93 256 421.93C164.526 421.93 90.3555 347.767 90.3555 256.28C90.3555 164.821 164.523 90.6562 256 90.6562C347.479 90.6562 421.65 164.821 421.65 256.28Z"
            fill="#6DAF5F"
          />
          <path
            d="M228.382 333.661L162.078 267.354L184.168 245.254L228.382 289.46L327.833 189.996L349.923 212.104L228.382 333.661Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_569_734">
            <rect width="512" height="512" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default approve;
