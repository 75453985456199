import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  communityPostListAction,
  createPostAction,
  getUserDetailsAction,
  joinCommunityPostListAction,
  postListAction,
} from "../../../actions/post";
import { employeePostAction } from "../../../actions/strategic";
import { toAbsoluteUrl } from "../../../utils";
import "./postInput.scss";

function PostInput({
  setEditIsOpen,
  setPostType,
  communityId,
  isMyPost,
  from,
  setUserDetails,
  setArgs,
}) {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const profilePic = JSON.parse(localStorage.getItem("profilePic"));
  const employeeId = JSON.parse(localStorage.getItem("employeeId"));
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [active, setActive] = useState("public");
  const [inputText, setInputText] = useState("");

  const openModal = () => {
    setEditIsOpen((prev) => !prev);
  };

  const photoClickHandler = () => {
    openModal();
    setPostType("photo");
  };

  const videoClickHandler = () => {
    openModal();
    setPostType("video");
  };

  const articleClickHandler = () => {
    openModal();
    setPostType("article");
  };

  const addPostHandler = () => {
    if (!inputText || !inputText.trim()) {
      toast.error("Please enter something");
      return;
    }
    let data = {
      type: 3,
      userId,
      description: inputText,
      isPrivate: active === "public" ? false : true,
    };

    if (communityId) {
      data = { ...data, communityId };
    }
    if (from === "employeePost") {
      data = {
        ...data,
        strategicPartnerId: employeeId,
        employeeId: userId,
        createdBy: 5,
      };
    }
    if (from === "postModule") {
      dispatch(getUserDetailsAction(userId)).then((res) => {
        setUserDetails(res.data);
      });
    }
    dispatch(createPostAction(data))
      .then((res) => {
        if (res.statusCode === 400) {
          toast.error(res.message);
        }
        setArgs();
        if (communityId) {
          dispatch(
            communityPostListAction(
              communityId,
              { page: 1, limit: 20 },
              isMyPost,
              true
            )
          );
        } else if (from === "employeePost") {
          const payload = {
            page: 1,
            limit: 20,
          };
          if (isMyPost) payload.isEmployee = "true";
          dispatch(
            employeePostAction(payload, employeeId, isMyPost, false, false)
          );
        } else {
          if (isMyPost === undefined) {
            dispatch(
              joinCommunityPostListAction(
                { _id: userId, page: 1, limit: 20 },
                false,
                false
              )
            );
          }
          dispatch(
            postListAction({ page: 1, limit: 20 }, isMyPost, false, false)
          );
        }
        setInputText("");
      })
      .catch((err) => toast.error(err.message));
  };

  const radioButtonHandler = (e) => {
    setActive(e.target.name);
  };

  return (
    <div className="postCreationCard">
      <div className="postInfo">
        <div className="postImage">
          <figure className="imageBorder">
            {profilePic && profilePic !== "null" ? (
              <img src={mediaURL + "userProfilePic/" + profilePic} alt="user" />
            ) : (
              <img
                src={toAbsoluteUrl("/images/placeholder.png")}
                alt="placeholder"
              />
            )}
          </figure>
        </div>
        <div className="postFormGroup">
          <form>
            {/* <input type="text" placeholder="Write something ..." /> */}
            <textarea
              value={inputText}
              placeholder="Write something ..."
              onChange={(e) => setInputText(e.target.value)}
            />
          </form>
        </div>
      </div>
      {!from && (
        <ul className="post-radio">
          <li>
            <div className="forgot">
              <div className="remember">
                <input
                  className="check"
                  type="checkbox"
                  id="public"
                  value="public"
                  name="public"
                  checked={active === "public"}
                  onChange={radioButtonHandler}
                />
                <label htmlFor="public">Public Post</label>
              </div>
            </div>
          </li>
          <li>
            <div className="forgot">
              <div className="remember">
                <input
                  className="check"
                  type="checkbox"
                  id="private"
                  value="private"
                  name="private"
                  checked={active === "private"}
                  onChange={radioButtonHandler}
                />
                <label htmlFor="private">Private Post</label>
              </div>
            </div>
          </li>
        </ul>
      )}
      <div className="postFooter">
        <ul>
          <li onClick={articleClickHandler}>
            <img
              src={toAbsoluteUrl("/images/uploadDesc.svg")}
              alt="photo_pic"
            />
            <span>Post Only</span>
          </li>
          <li onClick={photoClickHandler}>
            <img
              src={toAbsoluteUrl("/images/uploadImage.svg")}
              alt="image_pic"
            />
            <span>Photo</span>
          </li>
          <li onClick={videoClickHandler}>
            <img
              src={toAbsoluteUrl("/images/uploadVideo.svg")}
              alt="photo_pic"
            />
            <span>Video</span>
          </li>
        </ul>
        <div className="messageSend">
          <button onClick={addPostHandler}>
            <img
              src={toAbsoluteUrl("/images/uploadMessage.svg")}
              alt="photo_pic"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostInput;
