import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { doChangePass } from "../../actions/auth";
import Lock from "../../assets/svg/lock";
import CardSignin from "../../components/common/Card_signin";
import { renderTextInput } from "../../components/common/FormFields";
import Swiper from "../../components/common/Swiper";
import SwiperText from "../../components/common/SwiperText";
import "./../SignIn/signin.scss";
import "./changePassword.scss";
import { changePasswordValidation as validate } from "../../components/validations/signIn";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("webUser"));

  const [error, setError] = useState();
  const formSubmitHandler = (value) => {
    const id = user?.data?._id;

    const data = {
      currentPassword: value?.currPassword,
      newPassword: value?.newPassword,
      id,
    };
    dispatch(doChangePass(data))
      .then((res) => {
        navigate("/signin");
        toast.success(res?.message)
      })
      .catch((err) => setError(err.message));
  };

  return (
    <>
      <section className="signin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Change Password">
              <Form onSubmit={formSubmitHandler} validate={validate}>
                {({ handleSubmit }) => (
                  <>
                    <form className="form" onSubmit={handleSubmit}>
                      <ul>
                        <li>
                          <Field
                            name="currPassword"
                            type="password"
                            component={renderTextInput}
                            placeholder="Current Password"
                            containerClasses="form-group form-password"
                          >
                            <Lock />
                          </Field>
                        </li>
                        <li>
                          <Field
                            name="newPassword"
                            type="password"
                            component={renderTextInput}
                            placeholder="New Password"
                            containerClasses="form-group form-password"
                          >
                            <Lock />
                          </Field>
                        </li>
                        <li>
                          <Field
                            name="confirmPassword"
                            type="password"
                            component={renderTextInput}
                            placeholder="Confirm Password"
                            containerClasses="form-group form-password"
                          >
                            <Lock />
                          </Field>
                        </li>
                        {error && (
                          <h4
                            className="verification_error"
                            style={{ marginTop: "10px" }}
                          >
                            {error}
                          </h4>
                        )}
                        <li>
                          <div className="forgot changeCheck">
                            {/* <div className="remember">
                              <input
                                className="check"
                                type="checkbox"
                                id="rem"
                                checked={isRemembered}
                                onClick={() => setIsRemembered((prev) => !prev)}
                              />
                              <label htmlFor="rem">Remember Me</label>
                            </div> */}
                          </div>
                        </li>
                        <li className="changeButton">
                          <button>Change Password</button>
                        </li>
                      </ul>
                    </form>
                  </>
                )}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
