import { combineReducers } from "redux";

import api from "./api";
import auth from "./auth";
import charity from "./charity";
import metaReducer from "./meta";
import cause from "./cause";
import home from "./home";
import about from "./about";
import charityRegister from "./charityRegister";
import user from "./user";
import community from "./community";
import favourite from "./favourite";
import event from "./event";
import post from "./post";
import chatReducer from "./chat";
import cmsReducer from "./cms";
import faqReducer from "./faq";
import strategic from "./strategic";
import givingPartners from "./givingPartner";
import NotifictionReducer from './notification'

const rootReducer = combineReducers({
  api,
  auth,
  charity,
  cause,
  home,
  about,
  charityRegister,
  metaReducer,
  user,
  community,
  favourite,
  event,
  post,
  chat: chatReducer,
  cmsReducer,
  faqReducer,
  strategic,
  givingPartners,
  NotifictionReducer,
});

export default rootReducer;
