import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let isLoggedIn = false;

  const token = JSON.parse(localStorage.getItem("webUser"));
  if (token) {
    isLoggedIn = true;
  }

  useEffect(() => {
    if (!isLoggedIn) navigate("/signin", { replace: true });
  }, [isLoggedIn, navigate]);
  useEffect(() => {
    if (
      token?.data?.userType === 1 &&
      location?.pathname === "/Giving_Partner_Details"
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

export default AuthGuard;
