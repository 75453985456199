import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import ImgUpload from "../../components/common/img-upload";
import {
  renderCityField,
  renderField,
  renderNumberField,
  renderSelectField,
  rendertextarea,
} from "../../components/forms";
import Tookit from "../../components/svg/tookit";
import { ADD_CHARITY_DETAILS_STEPS_2 } from "../../constants/types";
import "../../pages/CharityRegistration/registration.scss";
import { registrationstep2 as validate } from "../../components/validations/charityRegister";
import {
  addTOCity,
  addTOCountry,
  addTOState,
} from "../../actions/charityRegister";
import localforage from "localforage";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";


const Steps2 = ({ setSteps, step, completedSteps, setCompletedSteps }) => {
  const dispatch = useDispatch();
  const [step2Data, setStep2Data] = useState("");

  useEffect(() => {
    localforage.getItem("charity_reg_step_2").then((res) => setStep2Data(res));
  }, []);

  const [charityImages, setCharityImages] = useState();
  const [oldCharityImages, setOldCharityImages] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const [charityLogo, setCharityLogo] = useState();
  const [oldCharityLogo, setOldCharityLogo] = useState([]);
  const [logo, setLogo] = useState();
  const [logoerror, setLogoerror] = useState("");

  const [images, setImages] = useState([]);
  const [imageserror, setImageerror] = useState("");
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",
    phone_code: "1",
  });
  const [selectedState, setselectedState] = useState();
  const [localLogo, setLocalLogo] = useState();
  const [localImages, setLocalImages] = useState([]);

  useEffect(() => {
    // setLocalLogo(JSON.parse(localStorage.getItem("charity_reg_step_2"))?.logo)
    // setLocalImages(JSON.parse(localStorage.getItem("charity_reg_step_2"))?.mediaImage)
    localforage.getItem("charity_reg_step_2").then((res) => {
      setLocalLogo(res?.logo);
      setLocalImages(res?.mediaImage);
    });
  }, []);

  useEffect(() => {
    setOldCharityLogo(step2Data?.charitylogo ? step2Data?.charitylogo : []);
    // eslint-disable-next-line
  }, [step2Data?.logo]);

  useEffect(() => {
    setOldCharityImages(
      step2Data?.charityimages ? step2Data?.charityimages : []
    );
    // eslint-disable-next-line
  }, [step2Data?.mediaImage]);

  useEffect(() => {
    if (logo) {
      setLogoerror("");
    }
  }, [logo]);

  useEffect(() => {
    if (images) {
      setImageerror("");
    }
  }, [images]);

  const onSubmit = (value) => {
    localforage.setItem("curr_reg_step", 1);
    if (!charityLogo?.length && !oldCharityLogo?.length) {
      setLogoerror("Please upload  Nonprofit Logo Image");
      return;
    } else {
      setLogoerror("");
    }
    const data = {
      description: value.CharityDescription,
      brief_description: value.CharityBriefDescription,
      phoneno: Number(
        `${value?.PhoneNumber?.toString()?.replace(/[^\d]/g, "")}`
      ),
      tagline: value.CharityTagLine,
      logo: logo ? logo : localLogo,
      mediaImage: images?.length > 0 ? images : localImages,
      charitylogo: oldCharityLogo?.length ? oldCharityLogo : charityLogo,
      charityimages: oldCharityImages?.length
        ? oldCharityImages
        : charityImages,
      countryCode: value?.country?.phone_code,
      address: {
        floor: value.CharityAddress,
        number: value.CharityAddress,
        street: value.CharityAddress,
        street2: value.CharityAddress2,
        city: value?.city,
        state: value.state,
        zipcode: value.pincode,
        country: value.country,
      },
    };
    dispatch({
      type: ADD_CHARITY_DETAILS_STEPS_2,
      payload: data,
    });
    const newCompleted = completedSteps;
    newCompleted[1] = true;
    setCompletedSteps(newCompleted);
    setSteps(2);
  };
  const prev = () => {
    setSteps(0);
  };

  const fetchCountry = (id) => {
    dispatch(addTOCountry()).then((res) => {
      const data = res.data;
      let countryArr = [];
      data.map((val) =>
        countryArr.push({
          value: val.id,
          label: val.name,
          phone_code: val.phone_code,
        })
      );
      setCountry(countryArr);
    });
  };

  const fetchState = (data) => {
    dispatch(addTOState(data)).then((res) => {
      const data = res.data;
      let stateArr = [];
      data.map((val) => stateArr.push({ value: val.id, label: val.name }));
      setState(stateArr);
    });
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data)).then((res) => {
      const data = res.data;
      let cityArr = [];
      data.map((val) => cityArr.push({ value: val.id, label: val.name }));
      setCity(cityArr);
    });
  };
  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    fetchCity({ stateId: selectedState?.value });
    // eslint-disable-next-line
  }, [selectedState]);
  return (
    <>
      <div className="section-steps">
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 col-box">
            <Form
              mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                CharityDescription: step2Data?.description || "",
                CharityBriefDescription: step2Data?.brief_description || "",
                PhoneNumber: step2Data?.phoneno || "",
                CharityTagLine: step2Data?.tagline || "",
                CharityAddress: step2Data?.address?.floor || "",
                country: step2Data?.address?.country || selectedCountry || "",
                city: step2Data?.address?.city || "",
                state: step2Data?.address?.state || "",
                pincode: step2Data?.address?.zipcode || "",
              }}
            >
              {({ handleSubmit, form, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className="form-box step-form-box"
                >
                  <div className=" d-flex flex-wrap w-100">
                    <div className="col-md-6  col-12 col-box">
                      <ul className="input-list">
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Brief Description{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="briefDesc"><Tookit /></span>
                            <ReactTooltip
                              id="briefDesc"
                              place="top"
                              variant="success"
                              content="This should be a limited description of the nonprofit’s reason for being, mission, mission statement, communities served, etc. It is limited to 150 characters (including spaces). This will be displayed under the heading “Explore Your Nonprofit” and will be displayed alongside of other nonprofits brief descriptions. It is often difficult to put in few words, so abbreviations should be used as well as an “&” instead of “and”, etc."
                            />
                          </label>
                          {/* <Field
                            name="CharityBriefDescription"
                            type="text"
                            placeholder="Charity Brief Description"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          /> */}
                          <Field
                            name="CharityBriefDescription"
                            component={rendertextarea}
                            placeholder="Enter a brief description of your nonprofit here"
                            className="form-grp textarea"
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Full Description{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="fullDesc"><Tookit /></span>
                            <ReactTooltip
                              id="fullDesc"
                              place="top"
                              variant="success"
                              content="This is a more robust description of the nonprofit’s reason for being, mission, mission statement, communities served, etc. There is no character limit, however three to four paragraphs of reasonable length should be sufficient. If your nonprofit offers a lot of programs or is involved in a number of projects, we suggest establishing “Communities” for each program or project where fuller descriptions can be provided."
                            />
                          </label>
                          {/* <Field
                            name="CharityDescription"
                            type="text"
                            placeholder="Charity Description"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          /> */}
                          <Field
                            name="CharityDescription"
                            component={rendertextarea}
                            placeholder="Enter a full description of your nonprofit here"
                            className="form-grp textarea"
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            {" "}
                            Nonprofit Tagline{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="tagLine"><Tookit /></span>
                            <ReactTooltip
                              id="tagLine"
                              place="top"
                              variant="success"
                              content="This information is optional. The tagline is generally a short, easily remembered phrase that your nonprofit uses in its advertisements or with its supporters, especially on television or the internet, so that people will recognize the nonprofit and its reason for being."
                            />
                          </label>
                          {/* <Field
                            name="CharityTagLine"
                            type="text"
                            placeholder="Charity Tagline"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          /> */}
                          <Field
                            name="CharityTagLine"
                            component={rendertextarea}
                            placeholder="Enter your nonprofit Tagline is here; it is optional,but recommended"
                            className="form-grp textarea"
                          />
                        </li>
                        <li>
                          <label className="label-text">
                            Nonprofit Logo Image{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="logoImg"><Tookit /></span>
                            <ReactTooltip
                              id="logoImg"
                              place="top"
                              variant="success"
                              content="You must attach any logo image to your Nonprofit’s profile to presented anywhere your Nonprofit is displayed of the Rosi platform. Please be mindful as to the format of your logo and that a square image will generally present better than a rectangle or circular/oval image."
                            />
                          </label>
                          <ImgUpload
                            multiple={false}
                            setImg={setLogo}
                            setImages={setCharityLogo}
                            oldImages={oldCharityLogo}
                            setOldImages={(images) => setOldCharityLogo(images)}
                            images={charityLogo}
                            moduleName="charityLogo"
                          />
                          <h6 className="text-danger">{logoerror}</h6>
                        </li>
                        <li>
                          <label className="label-text">
                            Nonprofit other Images{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="otherImage"><Tookit /></span>
                            <ReactTooltip
                              id="otherImage"
                              place="top"
                              variant="success"
                              content="This is optional information and the same parameters for the Logo image apply."
                            />
                          </label>
                          <ImgUpload
                            multiple={true}
                            oldImages={oldCharityImages}
                            setImg={setImages}
                            setOldImages={(images) =>
                              setOldCharityImages(images)
                            }
                            setImages={setCharityImages}
                            setDeleteImage={(image) =>
                              setDeleteImage([...deleteImage, image])
                            }
                            images={charityImages}
                            maxNumber={5 - oldCharityImages?.length}
                            moduleName="charityImages"
                          />
                          <h6 className="text-danger">{imageserror}</h6>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6  col-12 col-box">
                      <ul className="input-list">
                        <li>
                          <label className="label-text">
                            <span>*</span> Nonprofit Phone Number{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="phoneNo"><Tookit /></span>
                            <ReactTooltip
                              id="phoneNo"
                              place="top"
                              variant="success"
                              content="This should be the phone number of the Nonprofit or the Nonprofit Representative. It can be the mobile number as that of an individual Rosi account user."
                            />
                          </label>
                          <Field
                            name="PhoneNumber"
                            // type="text"
                            placeholder="Phone Number of the nonprofit or its represenative here"
                            component={renderNumberField}
                            formatStyle={"(###) ###-####"}
                            className="form-grp formGrp"
                            inputclass="input-box"
                          >
                            <div className="inputAddons">
                              <span>+1</span>
                            </div>
                          </Field>
                        </li>
                        <li className="nonprofileAddress">
                          <label className="label-text">
                            {" "}
                            <span>*</span> Nonprofit Mailing Address{" "}
                            <span className="tooltip-information-wrap" data-tooltip-id="mailAddress"><Tookit /></span>
                            <ReactTooltip
                              id="mailAddress"
                              place="top"
                              variant="success"
                              content="The must be the place where your Nonprofit receives its US Postal Service mail (it can include be a PO Box number). It should coincide with public records and will be the place where money granted to your Nonprofit will receive check payments, if that is the payment method chosen or if other methods of payment are not successful."
                            />
                          </label>
                          <Field
                            name="CharityAddress"
                            type="text"
                            placeholder="Nonprofit Mailing  Address - Line 1"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          />
                          <Field
                            name="CharityAddress2"
                            type="text"
                            placeholder="Nonprofit Mailing  Address - Line 2"
                            component={renderField}
                            className="form-grp nonAddress"
                            inputclass="input-box"
                          />
                          <div className="inner-form-list">
                            <Field
                              name="country"
                              placeholder="Country"
                              isCountryField={true}
                              setselectedCountry={setselectedCountry}
                              component={renderSelectField}
                              mutator={form.mutators.setValue}
                              className="form-grp col-grp formGrp dropdownGrp"
                              inputclass="input-box slectStep"
                              options={country}
                              disabled={true}
                              isMulti={false}
                              style={{ border: "none" }}
                            />
                            <Field
                              name="pincode"
                              // type="number"
                              placeholder="Zip code"
                              component={renderNumberField}
                              formatStyle={"#####"}
                              className="form-grp col-grp"
                              inputclass="input-box"
                            />
                          </div>
                          <div className="inner-form-list">
                            <Field
                              name="state"
                              type="text"
                              isStateField={true}
                              setselectedState={setselectedState}
                              placeholder="State"
                              component={renderSelectField}
                              mutator={form.mutators.setValue}
                              className="form-grp col-grp formGrp marginClass dropdownGrp"
                              // inputclass="input-box"
                              options={state}
                              isMulti={false}
                              style={{ border: "none", background: "none" }}
                            />

                            <Field
                              name="city"
                              type="text"
                              placeholder="City"
                              component={renderCityField}
                              className="form-grp col-grp formGrp dropdownGrp"
                              inputclass="input-box"
                              isMulti={false}
                              options={city}
                              style={{ border: "none", background: "none" }}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between w-100">
                      <div className="button-box">
                        <button
                          type="button"
                          className="link-btn"
                          onClick={prev}
                        >
                          prev
                        </button>
                      </div>
                      <div className="button-box">
                        <button type="submit" className="link-btn">
                          next
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps2;
