import {
  API_CHARITY_COMMUNITY_LIST,
  API_CHARITY_DETAILS,
  API_CHARITY_LIST,
  API_FETCHNOTIFICATION_LIST,
} from "../constants/api";
import {
  API,
  RENDER_CHARITY_LIST_F,
  RENDER_CHARITY_LIST_S,
  CHARITY_DETAILS_S,
  CHARITY_DETAILS_F,
  CHARITY_COMMUNITY_LIST_S,
  CHARITY_COMMUNITY_LIST_F,
} from "../constants/types";

export const renderCharityList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHARITY_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_CHARITY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_CHARITY_LIST_F,
      payload: data,
    }),
  },
});

export const newCharityList = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_FETCHNOTIFICATION_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: RENDER_CHARITY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: RENDER_CHARITY_LIST_F,
      payload: data,
    }),
  },
});

export const renderCharityDetails = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHARITY_DETAILS,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: CHARITY_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_DETAILS_F,
      payload: data,
    }),
  },
});

export const renderCharityCommunity = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHARITY_COMMUNITY_LIST,
    method: "POST",
    data: data,
    showLoader,
    success: (data) => ({
      type: CHARITY_COMMUNITY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_COMMUNITY_LIST_F,
      payload: data,
    }),
  },
});
