import {
  COMMUNITY_POST_LIST_F,
  COMMUNITY_POST_LIST_S,
  EMPLOYEE_POST_LIST_S,
  EMPLOYEE_POST_LIST_F,
  COMMUNITY_POST_COMMENT_LIST_S,
  COMMUNITY_POST_COMMENT_LIST_F,
  POST_LIST_S,
  POST_LIST_F,
  POST_DETAILS_F,
  POST_DETAILS_S,
  USER_DETAILS_S,
  USER_DETAILS_F,
  JOIN_COMMUNITY_POST_LIST_S,
  JOIN_COMMUNITY_POST_LIST_F,
} from "../constants/types";

const initialState = {
  loading: true,
  message: undefined,
  error: undefined,
  commuityPostList: {},
  employeePostList: [],
  communityCommentList: [],
  postList: {},
  joinCommunityPostList: {},
  postDetails: {},
  userPostDetails: {},
};

const postReducer = (state = initialState, action) => {
  let payload = {};
  switch (action.type) {
    case COMMUNITY_POST_LIST_S:
      payload = action.payload.data;
      if (Object.keys(state.commuityPostList).length && !action.payload.reset) {
        payload = {
          ...state.commuityPostList,
          communityPostList: [
            ...state.commuityPostList?.communityPostList,
            ...action.payload.data?.communityPostList,
          ],
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload?.message,
        commuityPostList: payload,
      };
    case COMMUNITY_POST_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        commuityPostList: {},
      };
    case COMMUNITY_POST_COMMENT_LIST_S:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        communityCommentList: action.payload.data,
      };
    case COMMUNITY_POST_COMMENT_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        communityCommentList: {},
      };
    case EMPLOYEE_POST_LIST_S:
      payload = action.payload.data;
      if (Object.keys(state.employeePostList).length && !action.payload.reset) {
        payload = {
          ...state.employeePostList,
          employeePostList: [
            ...state.employeePostList?.employeePostList,
            ...action.payload.data?.employeePostList,
          ],
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.message,
        employeePostList: payload,
      };
    case EMPLOYEE_POST_LIST_F:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        message: action.payload.message,
        employeePostList: {},
      };
    case POST_LIST_S: {
      payload = action.payload.data;
      if (Object.keys(state.postList).length && !action.payload.reset) {
        payload = {
          ...state.postList,
          PostList: [
            ...state.postList?.PostList,
            ...action.payload.data?.PostList,
          ],
        };
      }
      return {
        ...state,
        postList: payload,
      };
    }
    case POST_LIST_F:
      return {
        ...state,
        postList: {},
      };
    case JOIN_COMMUNITY_POST_LIST_S: {
      let payload = action.payload.data;
      if (
        Object.keys(state.joinCommunityPostList).length &&
        !action.payload.reset
      ) {
        payload = {
          ...state.joinCommunityPostList,
          communityUserPostList: [
            ...state.joinCommunityPostList?.communityUserPostList,
            ...action.payload?.data?.communityUserPostList,
          ],
        };
      }
      return {
        ...state,
        joinCommunityPostList: payload,
      };
    }
    case JOIN_COMMUNITY_POST_LIST_F:
      return {
        ...state,
        joinCommunityPostList: {},
      };
    case POST_DETAILS_S:
      return {
        ...state,
        postDetails: action.payload.data,
      };
    case POST_DETAILS_F:
      return {
        ...state,
        postDetails: {},
      };
    case USER_DETAILS_S:
      return {
        ...state,
        userPostDetails: action.payload.data,
      };
    case USER_DETAILS_F:
      return {
        ...state,
        userPostDetails: {},
      };
    default:
      return state;
  }
};

export default postReducer;
