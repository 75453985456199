import React from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

import "./tabs.scss";

function CustomTab({ data, className, defaultIndex, from }) {
  const navigate = useNavigate();

  const onTabChangeHandler = (e, ind) => {
    if (from === "community") {
      navigate(`/community/${ind + 1}`);
    }
  };
  return (
    <>
      <Tabs defaultIndex={defaultIndex} className={className}>
        <TabList>
          {data.map(({ heading }, i) => (
            <Tab key={heading} onClick={(e) => onTabChangeHandler(e, i)}>
              {" "}
              {heading}
            </Tab>
          ))}
        </TabList>

        {data.map(({ body, heading }, i) => (
          <TabPanel key={heading}>{body}</TabPanel>
        ))}
      </Tabs>
    </>
  );
}

export default CustomTab;
