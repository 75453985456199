import React, { useState } from "react";
import Swiper from "../../components/common/Swiper";
import SwiperText from "../../components/common/SwiperText";
import CardSignin from "../../components/common/Card_signin";
import { Field, Form } from "react-final-form";
import User from "../../assets/svg/User";
import {
  renderNumberInput,
  renderTextInput,
} from "../../components/common/FormFields";
import Email from "../../assets/svg/email";
import Mobile from "../../assets/svg/Mobile";
import { Link, useNavigate } from "react-router-dom";
import { createGuestUserAccount as validate } from "../../components/validations/signIn";
import "./guestSignin.scss";
import { useDispatch } from "react-redux";
import { doRegister } from "../../actions/auth";
import { LOGIN_S } from "../../constants/types";

function GuestInSignin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const onFormSubmit = (data) => {
    const payload = {
      ...data,
      userType: 3,
      countryCode: "+1",
      phoneNumber: `${
        data.phoneNumber ? data.phoneNumber?.replace(/[^\d]/g, "") : ""
      }`,
    };
    dispatch(doRegister(payload))
      .then((res) => {
        dispatch({ type: LOGIN_S, payload: res });
        navigate("/");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <section className="signin guestSignin">
        <div className="container-main">
          <div className="signin-wrapper">
            <div className="signinSwiper">
              <Swiper />
              <SwiperText />
            </div>
            <CardSignin title="Sign In As Guest">
              <Form onSubmit={onFormSubmit} validate={validate}>
                {({ handleSubmit }) => (
                  <>
                    <form className="form" onSubmit={handleSubmit}>
                      <ul>
                        <li className="main-input">
                          <Field
                            name="firstName"
                            type="text"
                            component={renderTextInput}
                            placeholder="First Name"
                            containerClasses="form-group"
                          >
                            <User />
                          </Field>
                        </li>
                        <li className="main-input">
                          <Field
                            name="lastName"
                            type="text"
                            component={renderTextInput}
                            placeholder="Last Name"
                            containerClasses="form-group"
                          >
                            <User />
                          </Field>
                        </li>
                        <li className="main-input">
                          <Field
                            name="email"
                            type="email"
                            component={renderTextInput}
                            placeholder="Email Address"
                            containerClasses="form-group"
                          >
                            <Email />
                          </Field>
                        </li>
                        <li className="main-input">
                          <Field
                            name="phoneNumber"
                            type="number"
                            component={renderNumberInput}
                            placeholder="Phone Number"
                            containerClasses="form-group"
                          >
                            <Mobile />
                          </Field>
                        </li>
                      </ul>
                      {error && (
                        <h4 style={{ color: "red", margin: "10px" }}>
                          {error}
                        </h4>
                      )}
                      <ul>
                        <li className="sign-btn">
                          <button className="buttonSubmit" type="submit">
                            Sign In As Guest User
                          </button>
                        </li>
                        <li className="login-forgot">
                          <Link to="/signin">Back to Login</Link>
                        </li>
                      </ul>
                    </form>
                  </>
                )}
              </Form>
            </CardSignin>
          </div>
        </div>
      </section>
    </>
  );
}

export default GuestInSignin;
