import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumbs.scss";

function Breadcrumbs(props) {
  const { list } = props;
  return (
    <>
      <ul className="bread-list">
        <li className="home">
          <Link to="/">
            <h4>Home {">"}</h4>
          </Link>
        </li>
        {list?.map((item, ind) => (
          <li key={item._id}>
            {!item.isActive ? (
              <Link to={item.link}>
                <h4 className="crumbs">
                  {item.name} {">"}
                </h4>
              </Link>
            ) : (
              <h4 className="crumb">{item.name}</h4>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

export default Breadcrumbs;
